import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={296.62}
    height={148}
    viewBox="0 0 78.481 39.158"
    {...props}
  >
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="a">
        <path d="M0 0h450v868.5H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="b">
        <path d="M0 0h450v868.5H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="c">
        <path d="M116 79h217v112H116Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="d">
        <path d="M116.492 79.508h216v111h-216z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="e">
        <path d="M0 0h450v869H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="f">
        <path d="M0 0h450v868.5H0Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="matrix(.35278 0 0 -.35278 -39.43 67.207)">
      <path
        className={"arrowHead"}
        d="m334.233 135.317-22 38h-20l22-38-22-38h20z"
        style={{
          fill: props.isMobile ? "#d4155b" : "#cacaca",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g className="contentArrow" style={{ opacity: props.isMobile ? 1 : '' }}>
    <g clipPath="url(#b)" transform="matrix(.35278 0 0 -.35278 -39.43 67.207)">
      <g clipPath="url(#c)" opacity={0.8}>
        <g clipPath="url(#d)">
          <path
            d="M133.731 97.317h159l22 38-22 38h-159z"
            style={{
              fill: "#f0f0f0",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#e)" transform="matrix(.35278 0 0 -.35278 -39.43 67.207)">
      <text
        xmlSpace="preserve"
        transform="matrix(1 0 0 -1 170.992 126.544)"
        style={{
          fontVariant: "normal",
          fontWeight: 500,
          fontSize: 22,
          writingMode: "lr-tb",
          fill: "#000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      >
          {props.navLabel}
      </text>
    </g>
    <g clipPath="url(#f)" transform="matrix(.35278 0 0 -.35278 -39.43 67.207)">
      <path
        d="M142.057 156.016a22.076 22.076 0 0 1-8.24 1.564c-11.231 0-20.532-8.424-21.866-19.287-.092-.92-.183-1.795-.183-2.716 0-12.152 9.85-22.049 22.049-22.049 12.153 0 22.05 9.85 22.05 22.05 0 9.253-5.754 17.171-13.81 20.438z"
        style={{
          fill: "#d4155b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>

    <g
    >
      { props.navIcon }
    </g>
    </g>
  </svg>
)

export default SvgComponent
