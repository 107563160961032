import React, { useState, useEffect  } from 'react'
import { useNavigate } from 'react-router-dom';
import Venn1 from '../assets/SVGRs/Venn1.js';
import Venn2 from '../assets/SVGRs/Venn2.js';
import Venn3 from '../assets/SVGRs/Venn3.js';
import CP1 from '../assets/SVGRs/CP1.js';
import PieR from '../assets/SVGRs/Pies';
import ArrowLeft from '../assets/SVGRs/ArrowLeft';
import ArrowRight from '../assets/SVGRs/ArrowRight';
import { translationMap, getTranslationMap } from '../features/translationMap';
import iconEnforcement from '../assets/Icon-Enforcement.svg';
import iconLicensing from '../assets/Icon-Licensing.svg';
import iconUsing from '../assets/Icon-Using.svg';
import iconProtect from '../assets/Icon-Protect.svg';
import iconCopyright from '../assets/copyright.png';
import ModalQuicklinks from './ModalQuicklinks';
import ModalHistory from './ModalHistory';
import ModalLeaf from './ModalLeaf';

import { Accordion, AccordionItem, Button, Modal } from '@carbon/react';
import { Add } from '@carbon/react/icons';
import { Routes, Route } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks';
import { setVennState }  from '../features/counter/vennState-slice';
import { setSelectedModal }   from '../features/counter/selectedModal-slice';


const navArrowMap = {
    "" : {
        left: undefined,
        right: {
             key: "m_protection",
             icon: iconProtect,
             label: "Protection"
        },
    },
    "m_protection" : {
        left: {
              key: "",
              icon: iconCopyright,
              label: "Home"
        },
        right: {
             key: "m_use",
             icon: iconUsing,
             label: "Use"
        },
    },
    "m_use" : {
        left: {
                key: "m_protection",
                icon: iconProtect,
                label: "Protection"
          },
        right: {
                    key: "m_exploitation",
                    icon: iconLicensing,
                    label: "Exploitation"
               },
        icon: iconLicensing
    },
    "m_use--with-permission" : {
        left: {
                key: "m_protection",
                icon: iconProtect,
                label: "Protection"
          },
        right: {
                    key: "m_exploitation",
                    icon: iconLicensing,
                    label: "Exploitation"
               },
        icon: iconLicensing
    },
    "m_use--without-permission" : {
        left: {
                key: "m_protection",
                icon: iconProtect,
                label: "Protection"
          },
        right: {
                    key: "m_exploitation",
                    icon: iconLicensing,
                    label: "Exploitation"
               },
        icon: iconLicensing
    },
    "m_exploitation" : {
        left: {
              key: "m_use",
              icon: iconUsing,
              label: "Use"
        },
        right: {
             key: "m_enforcement",
             icon: iconEnforcement,
             label: "Enforcement"
        }
    },
    "m_enforcement" : {
        left: {
            key: "m_exploitation",
            icon: iconLicensing,
            label: "Exploitation"
        },
        right: undefined
    },
}



export default function Home (props) {
 const { isMobile, selectedTrans } = props;
 const {value, previousValue, previousPreviousValue} = useAppSelector((state) => state.selectedModal);
 const selectedModal = value;
 const prevSelectedModal = previousValue;
 const previousPreviousValueModal = previousPreviousValue;
 const vennState = useAppSelector((state) => state.vennState.value);
 const dispatch = useAppDispatch();

  const [vennAtive, setVennAtive] = React.useState('');
  const [width, setWidth] = React.useState( window.innerWidth);
  const [height, setHeight] = React.useState( window.innerHeight);
  const [isAknCookies, setIsAknCookies] = useState(localStorage.getItem('isAknCookies') !== "false");
  const [isAknLngChange, setIsAknLngChange] = React.useState(false);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const updateVennState = (newState) => {
//   document.getElementById("myLegendAnimation1").beginElement();
//   document.getElementById("myLegendAnimation2").beginElement();
//   document.getElementById("myLegendAnimation3").beginElement();
//   document.getElementById("myLegendAnimation4").beginElement();
      if(newState === "initial") {
        setVennAtive('');
      }
      dispatch(setVennState(newState))
  };

// detect if back or forward browser button is clicked


 useEffect(() => {

     setTimeout(() => {

        document.querySelectorAll(".gt_options a").forEach(t => t.addEventListener('click', (evt) => {
            const newLanguage = evt.currentTarget.dataset.gtLang;
            props.setSelectedTrans(newLanguage);
            if (newLanguage !== "en") {
                setIsAknLngChange(true);
            } else {
                setIsAknLngChange(false);
            }
        }));
    console.debug("document.querySelectorAll",document.querySelectorAll(".gt_options a"));

     }, 1000);

   }, []);

  useEffect(() => {
    updateVennState('initial');
  }, [selectedModal]);


  const updateVennActive = (newState) => {
    setVennAtive(newState);
  };


useEffect(() => {
    // set resize listener
    window.addEventListener('resize', updateDimensions);
//     window.addEventListener("scroll",  handleScroll, { passive: true });

    // clean up function
    return () => {
      // remove resize listener
    window.removeEventListener('resize', updateDimensions);
    }
  }, [])

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const maxWidth = document.body.clientWidth

    if (currentScrollY / maxWidth > .9) {

    } else if (currentScrollY / maxWidth > .8) {

    } else if (currentScrollY / maxWidth > .7) {

     } else if (currentScrollY / maxWidth > .6) {

updateVennState('initial');
      } else if (currentScrollY / maxWidth > .5) {

updateVennState('rightHover');
           } else if (currentScrollY / maxWidth > .4) {

updateVennActive('e');
                       } else if (currentScrollY / maxWidth > .3) {

updateVennActive('r');
                                   } else if (currentScrollY / maxWidth > .2) {
updateVennActive('m');
                                               } else if (currentScrollY / maxWidth > .1) {
updateVennState('leftHover');
                                                                                               }
    console.log(currentScrollY);
  };

  const {navExpanded } = { navExpanded: false }//props;

    let sizeFactor = Math.min(width / (242*3), height/421);
        if (isMobile) {
            sizeFactor = sizeFactor * 1.25;
        }

    const styleCircleVenn = { width: 302 * sizeFactor, height: 302 * sizeFactor };



    const hideOverlap = vennState.indexOf("left") > -1 || vennState.indexOf("right") > -1 || selectedModal === "m_exploitation"

  const newIsMobile = document.body.clientWidth <= 768;
   if (props.isMobile !== newIsMobile) props.setIsMobile(newIsMobile);

  return (<>
    { isAknCookies && <Modal

            style={{ textAlign: 'left' }}
             open={true}
             onRequestClose={() => {
             setIsAknCookies(false);
             localStorage.setItem('isAknCookies','false');
             }}

      modalHeading="Cookie Policy"
      modalLabel="Notice"
             passiveModal
             > <span style={{ fontSize: 18}}>
             <>
<p className="c10 c9">
    <span className="c0">
      We may use third-party Service providers to monitor and analyse the use of{" "}
    </span>
    <b className="c0 c7">
        copyrightuser.eu
    </b>
    <span className="c0">
      . The Service Providers we use may have access to Your Personal Data.
      These third-party vendors collect, store, use, process and transfer
      information about Your activity on{" "}
    </span>
    <span className="c7 c0">
      <b
        className="c11"
      >
        copyrightuser.eu
      </b>
    </span>
    <span className="c3 c0">
      &nbsp;in accordance with their Privacy Policies. Detailed information on
      the third-party service:
    </span>
  </p>
  <br />
  <ul className="c12 lst-kix_dm26wvz7jlxf-0 start">
    <li className="c4 li-bullet-0">
      <h4 className="c0 c14">Google Analytics</h4>
    </li>
  </ul>
  <p className="c10 c9">
    <span className="c3 c0">
      Google Analytics is a web analytics service offered by Google that tracks
      and reports website traffic. Google uses the data collected to track and
      monitor the use of our Service. This data is shared with other Google
      services. Google may use the collected data to contextualize and
      personalize the ads of its own advertising network.
    </span>
  </p><br />
  <p className="c5 c9">
    <span className="c3 c0" />
  </p>
  <p className="c10 c9">
    <span className="c0">
      You can opt-out of having made your activity on the Service available to
      Google Analytics by installing the Google Analytics opt-out browser
      add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
      analytics.js and dc.js) from sharing information with Google Analytics
      about visits activity. For more information on the privacy practices of
      Google, please visit the Google Privacy & Terms web page:{" "}
    </span>
    <span className="c7 c0">
      <a
        target="_blank"
        className="c11"
        href="https://policies.google.com/privacy"
      >
        https://policies.google.com/privacy
      </a>
    </span>
  </p>
  <p className="c5 c9">
    <span className="c3 c0" />
  </p>

  <br />
  <ul className="c12 lst-kix_dm26wvz7jlxf-0 start">
    <li className="c4 li-bullet-0">
      <h4 className="c0 c14">Contact Us</h4>
    </li>
  </ul>
  <p className="c10 c9">
    <span className="c3 c0">
      If you have any questions about Your Personal Data, You can contact us:
    </span>
  </p>
  <p className="">
    <li style={{ paddingLeft: '1em'}} className=" ">
      <span className="">By visiting this page on our website:</span>
      <span className="c2 c0">&nbsp;</span>
      <span className="c0 c1">
        <a
          className="c11"
          href="https://www.google.com/url?q=https://www.create.ac.uk/contact-information/&sa=D&source=editors&ust=1691991742611150&usg=AOvVaw1xuE3Kj9dCkQzKD_NU-S73"
        >
          https://www.create.ac.uk/contact-information/
        </a>
      </span>
      <span className="c2 c0">&nbsp;</span>
    </li>
    <li style={{ paddingLeft: '1em'}} className=" ">
      <span className="c0">By sending us an email:</span>
      <span className="c0 c2">&nbsp;</span>
      <span className="c0 c9 c16">contact@create.ac.uk</span>
    </li>
  </p>
  <p className="c5">
    <span className="c3 c8" />
  </p>
</>
</span>
    </Modal> }

    <ModalHistory selectedTrans={props.selectedTrans} open={selectedModal === "m_history"} setSelectedModal={(v) => { dispatch(setSelectedModal(v)) }} sidebarOpen={navExpanded} />
    <ModalQuicklinks quicklinksMapping={props.qlMapping} selectedTrans={props.selectedTrans} open={selectedModal === "m_sitemap"} setSelectedModal={(v) => { dispatch(setSelectedModal(v)) }} sidebarOpen={navExpanded} />
    <ModalLeaf isMobile={isMobile} dispatch={dispatch} selectedTrans={props.selectedTrans}
    setSelectedModal={setSelectedModal}  prevSelectedModal={prevSelectedModal}
    styleCircleVenn={styleCircleVenn}
    previousPreviousValueModal={previousPreviousValueModal} selectedModal={selectedModal}
    open={selectedModal !== "" && (selectedModal.indexOf("m_") === -1 || selectedModal === "m_enforcement" || selectedModal === "m_economic-rights" || selectedModal === "m_related-rights" || selectedModal === "m_moral-rights" || selectedModal === "m_exceptions" || selectedModal === "m_copyright-duration") }
    sidebarOpen={navExpanded} />
    { isAknLngChange && <Modal

                style={{ textAlign: 'left' }}
                className="notranslate"
                 open={true}
                 onRequestClose={() => {
                 setIsAknLngChange(false);
                 }}

          modalHeading="Dynamic Translation"
          modalLabel="Notice"
                 passiveModal
                 >
                 <p className="notranslate" style={{ fontSize: 18}}>
                     The translation below has been automatically generated by Google Translate and is based on the live content of the website, which is being regularly updated to reflect the current legislation. Translations of the original content (March 2023) in all 24 official languages of the European Union have been curated by copyright experts and can be downloaded on the following page: <span style={{ paddingLeft: '4px', cursor: "pointer", textDecoration: "underline", color: "#0645ad" }} className="quicklinks__link notranslate" onClick={() => {
                     setIsAknLngChange(false); dispatch(setSelectedModal('about')) }} >{getTranslationMap(selectedTrans, "About")}</span>

<hr/>
If you are navigating the infographics, please note that these were designed based on the English language. Due to the different number of characters and word count, the translated keywords - which have been curated by copyright experts - may distort the layout. For a smoother experience, please go back to the English version.
                     </p>
        </Modal> }

    <div className="App-header" style = {{
        paddingTop: selectedModal === "m_protection" ? (isMobile ? '48px' : '16px') : ''
    }}>
      <div id="infographic-wrapper"
      style={{
//         paddingBottom: (isMobile && selectedModal === "m_protection") ? 186 : ''
      }}
      >

       { isMobile && (selectedModal === "" || selectedModal.indexOf("m_use") > -1 || selectedModal === "m_protection" || selectedModal === "m_exploitation" || selectedModal === "m_enforcement") && <div style={{ paddingBottom: '2em', color: 'black'}}>
            <h5> { selectedModal === "" ? "Welcome to CopyrightUser.eu" : selectedModal === "m_use--with-permission" ? "USE (With Permission)" : selectedModal === "m_use--without-permission" ? "USE (Without Permission)" :
            selectedModal === "m_use" || selectedModal === "m_protection" || selectedModal === "m_exploitation" || selectedModal === "m_enforcement" ? selectedModal.slice(2).toUpperCase() : ""} </h5>
        </div> }
        { navArrowMap[selectedModal]?.right && !isMobile && <ArrowRight selectedTrans={props.selectedTrans}
            navLabel = { getTranslationMap(selectedTrans, navArrowMap[selectedModal].right.label)}
            navIcon = {<image x="-8.5" y="15.5" height="32" width="32"  href={navArrowMap[selectedModal].right.icon} style={{ height: 8 }} alt="ProtectLogo"/>}
            style={{
                 height: Math.floor(styleCircleVenn.height / 6),
                 width: Math.floor(styleCircleVenn.height / 3), position: 'absolute',
                 right: '3%', top: 'auto',
                 marginTop: Math.floor(styleCircleVenn.height / 2 - styleCircleVenn.height / 12)
            }}
            onClick={() => dispatch(setSelectedModal(navArrowMap[selectedModal].right.key))}
            className="arrowRight notranslate"
        /> }

        { navArrowMap[selectedModal]?.left && !isMobile && <ArrowLeft selectedTrans={props.selectedTrans}
            navLabel = { getTranslationMap(selectedTrans, navArrowMap[selectedModal].left.label)}
            navIcon = {<image x="47.5" y={  navArrowMap[selectedModal].left.label === "Home" ? "5.0" : "9.5"} height="32" width="32"  href={navArrowMap[selectedModal].left.icon} style={{ height: navArrowMap[selectedModal].left.label === "Home" ? 16 : 8 }} alt="ProtectLogo"/>}
            style={{
              height: Math.floor(styleCircleVenn.height / 6),
              width: Math.floor(styleCircleVenn.height / 3), position: 'absolute',
              left: "calc(3% + 200px)", top: 'auto',
              marginTop: Math.floor(styleCircleVenn.height / 2 - styleCircleVenn.height / 12)
            }}
            onClick={() => dispatch(setSelectedModal(navArrowMap[selectedModal].left.key))}
            className="arrowLeft notranslate"
        /> }
        <PieR className={ selectedModal === "m_protection" ?  "pies--explode" : ""} selectedTrans={props.selectedTrans}
            style={{
//                 marginBottom: isMobile ? (styleCircleVenn.height * (isMobile ? 1.65 : 1)) : '',
                width: styleCircleVenn.width * (isMobile ? 1.65 : 1),
                height: styleCircleVenn.height * (isMobile ? 1.65 : 1),
                opacity: selectedModal !== "m_protection" ? '0' : '1',
        visibility: !(selectedModal !== "m_protection") ? 'visible' : 'hidden',
                pointerEvents: selectedModal !== "m_protection" ? 'none' : 'all',
                left: isMobile ? styleCircleVenn.width * .08 : 'calc(3% + 250px)',//'auto',
                 top: 'auto', position: 'absolute',
//                  left: isMobile ? '4%' : 'calc(3% + 250px)',
            }}
        />
        <div className="vennCirclesWrapper"
        style={ isMobile ? {
            display: selectedModal === "m_protection" ? "inline-grid" : "",
            width:  selectedModal === "m_protection" ? "100%" : "",
            display: selectedModal.indexOf("m_use--") > -1 ? "block" :  selectedModal === "m_protection" ? "inline-grid" : "",
            transform: selectedModal === "m_use--with-permission" ? "translateX(27%) translateY(30%) scale(1.6)" :
            selectedModal === "m_use--without-permission" ? "translateX(-27%) translateY(30%) scale(1.6)" : '',
            paddingBottom: selectedModal === "m_use--with-permission" || selectedModal === "m_use--without-permission" ? '15%' : ''
        } : {
            position: selectedModal === "m_protection" ? "initial" : "",
        }}
        >
        <Venn1 isMobile={isMobile} dispatch={dispatch} selectedTrans={props.selectedTrans}
            setSelectedModal={setSelectedModal}
            styleCircleVenn={styleCircleVenn}
            className={selectedModal === "m_protection" ? "hideMe" : ""}
            vennActive={vennAtive}
            vennState={vennState === "leftHover" || selectedModal  ===  "m_exploitation" ? 1 : 0}
            selectedModal={selectedModal}
            updateVennState={updateVennState}
            updateVennActive={updateVennActive}
            style={{ ...styleCircleVenn,
            transform: (selectedModal === "m_protection" ? "scale(0.795) translate(-0px, 0px)" : ""),
             pointerEvents: (!isMobile && vennState === "rightHover" || (selectedModal.indexOf("m_use") > -1) || selectedModal === "m_protection") ? 'none' : 'all',
        visibility: !(selectedModal === "m_exploitation" && isMobile) ? 'visible' : 'hidden',
             opacity: (selectedModal === "m_exploitation" && isMobile) ? 0 : (selectedModal.indexOf("m_use") > -1 && selectedModal !== "m_use--with-permission") ? .6 : (isMobile && (vennState === "rightHover" || vennState === "middleHover") ) ? .4 : 1}}
        />
        { !(isMobile && selectedModal === "m_exploitation") && <Venn2 isMobile={isMobile} dispatch={dispatch} selectedTrans={props.selectedTrans}
                          setSelectedModal={setSelectedModal}
        className={selectedModal === "m_protection" ? "hideMe" : ""}
        updateVennState={updateVennState}
        vennState={vennState === "middleHover" ? 1 : 0}
            selectedModal={selectedModal}
        updateVennActive={updateVennActive}
        doPointerEvents={!isMobile && hideOverlap ? 'none' : ((selectedModal.indexOf("m_use") > -1) || selectedModal === "m_protection") ? 'none' : 'all'}

        style={{
        transform: (selectedModal === "m_protection" ? "translate(-80%, 0%)" : ""),
         pointerEvents:  (vennState === "rightHover" || vennState === "leftHover") && !isMobile ? 'none' : 'all',
         visibility: !(((hideOverlap) || selectedModal === "m_protection") && !isMobile) ? 'visible' : 'hidden',
         opacity: (hideOverlap) || selectedModal === "m_protection"
         ? (isMobile ? .3 : 0)
         : (selectedModal === "m_use") ? .6 : 1,
         width: 107 * sizeFactor,
         position: 'relative',
        marginLeft: -107 * sizeFactor,
        marginRight: -107 * sizeFactor,
        paddingTop: 35 * sizeFactor,
        paddingBottom: 35 * sizeFactor }} />}

        { !(isMobile && (selectedModal === "m_exploitation" || selectedModal === "m_protection")) && <Venn3  isMobile={isMobile}  dispatch={dispatch} selectedTrans={props.selectedTrans}
            setSelectedModal={setSelectedModal}
            className={selectedModal === "m_protection" ? "hideMe" : ""}
            vennActive={vennAtive}
            selectedModal={selectedModal}
            sizeFactor={sizeFactor}
            styleCircleVenn={styleCircleVenn}
            titleStyleMargins={{ left: -107 * sizeFactor, right: -107 * sizeFactor, bottom: 35 * sizeFactor }}
            vennState={vennState === "rightHover" ? 1 : 0}
            updateVennState={updateVennState}
            updateVennActive={updateVennActive}
            deepOpacity={selectedModal === "m_exploitation" || (selectedModal.indexOf("m_use") > -1)
                                          ? (selectedModal === "m_use--without-permission" ? 1 : 1)
                                          : (hideOverlap || vennState === "middleHover") && (isMobile && vennState !== "rightHover")  ? .3 : 1}
            style={{ ...styleCircleVenn,
            height:  styleCircleVenn.height * ((selectedModal === "m_protection" && isMobile) ? 1.65 : 1),
                 transform: (selectedModal === "m_protection" ? "scale(0.8) translate("  + -302 * sizeFactor *0.80 + "px, 0px)" : ""),
                 pointerEvents: (!isMobile && vennState === "leftHover" || selectedModal === "m_protection") ? 'none' : (selectedModal.indexOf("m_use") > -1) ? 'none' : 'all',
                 opacity: selectedModal === "m_exploitation" || (selectedModal.indexOf("m_use") > -1)
                 ? (selectedModal === "m_use--without-permission" ? 1 : .6)
                 : 1}
             }
        />}

        </div>

        <div className="legendWrapper"
        style={{
        left: !isMobile && selectedModal === "m_protection" ? 'calc(3% + 250px)' : '',
//         position: !isMobile && selectedModal === "m_protection" ? 'absolute' : '',
        }}

        >
        { !(isMobile && (selectedModal === "m_exploitation" || selectedModal === "m_protection" ||  selectedModal.indexOf("m_use--") > -1) ) &&
        <CP1 isMobile={isMobile} dispatch={dispatch} selectedTrans={props.selectedTrans}
        setSelectedModal={setSelectedModal}
        vennState={(vennState.indexOf("left") > -1 || selectedModal === "m_use--with-permission" || selectedModal === "m_exploitation" || selectedModal === "m_protection") ? 2 : (vennState.indexOf("right") > -1 || selectedModal === "m_use--without-permission") ? 3 : 1}
        style={{ width: styleCircleVenn.width * 2  - (107 * sizeFactor) }} />}
        </div>

        { isMobile && <div style={{
            height: vennState === "leftHover" ? "480px" :
             vennState === "rightHover" ? "580px" :
             vennState === "middleHover" ? "540px" :
             selectedModal === "m_use--without-permission" ? "340px" :
             selectedModal === "m_use--with-permission" ? "480px" : "",
             paddingTop: (selectedModal.indexOf("m_use--") > -1) ? '5em' : (selectedModal === "m_exploitation") ? ( isMobile && (window.screen.width  > window.screen.height) ? '50vw' : '29vh') : '2em',
             pointerEvents: 'all'}}>

        <div className={"accordionContentMobile"}>
        {
           vennState === "leftHover" && <Accordion className={"accordion--red"} size='lg'>
              <AccordionItem title={"Economic Rights"}
              onHeadingClick={(e) => {
                  dispatch(setSelectedModal("m_economic-rights"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={"Moral Rights"} onHeadingClick={(e) => {
                                                                  dispatch(setSelectedModal("m_moral-rights"));
                                                                  e.stopPropagation()
                                                              }}>
              </AccordionItem>
              <AccordionItem title={"Related Rights"} onHeadingClick={(e) => {
                                                                    dispatch(setSelectedModal("m_related-rights"));
                                                                    e.stopPropagation()
                                                                }}>
              </AccordionItem>
            </Accordion>
        }
        {
           vennState === "rightHover" && <Accordion className={"accordion--blue"} size='lg'>
              <AccordionItem title={"Exceptions"}
              onHeadingClick={(e) => {
                  dispatch(setSelectedModal("m_exceptions"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={getTranslationMap(selectedTrans, "Open Licences")} className={"notranslate"}
              onHeadingClick={(e) => {
                window.location.hash = "open-licences";
                  dispatch(setSelectedModal("THE PUBLIC DOMAIN"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={"Out Of Copyright Works"}
              onHeadingClick={(e) => {
                  dispatch(setSelectedModal("m_copyright-duration"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={"Ideas"}
              onHeadingClick={(e) => {
                window.location.hash = "idea-expression";
                  dispatch(setSelectedModal("THE PUBLIC DOMAIN"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={"De Minimis"}
              onHeadingClick={(e) => {
                    window.location.hash = "de-minimis";
                  dispatch(setSelectedModal("THE PUBLIC DOMAIN"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
            </Accordion>
        }
        {
           vennState === "middleHover" && <Accordion className={"accordion--yellow"} size='lg'>

              <AccordionItem title={"Ideas"}
              onHeadingClick={(e) => {
                window.location.hash = "idea-expression";
                  dispatch(setSelectedModal("THE PUBLIC DOMAIN"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={"De Minimis"}
              onHeadingClick={(e) => {
                    window.location.hash = "de-minimis";
                  dispatch(setSelectedModal("THE PUBLIC DOMAIN"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={"Exceptions"}
              onHeadingClick={(e) => {
                  dispatch(setSelectedModal("m_exceptions"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
              <AccordionItem title={getTranslationMap(selectedTrans, "Open Licences")} className={"notranslate"}
              onHeadingClick={(e) => {
                window.location.hash = "open-licences";
                  dispatch(setSelectedModal("THE PUBLIC DOMAIN"));
                  e.stopPropagation()
              }}>
              </AccordionItem>
            </Accordion>
        }
        {
           selectedModal === "m_use--without-permission" && <Accordion className={"accordion--blue"} size='lg'>
            <AccordionItem title={"Out Of Copyright Works"}
                          onHeadingClick={(e) => {
                              dispatch(setSelectedModal("m_copyright-duration"));
                              e.stopPropagation()
                          }}>
            </AccordionItem>
            </Accordion>
        }
        {
           selectedModal === "m_use--with-permission" && <Accordion className={"accordion--red"} size='lg'>
            <AccordionItem title={"Licences"}
            onHeadingClick={(e) => {
window.location.hash = "clearing-rights";
                dispatch(setSelectedModal("GETTING PERMISSION"));
                e.stopPropagation()
            }}>
            </AccordionItem>
            <AccordionItem title={"Assignments"}
            onHeadingClick={(e) => {
window.location.hash = "clearing-rights";
                dispatch(setSelectedModal("GETTING PERMISSION"));
                e.stopPropagation()
            }}>
            </AccordionItem>
          </Accordion>
        }
        </div>
        <div className="mobileBottomNav"
        style={{
                marginTop: selectedModal === "m_protection" ? '120%' : ''
//             paddingTop: selectedModal === "m_protection" ? (styleCircleVenn.height * 1.65 + 64) : "",
//             paddingBottom: selectedModal === "m_protection" ? 86 : '',
        }}>
        <hr/>
            { navArrowMap[selectedModal]?.right &&
            <div style={{/*  paddingTop: selectedModal === "m_exploitation" ? '186px' : '', */
             paddingBottom:  selectedModal === "" ? 200 : 216, float: navArrowMap[selectedModal]?.left ? 'right' : '', color: 'black'}}>
            <h5 style={{ fontWeight: 400, paddingTop: '.8em', paddingBottom: '.8em'}}>Next</h5><ArrowRight
                isMobile={isMobile}
                    navLabel = {  getTranslationMap(selectedTrans, navArrowMap[selectedModal].right.label)}
                    navIcon = {<image x="-8.5" y="15.5" height="32" width="32"  href={navArrowMap[selectedModal].right.icon} style={{ height: 8 }} alt="ProtectLogo"/>}
                    style={{
                    pointerEvents: 'all',
                      height: navArrowMap[selectedModal]?.left ? Math.floor(styleCircleVenn.height / 2.1)
                                                    :  Math.floor(styleCircleVenn.height / 2.1),
                      width: navArrowMap[selectedModal]?.left ? Math.floor(styleCircleVenn.height / 1.1)
                      :  Math.floor(styleCircleVenn.height / 1.1),
//                          marginTop: Math.floor(styleCircleVenn.height / 2 - styleCircleVenn.height / 12)
                    }}
                    onClick={() => dispatch(setSelectedModal(navArrowMap[selectedModal].right.key))}
                    className="arrowRight notranslate"
                /></div> }

                { navArrowMap[selectedModal]?.left && selectedModal !== "m_enforcement" &&
                <div style={{
             position: selectedModal === "m_enforcement" ? 'absolute' : '',
             zIndex: selectedModal === "m_enforcement" ? 99 : '',
             bottom: selectedModal === "m_enforcement" ? 128 : '',
             width: selectedModal === "m_enforcement" ? '100%' : '',
             float: navArrowMap[selectedModal]?.right ? 'left' : '', color: 'black'}}>
                {  selectedModal !== "m_enforcement" && <h5 style={{ fontWeight: 400, paddingTop: '.8em', paddingBottom: '.8em'}}>Previous</h5>}<ArrowLeft
                isMobile={isMobile}
                    navLabel = { getTranslationMap(selectedTrans, navArrowMap[selectedModal].left.label)}
                    navIcon = {<image x="47.5" y={  navArrowMap[selectedModal].left.label === "Home" ? "5.0" : "9.5"} height="32" width="32"  href={navArrowMap[selectedModal].left.icon} style={{ height: navArrowMap[selectedModal].left.label === "Home" ? 16 : 8 }} alt="ProtectLogo"/>}
                    style={{
                    pointerEvents: 'all',
                      height: navArrowMap[selectedModal]?.left ? Math.floor(styleCircleVenn.height / 2.1)
                                                    :  Math.floor(styleCircleVenn.height / 2.1),
                      width: navArrowMap[selectedModal]?.left ? Math.floor(styleCircleVenn.height / 1.1)
                      :  Math.floor(styleCircleVenn.height / 1.1),
//                       marginTop: Math.floor(styleCircleVenn.height / 2 - styleCircleVenn.height / 12)
                    }}
                    onClick={() => dispatch(setSelectedModal(navArrowMap[selectedModal].left.key))}
                    className="arrowLeft notranslate"
                /></div> }
                </div>
        </div>}

      </div>
        </div>
    </>)
}