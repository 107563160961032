import React from 'react';
import logo from './assets/logo.png';
import iconEnforcement from './assets/Icon-Enforcement.svg';
import iconLicensing from './assets/Icon-Licensing.svg';
import iconUsing from './assets/Icon-Using.svg';
import iconProtect from './assets/Icon-Protect.svg';
import { setVennState }  from './features/counter/vennState-slice';
import { setSelectedModal }   from './features/counter/selectedModal-slice';
import { translationMap, getTranslationMap } from './features/translationMap';
import './App.scss';
import {
HeaderContainer, HeaderNavigation, HeaderMenu, HeaderGlobalBar, HeaderGlobalAction, HeaderMenuItem, Header, HeaderName ,HeaderMenuButton, SkipToContent,
SideNavItems,SideNav,SideNavMenu,SideNavMenuItem, SideNavLink, SideNavDivider
} from '@carbon/react';
 import { Fade, Search } from '@carbon/react/icons';
import { Routes, Route } from 'react-router-dom'
import  Home  from './components/Home'
import { useAppDispatch, useAppSelector } from './hooks';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export const qlMapping = {
    "20":["PROTECTED WORKS","ECONOMIC RIGHTS","RELATED RIGHTS","MORAL RIGHTS","AUTHORS AND OWNERS"],
    "21":["THE PUBLIC DOMAIN","COPYRIGHT DURATION","COPYRIGHT EXCEPTIONS","MANDATORY EXCEPTIONS","OPTIONAL EXCEPTIONS","GETTING PERMISSION","CONTRACTS AND TPMS","ORPHAN WORKS","OUT OF COMMERCE WORKS"],
    "22":["GIVING PERMISSION","COLLECTING SOCIETIES"],
    "23":["ENFORCEMENT","PRIVATE ENFORCEMENT","ONLINE INTERMEDIARIES","PUBLIC ENFORCEMENT"]
};

const qmn = {
    "20": "Protection",
    "21": "Use",
    "22": "Exploitation",
    "23": "Enforcement",
}

const categoryIdMap = {
    6: "leaf-research",
    7: "leaf-legislation",
    5: "leaf-guidance",
    4: "leaf-content",
    8: "leaf-cases"

}

const translationIdMap = {
    en: {id: 9, name: 'English'},
    de: {id: 18, name: 'German'},
    fr: {id: 15, name: 'French'},
    it: {id: 16, name: 'Italian'},
    es: {id: 19, name: 'Spanish'},
    pl: {id: 14, name: 'Polish'},
    nl: {id: 12, name: 'Dutch'},
    ro: {id: 17, name: 'Romanian'},
    cs: {id: 10, name: 'Czech'},
    hu: {id: 13, name: 'Hungarian'},
    pt: {id: 11, name: 'Portuguese'},
};

function calculateSelectedModal() {
  const hash = window.location.pathname;
  console.debug("calculateSelectedModal");
  if (hash === "") {
    return null;
  } else
  {
      const parts = hash.slice(1).split("/");

      if (parts.length > 1) {
        const catName = parts[0].replace(/-/g, " ");
        const leafId = parts[1].replace(/-/g, " ");
        const catId = Object.keys(qlMapping).find((key) => {
          const qns = qmn[key.toString()] || "";
          return qns.toLowerCase() === catName.toLowerCase();
        });
        if (catId) {
          const selectedModalCopy = qlMapping[catId].find(
            (d) => d.replace(/-/g, " ").toLowerCase() === leafId.toLowerCase()
          );
          if (selectedModalCopy) {
            return selectedModalCopy;
          } else {
            return null;
          }
        } else {
          const selectedModalCopy = "- " + leafId.replace(/^\w/, (c) => c.toUpperCase());
          return selectedModalCopy;
        }
      }  else {
          let selectedModalCopy = "m_" + hash.slice(1);
          if (selectedModalCopy === "m_about") selectedModalCopy = "about";
          if (selectedModalCopy === "m_history") selectedModalCopy = "history";
          if (selectedModalCopy === "m_") selectedModalCopy = "";
          return selectedModalCopy;
        }
//       }else {
//         return null;
//       }

  }
}

async function fetchTranslations(lang) {
//wp-
  const langId = translationIdMap[lang].id
  const response = await fetch(`https://backend.copyrightuser.eu/wp-json/wp/v2/posts?per_page=1000&categories=${langId}`);
  const data = await response.json();

  const formattedTranslation = {};


  data.forEach((de) => {
      const categoryIdKeys = Object.keys(categoryIdMap).map(el => +el);


      const leafType= de.categories.find(c => categoryIdKeys.includes(c))
      // leaf nodes
      if (leafType) {
        const potentialTag = de.tags[0];
        const id = de.title.rendered.toUpperCase();
        if (potentialTag && qlMapping[potentialTag.toString()].indexOf(id) === -1) { qlMapping[potentialTag.toString()].push(id); }
        const category = categoryIdMap[leafType];
        formattedTranslation[id + "--" + category] = de.content.rendered;
      }

  })

  console.debug("Translation",formattedTranslation);
  return {ft: formattedTranslation, ql: qlMapping};
}

export default React.memo( props => {
  const [navExpanded, setNavExpanded] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(() => document.body.clientWidth <= 768);
  const [quicklinksMapping, setQuicklinksNapping] = React.useState({
       "20": [],
       "21": [],
       "22": [],
       "23": []
   });
  const [selectedTrans, setSelectedTrans] = React.useState("en");
  const [mainState, setMainState] = React.useState("");
  const selectedModal = useAppSelector((state) => state.selectedModal.value);
  const prevSelectedModal = useAppSelector((state) => state.selectedModal.previousValue);
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();

 const sideNavRef = useRef(null);

  const handleClickOutside = (event) => {
    if (isMobile && navExpanded && sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      setNavExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

    useEffect(() => {
      async function loadTranslations(lang) {
        const translations = await fetchTranslations(lang);
        setQuicklinksNapping(translations.ql);
        i18n.use(initReactI18next).init({
          resources: { [lang]: { translation: translations.ft } },
          lng: lang,
          interpolation: { escapeValue: false },
        });
      }

      loadTranslations(i18n.language);
    }, [i18n.language]);

    useEffect(()=>{
    var hash = window.location.pathname.slice(1);
    var arrayLocations = hash.split("/").filter(i => i)
    if (arrayLocations.length === 1) {
        let sM = "m_" + arrayLocations[0];
        if (sM === "m_about") sM = "about";
        if (sM === "m_history") sM = "history";
        dispatch(setSelectedModal(sM));
    } else if (arrayLocations.length === 2) {
        dispatch(setSelectedModal(arrayLocations[1].replaceAll("-", " ").toUpperCase()))
    }
        // do stuff here...
    }, []) // <-- empty dependency array

    const handleBackForward = (event) => {
        // Initial page load
        console.log('hashchange',event, selectedModal, prevSelectedModal);
        console.log('hashchange based on the curretn hash, selectedModal should be', calculateSelectedModal());
        const calculatedSelectedModal = calculateSelectedModal();


        // check if first character of calculatedSelectedModal is "-"


        if (calculatedSelectedModal && calculatedSelectedModal[0] !== "-") {
            if ((calculateSelectedModal() || "").toUpperCase() !== (selectedModal || "").toUpperCase()) {
                dispatch(setSelectedModal(calculateSelectedModal()));
            }
        }
    };

  useEffect(() => {

    // Set initial state object
    window.history.replaceState({ isBackForward: false }, '');
    window.history.pushState({page: 1}, "", "");
    window.addEventListener('popstate', handleBackForward);

    return () => {
      window.removeEventListener('popstate', handleBackForward);
    };
  }, [handleBackForward]);

    useEffect(() => {
              if (selectedModal) {

              const currentHash = window.location.hash;
              console.debug("calc")
                  if (selectedModal.indexOf("m_") > -1) {
                     window.history.replaceState(null, "", "/" + selectedModal.slice("2") + currentHash)
                  } else {
                      const leafId = selectedModal.replaceAll(" ", "-").toLowerCase()
                      const catId = Object.keys(qlMapping)[Object.values(qlMapping).findIndex(d => d.includes(selectedModal))];
                      if (catId) {
                          const leafId = selectedModal.replaceAll(" ", "-").toLowerCase()
                          const catName = qmn[catId.toString()] || ""
                          window.history.replaceState(null, "", "/" + catName.toLowerCase() + "/" + leafId + currentHash)
                      } else {
                          const leafId = selectedModal.replaceAll(" ", "-").toLowerCase()

                          if (leafId === "about" || leafId === "history" || leafId === "sitemap") {
                                window.history.replaceState(null, "", "/" + leafId + currentHash)
                          } else {
                                window.history.replaceState(null, "", "/-/" + leafId + currentHash)
                          }
                      }
                  }
              } else {
              const currentHash = window.location.hash;
                  window.history.replaceState(null, "", "/" + currentHash)
//                   window.location.pathname = "/"
              }
    }, [selectedModal]);

console.debug("isMobile",isMobile);
  return( <div className={ isMobile ? "isMobile App" : "App"}>
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <>
        <Header aria-label="">
                {isMobile && <> <SkipToContent />
                 <HeaderMenuButton
                   aria-label="Open menu"
                   onClick={() => setNavExpanded(!navExpanded)}
                   isActive={navExpanded || !isMobile}
                   className="navIconMobile"
                  />
                  <HeaderName style={{ width: '100%' }} href="#" prefix="">
                    <div style={{ width: isMobile ? 'calc(100% - 48px)' : '100%' }} onClick={()=> {setNavExpanded(false) ; dispatch(setSelectedModal(""))}} className="navLogoWrapper"><img  src={logo} style={{ marginTop: 6, height: 32 }} alt="UsingLogo"/><span style={{ color: "#d31e5d"}}></span></div>
                  </HeaderName>
                  <HeaderGlobalBar>
                  </HeaderGlobalBar> </>}
       <SideNav
       ref={sideNavRef}
           id="mainSideNav"
           style={{ marginTop: isMobile ? 48 : 0 }}
           aria-label="Side navigation"
           isFixedNav={true}
           onOverlayClick={() => { if (isMobile) setNavExpanded(false)} }
           expanded={navExpanded || !isMobile}
           isChildOfHeader={false}>
           <SideNavItems style={{ paddingTop: isMobile ? 0 : 16 }}>
                       <SideNavLink  href={"/"} onClick={(e)=> {e.preventDefault(); window.location.hash = ""; setNavExpanded(false) ; dispatch(setSelectedModal(""))}}
                       renderIcon={() => <div className="navLogoWrapper"  style={{ pointerEvents: 'all'}}
                        onClick={()=> {setNavExpanded(false) ; dispatch(setSelectedModal(""))}}><img  src={logo} style={{ marginTop: -6, width: 72}} alt="UsingLogo"/><span style={{ color: "#d31e5d"}}></span></div>}
                       className={"sideNaveMenu__iconWrapper textSide"}
                        style={{     background: 'transparent', display: isMobile ? 'none' : 'initial', cursor: 'pointer', pointerEvents: 'all', height: 56 }}
                        /* onClick={()=> {setNavExpanded(false) ; dispatch(setSelectedModal(""))}} */ ></SideNavLink>
              <SideNavLink tabIndex="1" href={"/protection"} onClick={(e)=> {e.preventDefault(); window.location.hash = ""; setNavExpanded(false) ; dispatch(setSelectedModal("m_protection"))}} className={"sideNaveMenu__iconWrapper" + (selectedModal === "m_protection" ? " activeNaveItem" : "")} large renderIcon={() => <div className="navIconWrapper"><img  src={iconProtect} style={{ height: 18 }} alt="ProtectLogo"/></div>} title="Using & Reusing">
                 <div className="nav__title notranslate">{getTranslationMap(selectedTrans, "Protection")}</div>
              </SideNavLink>
             <SideNavLink tabIndex="2" href={"/use"} onClick={(e)=> {e.preventDefault(); window.location.hash = ""; setNavExpanded(false) ; dispatch(setSelectedModal("m_use"))}} className={"sideNaveMenu__iconWrapper" + ((selectedModal.indexOf("m_use") > -1) ? " activeNaveItem" : "")} large renderIcon={() => <div className="navIconWrapper"><img  src={iconUsing} style={{ height: 18 }} alt="UsingLogo"/></div>} title="Using & Reusing">
                <div className="nav__title notranslate">{getTranslationMap(selectedTrans, "Use")}</div>
             </SideNavLink>
              <SideNavLink tabIndex="3" href={"/exploitation"} onClick={(e)=> {e.preventDefault();window.location.hash = ""; setNavExpanded(false) ; dispatch(setSelectedModal("m_exploitation"))}} className={"sideNaveMenu__iconWrapper" + (selectedModal === "m_exploitation" ? " activeNaveItem" : "")} large renderIcon={() => <div className="navIconWrapper"><img  src={iconLicensing} style={{ height: 18 }} alt="ProtectLogo"/></div>} title="Licensing & Exploiting">
                 <div className="nav__title notranslate">{getTranslationMap(selectedTrans, "Exploitation")}</div>
              </SideNavLink>
              <SideNavLink tabIndex="4" href={"/enforcement"} onClick={(e)=>{ e.preventDefault();window.location.hash = "";
              setNavExpanded(false) ;
                dispatch(setSelectedModal("m_enforcement"));
              }} className={"sideNaveMenu__iconWrapper" + (selectedModal === "m_enforcement" ? " activeNaveItem" : "")} large renderIcon={() => <div className="navIconWrapper"><img  src={iconEnforcement} style={{ height: 18 }} alt="EnforcementLogo"/></div>} title="Enforcement">
                 <div className="nav__title notranslate">{getTranslationMap(selectedTrans, "Enforcement")}</div>
              </SideNavLink>
               <SideNavLink  disabled={true}
               className={"sideNaveMenu__iconWrapper textSide"}
               style={{ pointerEvents: 'none', height: 0, marginTop: -8 }}
               onClick={(e)=> { e.preventDefault();window.location.hash = ""; }}>
                  <div className="nav__title"></div>
               </SideNavLink>
               <SideNavLink href={"/about"}  tabIndex={5}
               className={"sideNaveMenu__iconWrapper textSide" + ((selectedModal && selectedModal.toLowerCase() === "about") ? " activeNaveItem" : "")}
               style={{ cursor: 'pointer', height: 56 }}
               onClick={(e)=> { e.preventDefault(); window.location.hash = ""; setNavExpanded(false) ; dispatch(setSelectedModal("about"))}}>
                  <div className="nav__title notranslate">{getTranslationMap(selectedTrans, "About")}</div>
               </SideNavLink>
               <SideNavLink href={"/sitemap"} tabIndex={6}
               className={"sideNaveMenu__iconWrapper textSide" + (selectedModal === "m_sitemap" ? " activeNaveItem" : "")}
               style={{ cursor: 'pointer', height: 56 }}
               onClick={(e)=> {  e.preventDefault(); window.location.hash = ""; setNavExpanded(false) ; dispatch(setSelectedModal("m_sitemap"))}}>
                  <div className="nav__title notranslate">{getTranslationMap(selectedTrans, "Sitemap")}</div>
               </SideNavLink>
               <SideNavLink href={"/history"}  tabIndex={7}
                              className={"sideNaveMenu__iconWrapper textSide" + ((selectedModal && selectedModal.toLowerCase() === "history") ? " activeNaveItem" : "")}
                              style={{ cursor: 'pointer', height: 56 }}
                              onClick={(e)=> { e.preventDefault(); window.location.hash = ""; setNavExpanded(false) ; dispatch(setSelectedModal("history"))}}>
                                 <div className="nav__title notranslate">{getTranslationMap(selectedTrans, "History")}</div>
                              </SideNavLink>
                               </SideNavItems>
         </SideNav>
        </Header>
      </>
    )}
  />

    <Home
        setSelectedTrans={setSelectedTrans} selectedTrans={selectedTrans}
        setIsMobile={setIsMobile} qlMapping={quicklinksMapping} isMobile={isMobile}></Home>
    </div>
    )
})