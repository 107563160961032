// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState = {
  value: 'initial',
};

const counterSlice = createSlice({
  name: 'vennState',
  initialState,
  reducers: {
    setVennState(state, action) {
      state.value = action.payload;
    },
    // decrement
    // reset
  },
});

export const { setVennState } = counterSlice.actions;
export default counterSlice.reducer;
