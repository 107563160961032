import * as React from "react"
import iconCross from '../../assets/LE-cross.svg';
import InfoIconR from './InfoIconR';
import AssignmentR from './AssignmentR';


import iconTwoRedArrows from '../../assets/Pyw-1.svg';
import iconBlueGraphic from '../../assets/Pyw-2.svg';
import iconProfile from '../../assets/Pyw-3.svg';
import iconTwoRedBlueArrows from '../../assets/Pyw-4.svg';
import iconTwoBlueArrows from '../../assets/Pyw-5.svg';
import iconPie from '../../assets/Pyw-6.svg';
import iconCopyright from '../../assets/copyright.png';
import circleGuidance from '../../assets/circleGuidance.png';
import { getTranslationMap } from '../../features/translationMap';


const SvgComponent = (props) => {

  const [assignmentExpended, setAssignmentExpended] = React.useState(false);
  const [titleHover, setTitleHover] = React.useState(-1);

var s = "author’s own intellectual creation"

// add a <br> before and after the third word
var s2 = s.replace(/(\w+\s+\w+\s+\w+)/, '<br>$1<br>');



  const { isMobile, selectedTrans } = props;
console.debug("isMobile",isMobile, props.vennState)
return (<>
{ props.vennState === 1 && !props.isMobile && props.selectedModal !== "m_exploitation" && <div style={{ display: 'inline' }} className="vennTitles">
    <div className={'notranslate'} style={{ marginTop: props.styleCircleVenn.width *0.09, paddingBottom: 8, marginLeft: props.styleCircleVenn.width *0.5  - 40,
     fontWeight: (props.vennActive === "e" ) ? 800 : 400 }}>
    {getTranslationMap(selectedTrans, "Economic rights")}
    </div>
    <div className={'notranslate'} style={{ textOverflow: 'ellipsis', paddingBottom: 8, maxWidth: props.styleCircleVenn.width *0.629, overflow: 'hidden', marginTop:  props.styleCircleVenn.width *0.629, marginLeft: props.styleCircleVenn.width *0.15  - 40,
     fontWeight: (props.vennActive === "r" ) ? 800 : 400 }}>
    {getTranslationMap(selectedTrans, "Related rights")}
    </div>
    <div title={getTranslationMap(selectedTrans, "Moral rights")} className={'notranslate'}
     style={{ textOverflow: 'ellipsis', paddingBottom: 8, maxWidth: props.styleCircleVenn.width *0.629, overflow: 'hidden', marginTop: props.styleCircleVenn.width *0.629, marginLeft:  props.styleCircleVenn.width *0.82  - 40,
    fontWeight: (props.vennActive === "m" ) ? 800 : 400 }}>
    {getTranslationMap(selectedTrans, "Moral rights")}
    </div>
</div> }
{ <div className={(props.selectedModal === "m_protection" ? "m_protection_content_wrapper " : "") + "vennCircle-content " + (props.selectedModal === "m_protection" || props.selectedModal === "m_enforcement" ? "vennCircle-protect-content--active" : "")}  style={{
        width: props.style.width * (props.isMobile ? 1.65 : 1),
        height: props.style.height * (props.isMobile ? 1.65 : 1),
        opacity: props.selectedModal === "m_protection" ? 1 : 0,
        visibility: props.selectedModal === "m_protection" ? 'visible' : 'hidden',
        pointerEvents: props.selectedModal === "m_protection" ? 'all' : 'none',

        transitionDelay: '0s',
         left: props.isMobile ? '' : 'calc(3% + 250px)',

    }}>

         <div title={getTranslationMap(selectedTrans, "Related Rights")} onClick={() => props.dispatch(props.setSelectedModal("m_related-rights"))} className="notranslate mainCategoryUsing protect__purple" style={{
            maxWidth: props.style.width * (isMobile ? .7 : 0.5), paddingBottom: 8,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
         fontSize: (isMobile ? '5vw' : Math.floor(props.style.width * 0.05)), top: props.style.width * 0.55 * (isMobile ? 1.65 : 1), left: 0.05 * props.style.width * (isMobile ? 2.75 : 1) }}>{getTranslationMap(selectedTrans, "Related Rights")}</div>
         <div title={getTranslationMap(selectedTrans, "Economic Rights")} onClick={() => props.dispatch(props.setSelectedModal("m_economic-rights"))} className="notranslate mainCategoryUsing protect__red" style={{
            maxWidth: props.style.width *  (isMobile ? .9 : 0.5), paddingBottom: 8,
            textOverflow: 'ellipsis',
            overflow: 'hidden', fontSize: (isMobile ? '5vw' : Math.floor(props.style.width * 0.05)), top: props.style.width * 0.15 * (isMobile ? 1.65 : 1), left: 0.315 * props.style.width * (isMobile ? 1.65 : 1)}}>{getTranslationMap(selectedTrans, "Economic Rights")}</div>
         <div title={getTranslationMap(selectedTrans, "Moral Rights")} onClick={() => props.dispatch(props.setSelectedModal("m_moral-rights"))} className="notranslate mainCategoryUsing protect__pink" style={{
            maxWidth: props.style.width *  (isMobile ? .7 : 0.5), paddingBottom: 8,
            textOverflow: 'ellipsis',
            overflow: 'hidden', fontSize: (isMobile ? '5vw' : Math.floor(props.style.width * 0.05)), top: props.style.width * 0.55 * (isMobile ? 1.65 : 1), left:  0.6 * props.style.width * (isMobile ? 1.75 : 1)}}>{getTranslationMap(selectedTrans, "Moral Rights")}</div>

         { (!isMobile || props.selectedModal === "m_protection") && <div className={ isMobile ? "protect__rightMobile" : "protect__rightAbsolute"}
         style={{
         paddingTop: isMobile ? (props.style.height * 1.65 + 32) : '',
         width: props.styleCircleVenn?.width * 0.8,
         paddingRight: props.styleCircleVenn?.width * 0.2,
         left: isMobile ? '' : ('calc(6% + 250px + ' + Math.floor(props.styleCircleVenn?.width) + 'px)'),
         height: props.styleCircleVenn?.width * 0.85 }}>
             <div className="protect__rightColumn">
                 <div  onClick={() => props.dispatch(props.setSelectedModal("AUTHORS AND OWNERS"))} className="protect__rightColumn__row protect__rightColumn__row--blue"><span><img alt="icon left column" className="iconLeftColumn" src={iconProfile} style={{ border: '2px solid white', background: 'black', width: '125%', height: '125%', borderRadius: '50%', padding: '20%' }} /></span>
                 <span className={'notranslate'} style={{ fontWeight: 800, paddingTop: '.4em', paddingBottom: '.4em'  }}>{getTranslationMap(selectedTrans, "Author")}</span></div>
                 <div className="protect__rightColumn__row protect__rightColumn__bridge"><span className="notranslate"><img alt="icon left column" className="iconLeftColumn" src={iconTwoBlueArrows} style={{ height: '2.6em', position: 'absolute' }} />{getTranslationMap(selectedTrans, "Personality")}</span></div>
                 <div  onClick={() => props.dispatch(props.setSelectedModal("PROTECTED WORKS"))} className="protect__rightColumn__row protect__rightColumn__row--blue"><span style={{  }}><img alt="icon left column" className="iconLeftColumn" src={iconBlueGraphic} style={{ background: 'transparent', height: '100%', padding: '10%'}} /></span><span className={"notranslate"} style={{ paddingTop: '1em', paddingBottom: '1em'}}>{getTranslationMap(selectedTrans, "Creative choices").split(" ")[0]} <br/> {getTranslationMap(selectedTrans, "Creative choices").split(" ")[1]}</span></div>
                 <div className="protect__rightColumn__row protect__rightColumn__bridge"><span style={{}}><img className="iconLeftColumn notranslate" alt="icon left column" src={iconTwoRedBlueArrows} style={{ height: '2.6em', position: 'absolute' }} />{getTranslationMap(selectedTrans, "Originality")}</span></div>
                 <div  onClick={() => props.dispatch(props.setSelectedModal("PROTECTED WORKS"))} className="protect__rightColumn__row protect__rightColumn__row--red"><span><img className="iconLeftColumn" alt="icon left column" src={iconPie} style={{ background: 'pink', height: '100%', padding: '10%'}} /></span><span className={"notranslate"} style={{ paddingTop: '1em', paddingBottom: '1em'}}>{getTranslationMap(selectedTrans, "author’s own intellectual creation")}</span></div>
                 <div className="protect__rightColumn__row protect__rightColumn__bridge"><span><img className="iconLeftColumn" alt="icon left column" src={iconTwoRedArrows} style={{ height: '1.8em' }} /></span></div>
                 <div  onClick={() => props.dispatch(props.setSelectedModal("AUTHORS AND OWNERS"))} className="protect__rightColumn__row protect__rightColumn__row--red"><span><img className="iconLeftColumn" alt="icon left column" src={iconProfile} style={{ border: '2px solid white', background: 'black', width: '125%', height: '125%', borderRadius: '50%', padding: '20%' }} /></span><span className={"notranslate"} style={{ fontWeight: 800, paddingTop: '.4em', paddingBottom: '.4em' }}>{getTranslationMap(selectedTrans, "Copyright Owner")}</span></div>
             </div>
         </div> }
    </div>}

<div className={((props.selectedModal.indexOf("m_use") > -1 || (isMobile && props.vennState === 1))  ? "m_use_content_wrapper " : "") + "vennCircle-content " + (props.selectedModal.indexOf("m_use") > -1 || (isMobile && props.vennState === 1) ? "vennCircle-content--active" : "")}  style={{
        width: props.style.width,
        height: props.style.height,
        opacity: (props.selectedModal.indexOf("m_use") > -1 || (isMobile && props.vennState === 1)) ? 1 : 0,
        visibility: (props.selectedModal.indexOf("m_use") > -1 || (isMobile && props.vennState === 1)) ? 'visible' : 'hidden',
        pointerEvents: (props.selectedModal.indexOf("m_use") > -1) ? 'all' : 'none',
        transitionDelay: '0s'
    }}>
        { (!isMobile || props.selectedModal.indexOf("m_use") > -1) && <div
         style={{
             borderRadius: (props.selectedModal === "m_use--with-permission" && !isMobile) ? 0 : '',
             border: (props.selectedModal === "m_use--with-permission" && !isMobile) ? 'none' : (props.selectedModal === "m_use--with-permission" && isMobile) ? '2px solid rgb(212, 21, 91)' : '',
             background: (props.selectedModal === "m_use--with-permission" && !isMobile) ? 'rgb(212, 21, 91)' : (props.selectedModal === "m_use--with-permission" && isMobile) ? 'rgb(212, 21, 91)' : '',
             color: (props.selectedModal === "m_use--with-permission" && !isMobile) ? 'rgb(255, 255, 255)' : (props.selectedModal === "m_use--with-permission" && isMobile) ? 'white' : '',
             fontSize: (props.selectedModal === "m_use--with-permission" && !isMobile) ? '1.0em' : ((props.selectedModal === "m_use--with-permission" || props.selectedModal === "m_use--without-permission") && isMobile) ? '8px' : isMobile ? '' : '',
             fontWeight: (props.selectedModal === "m_use--with-permission" && !isMobile) ? '400' : '',
                                               paddingLeft: '3vw',
                                               paddingRight: '3vw',
             marginTop: ((props.selectedModal === "m_use--with-permission" || props.selectedModal === "m_use--without-permission") && isMobile) ?  (-1 * props.style.width) : (props.selectedModal === "m_use--with-permission" ? (-.4 * props.style.width) : 0),
             marginRight: (props.selectedModal === "m_use--without-permission" && isMobile) ? (isMobile ?  -.95 * props.style.width : (0.3 * props.style.width)) :
                         (props.selectedModal === "m_use--with-permission" && isMobile) ? 0.30 * props.style.width : (props.selectedModal === "m_use--with-permission" ? 0.30 * props.style.width : -0.1 * props.style.width)
         }}
         className="mainCategoryUsing redCategory notranslate" onClick={ () =>  props.dispatch(props.setSelectedModal("m_use--with-permission"))}
         >{getTranslationMap(selectedTrans, "With Permission")}</div>}
         
<div style={{ marginLeft: -0.25 * props.style.width }}>
<table style={{
    transition: (props.selectedModal === "m_use--with-permission" || (isMobile && props.vennState === 1) )? 'all 1.5s ease-in-out 0s' : 'all 0s ease-in-out 0s',
    opacity: (props.selectedModal === "m_use--with-permission") || (isMobile && props.vennState === 1) ? 1 : 0,
        visibility: (props.selectedModal === "m_use--with-permission") || (isMobile && props.vennState === 1) ? 'visible' : 'hidden',
    pointerEvents: (props.selectedModal === "m_use--with-permission") || (!isMobile && props.vennState === 1) ? 'all' : 'none',
}}>
<tr><td><img alt="icon vennCircle" className="vennCircle-copyright"  src={iconCopyright}
style={{ height: Math.floor(props.style.width / 4), marginTop: isMobile ? '' : '4.6vw', paddingTop: isMobile ? '' : '3.6vw' }} alt="cross-icon"/>
<div style={{ color: 'white', fontWeight: 600, fontSize: (props.selectedModal === "m_use--with-permission") ? 'smaller' : 'larger' , paddingBottom: '1.6vw' }}>Copyright</div>
</td></tr>
{ !isMobile && <tr><td><div onClick={() => {
window.location.hash = "clearing-rights";
props.dispatch(props.setSelectedModal("GETTING PERMISSION"))}} className="mainCategoryUsing redCategory" style={{
                                               position: 'initial',
                                               fontWeight: 800,
                                               marginBottom: 16,
                                               paddingLeft: '2vw',
                                               paddingRight: '2vw',
                                               fontSize: 'medium'
                                           }}>Licences</div></td></tr> }
{ !isMobile && <tr><td><div onClick={() => {
window.location.hash = "clearing-rights";
props.dispatch(props.setSelectedModal("GETTING PERMISSION"))}} className="mainCategoryUsing redCategory notranslate" style={{
                                               position: 'initial',
                                               paddingLeft: '2vw',
                                               paddingRight: '2vw',
                                               fontWeight: 800,
                                               fontSize: 'medium'
                                         }}>{getTranslationMap(selectedTrans, "Assignment")}</div></td></tr> }
</table>
</div>
         { (!isMobile || props.selectedModal.indexOf("m_use")) > -1 && <div className="mainCategoryUsing blueCategory notranslate"
         
         style={{
         
          left: props.style.width,
          
             borderRadius: (props.selectedModal === "m_use--without-permission" && !isMobile) ? 0 : '',
             border: (props.selectedModal === "m_use--without-permission" && !isMobile) ? 'none'  : (props.selectedModal === "m_use--without-permission" && isMobile) ? '2px solid #4b7fbf' : '',
             background: (props.selectedModal === "m_use--without-permission") ? '#4b7fbf' : '',
             color: (props.selectedModal === "m_use--without-permission") ? 'rgb(255, 255, 255)' : '',
             fontSize:  ((props.selectedModal === "m_use--with-permission" || props.selectedModal === "m_use--without-permission") && isMobile) ? '8px' : (props.selectedModal === "m_use--without-permission") ? (isMobile ? '10px' : '1.0em') : '',
             fontWeight: (props.selectedModal === "m_use--without-permission" && !isMobile) ? '400' : '',
                                               paddingLeft: '3vw',
                                               paddingRight: '3vw',
             marginTop: ((props.selectedModal === "m_use--without-permission" || props.selectedModal === "m_use--with-permission") && isMobile) ? -1.0 * props.style.width : (props.selectedModal === "m_use--without-permission") ? (isMobile ? -1.0 * props.style.width : -0.4 * props.style.width) : 0,
             marginLeft: (props.selectedModal === "m_use--with-permission" && isMobile) ? -0.425 * props.style.width :
                         (props.selectedModal === "m_use--without-permission" && isMobile) ? 0.2 * props.style.width : props.selectedModal === "m_use--without-permission" ? 0 : -0.1 * props.style.width
         }}
         
         onClick={ () => props.dispatch(props.setSelectedModal("m_use--without-permission"))}
         >{getTranslationMap(selectedTrans, "Without Permission")}</div> }

         <div style={{
         position: 'absolute',
         left: props.style.width * 1.075,
          marginLeft: (props.selectedModal === "m_use--with-permission") ? 0.1 * props.style.width :
                                   (props.selectedModal === "m_use--without-permission") ? 0. * props.style.width : -0.1 * props.style.width

                                   }}>
         <table style={{
             transition: (props.selectedModal === "m_use--without-permission") ? 'all 1.5s ease-in-out 0s' : 'all 0s ease-in-out 0s',
             opacity: (props.selectedModal === "m_use--without-permission") ? 1 : 0,
        visibility: (props.selectedModal === "m_use--without-permission") ? 'visible' : 'hidden',
             pointerEvents: (props.selectedModal === "m_use--without-permission") ? 'all' : 'none',
         }}>
         <tr><td>
         <div style={{
             color: 'white',
             paddingLeft: isMobile ? '7vw' : '',
             fontWeight: 600,
             fontSize: isMobile ? 'smaller' : 'larger',
             paddingBottom: '1.6vw'
          }}>Public<br/>Domain</div>
         </td></tr>
         { !isMobile && <tr><td><div onClick={ () => props.dispatch(props.setSelectedModal("m_copyright-duration"))}
          className="mainCategoryUsing blueCategory" style={{
                                                        position: 'initial',
                                                        paddingLeft: '2vw',
                                                        paddingRight: '2vw',
                                                        fontWeight: 800,
                                                        fontSize: 'medium'
                                                  }}>Out of Copyright Works</div></td></tr> }
         </table>
         </div>
</div>


<div className={"vennCircle-content " + (props.selectedModal === "m_exploitation" ? "vennCircle-content--active" : "")}  style={{
    width: props.style.width,
    height: props.style.height,
    opacity: props.selectedModal === "m_exploitation" ? 1 : 0,
        visibility: (props.selectedModal === "m_exploitation") ? 'visible' : 'hidden',
    pointerEvents: props.selectedModal === "m_exploitation" ? 'all' : 'none',
        transform: !isMobile ? '' : 'translate(0%, 30%) scale(2.4)',
        zIndex: 999,
         display: 'inline',
}}>
<div  title={getTranslationMap(selectedTrans, "Related Rights")} onClick={() => { { props.dispatch(props.setSelectedModal("m_related-rights"))}}}
onMouseOver={() => {  console.debug("museover4"); setTitleHover(1) }}
onMouseOut={() => { if (!props.isMobile) setTitleHover(-1)}}  style={{ textOverflow: 'ellipsis', overflow: 'hidden', top:  Math.floor(props.style.height / 4) - 4,  left: Math.floor(props.style.width / 6), width: Math.floor(props.style.width / 6) }} className="vennCircle-content-titleBox notranslate">
    {getTranslationMap(selectedTrans, "Related Rights")}
</div>
    <div style={{  borderLeftColor: titleHover === 1 ? '#d4155b' : 'lightgrey',left: Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12), height:  Math.floor(props.style.height / 5), top:  Math.floor(props.style.width / 4) }} className="vl" ></div>

<div title={getTranslationMap(selectedTrans, "Economic Rights")} onClick={() => { props.dispatch(props.setSelectedModal("m_economic-rights"))}}
 onMouseOver={() =>  { console.debug("museover6"); setTitleHover(2) }}
 onMouseOut={() => { if (!props.isMobile) setTitleHover(-1)}} style={{ textOverflow: 'ellipsis', overflow: 'hidden', top:  Math.floor(props.style.height / 6),  left: Math.floor(props.style.width / 2 - props.style.width / 8.2), width: Math.floor(props.style.width / 4) }}  className="vennCircle-content-titleBox notranslate">
     {getTranslationMap(selectedTrans, "Economic Rights")}
</div>
    <div style={{ borderLeftColor: titleHover === 2 ? '#d4155b' : 'lightgrey', left: '50%', height:  Math.floor(props.style.height / 5), top:  Math.floor(props.style.width / 4) }} className="vl" ></div>

<div title={getTranslationMap(selectedTrans, "Moral Rights")} onClick={() => { props.dispatch(props.setSelectedModal("m_moral-rights"))}}
style={{ textOverflow: 'ellipsis', overflow: 'hidden', top:  Math.floor(props.style.height / 4)-4, right: Math.floor(props.style.width / 6), width: Math.floor(props.style.width / 6) }}  className="vennCircle-content-titleBox vennCircle-content-titleBox--active notranslate">
    {getTranslationMap(selectedTrans, "Moral Rights")}
</div>
    <div style={{ borderLeft: '1px solid black', right: Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12), height:  Math.floor(props.style.height / 18), top:  Math.floor(props.style.width / 3) }} className="vl" ></div>
    <div style={{ right: Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12) - 5  , top:  Math.floor(props.style.width / 3)+ Math.floor(props.style.height / 18) - 6 }} className="triangle_down" ></div>
    <img alt="icon vennCircle" className="vennCircle-content__crossIcon"  src={iconCross} style={{ height: Math.floor(props.style.width / 28), right: -Math.floor(props.style.width / 28)/2 +  Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12) , top:   Math.floor(props.style.width / 28 *0.2) + 12 + Math.floor(props.style.width / 3)+ Math.floor(props.style.height / 18) - 6 }} alt="cross-icon"/>

<div  style={{ zIndex:3, borderTopColor: titleHover === 1 || titleHover === 2 ? '#d4155b' : 'lightgrey', left: Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12) - 2, width: -Math.floor(props.style.width / 6) +Math.floor(props.style.width - Math.floor(props.style.width / 6)) - (Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12)), top: Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4)  }} className="hl"></div>

<div  style={{ zIndex:4, borderTopColor: titleHover === 1 ? '#d4155b' : 'lightgrey', left: Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12) - 2, width: -1-Math.floor(props.style.width / 2) +Math.floor(props.style.width - Math.floor(props.style.width / 6)) - (Math.floor(props.style.width / 6) + Math.floor(props.style.width / 12)), top: Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4)  }} className="hl"></div>

   <div style={{  borderLeftColor: titleHover === 1 || titleHover === 2 ? '#d4155b' : 'lightgrey', left: 2* Math.floor(props.style.width / 6), height:  Math.floor(props.style.height / 24), top: -2 + Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4)  }} className="vl" ></div>
   <div style={{  borderTopColor: titleHover === 1 || titleHover === 2 ? '#d4155b' : 'lightgrey', left: -8 + 2* Math.floor(props.style.width / 6), top: -2 + Math.floor(props.style.height / 24) + Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4) }} className="triangle_down triangle_down--big" ></div>


    <div style={{  borderLeftColor: titleHover === 1 || titleHover === 2 ? '#d4155b' : 'lightgrey', right: 2*Math.floor(props.style.width / 6), height:  Math.floor(props.style.height / 24), top: -2 + Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4)  }} className="vl" ></div>
    <div style={{ borderTopColor: titleHover === 1 || titleHover === 2 ? '#d4155b' : 'lightgrey', right: -6 + 2*Math.floor(props.style.width / 6), top: -2 + Math.floor(props.style.height / 24) +Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4) }} className="triangle_down triangle_down--big" ></div>

<div style={{ lineHeight: Math.floor(props.style.height / 3 /8) + "px", top: 32 +  Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4) ,  right:  Math.floor(props.style.width / 8) + Math.floor(props.style.width / 12), width: Math.floor(props.style.width / 4) }} className="vennCircle-content__ContentBox">
    <div className="vennCircle-content__ContentBox__title notranslate">{getTranslationMap(selectedTrans, "Assignment")}</div>
    <AssignmentR
     onClick={() => {  window.location.hash = "assignment"; props.dispatch(props.setSelectedModal("GIVING PERMISSION")) }}
    onMouseOver={() => { setAssignmentExpended(true) }}
     onMouseOut={() => {if (!props.isMobile) setAssignmentExpended(false)}} isActive={assignmentExpended || isMobile} style={{ width: '100%', height: '4vw', display: 'inline', cursor: 'pointer' }} />

<br/>
<br/>
    </div>

    <div style={{ lineHeight: Math.floor(props.style.height / 3 /8) + "px", top: 32 +  Math.floor(props.style.height / 5) + Math.floor(props.style.width / 4) ,  left: Math.floor(props.style.width / 8) + Math.floor(props.style.width / 12), width: Math.floor(props.style.width / 4) }} className="vennCircle-content__ContentBox">
        <div className="vennCircle-content__ContentBox__title notranslate">{getTranslationMap(selectedTrans, "Licences")}</div>
        <div onClick={() => {
window.location.hash = "exclusive-non-exclusive";
props.dispatch(props.setSelectedModal("GIVING PERMISSION")) }} className="vennCircle-content__ContentBox__item notranslate">{getTranslationMap(selectedTrans, "Exclusive")}</div>
        <div onClick={() => {
window.location.hash = "exclusive-non-exclusive";
props.dispatch(props.setSelectedModal("GIVING PERMISSION")) }} className="vennCircle-content__ContentBox__item notranslate">{getTranslationMap(selectedTrans, "Non-Exclusive")}</div>
        <div onClick={() => { props.dispatch(props.setSelectedModal("COLLECTING SOCIETIES")) }} className="vennCircle-content__ContentBox__item notranslate">{getTranslationMap(selectedTrans, "Collective")}</div>
        <div onClick={() => {
        window.location.hash = "creative-commons";
        props.dispatch(props.setSelectedModal("GIVING PERMISSION")) }} onMouseOut={() => { if (!props.isMobile) document.getElementById("Layer_3").style.opacity = 0.6}}
        onMouseOver={() => {  console.debug("museover9"); document.getElementById("Layer_3").style.opacity = 1 }} className="vennCircle-content__ContentBox__item vennCircle-content__ContentBox__item--blue notranslate">{getTranslationMap(selectedTrans, "Open")}</div>
    </div>


    </div>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 607.56 607.56"}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-r-6{opacity:.2}.cls-moral-1{opacity:.05}.cls-e-6{opacity:.2}.cls-moral-5{fill:none;stroke:#000;stroke-miterlimit:10}.cls-moral-6{opacity:.2}.cls-moral-7{opacity:.3}.cls-Venn1-1{opacity:.05}.cls-Venn1-3{fill:none;stroke:#000;stroke-miterlimit:10}.cls-Venn1-6{opacity:.3}"
        }
      </style>
      <pattern
        id="Motivo_nuevo_2"
        x={0}
        y={0}
        width={179.4}
        height={310.5}
        patternTransform={ isMobile ? "translate(-252.8 9023.78) scale(2.5)" : "translate(-252.8 9023.78)" }
        patternUnits="userSpaceOnUse"
      >
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h179.4v310.5H0z"
        />
        <path className="cls-Venn1-1-2" d="m179.4 207.01-89.63 51.74v103.5" />
        <path className="cls-Venn1-1-2" d="m194.34 215.63-89.63 51.75v103.49" />
        <path className="cls-Venn1-1-2" d="M209.28 224.25 119.65 276v103.5" />
        <path className="cls-Venn1-1-2" d="m224.21 232.88-89.63 51.75v103.49" />
        <path className="cls-Venn1-1-2" d="m239.15 241.5-89.63 51.75v103.5" />
        <path className="cls-Venn1-1-2" d="m254.09 250.13-89.63 51.75v103.49" />
        <path
          className="cls-Venn1-1-2"
          d="M269.03 258.75 179.4 310.5V414M179.4 310.5l89.63-51.75M269.03 276l-89.63 51.75M269.03 293.25 179.4 345M269.03 310.5l-89.63 51.75"
        />
        <circle className="cls-Venn1-1-4" cx={179.32} cy={310.53} r={18.08} />
        <path d="M168.5 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-Venn1-1-2" d="m0 207.01-89.63 51.74v103.5" />
        <path className="cls-Venn1-1-2" d="m14.94 215.63-89.63 51.75v103.49" />
        <path className="cls-Venn1-1-2" d="M29.88 224.25-59.75 276v103.5" />
        <path className="cls-Venn1-1-2" d="m44.81 232.88-89.63 51.75v103.49" />
        <path className="cls-Venn1-1-2" d="m59.75 241.5-89.63 51.75v103.5" />
        <path className="cls-Venn1-1-2" d="m74.69 250.13-89.63 51.75v103.49" />
        <path
          className="cls-Venn1-1-2"
          d="M89.63 258.75 0 310.5V414M0 310.5l89.63-51.75M89.63 276 0 327.75M89.63 293.25 0 345M89.63 310.5 0 362.25"
        />
        <circle className="cls-Venn1-1-4" cx={-0.08} cy={310.53} r={18.08} />
        <path d="M-10.9 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-Venn1-1-2"
          d="m269.1 51.76-89.63 51.74V207M89.7 51.76.07 103.5V207"
        />
        <path className="cls-Venn1-1-2" d="m104.64 60.38-89.63 51.75v103.49" />
        <path className="cls-Venn1-1-2" d="m119.58 69-89.63 51.75v103.5" />
        <path className="cls-Venn1-1-2" d="m134.51 77.63-89.63 51.75v103.49" />
        <path className="cls-Venn1-1-2" d="M149.45 86.25 59.82 138v103.5" />
        <path className="cls-Venn1-1-2" d="m164.39 94.88-89.63 51.75v103.49" />
        <path
          className="cls-Venn1-1-2"
          d="M179.33 103.5 89.7 155.25v103.5M89.7 155.25l89.63-51.75M179.33 120.75 89.7 172.5M179.33 138 89.7 189.75M179.33 155.25 89.7 207M179.33 172.5 89.7 224.25M179.33 189.75 89.7 241.5M179.33 207 89.7 258.75"
        />
        <circle className="cls-Venn1-1-4" cx={179.62} cy={207.28} r={18.08} />
        <path d="M168.8 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-Venn1-1-4" cx={89.62} cy={155.28} r={18.08} />
        <path d="M78.8 173.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-Venn1-1-2"
          d="m-.07 103.5-89.63 51.75v103.5M-89.7 155.25-.07 103.5M-.07 120.75-89.7 172.5M-.07 138l-89.63 51.75M-.07 155.25-89.7 207M-.07 172.5l-89.63 51.75M-.07 189.75-89.7 241.5M-.07 207l-89.63 51.75"
        />
        <circle className="cls-Venn1-1-4" cx={0.22} cy={207.28} r={18.08} />
        <path d="M-10.6 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-Venn1-1-2" d="M179.4-103.49 89.77-51.75v103.5" />
        <path className="cls-Venn1-1-2" d="m194.34-94.87-89.63 51.75V60.37" />
        <path className="cls-Venn1-1-2" d="M209.28-86.25 119.65-34.5V69" />
        <path className="cls-Venn1-1-2" d="m224.21-77.62-89.63 51.75V77.62" />
        <path className="cls-Venn1-1-2" d="m239.15-69-89.63 51.75v103.5" />
        <path className="cls-Venn1-1-2" d="M254.09-60.37 164.46-8.62V94.87" />
        <path
          className="cls-Venn1-1-2"
          d="M269.03-51.75 179.4 0v103.5M179.4 0l89.63-51.75M269.03-34.5 179.4 17.25M269.03-17.25 179.4 34.5M269.03 0 179.4 51.75M269.03 17.25 179.4 69M269.03 34.5 179.4 86.25M269.03 51.75 179.4 103.5"
        />
        <circle className="cls-Venn1-1-4" cx={179.32} cy={0.03} r={18.08} />
        <path d="M168.5 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-Venn1-1-2" d="m0-103.49-89.63 51.74v103.5" />
        <path className="cls-Venn1-1-2" d="m14.94-94.87-89.63 51.75V60.37" />
        <path className="cls-Venn1-1-2" d="M29.88-86.25-59.75-34.5V69" />
        <path className="cls-Venn1-1-2" d="m44.81-77.62-89.63 51.75V77.62" />
        <path className="cls-Venn1-1-2" d="m59.75-69-89.63 51.75v103.5" />
        <path className="cls-Venn1-1-2" d="M74.69-60.37-14.94-8.62V94.87" />
        <path
          className="cls-Venn1-1-2"
          d="M89.63-51.75 0 0v103.5M0 0l89.63-51.75M89.63-34.5 0 17.25M89.63-17.25 0 34.5M89.63 0 0 51.75M89.63 17.25 0 69M89.63 34.5 0 86.25M89.63 51.75 0 103.5"
        />
        <circle className="cls-Venn1-1-4" cx={89.92} cy={52.03} r={18.08} />
        <path d="M79.1 70.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-Venn1-1-4" cx={-0.08} cy={0.03} r={18.08} />
        <path d="M-10.9 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.78-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32ZM-4.72 8.91c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57C5.72-7.61 6.3-6.21 6.3-4.48v.06c0 .39-.19.58-.58.58L4.33-3.9c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
      </pattern>
      <style>
        {
          ".cls-Venn1-1-2{fill:none}.cls-Venn1-1-4{fill:#d4155b;stroke:#d4155b;stroke-miterlimit:10}.cls-Venn1-1-2{stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}"
        }
      </style>
    </defs>
    <g id="Venn1_Layer_1-2"
    onMouseOut={(e) => {
    if (props.isMobile) return;
        if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;

        console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);

        if((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial")}
    }
    onMouseOver={(e) => {
    e.stopPropagation();
     console.debug("museover10");
        if (props.selectedModal === "m_exploitation" || props.isMobile) return;
        props.updateVennState("leftHover")}
    }>
     <path
     onClick={(e) => {
        e.stopPropagation();
        console.debug("click and stopped ?");
        if (props.isMobile && props.vennState === 1) {
        console.debug("revert and stopped ?");
          props.updateVennState("initial");
        } else if (props.isMobile) {
          props.updateVennState("leftHover");
        }
     }}
        d={ props.vennState === 1 && !props.isMobile ? "M 303.78, 303.78 m -303.78, 0 a 303.78,303.78 0 1,0 607.56,0 a 303.78,303.78 0 1,0 -607.56,0" : "M392.44 303.78c0-92.97 41.78-176.16 107.56-231.89C447.06 27.06 378.59 0 303.78 0 136.01 0 0 136.01 0 303.78s136.01 303.78 303.78 303.78c74.81 0 143.28-27.06 196.22-71.89-65.79-55.72-107.56-138.92-107.56-231.89Z" }
        style={{
          fill: "#d4155b",
        }}
      />
      <path
        d={ props.selectedModal === "m_exploitation" ? "M303.78, 303.78 m -303.78, 0 a 303.78,303.78 0 1,0 607.56,0 a 303.78,303.78 0 1,0 -607.56,0" : "M392.44 303.78c0-92.97 41.78-176.16 107.56-231.89C447.06 27.06 378.59 0 303.78 0 136.01 0 0 136.01 0 303.78s136.01 303.78 303.78 303.78c74.81 0 143.28-27.06 196.22-71.89-65.79-55.72-107.56-138.92-107.56-231.89Z"}
        style={{
            pointerEvents: "none",
        visibility: (props.vennState === 1 && !props.isMobile && props.selectedModal !== "m_exploitation") ? 'hidden' : 'visible',
            opacity:  props.vennState === 1 && !props.isMobile && props.selectedModal !== "m_exploitation" ? 0 : props.selectedModal === "m_use--with-permission" ? .3 : props.vennState === 1 && props.isMobile ? .3 : 1,
            fill: props.selectedModal === "m_use--without-permission" ? "lightgrey" : "url(#Motivo_nuevo_2)",
        }}
      />

      { props.vennState === 1 && <>
        {!props.isMobile && <>
      <image  className={'vennInnerGraphic'} href={circleGuidance} x="145" y="145" height="320" width="320" />
      <path
        onClick={() => { if (!props.isMobile) props.dispatch(props.setSelectedModal("m_economic-rights"))} }
        onMouseOver={() => { console.debug("museover1");
        if (props.selectedModal === "m_exploitation") return;
         props.updateVennActive("e")}}
        className="notOnHover"
        style={{ opacity: props.vennActive === "e" ? 1 : .05, fill: props.vennActive === "e" ? "#ed7094" : "initial" }}
        d="M303.78 137.02c60.51 0 113.49 32.23 142.72 80.46l108.79-61.63c-12.48-21.21-27.59-40.76-45.2-58.37-26.75-26.75-57.94-47.77-92.7-62.48-36.05-15.25-74.27-22.98-113.61-22.98S226.22 19.75 190.17 35c-34.76 14.7-65.95 35.72-92.7 62.48-18.64 18.64-34.5 39.45-47.37 62.11l107.78 63.4c28.45-51.26 83.12-85.97 145.9-85.97Z"
      />
      <path
        onClick={() => { if (!props.isMobile) props.dispatch(props.setSelectedModal("m_related-rights"))} }
        onMouseOver={() => { console.debug("museover2");
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("r")}}
        className="notOnHover"
        style={{
          fill: props.vennActive === "r" ? "#ed7094" : "initial",
          opacity: props.vennActive === "r" ? 1 : .05 }}
        d="M303.78 470.54c-92.1 0-166.76-74.66-166.76-166.76 0-29.32 7.57-56.86 20.86-80.8L50.1 159.58c-5.6 9.86-10.65 20.06-15.1 30.59-15.25 36.05-22.98 74.27-22.98 113.61S19.75 381.34 35 417.39c14.7 34.76 35.72 65.95 62.48 92.7 26.75 26.75 57.94 47.77 92.7 62.48 36.05 15.25 74.27 22.98 113.61 22.98.67 0 1.33-.02 1.99-.02l1.02-125.01c-1 .02-2.01.04-3.01.04Z"
      />
      <path
        onClick={() => { if (!props.isMobile) props.dispatch(props.setSelectedModal("m_moral-rights"))} }
        onMouseOver={() => {  console.debug("museover3"); props.updateVennActive("m") }}
        className="notOnHover"
        style={{
          fill: props.vennActive === "m" ? "#ed7094" : "initial",
          opacity: props.vennActive === "m" ? 1 : .05,
        }}
        d="M572.56 190.17a290.761 290.761 0 0 0-17.28-34.33l-108.79 61.63c15.26 25.18 24.05 54.72 24.05 86.31 0 91.09-73.04 165.12-163.75 166.73l-1.02 125.01c38.63-.26 76.17-7.96 111.61-22.96 34.76-14.7 65.95-35.72 92.7-62.48 26.75-26.75 47.77-57.94 62.48-92.7 15.25-36.05 22.98-74.27 22.98-113.61s-7.73-77.56-22.98-113.61Z"
      />
      { props.vennActive === "m" && <path
        className="cls-moral-6"
        d="M95.34 95.34c27.07-27.08 58.6-48.33 93.7-63.18C225.38 16.79 263.98 9 303.78 9s78.4 7.79 114.74 23.16c35.1 14.85 66.63 36.1 93.7 63.18 17.82 17.82 33.1 37.58 45.71 59l7.81-4.43C512.94 60.21 415.4 0 303.78 0S92.03 61.81 39.74 153.48l7.74 4.55c13.01-22.86 29.02-43.86 47.86-62.7ZM303.78 598.56c-39.8 0-78.4-7.79-114.74-23.16-35.1-14.85-66.63-36.1-93.7-63.18-27.08-27.08-48.33-58.6-63.18-93.7C16.79 382.18 9 343.58 9 303.78s7.79-78.4 23.16-114.74c4.52-10.68 9.63-21.02 15.32-31l-7.74-4.55C14.45 197.8 0 249.1 0 303.78c0 167.77 136.01 303.78 303.78 303.78.63 0 1.26-.02 1.89-.02l.07-9c-.66 0-1.31.02-1.97.02Z"
      />}
      { props.vennActive === "r" && <>
        <path
            style={{
              fill: "#f6d0de",
            }}
            d="M303.78 598.56c-39.8 0-78.4-7.79-114.74-23.16-35.1-14.85-66.63-36.1-93.7-63.18-27.08-27.08-48.33-58.6-63.18-93.7C16.79 382.18 9 343.58 9 303.78s7.79-78.4 23.16-114.74c4.52-10.68 9.63-21.02 15.32-31l-7.74-4.55C14.45 197.8 0 249.1 0 303.78c0 167.77 136.01 303.78 303.78 303.78.63 0 1.26-.02 1.89-.02l.07-9c-.66 0-1.31.02-1.97.02Z"
      />

      <path
        className="cls-r-6"
        d="M95.34 95.34c27.07-27.08 58.6-48.33 93.7-63.18C225.38 16.79 263.98 9 303.78 9s78.4 7.79 114.74 23.16c35.1 14.85 66.63 36.1 93.7 63.18 17.82 17.82 33.1 37.58 45.71 59l7.81-4.43C512.94 60.21 415.4 0 303.78 0S92.03 61.81 39.74 153.48l7.74 4.55c13.01-22.86 29.02-43.86 47.86-62.7Z"
      />
      <path
        className="cls-r-6"
        d="M607.56 303.78c0-56.15-15.25-108.74-41.82-153.86l-7.81 4.43c6.55 11.13 12.39 22.7 17.47 34.7 15.37 36.34 23.16 74.94 23.16 114.74s-7.79 78.4-23.16 114.74c-14.85 35.1-36.1 66.63-63.18 93.7-27.08 27.08-58.6 48.33-93.7 63.18-35.74 15.12-73.67 22.88-112.77 23.14l-.07 9c166.9-1.02 301.89-136.62 301.89-303.76Z"
      />
</>}
       </>}

      { props.vennActive === "e" && <><path
            d="M95.34 95.34c27.07-27.08 58.6-48.33 93.7-63.18C225.38 16.79 263.98 9 303.78 9s78.4 7.79 114.74 23.16c35.1 14.85 66.63 36.1 93.7 63.18 17.82 17.82 33.1 37.58 45.71 59l7.81-4.43C512.94 60.21 415.4 0 303.78 0S92.03 61.81 39.74 153.48l7.74 4.55c13.01-22.86 29.02-43.86 47.86-62.7Z"
            style={{
              fill: "#f6d0de",
            }}
          />
        <path
        className="cls-e-6"
        d="M303.78 598.56c-39.8 0-78.4-7.79-114.74-23.16-35.1-14.85-66.63-36.1-93.7-63.18-27.08-27.08-48.33-58.6-63.18-93.7C16.79 382.18 9 343.58 9 303.78s7.79-78.4 23.16-114.74c4.52-10.68 9.63-21.02 15.32-31l-7.74-4.55C14.45 197.8 0 249.1 0 303.78c0 167.77 136.01 303.78 303.78 303.78.63 0 1.26-.02 1.89-.02l.07-9c-.66 0-1.31.02-1.97.02ZM607.56 303.78c0-56.15-15.25-108.74-41.82-153.86l-7.81 4.43c6.55 11.13 12.39 22.7 17.47 34.7 15.37 36.34 23.16 74.94 23.16 114.74s-7.79 78.4-23.16 114.74c-14.85 35.1-36.1 66.63-63.18 93.7-27.08 27.08-58.6 48.33-93.7 63.18-35.74 15.12-73.67 22.88-112.77 23.14l-.07 9c166.9-1.02 301.89-136.62 301.89-303.76Z"
      />
      </>}
      <path
      className="notOnHover"
        style={{
            opacity: props.vennActive === "m" ?  1 : .2,
            fill: props.vennActive === "m" ? "#f6d0de" : "#f6d0de"
        }}
        d={ props.vennActive === "m" ? "M607.56 303.78c0-56.15-15.25-108.74-41.82-153.86l-7.81 4.43c6.55 11.13 12.39 22.7 17.47 34.7 15.37 36.34 23.16 74.94 23.16 114.74s-7.79 78.4-23.16 114.74c-14.85 35.1-36.1 66.63-63.18 93.7-27.08 27.08-58.6 48.33-93.7 63.18-35.74 15.12-73.67 22.88-112.77 23.14l-.07 9c166.9-1.02 301.89-136.62 301.89-303.76Z" : "M95.34 95.34c27.07-27.08 58.6-48.33 93.7-63.18C225.38 16.79 263.98 9 303.78 9s78.4 7.79 114.74 23.16c35.1 14.85 66.63 36.1 93.7 63.18 17.82 17.82 33.1 37.58 45.71 59l7.81-4.43C512.94 60.21 415.4 0 303.78 0S92.03 61.81 39.74 153.48l7.74 4.55c13.01-22.86 29.02-43.86 47.86-62.7ZM303.78 598.56c-39.8 0-78.4-7.79-114.74-23.16-35.1-14.85-66.63-36.1-93.7-63.18-27.08-27.08-48.33-58.6-63.18-93.7C16.79 382.18 9 343.58 9 303.78s7.79-78.4 23.16-114.74c4.52-10.68 9.63-21.02 15.32-31l-7.74-4.55C14.45 197.8 0 249.1 0 303.78c0 167.77 136.01 303.78 303.78 303.78.63 0 1.26-.02 1.89-.02l.07-9c-.66 0-1.31.02-1.97.02ZM607.56 303.78c0-56.15-15.25-108.74-41.82-153.86l-7.81 4.43c6.55 11.13 12.39 22.7 17.47 34.7 15.37 36.34 23.16 74.94 23.16 114.74s-7.79 78.4-23.16 114.74c-14.85 35.1-36.1 66.63-63.18 93.7-27.08 27.08-58.6 48.33-93.7 63.18-35.74 15.12-73.67 22.88-112.77 23.14l-.07 9c166.9-1.02 301.89-136.62 301.89-303.76Z"}
      />
      <g className="cls-Venn1-6">
        <path
          className="cls-Venn1-3"
          d="m557.93 154.34 7.81-4.42M446.5 217.47l108.79-61.63"
        />
      </g>
      <g className="cls-Venn1-6">
        <path
          className="cls-Venn1-3"
          d="m47.48 158.04-7.74-4.56M157.88 222.98 50.1 159.58"
        />
      </g>
      <g
        style={{
          opacity: 0.3,
        }}
      >
        <path
          className="cls-Venn1-3"
          d="m305.75 598.54-.08 9M306.79 470.51l-1.02 125.01"
        />
      </g>
      </>}
        <circle style={{ fill: 'white'}} cx={303.44} cy={303.44} r={ props.selectedModal === "m_exploitation" ? 270 : 0} />
    </g>
  </svg>
  </>
)}

export default SvgComponent
