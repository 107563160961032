import * as React from "react"
import { getTranslationMap } from '../../features/translationMap';

import circleBlue from '../../assets/innerBlue.png';
const SvgComponent = (props) => {

const {selectedTrans } = props;

return (<>
{ props.vennState === 1 && <div

style={{
    position: 'absolute',
//     right: '0',
//     right: (props.titleStyleMargins.sizeFactor * 10) + 'px',
    width: props.styleCircleVenn.width,
    height: props.styleCircleVenn.width,//'100%',
    pointerEvents: 'none',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'center',
       display: 'inline',

}}
><div
style={{
    width: props.styleCircleVenn.width,
    height: props.styleCircleVenn.width,//'100%',
        position: 'absolute'
}}
className="vennTitles">
{ props.isMobile ? <>
        <div className={"notranslate"} style={{ color: 'white', top: props.styleCircleVenn.width *0.4, left: props.styleCircleVenn.width *0.36, fontWeight: 800 }}>
        {getTranslationMap(selectedTrans, "Public Domain")}
        </div>
        </>
    : <>
        <div className={"notranslate"} style={{ top: props.styleCircleVenn.width *0.185, left: props.styleCircleVenn.width *0.15, fontWeight: (props.vennActive === "de" ) ? 800 : 400 }}>
        {getTranslationMap(selectedTrans, "De Minimis")}
        </div>
        <div className={"notranslate"} style={{ top: props.styleCircleVenn.width *0.185, right: props.styleCircleVenn.width *0.17, fontWeight: (props.vennActive === "ex" ) ? 800 : 400 }}>
        {getTranslationMap(selectedTrans, "Exceptions")}
        </div>
        <div className={"notranslate"} style={{ top: props.styleCircleVenn.width *0.58, right: props.styleCircleVenn.width *0.056,fontWeight: (props.vennActive === "op" ) ? 800 : 400 }}>
        {getTranslationMap(selectedTrans, "Open Licences")}
        </div>
        <div className={"notranslate"} style={{ top: props.styleCircleVenn.width *0.58, left: props.styleCircleVenn.width *0.06,fontWeight: (props.vennActive === "id" ) ? 800 : 400 }}>
        {getTranslationMap(selectedTrans, "Ideas")}
        </div>
        <div className={"notranslate"} style={{ top: props.styleCircleVenn.width *0.82, left: props.styleCircleVenn.width *0.5-59,fontWeight: (props.vennActive === "ou" ) ? 800 : 400 }}>
        {getTranslationMap(selectedTrans, "Out of copyright works")}
        </div>
    </>}
</div>
</div> }
  <svg
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 394 607.56"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-none-1{opacity:.05}.cls-none-3{fill:none;stroke:#000;stroke-miterlimit:10}.cls-none-4{opacity:.2}.cls-none-5{opacity:.3}"
        }
      </style>
      <clipPath id="clippath">
        <path
          className="cls-Venn3-3-3-3"
          d="M196.22 0C121.41 0 52.94 27.06 0 71.89c65.79 55.72 107.56 138.92 107.56 231.89S65.79 479.94 0 535.67c52.94 44.84 121.41 71.89 196.22 71.89C363.99 607.56 500 471.55 500 303.78S363.99 0 196.22 0Z"
        />
      </clipPath>
      <pattern
        id="New_Pattern_Swatch_8"
        x={0}
        y={0}
        width={444.87}
        height={444.87}
        patternTransform={ props.isMobile ? "translate(-4190.76 7083.15) scale(2.75)" : "translate(-4190.76 7083.15)" }
        patternUnits="userSpaceOnUse"
      >
        <path className="cls-Venn3-3-1" d="M0 0h444.87v444.87H0z" />
        <path
          className="cls-Venn3-3-1"
          d="M404.32 438.96c-.28.01-.57.01-.85.02.21.17.43.33.64.51.07-.18.14-.35.21-.53ZM370.79 430.64c.68-.12 1.37-.22 2.06-.3-.8-.52-1.59-1.06-2.36-1.62.11.64.21 1.27.3 1.92ZM135.49 10.74c-.09-.14-.17-.27-.26-.41-.05.26-.09.53-.14.79.13-.13.26-.26.4-.38ZM182.52 255.09c.14-.11.28-.23.43-.34-.04-.1-.09-.2-.14-.31-.1.21-.19.43-.29.64ZM194.01 346.96s.03-.09.04-.13c-.04-.02-.09-.04-.13-.06.03.06.06.12.08.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M62.9 29.6c-.83-.99-1.61-2.03-2.34-3.1-.51 2.25-1.16 4.45-1.97 6.58 1.37-1.23 2.81-2.39 4.31-3.48ZM43.99 53.49c.12.04.25.07.37.11.08-.22.17-.43.26-.65-.21.18-.41.37-.63.54ZM3.58 63.85c-1.21-.23-2.4-.5-3.58-.82v4.88c1.11-1.43 2.3-2.79 3.58-4.06ZM16.22 55.29c-1.14.1-2.29.16-3.46.16-4.47 0-8.75-.75-12.76-2.11v7.84c1.7.48 3.43.87 5.2 1.15a40.93 40.93 0 0 1 11.01-7.04ZM28.79 52.07c1.26-.12 2.54-.18 3.83-.18 3.16 0 6.22.37 9.18 1.04 1.7-1.33 3.31-2.78 4.82-4.33 2.3-4.46 5.2-8.57 8.6-12.2 1.84-3.77 3.19-7.81 3.98-12.06a41.036 41.036 0 0 1-5.8-21.09c0-1.1.06-2.18.14-3.26h-4.26a39.547 39.547 0 0 1 3.23 15.71c0 16.24-9.75 30.2-23.71 36.36Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M46.61 48.6a49.467 49.467 0 0 1-4.82 4.33c.74.17 1.47.36 2.19.56l.63-.54c.6-1.48 1.27-2.93 2-4.34ZM5.2 62.33c-1.77-.29-3.5-.68-5.2-1.15v1.86c1.18.32 2.37.59 3.58.82.53-.52 1.07-1.03 1.62-1.52ZM58.59 33.08c.81-2.13 1.46-4.32 1.97-6.58-.48-.7-.94-1.42-1.38-2.16A47.19 47.19 0 0 1 55.2 36.4c1.08-1.16 2.21-2.27 3.39-3.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M50.72 15.71c0-5.6-1.23-10.92-3.42-15.71h-8c2.73 4.6 4.3 9.97 4.3 15.71 0 17.03-13.81 30.84-30.84 30.84-4.55 0-8.87-.99-12.76-2.77v7.67c3.99 1.43 8.28 2.21 12.76 2.21 20.93 0 37.96-17.03 37.96-37.96Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M16.22 55.29c3.91-1.7 8.14-2.81 12.57-3.22C42.75 45.91 52.5 31.95 52.5 15.71c0-5.58-1.16-10.89-3.23-15.71h-1.96a37.692 37.692 0 0 1 3.42 15.71c0 20.93-17.03 37.96-37.96 37.96-4.48 0-8.77-.78-12.76-2.21v1.89c4.01 1.36 8.29 2.11 12.76 2.11 1.17 0 2.32-.06 3.46-.16Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M41.82 15.71c0-5.79-1.71-11.18-4.63-15.71h-9.12c4.09 3.99 6.63 9.55 6.63 15.71 0 12.12-9.82 21.94-21.94 21.94C8 37.65 3.6 36.13 0 33.55v8.26c3.85 1.89 8.18 2.96 12.76 2.96 16.02 0 29.06-13.04 29.06-29.06Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M43.6 15.71C43.6 9.97 42.03 4.6 39.3 0h-2.11c2.93 4.53 4.63 9.93 4.63 15.71 0 16.02-13.04 29.06-29.06 29.06-4.58 0-8.91-1.07-12.76-2.96v1.97c3.89 1.77 8.21 2.77 12.76 2.77 17.03 0 30.84-13.81 30.84-30.84Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.92 15.71c0-6.35-2.95-12.01-7.55-15.71H.15L0 .12v12.89C1.24 7.1 6.48 2.67 12.76 2.67c7.2 0 13.04 5.84 13.04 13.04s-5.84 13.04-13.04 13.04C6.48 28.75 1.25 24.32 0 18.41V31.3c3.48 2.85 7.92 4.56 12.76 4.56 11.12 0 20.16-9.04 20.16-20.16Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M34.7 15.71c0-6.16-2.54-11.73-6.63-15.71h-2.69c4.6 3.7 7.55 9.37 7.55 15.71 0 11.12-9.04 20.16-20.16 20.16-4.84 0-9.28-1.71-12.76-4.56v2.25c3.6 2.58 8 4.1 12.76 4.1 12.12 0 21.94-9.82 21.94-21.94ZM0 0v.12L.15 0H0Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M12.76 4.45c6.21 0 11.26 5.05 11.26 11.26s-5.05 11.26-11.26 11.26S1.5 21.92 1.5 15.71 6.55 4.45 12.76 4.45ZM8.61 15.71c0 2.29 1.86 4.15 4.15 4.15s4.15-1.86 4.15-4.15-1.86-4.15-4.15-4.15-4.15 1.86-4.15 4.15Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M0 18.41v-5.4C1.24 7.1 6.48 2.67 12.76 2.67c7.2 0 13.04 5.84 13.04 13.04s-5.84 13.04-13.04 13.04C6.48 28.75 1.25 24.32 0 18.41ZM12.76 4.45C6.55 4.45 1.5 9.5 1.5 15.71s5.05 11.26 11.26 11.26 11.26-5.05 11.26-11.26S18.97 4.45 12.76 4.45Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M10.39 15.71c0 1.3 1.06 2.37 2.37 2.37s2.37-1.06 2.37-2.37-1.06-2.37-2.37-2.37-2.37 1.06-2.37 2.37Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M8.61 15.71c0-2.29 1.86-4.15 4.15-4.15s4.15 1.86 4.15 4.15-1.86 4.15-4.15 4.15S8.61 18 8.61 15.71Zm4.15-2.37c-1.3 0-2.37 1.06-2.37 2.37s1.06 2.37 2.37 2.37 2.37-1.06 2.37-2.37-1.06-2.37-2.37-2.37Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M416.11 41.74c-.49 1.21-1.03 2.39-1.62 3.54 2.03.65 4 1.42 5.92 2.3a49.215 49.215 0 0 1-4.3-5.83ZM411.21 0h-1.69c.41.49.81 1 1.2 1.51.15-.51.32-1.01.49-1.51ZM444.22 68.77c.21-.29.43-.57.65-.86v-4.88c-2.27-.61-4.47-1.37-6.59-2.29 2.22 2.48 4.21 5.16 5.94 8.02ZM417.92 16.74c0-.34-.03-.68-.03-1.02 0-5.58 1.16-10.89 3.23-15.71h-8.03c-.39 1.1-.74 2.23-1.05 3.37 2.7 4.01 4.71 8.53 5.87 13.37ZM419.04 25.18c.01.4.03.81.03 1.21 0 4.62-.77 9.05-2.17 13.19a47.45 47.45 0 0 0 8.45 10.56c3.24 1.91 6.27 4.14 9.04 6.66 3.27 1.86 6.79 3.34 10.48 4.37v-7.84c-12.76-4.32-22.6-14.94-25.83-28.17Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M412.05 3.37c.31-1.14.66-2.26 1.05-3.37h-1.89c-.17.5-.34 1-.49 1.51.46.61.91 1.22 1.33 1.85ZM425.35 50.14a47.678 47.678 0 0 1-8.45-10.56c-.25.73-.51 1.44-.79 2.15 1.29 2.05 2.73 4 4.3 5.83 1.7.78 3.35 1.63 4.95 2.57ZM438.28 60.75c2.12.91 4.33 1.68 6.59 2.29v-1.86c-3.7-1.04-7.21-2.52-10.48-4.37a55.59 55.59 0 0 1 3.89 3.94Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M444.87 51.46v-7.67c-10.66-4.85-18.08-15.59-18.08-28.07 0-5.74 1.57-11.11 4.3-15.71h-8a37.692 37.692 0 0 0-3.42 15.71c0 16.45 10.53 30.49 25.2 35.74Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M417.92 16.74a41.85 41.85 0 0 1 1.12 8.44c3.23 13.22 13.08 23.84 25.83 28.17v-1.89c-14.67-5.25-25.2-19.29-25.2-35.74 0-5.6 1.23-10.92 3.42-15.71h-1.96a39.547 39.547 0 0 0-3.23 15.71c0 .34.02.68.03 1.02Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M444.87 41.81v-8.26c-5.56-3.98-9.18-10.49-9.18-17.84 0-6.16 2.54-11.73 6.63-15.71h-9.12a28.846 28.846 0 0 0-4.63 15.71c0 11.45 6.66 21.37 16.3 26.1Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M444.87 43.79v-1.97c-9.64-4.73-16.3-14.65-16.3-26.1 0-5.79 1.71-11.18 4.63-15.71h-2.11a30.68 30.68 0 0 0-4.3 15.71c0 12.48 7.42 23.22 18.08 28.07Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M437.47 15.71c0 6.28 2.89 11.9 7.4 15.6V18.42c-.18-.87-.28-1.78-.28-2.7s.1-1.83.28-2.7V.12c-4.52 3.7-7.4 9.32-7.4 15.6Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M435.69 15.71c0 7.36 3.62 13.86 9.18 17.84V31.3c-4.52-3.7-7.4-9.32-7.4-15.6s2.89-11.9 7.4-15.6v-.12h-2.55c-4.09 3.99-6.63 9.55-6.63 15.71Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M444.87 18.41v-5.4c-.18.87-.28 1.78-.28 2.7s.1 1.83.28 2.7Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M193.17 349.6a39.1 39.1 0 0 0-1.82-4.07c-3.78-1.94-7.3-4.3-10.5-7.04 6.52 6.38 10.58 15.27 10.58 25.11 0 3.37-.48 6.62-1.37 9.7 1.24.21 2.47.46 3.68.76a41.301 41.301 0 0 1-2.15-13.13c0-3.93.56-7.73 1.59-11.33ZM173.85 331.29c-.29.47-.59.93-.89 1.39 2.04 1.1 3.95 2.4 5.73 3.86a54.73 54.73 0 0 1-4.84-5.25Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M191.34 345.53a39.1 39.1 0 0 1 1.82 4.07c.26-.89.53-1.78.84-2.65-.03-.06-.06-.12-.08-.19-.87-.39-1.74-.8-2.59-1.24Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M139.99 390.9c0 .15 0 .29-.01.44.83-.96 1.68-1.9 2.57-2.8-.94-.52-1.85-1.1-2.72-1.72.1 1.35.17 2.7.17 4.07ZM128.06 357.07c.14.17.27.34.4.51.04-.18.08-.35.12-.52-.17 0-.35.01-.52.01ZM171.94 334.17a54.41 54.41 0 0 1-3.03 3.91c9.4 4.65 15.86 14.33 15.86 25.53 0 3.14-.51 6.15-1.45 8.98 1.67.07 3.32.23 4.96.45.88-2.99 1.37-6.15 1.37-9.43 0-12.73-7.18-23.82-17.7-29.44Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M180.84 338.49c-.74-.63-1.46-1.28-2.16-1.95a34.847 34.847 0 0 0-5.73-3.86c-.33.5-.67 1-1.02 1.49 10.52 5.62 17.7 16.7 17.7 29.44 0 3.27-.48 6.44-1.37 9.43.6.08 1.19.17 1.78.27.88-3.08 1.37-6.33 1.37-9.7 0-9.84-4.05-18.73-10.58-25.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M167.7 339.47a55.025 55.025 0 0 1-3.5 3.5c8.3 3.18 14.19 11.22 14.19 20.64 0 3.25-.71 6.34-1.97 9.12 1.48-.12 2.98-.2 4.5-.2.18 0 .35.01.53.01.99-2.79 1.54-5.8 1.54-8.93 0-10.64-6.26-19.85-15.29-24.13ZM129.91 359.51c1.72 2.4 3.25 4.94 4.56 7.61-.18-1.14-.28-2.32-.28-3.51 0-2.47.41-4.85 1.16-7.07-1.62.23-3.27.39-4.93.47-.21.82-.38 1.65-.51 2.5ZM137.81 375.72c.82 2.81 1.42 5.71 1.78 8.69 1.34 1.08 2.78 2.02 4.31 2.83 1.22-1.15 2.48-2.24 3.8-3.27-4.06-1.71-7.49-4.61-9.89-8.25Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M168.91 338.07c-.4.47-.8.94-1.22 1.4 9.03 4.29 15.29 13.49 15.29 24.13 0 3.13-.55 6.14-1.54 8.93.63 0 1.25.02 1.87.05.94-2.82 1.45-5.84 1.45-8.98 0-11.2-6.46-20.88-15.86-25.53ZM139.82 386.83c.87.62 1.78 1.2 2.72 1.72.44-.45.89-.88 1.35-1.31-1.53-.81-2.97-1.75-4.31-2.83.1.8.17 1.61.23 2.42ZM128.58 357.05l-.12.52c.5.63.98 1.28 1.45 1.93.13-.85.3-1.68.51-2.5-.61.03-1.22.04-1.84.05Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M162.66 344.31c-1.54 1.3-3.14 2.52-4.82 3.65 7.96.78 14.18 7.48 14.18 15.65 0 4.04-1.53 7.7-4.03 10.49 2.07-.51 4.19-.89 6.35-1.16 1.45-2.8 2.28-5.97 2.28-9.33 0-8.98-5.86-16.62-13.96-19.29ZM140.57 363.6c0-3.25.99-6.27 2.68-8.78-1.92.57-3.88 1.03-5.88 1.39-.9 2.29-1.4 4.78-1.4 7.39 0 8.78 5.59 16.27 13.4 19.11 1.74-1.26 3.55-2.41 5.44-3.46-7.99-.75-14.25-7.46-14.25-15.65Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M164.2 342.96c-.5.46-1.02.9-1.54 1.34 8.1 2.68 13.96 10.31 13.96 19.29 0 3.36-.83 6.53-2.28 9.33.69-.08 1.39-.16 2.09-.21a22.05 22.05 0 0 0 1.97-9.12c0-9.42-5.89-17.46-14.19-20.64ZM135.97 363.6c0-2.61.5-5.1 1.4-7.39-.67.12-1.34.23-2.02.32-.75 2.22-1.16 4.59-1.16 7.07 0 1.19.1 2.37.28 3.51a53.67 53.67 0 0 1 3.34 8.61 22.21 22.21 0 0 0 9.89 8.25c.55-.43 1.11-.85 1.68-1.26-7.81-2.84-13.4-10.33-13.4-19.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M163.9 375.27c-1.8.6-3.55 1.28-5.26 2.06-.77.13-1.55.21-2.35.21-7.69 0-13.95-6.26-13.95-13.95 0-3.82 1.55-7.29 4.05-9.81 3-1.1 5.87-2.47 8.6-4.07.43-.04.86-.07 1.3-.07 7.69 0 13.95 6.26 13.95 13.95 0 4.88-2.53 9.18-6.34 11.67Zm1.74-11.67c0-5.16-4.19-9.35-9.35-9.35s-9.35 4.19-9.35 9.35 4.19 9.35 9.35 9.35 9.35-4.19 9.35-9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M157.84 347.96c-.92.62-1.88 1.2-2.84 1.77.43-.04.86-.07 1.3-.07 7.69 0 13.95 6.26 13.95 13.95 0 4.88-2.53 9.18-6.34 11.67 1.34-.44 2.7-.84 4.08-1.18 2.5-2.78 4.03-6.45 4.03-10.49 0-8.16-6.22-14.87-14.18-15.65ZM142.35 363.6c0-3.82 1.55-7.29 4.05-9.81-1.03.38-2.09.72-3.15 1.03a15.669 15.669 0 0 0-2.68 8.78c0 8.19 6.26 14.9 14.25 15.65 1.25-.69 2.53-1.33 3.83-1.92-.77.13-1.55.21-2.35.21-7.69 0-13.95-6.26-13.95-13.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M156.29 371.17c-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57 7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57Zm2.97-7.57a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M165.64 363.6c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35 9.35 4.19 9.35 9.35Zm-9.35 7.57c4.17 0 7.57-3.4 7.57-7.57s-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57 3.4 7.57 7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M156.29 362.41c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M159.26 363.6a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M38.98 417.35c-.72.2-1.45.37-2.18.53 1.4.79 2.75 1.64 4.06 2.56-.66-1.01-1.29-2.04-1.88-3.09ZM55.18 436.06c.07-.24.15-.47.23-.71-.39-.27-.77-.54-1.15-.82.32.5.62 1.01.92 1.52ZM0 407.65v5.61c1.85-.5 3.73-.89 5.66-1.17-2.02-1.32-3.91-2.8-5.66-4.44ZM48.09 429.29c-.79-.78-1.56-1.59-2.3-2.42a47.44 47.44 0 0 0-11.87-8.49 41.048 41.048 0 0 1-25.8-4.79c-2.79.27-5.5.8-8.12 1.54v7.84c4.01-1.36 8.29-2.11 12.76-2.11 16.36 0 30.41 9.89 36.5 24.02h4.26c.17-2.16.5-4.27.99-6.33a47.14 47.14 0 0 0-6.43-9.26Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M40.86 420.44c-1.31-.92-2.66-1.77-4.06-2.56-.95.2-1.9.37-2.87.5a47.44 47.44 0 0 1 11.87 8.49c-1.8-2.01-3.45-4.16-4.94-6.43ZM48.09 429.29c2.49 2.81 4.65 5.91 6.43 9.26.2-.84.42-1.67.67-2.49-.3-.51-.6-1.02-.92-1.52a54.215 54.215 0 0 1-6.17-5.24ZM5.66 412.09c-1.93.28-3.81.67-5.66 1.17v1.86a47.35 47.35 0 0 1 8.12-1.54c-.84-.47-1.66-.97-2.46-1.49Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M12.76 422.63c-4.48 0-8.77.78-12.76 2.21v7.67c3.89-1.77 8.21-2.77 12.76-2.77 11.29 0 21.16 6.07 26.53 15.13h8c-5.99-13.11-19.21-22.24-34.54-22.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M12.76 420.85c-4.47 0-8.75.75-12.76 2.11v1.89c3.99-1.43 8.28-2.21 12.76-2.21 15.33 0 28.55 9.14 34.54 22.24h1.96c-6.09-14.13-20.14-24.02-36.5-24.02Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M12.76 431.53c-4.58 0-8.91 1.07-12.76 2.96v8.26c3.6-2.58 8-4.1 12.76-4.1 5.96 0 11.35 2.38 15.31 6.23h9.12c-5.18-8.02-14.19-13.35-24.42-13.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M12.76 429.75c-4.55 0-8.87.99-12.76 2.77v1.97c3.85-1.89 8.18-2.96 12.76-2.96 10.24 0 19.25 5.33 24.42 13.35h2.11c-5.37-9.05-15.24-15.13-26.53-15.13Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M.15 444.87h25.22c-3.46-2.78-7.84-4.45-12.61-4.45S3.6 442.09.15 444.87Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M0 442.74v2.13h.15c3.46-2.78 7.84-4.45 12.61-4.45s9.16 1.67 12.61 4.45h2.69a21.867 21.867 0 0 0-15.31-6.23c-4.76 0-9.16 1.52-12.76 4.1Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M406.3 438.84c-.27.62-.51 1.26-.75 1.89 1.42 1.29 2.74 2.67 3.97 4.14h1.69c.85-2.52 1.9-4.94 3.13-7.26-2.61.6-5.29 1.01-8.03 1.22Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M405.55 440.73c.24-.63.49-1.27.75-1.89-.66.05-1.32.09-1.98.12-.07.18-.14.35-.21.53.49.4.97.82 1.44 1.24Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M416.73 437.01a47.686 47.686 0 0 0-3.64 7.87h8.03c4.43-10.27 13.07-18.3 23.74-21.92v-4.93c-7 9.06-16.82 15.82-28.14 18.98Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M414.33 437.62a49.344 49.344 0 0 0-3.13 7.26h1.89c.97-2.75 2.19-5.38 3.64-7.87-.79.22-1.59.43-2.4.61Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M423.09 444.87h8c3.21-5.4 8.02-9.74 13.77-12.36v-7.67c-9.67 3.46-17.54 10.75-21.78 20.03Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M421.13 444.87h1.96c4.24-9.28 12.1-16.57 21.78-20.03v-1.89a39.818 39.818 0 0 0-23.74 21.92Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M433.21 444.87h9.12c.79-.77 1.64-1.48 2.55-2.13v-8.26c-4.77 2.34-8.8 5.95-11.67 10.39Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M431.1 444.87h2.11c2.86-4.44 6.9-8.05 11.67-10.39v-1.97a30.91 30.91 0 0 0-13.77 12.36ZM444.87 444.87v-2.13c-.9.65-1.75 1.36-2.55 2.13h2.55Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M41.76 133.56c-.05-.07-.1-.14-.15-.2-2.31.51-4.69.84-7.12.95a45.69 45.69 0 0 1 3.58 4.83 53.31 53.31 0 0 1 3.69-5.58ZM35.14 192.94c1.13.22 2.24.49 3.34.8-.59-.98-1.14-1.99-1.66-3-.54.75-1.1 1.49-1.68 2.21Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="m41.99 133.27-.37.09c.05.07.1.14.15.2.07-.1.15-.19.23-.29Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.83 180.54a37.48 37.48 0 0 1-9.21 11.82c1.21-.11 2.43-.17 3.66-.17 1.97 0 3.91.15 5.81.42 1.01-1.18 1.95-2.41 2.83-3.7a54.359 54.359 0 0 1-3.09-8.37ZM32.14 134.34c-5.04-.06-9.87-1.02-14.32-2.73a37.45 37.45 0 0 1 15.77 17.52c.94-2.85 2.11-5.59 3.49-8.21a44.17 44.17 0 0 0-4.94-6.58ZM0 201.28v1.23c.5-.44 1-.87 1.52-1.28-.5.03-1.01.04-1.52.05ZM1.25 119.86c-.41-.02-.83-.03-1.25-.03v6.92c4.39.07 8.6.89 12.49 2.35a41.46 41.46 0 0 1-11.24-9.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M35.92 188.9c-.88 1.28-1.83 2.52-2.83 3.7.69.1 1.38.21 2.06.34.58-.72 1.14-1.46 1.68-2.21-.31-.6-.61-1.21-.9-1.83ZM0 118.33v1.5c.42 0 .83.01 1.25.03-.43-.5-.84-1.01-1.25-1.54ZM38.07 139.14a45.69 45.69 0 0 0-3.58-4.83c-.62.03-1.25.05-1.87.05-.16 0-.32-.01-.48-.01a44.17 44.17 0 0 1 4.94 6.58c.32-.6.65-1.2.99-1.78Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M20.13 192.82c5.27-3.8 9.46-9 12.01-15.05a54.15 54.15 0 0 1-1.28-11.68c0-4.92.67-9.69 1.91-14.22-4.91-13.43-17.72-23.08-32.76-23.32v6.57c15.71.31 28.34 13.13 28.34 28.92S15.71 192.64.01 192.96v6.57c1.46-.02 2.9-.14 4.32-.33a41.05 41.05 0 0 1 15.81-6.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M4.32 199.17c-1.42.2-2.86.31-4.32.33v1.78c.51 0 1.02-.02 1.52-.05.9-.72 1.83-1.42 2.8-2.06ZM32.83 180.54c-.25-.91-.49-1.84-.69-2.77-2.55 6.06-6.75 11.25-12.01 15.05 1.15-.2 2.31-.36 3.49-.46 3.82-3.26 6.96-7.28 9.21-11.82ZM32.76 151.86c.25-.92.53-1.83.82-2.73-3.23-7.41-8.8-13.55-15.77-17.52-1.84-.71-3.62-1.55-5.33-2.5a37.23 37.23 0 0 0-12.49-2.35v1.78c15.05.25 27.85 9.9 32.76 23.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M26.56 164.02c0-14.77-11.86-26.82-26.56-27.14v6.57c11.1.31 20 9.4 20 20.57s-8.9 20.26-20 20.57v6.57c14.7-.31 26.56-12.36 26.56-27.14Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M28.34 164.02c0-15.78-12.64-28.6-28.34-28.92v1.78c14.7.31 26.56 12.36 26.56 27.14S14.7 190.84 0 191.16v1.78c15.71-.31 28.34-13.13 28.34-28.92Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M18.22 164.02c0-10.17-8.12-18.46-18.22-18.77v6.57c6.48.31 11.65 5.65 11.65 12.21S6.48 175.93 0 176.24v6.57c10.1-.31 18.22-8.6 18.22-18.77Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M20 164.02c0-11.17-8.9-20.26-20-20.57v1.79c10.1.31 18.22 8.6 18.22 18.77S10.1 182.47 0 182.78v1.79c11.1-.31 20-9.4 20-20.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M9.87 164.02c0-5.57-4.38-10.12-9.87-10.43v6.6c1.87.28 3.3 1.88 3.3 3.83s-1.44 3.55-3.3 3.83v6.6c5.49-.31 9.87-4.86 9.87-10.43Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M11.65 164.02c0-6.56-5.17-11.9-11.65-12.21v1.78c5.49.31 9.87 4.86 9.87 10.43S5.49 174.14 0 174.45v1.78c6.48-.31 11.65-5.65 11.65-12.21Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M1.52 164.02c0-.96-.65-1.76-1.52-2.02v4.03c.88-.26 1.52-1.06 1.52-2.02Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M3.3 164.02c0-1.95-1.44-3.55-3.3-3.83V162c.88.26 1.52 1.06 1.52 2.02s-.65 1.76-1.52 2.02v1.81c1.87-.28 3.3-1.88 3.3-3.83Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.62 57.99c3.41 0 6.71.5 9.83 1.4.37-1.4.8-2.78 1.28-4.13-3.53-1.04-7.25-1.6-11.11-1.6-13.54 0-25.51 6.86-32.62 17.29v9.13c5.18-12.94 17.82-22.09 32.62-22.09ZM32.62 132.57c3.92 0 7.7-.58 11.28-1.65a54.02 54.02 0 0 1 18.87-14.09c-.87-.64-1.73-1.29-2.56-1.98-6.43 8.16-16.4 13.4-27.59 13.4-14.79 0-27.44-9.15-32.62-22.09v9.13c7.11 10.43 19.08 17.29 32.62 17.29Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M1.25 119.86c3.16 3.71 6.97 6.84 11.24 9.24 1.7.96 3.48 1.79 5.33 2.5 4.45 1.71 9.28 2.68 14.32 2.73.16 0 .32.01.48.01.63 0 1.25-.02 1.87-.05 2.44-.11 4.81-.43 7.12-.95.13-.03.25-.06.37-.09.62-.8 1.25-1.58 1.91-2.35a39.302 39.302 0 0 1-11.28 1.65c-13.54 0-25.51-6.86-32.62-17.29v3.04c.4.52.82 1.03 1.25 1.54ZM32.62 53.67c3.86 0 7.58.57 11.11 1.6.2-.56.41-1.12.63-1.68-.12-.04-.25-.07-.37-.11-.72-.21-1.45-.39-2.19-.56-2.95-.67-6.02-1.04-9.18-1.04-1.29 0-2.57.07-3.83.18-4.43.41-8.66 1.52-12.57 3.22a41.421 41.421 0 0 0-12.63 8.56A41.902 41.902 0 0 0 .01 67.9v3.04c7.11-10.43 19.08-17.29 32.62-17.29Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.62 126.47c10.64 0 20.12-5.02 26.23-12.8a51.053 51.053 0 0 1-3.5-3.4c-5.2 6.88-13.45 11.33-22.73 11.33-15.73 0-28.48-12.75-28.48-28.48s12.75-28.48 28.48-28.48c2.97 0 5.84.46 8.53 1.3.22-1.63.52-3.23.88-4.81-2.99-.88-6.14-1.36-9.41-1.36C16.61 59.77 3.2 71.12 0 86.19v13.86c3.2 15.07 16.61 26.42 32.62 26.42Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M32.62 128.25c11.2 0 21.16-5.24 27.59-13.4-.46-.38-.91-.78-1.36-1.18-6.11 7.78-15.59 12.8-26.23 12.8-16.01 0-29.42-11.35-32.62-26.42v6.1c5.18 12.94 17.82 22.09 32.62 22.09ZM32.62 59.77c3.27 0 6.42.48 9.41 1.36.13-.58.27-1.16.42-1.74-3.12-.91-6.42-1.4-9.83-1.4-14.79 0-27.44 9.15-32.62 22.09v6.1c3.2-15.07 16.61-26.42 32.62-26.42Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.62 66.42c-14.72 0-26.7 11.98-26.7 26.7s11.98 26.7 26.7 26.7c8.81 0 16.64-4.29 21.5-10.9a51.327 51.327 0 0 1-3-3.72c-3.95 6.03-10.76 10.02-18.5 10.02-12.21 0-22.1-9.9-22.1-22.1s9.9-22.1 22.1-22.1c2.84 0 5.55.54 8.04 1.52.02-1.61.1-3.21.26-4.78-2.62-.86-5.41-1.33-8.3-1.33Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M54.12 108.92c-4.87 6.6-12.69 10.9-21.5 10.9-14.72 0-26.7-11.98-26.7-26.7s11.98-26.7 26.7-26.7c2.9 0 5.69.47 8.3 1.33.06-.61.15-1.21.23-1.81-2.69-.85-5.56-1.3-8.53-1.3-15.73 0-28.48 12.75-28.48 28.48s12.75 28.48 28.48 28.48c9.29 0 17.53-4.45 22.73-11.33-.42-.44-.83-.89-1.23-1.35Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M12.3 93.12c0 11.21 9.12 20.32 20.32 20.32 7.36 0 13.82-3.93 17.38-9.81a53.902 53.902 0 0 1-2.82-4.6c-2.34 5.75-7.97 9.81-14.57 9.81-8.68 0-15.72-7.04-15.72-15.72S23.93 77.4 32.61 77.4c3.11 0 6 .92 8.44 2.47-.22-1.78-.35-3.58-.39-5.4-2.47-1.07-5.2-1.67-8.06-1.67-11.21 0-20.32 9.12-20.32 20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M10.52 93.12c0 12.21 9.9 22.1 22.1 22.1 7.74 0 14.55-3.99 18.5-10.02-.38-.52-.75-1.04-1.12-1.57-3.57 5.87-10.02 9.81-17.38 9.81-11.21 0-20.32-9.12-20.32-20.32S21.42 72.8 32.62 72.8c2.86 0 5.59.6 8.06 1.67 0-.4-.03-.8-.03-1.21 0-.24.02-.48.02-.73-2.49-.98-5.21-1.52-8.04-1.52-12.21 0-22.1 9.9-22.1 22.1Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.62 79.18c3.33 0 6.4 1.18 8.8 3.14.86 5.12 2.44 9.99 4.63 14.51-1.63 5.9-7.02 10.24-13.43 10.24-7.69 0-13.95-6.26-13.95-13.95s6.26-13.95 13.95-13.95Zm0 4.6c-5.16 0-9.35 4.19-9.35 9.35s4.19 9.35 9.35 9.35 9.35-4.19 9.35-9.35-4.19-9.35-9.35-9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M16.89 93.12c0 8.68 7.04 15.72 15.72 15.72 6.59 0 12.23-4.06 14.57-9.81-.4-.73-.77-1.46-1.13-2.21-1.63 5.9-7.02 10.24-13.43 10.24-7.69 0-13.95-6.26-13.95-13.95s6.26-13.95 13.95-13.95c3.33 0 6.4 1.18 8.8 3.14-.14-.81-.25-1.62-.35-2.44a15.607 15.607 0 0 0-8.44-2.47c-8.68 0-15.72 7.04-15.72 15.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.62 100.69c-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57 7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57Zm2.97-7.57a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M32.62 83.77c5.16 0 9.35 4.19 9.35 9.35s-4.19 9.35-9.35 9.35-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35Zm0 16.92c4.17 0 7.57-3.4 7.57-7.57s-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57 3.4 7.57 7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M32.62 91.93c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M35.59 93.12a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M27.28 198.29c7.05 0 13.6 2.08 19.1 5.65a54.752 54.752 0 0 1-6.43-7.88c-3.98-1.35-8.24-2.1-12.67-2.1-10.58 0-20.19 4.19-27.28 10.99v6.34c6.44-7.93 16.27-13 27.28-13Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M27.28 193.96c4.43 0 8.69.75 12.67 2.1-.51-.76-1-1.54-1.47-2.33-1.1-.31-2.21-.58-3.34-.8-.68-.13-1.37-.25-2.06-.34-1.9-.27-3.84-.42-5.81-.42-1.24 0-2.46.06-3.66.17-1.18.1-2.34.26-3.49.46a41.05 41.05 0 0 0-15.81 6.35c-.96.65-1.89 1.34-2.8 2.06-.52.41-1.03.84-1.52 1.28v2.44c7.09-6.8 16.7-10.99 27.28-10.99Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M0 241.6v.52c.06 0 .11 0 .16.01-.06-.18-.11-.35-.16-.53ZM27.28 204.94c7.95 0 15.14 3.26 20.31 8.52 1.21-1.08 2.47-2.1 3.78-3.06-6.08-6.36-14.63-10.33-24.09-10.33-11.27 0-21.24 5.62-27.28 14.2v10.96c3.52-11.74 14.4-20.3 27.28-20.3Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M27.28 200.07c9.47 0 18.02 3.97 24.09 10.33.44-.32.88-.64 1.33-.95a54.27 54.27 0 0 1-6.32-5.5 34.947 34.947 0 0 0-19.1-5.65c-11.01 0-20.84 5.07-27.28 13v2.98c6.04-8.58 16.01-14.2 27.28-14.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M27.28 211.31c6.19 0 11.78 2.54 15.79 6.64a58.39 58.39 0 0 1 3.2-3.29c-4.84-4.91-11.57-7.95-18.99-7.95-14.72 0-26.7 11.98-26.7 26.7 0 3.11.54 6.09 1.52 8.86 1.79.18 3.56.44 5.29.79a21.988 21.988 0 0 1-2.21-9.65c0-12.21 9.9-22.1 22.1-22.1Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M2.1 242.28c-.98-2.77-1.52-5.76-1.52-8.86 0-14.72 11.98-26.7 26.7-26.7 7.42 0 14.14 3.05 18.99 7.95.43-.41.87-.82 1.32-1.21-5.17-5.26-12.35-8.52-20.31-8.52-12.88 0-23.76 8.56-27.28 20.3v16.36c.05.18.11.36.16.53.65.04 1.29.08 1.93.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M27.28 249.14c-2.04 0-3.98-.4-5.77-1.11 2.77 1.43 5.41 3.1 7.87 4.97.02-1.36.09-2.72.21-4.05-.76.11-1.53.19-2.32.19ZM11.55 233.42c0-8.68 7.04-15.72 15.72-15.72 4.7 0 8.9 2.07 11.78 5.33.89-1.28 1.83-2.52 2.83-3.71-3.7-3.83-8.88-6.22-14.61-6.22-11.21 0-20.32 9.12-20.32 20.32 0 3.7 1 7.18 2.74 10.17 3.34.82 6.56 1.96 9.62 3.37-4.64-2.73-7.77-7.77-7.77-13.54Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M27.28 213.09c5.73 0 10.91 2.39 14.61 6.22.38-.46.78-.91 1.18-1.36a22.01 22.01 0 0 0-15.79-6.64c-12.21 0-22.1 9.9-22.1 22.1 0 3.46.8 6.73 2.21 9.65.77.16 1.54.33 2.3.52a20.174 20.174 0 0 1-2.74-10.17c0-11.21 9.12-20.32 20.32-20.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M27.28 219.47c-7.69 0-13.95 6.26-13.95 13.95s6.26 13.95 13.95 13.95c.86 0 1.7-.09 2.52-.24.21-1.69.5-3.36.86-5-1.05.41-2.19.64-3.38.64-5.16 0-9.35-4.19-9.35-9.35s4.19-9.35 9.35-9.35c3.54 0 6.61 1.96 8.2 4.86.79-1.5 1.64-2.96 2.56-4.38-2.56-3.1-6.43-5.08-10.76-5.08Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M29.6 248.95c.06-.61.13-1.22.2-1.83-.82.15-1.66.24-2.52.24-7.69 0-13.95-6.26-13.95-13.95s6.26-13.95 13.95-13.95c4.33 0 8.2 1.98 10.76 5.08.33-.51.67-1.02 1.02-1.52-2.88-3.26-7.09-5.33-11.78-5.33-8.68 0-15.72 7.04-15.72 15.72 0 5.78 3.12 10.81 7.77 13.54.74.34 1.47.7 2.19 1.07 1.79.71 3.73 1.11 5.77 1.11.79 0 1.56-.08 2.32-.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M34.45 231.01a53.62 53.62 0 0 0-3.24 8.87c-1.15.7-2.49 1.11-3.93 1.11-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57c3.33 0 6.16 2.16 7.17 5.16Zm-4.2 2.41a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M27.28 224.07c-5.16 0-9.35 4.19-9.35 9.35s4.19 9.35 9.35 9.35c1.19 0 2.33-.23 3.38-.64.17-.76.35-1.51.55-2.25-1.15.7-2.49 1.11-3.93 1.11-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57c3.33 0 6.16 2.16 7.17 5.16.33-.7.67-1.39 1.03-2.08a9.333 9.333 0 0 0-8.2-4.86Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M27.28 232.22c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M30.25 233.42a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M33.12 338.42c-.9-.11-1.81-.2-2.73-.26a53.99 53.99 0 0 1-6.66 4.55c1.46-.18 2.94-.29 4.45-.29 3.6 0 7.08.54 10.35 1.55a41.13 41.13 0 0 1-5.4-5.56ZM8.07 348.75c-2.58.55-5.24.92-7.95 1.08-.04.04-.08.09-.13.13v6.6a35.24 35.24 0 0 1 8.07-7.82ZM28.17 417.01c3.43 0 6.76-.44 9.94-1.27-.68-1.3-1.31-2.63-1.88-3.99-2.59.61-5.28.94-8.06.94-11.53 0-21.76-5.56-28.17-14.14v6.6c7.17 7.32 17.14 11.87 28.17 11.87Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M8.12 413.58a41.048 41.048 0 0 0 25.8 4.79c.97-.14 1.93-.3 2.87-.5.74-.16 1.46-.34 2.18-.53-.3-.53-.59-1.07-.87-1.61-3.18.83-6.51 1.27-9.94 1.27-11.03 0-21-4.55-28.17-11.87v2.51c1.75 1.64 3.64 3.13 5.66 4.44.8.52 1.62 1.02 2.46 1.49ZM0 349.85v.12s.08-.09.13-.13H0ZM31.94 336.88c-.51.44-1.02.87-1.55 1.29.92.05 1.83.14 2.73.26-.41-.5-.8-1.02-1.18-1.54Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M28.17 349.08c6.6 0 12.66 2.25 17.49 6.01 1.08-1.22 2.22-2.38 3.4-3.5-5.72-4.61-12.99-7.38-20.89-7.38-11.84 0-22.25 6.21-28.17 15.53v13.63c2.02-13.74 13.86-24.29 28.17-24.29ZM28.17 410.9c2.54 0 5.01-.3 7.39-.84-.58-1.52-1.08-3.06-1.52-4.64-1.9.4-3.86.61-5.87.61-14.31 0-26.14-10.55-28.17-24.29v13.63c5.92 9.33 16.33 15.53 28.17 15.53Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M28.17 412.68c2.77 0 5.47-.33 8.06-.94-.23-.55-.45-1.11-.66-1.68-2.38.54-4.85.84-7.39.84-11.84 0-22.25-6.21-28.17-15.53v3.17c6.4 8.58 16.64 14.14 28.17 14.14ZM28.17 344.21c7.9 0 15.17 2.77 20.89 7.38.26-.25.52-.5.79-.74a41.519 41.519 0 0 1-11.33-6.87 35.036 35.036 0 0 0-10.35-1.55c-1.51 0-2.99.11-4.45.29a53.37 53.37 0 0 1-15.65 6.03A35.24 35.24 0 0 0 0 356.57v3.17c5.92-9.33 16.33-15.53 28.17-15.53Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M28.17 399.66c-12.21 0-22.1-9.9-22.1-22.1s9.9-22.1 22.1-22.1c5.11 0 9.8 1.74 13.55 4.64a49.3 49.3 0 0 1 2.77-3.65c-4.52-3.5-10.17-5.59-16.32-5.59-14.72 0-26.7 11.98-26.7 26.7s11.98 26.7 26.7 26.7c1.86 0 3.67-.19 5.42-.55-.36-1.48-.67-2.98-.91-4.51-1.46.3-2.97.46-4.52.46Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M28.17 406.03c2.01 0 3.98-.21 5.87-.61-.16-.57-.31-1.15-.45-1.72-1.75.36-3.56.55-5.42.55-14.72 0-26.7-11.98-26.7-26.7s11.98-26.7 26.7-26.7c6.14 0 11.8 2.09 16.32 5.59.38-.46.78-.91 1.17-1.36a28.363 28.363 0 0 0-17.49-6.01c-14.31 0-26.14 10.55-28.17 24.29v8.37c2.02 13.74 13.86 24.29 28.17 24.29Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M7.85 377.55c0 11.21 9.12 20.32 20.32 20.32 1.47 0 2.9-.16 4.29-.46a59.1 59.1 0 0 1-.36-4.65c-1.26.32-2.57.51-3.93.51-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72c3.89 0 7.44 1.42 10.18 3.76a55.2 55.2 0 0 1 2.36-4 20.228 20.228 0 0 0-12.55-4.35c-11.21 0-20.32 9.12-20.32 20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M6.07 377.55c0 12.21 9.9 22.1 22.1 22.1 1.55 0 3.06-.16 4.52-.46-.09-.59-.16-1.18-.23-1.78-1.38.3-2.82.46-4.29.46-11.21 0-20.32-9.12-20.32-20.32s9.12-20.32 20.32-20.32c4.73 0 9.09 1.63 12.55 4.35.33-.5.66-1 1-1.49a21.981 21.981 0 0 0-13.55-4.64c-12.21 0-22.1 9.9-22.1 22.1Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M14.22 377.55c0 7.69 6.26 13.95 13.95 13.95 1.35 0 2.65-.2 3.88-.56v-.04c0-1.67.09-3.33.24-4.96-1.24.61-2.64.97-4.12.97-5.16 0-9.35-4.19-9.35-9.35s4.19-9.35 9.35-9.35c3 0 5.65 1.41 7.37 3.61.59-1.56 1.25-3.09 1.98-4.58-2.48-2.24-5.75-3.62-9.34-3.62-7.69 0-13.95 6.26-13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M12.44 377.55c0 8.68 7.04 15.72 15.72 15.72 1.36 0 2.67-.19 3.93-.51-.02-.61-.05-1.21-.05-1.83-1.23.36-2.53.56-3.88.56-7.69 0-13.95-6.26-13.95-13.95s6.26-13.95 13.95-13.95c3.6 0 6.87 1.38 9.34 3.62.27-.55.55-1.1.84-1.65-2.74-2.34-6.3-3.76-10.18-3.76-8.68 0-15.72 7.04-15.72 15.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M34.8 373.91a53.123 53.123 0 0 0-2.26 9.82c-1.24.88-2.74 1.4-4.37 1.4-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57c2.85 0 5.34 1.59 6.63 3.92Zm-3.66 3.65a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M28.17 368.21c-5.16 0-9.35 4.19-9.35 9.35s4.19 9.35 9.35 9.35c1.48 0 2.87-.35 4.12-.97.07-.74.16-1.48.25-2.21-1.24.88-2.74 1.4-4.37 1.4-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57c2.85 0 5.34 1.59 6.63 3.92.23-.7.48-1.4.74-2.09-1.71-2.19-4.37-3.61-7.37-3.61Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M28.17 376.36c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M31.14 377.55a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M30.45 264.72c-.62-3.05-.97-6.2-1.05-9.41-8.15-6.56-18.31-10.72-29.4-11.41v6.23c12.01.84 22.75 6.29 30.45 14.59ZM30.9 335.4a41.35 41.35 0 0 1-3.08-5.53c-7.43 6.82-17.12 11.22-27.81 11.96v6.23c11.78-.73 22.51-5.38 30.9-12.67Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M8.07 348.75a53.65 53.65 0 0 0 15.65-6.03 53.246 53.246 0 0 0 8.21-5.84c-.36-.49-.7-.98-1.04-1.49-8.39 7.28-19.12 11.94-30.9 12.67v1.78h.13c2.71-.17 5.36-.53 7.95-1.08ZM29.4 255.31c-.01-.48-.04-.95-.04-1.43 0-.29.02-.58.02-.87-2.47-1.88-5.1-3.54-7.87-4.97A53.453 53.453 0 0 0 9.7 243.6c-.76-.19-1.53-.36-2.3-.52-1.74-.35-3.5-.61-5.29-.79-.64-.06-1.29-.11-1.93-.15-.06 0-.11 0-.16-.01v1.78c11.09.69 21.24 4.85 29.4 11.41Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M24.79 320.52C18.63 327.56 9.87 332.25 0 333.11v6.94c10.44-.76 19.87-5.16 27.05-11.94-.98-2.42-1.75-4.96-2.26-7.59ZM32.93 287.02c1.36-1.72 2.85-3.33 4.47-4.81a53.477 53.477 0 0 1-5.98-13.6c-7.47-9.44-18.7-15.77-31.42-16.7v6.94c16.04 1.39 29.17 12.93 32.93 28.17Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M27.05 328.11c-7.18 6.78-16.61 11.17-27.05 11.94v1.78a45.781 45.781 0 0 0 27.81-11.96c-.27-.58-.52-1.16-.76-1.76ZM31.42 268.62c-.36-1.28-.69-2.58-.96-3.89-7.71-8.31-18.44-13.75-30.45-14.59v1.78c12.71.93 23.95 7.26 31.42 16.7Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M24.41 318.19a40.995 40.995 0 0 1 7.12-29.29C28.45 273.75 15.68 262.08 0 260.64v6.6c14.44 1.62 25.67 13.87 25.67 28.74S14.44 323.1 0 324.72v6.6c9.85-.9 18.54-5.84 24.41-13.14Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M24.79 320.52c-.15-.77-.27-1.55-.38-2.34-5.87 7.3-14.56 12.24-24.41 13.14v1.78c9.87-.85 18.63-5.55 24.79-12.59ZM31.53 288.9c.45-.64.92-1.26 1.4-1.87-3.76-15.24-16.89-26.78-32.93-28.17v1.78c15.68 1.43 28.45 13.1 31.53 28.26Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M23.89 295.98c0-13.87-10.45-25.33-23.89-26.95v6.62c9.82 1.56 17.33 10.07 17.33 20.32S9.82 314.73 0 316.29v6.62c13.44-1.61 23.89-13.08 23.89-26.95Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M25.67 295.98c0-14.88-11.23-27.12-25.67-28.74v1.79c13.44 1.61 23.89 13.08 23.89 26.95S13.44 321.31 0 322.93v1.79c14.44-1.62 25.67-13.87 25.67-28.74Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M15.55 295.98c0-9.26-6.73-16.95-15.55-18.5v6.72c5.17 1.43 8.98 6.16 8.98 11.78S5.17 306.34 0 307.76v6.72c8.82-1.55 15.55-9.25 15.55-18.5Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M17.33 295.98c0-10.26-7.51-18.76-17.33-20.32v1.82c8.82 1.55 15.55 9.25 15.55 18.5S8.82 312.93 0 314.48v1.82c9.82-1.56 17.33-10.07 17.33-20.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M7.2 295.98c0-4.63-3.03-8.56-7.2-9.93v7.81c.4.61.63 1.34.63 2.12s-.24 1.51-.63 2.12v7.81c4.18-1.37 7.2-5.3 7.2-9.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M8.98 295.98c0-5.63-3.81-10.36-8.98-11.78v1.85c4.18 1.37 7.2 5.3 7.2 9.93s-3.03 8.56-7.2 9.93v1.85c5.17-1.43 8.98-6.16 8.98-11.78Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M0 293.86v4.23c.4-.61.63-1.34.63-2.12s-.24-1.51-.63-2.12Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M438.48 209.63s0-.01.01-.02h-.02s0 .01.01.02ZM414.56 186.51c.58 1.88 1.03 3.81 1.34 5.79 8.64 2.69 16.05 8.15 21.22 15.33.86.14 1.72.26 2.59.35 1.55-1.98 3.28-3.81 5.16-5.47v-1.23c-.2 0-.39.01-.59.01-12.14 0-22.92-5.8-29.72-14.79ZM408.5 174.46c-.49-1.68-.86-3.4-1.11-5.17-.34.82-.7 1.62-1.08 2.41.77.89 1.5 1.8 2.2 2.75ZM444.29 126.74c.2 0 .39.01.59.01v-3.3c-.65 1.13-1.32 2.24-2.05 3.32.49-.02.97-.04 1.46-.04Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M438.47 209.62h.02c.39-.56.8-1.1 1.22-1.64-.87-.09-1.73-.21-2.59-.35.47.65.92 1.31 1.34 1.99Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M415.36 164.02c0-7.47 2.83-14.28 7.48-19.41a53.908 53.908 0 0 1-10.59 4.14c-.69 1.45-1.29 2.95-1.78 4.5v.39c0 4.02-.59 7.9-1.66 11.57.63 19.03 16.29 34.32 35.47 34.32.2 0 .39-.01.59-.01v-6.57c-.2 0-.39.01-.59.01-15.98 0-28.93-12.95-28.93-28.93ZM441.51 128.64a54.576 54.576 0 0 1-7.67 8.4c3.24-1.26 6.76-1.95 10.45-1.95.2 0 .39.01.59.01v-6.57c-.2 0-.39-.02-.59-.02-.94 0-1.86.05-2.78.12Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M442.82 126.78c-.42.63-.87 1.25-1.32 1.86.92-.07 1.84-.12 2.78-.12.2 0 .39.01.59.02v-1.78c-.2 0-.39-.01-.59-.01-.49 0-.98.02-1.46.04ZM410.47 153.24c.49-1.55 1.09-3.05 1.78-4.5-.66.18-1.33.35-2.01.5.14 1.31.21 2.65.22 3.99ZM408.82 165.2c-.41 1.4-.88 2.76-1.43 4.09.25 1.77.63 3.49 1.11 5.17 2.64 3.62 4.71 7.68 6.06 12.05 6.81 8.98 17.59 14.79 29.72 14.79.2 0 .39-.01.59-.01v-1.78c-.2 0-.39.01-.59.01-19.18 0-34.84-15.29-35.47-34.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M417.13 164.02c0 14.97 12.18 27.15 27.15 27.15.2 0 .39-.01.59-.01v-6.57c-.2 0-.39.01-.59.01-11.37 0-20.58-9.22-20.58-20.58s9.22-20.58 20.58-20.58c.2 0 .39 0 .59.01v-6.57c-.2 0-.39-.02-.59-.02-14.97 0-27.15 12.18-27.15 27.15Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M433.84 137.04c-3.32 2.95-7.02 5.5-11 7.56a28.805 28.805 0 0 0-7.48 19.41c0 15.98 12.95 28.93 28.93 28.93.2 0 .39-.01.59-.01v-1.78c-.2 0-.39.01-.59.01-14.97 0-27.15-12.18-27.15-27.15s12.18-27.15 27.15-27.15c.2 0 .39.01.59.02v-1.78c-.2 0-.39-.01-.59-.01-3.69 0-7.21.7-10.45 1.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M425.48 164.02c0 10.37 8.44 18.8 18.8 18.8.2 0 .39-.02.59-.03v-6.57c-.2 0-.39.03-.59.03-6.76 0-12.24-5.48-12.24-12.24s5.48-12.24 12.24-12.24c.2 0 .39.02.59.03v-6.57c-.2 0-.39-.03-.59-.03-10.37 0-18.8 8.44-18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M423.7 164.02c0 11.37 9.22 20.58 20.58 20.58.2 0 .39 0 .59-.01v-1.79c-.2 0-.39.03-.59.03-10.37 0-18.8-8.44-18.8-18.8s8.44-18.8 18.8-18.8c.2 0 .39.02.59.03v-1.79c-.2 0-.39-.01-.59-.01-11.37 0-20.58 9.22-20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M433.83 164.02c0 5.77 4.69 10.46 10.46 10.46.2 0 .39-.02.59-.03v-6.6c-.19.03-.39.06-.59.06-2.15 0-3.89-1.74-3.89-3.89s1.74-3.89 3.89-3.89c.2 0 .39.03.59.06v-6.6c-.2-.01-.39-.03-.59-.03-5.77 0-10.46 4.69-10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M432.05 164.02c0 6.76 5.48 12.24 12.24 12.24.2 0 .39-.02.59-.03v-1.78c-.2.01-.39.03-.59.03-5.77 0-10.46-4.69-10.46-10.46s4.69-10.46 10.46-10.46c.2 0 .39.02.59.03v-1.78c-.2 0-.39-.03-.59-.03-6.76 0-12.24 5.48-12.24 12.24Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M442.18 164.02c0 1.16.95 2.11 2.11 2.11.21 0 .4-.04.59-.09v-4.03c-.19-.05-.38-.09-.59-.09-1.16 0-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M440.4 164.02c0 2.15 1.74 3.89 3.89 3.89.2 0 .39-.03.59-.06v-1.81c-.19.05-.38.09-.59.09-1.16 0-2.11-.95-2.11-2.11s.95-2.11 2.11-2.11c.21 0 .4.04.59.09v-1.81c-.19-.03-.39-.06-.59-.06-2.15 0-3.89 1.74-3.89 3.89ZM444.87 69.9v-1.99c-.22.28-.44.57-.65.86.23.37.43.75.65 1.13Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M439.49 211.31c.83 1.47 1.59 2.98 2.24 4.55.93-1.61 1.98-3.14 3.14-4.57v-6.34a38.96 38.96 0 0 0-5.38 6.36Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M439.71 207.98c-.42.54-.83 1.08-1.22 1.64 0 0 0 .01-.01.02.35.55.69 1.11 1.01 1.68 1.57-2.31 3.38-4.43 5.38-6.36v-2.44a41.095 41.095 0 0 0-5.16 5.47Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M443.55 242.06c.44.02.88.03 1.32.06v-.52c-.26-.86-.48-1.74-.65-2.64-.19 1.05-.4 2.08-.67 3.1ZM442.56 218.07c.94 2.68 1.6 5.49 1.97 8.39.1-.41.22-.82.34-1.23v-10.96c-.85 1.21-1.62 2.48-2.31 3.79Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M442.56 218.07c.69-1.32 1.46-2.59 2.31-3.79v-2.98a35.211 35.211 0 0 0-3.14 4.57c.3.72.58 1.46.83 2.2ZM444.22 238.96c.18.89.4 1.77.65 2.64v-9.92c0 2.49-.23 4.92-.65 7.29ZM444.87 231.67v-6.44c-.12.41-.24.81-.34 1.23.22 1.71.34 3.45.34 5.21Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M444.87 352.06v-2.09c-.3.31-.61.62-.9.94.31.38.6.76.9 1.14Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M443.13 349.92c.28.33.57.66.85 1 .29-.32.6-.63.9-.94v-.12c-.58.03-1.16.05-1.75.07Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M405.55 324.48c-1 1.97-2.1 3.87-3.32 5.69.27.31.53.62.8.92 3.53.06 6.99.44 10.32 1.15-2.9-2.27-5.52-4.87-7.81-7.76ZM433.73 341.27c2.83 2.04 5.45 4.36 7.83 6.9h.05c1.09 0 2.18-.05 3.26-.11v-6.23c-1.08.08-2.16.13-3.26.13-2.69 0-5.32-.24-7.88-.69ZM440.56 250.03c.35 0 .7-.03 1.06-.03 1.1 0 2.18.05 3.26.13v-6.23c-.6-.04-1.21-.06-1.82-.08a40.33 40.33 0 0 1-2.49 6.2Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M443.05 243.83c.61.02 1.22.04 1.82.08v-1.78c-.44-.03-.88-.04-1.32-.06-.15.59-.32 1.18-.5 1.77ZM401.59 331.08c.19 0 .38-.01.58-.01.29 0 .58.02.87.02-.27-.3-.53-.61-.8-.92-.21.31-.43.61-.64.92ZM441.62 348.17h-.05c.53.57 1.05 1.15 1.56 1.74.58-.02 1.17-.03 1.75-.07v-1.78c-1.08.07-2.16.11-3.26.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M413.3 271.76c-3 .72-6.12 1.12-9.34 1.14 1.43 2.44 2.68 5.01 3.72 7.68a37.35 37.35 0 0 1 5.62-8.82ZM409.36 314.65a53.68 53.68 0 0 1-2.94 8.02c3.28 4.31 7.32 8 11.93 10.86 3.9 1.22 7.6 2.89 11.06 4.91 3.88 1.12 7.97 1.73 12.21 1.73 1.1 0 2.18-.05 3.26-.13v-6.94c-1.07.09-2.16.15-3.26.15-13.78 0-25.81-7.48-32.26-18.61ZM441.62 251.78c-.67 0-1.34.02-2.01.05a41.495 41.495 0 0 1-5.51 7.63c2.43-.5 4.94-.76 7.52-.76 1.1 0 2.18.06 3.26.15v-6.94c-1.08-.08-2.16-.13-3.26-.13Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M405.55 324.48c2.28 2.89 4.91 5.49 7.81 7.76 1.7.36 3.36.79 5 1.3a44.641 44.641 0 0 1-11.93-10.86c-.28.61-.57 1.21-.87 1.8ZM441.62 250c-.35 0-.7.02-1.06.03-.3.61-.62 1.21-.96 1.8.67-.03 1.34-.05 2.01-.05 1.1 0 2.18.05 3.26.13v-1.78c-1.08-.08-2.16-.13-3.26-.13ZM429.41 338.45c1.49.87 2.93 1.81 4.33 2.82 2.56.44 5.19.69 7.88.69 1.1 0 2.18-.05 3.26-.13v-1.78c-1.08.08-2.16.13-3.26.13-4.24 0-8.33-.61-12.21-1.73Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M441.62 260.48c-3.46 0-6.8.51-9.96 1.43a41.143 41.143 0 0 1-15.04 8.89 35.673 35.673 0 0 0-8.05 12.24 53.977 53.977 0 0 1 2.78 17.09c0 4.11-.47 8.1-1.34 11.95 5.88 11.49 17.84 19.38 31.61 19.38 1.1 0 2.18-.06 3.26-.16v-6.6c-1.07.12-2.15.19-3.26.19-15.98 0-28.93-12.95-28.93-28.93s12.95-28.93 28.93-28.93c1.1 0 2.19.07 3.26.19v-6.6c-1.07-.1-2.16-.16-3.26-.16Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M408.57 283.05a35.53 35.53 0 0 1 8.05-12.24c-1.09.36-2.19.68-3.32.95a37.235 37.235 0 0 0-5.62 8.82c.32.82.62 1.64.89 2.48ZM441.62 258.7c-2.58 0-5.09.26-7.52.76-.78.85-1.6 1.67-2.44 2.45 3.16-.93 6.5-1.43 9.96-1.43 1.1 0 2.18.06 3.26.16v-1.78c-1.07-.09-2.16-.15-3.26-.15ZM410 312.1c-.19.86-.41 1.71-.64 2.56 6.45 11.12 18.48 18.61 32.26 18.61 1.1 0 2.18-.06 3.26-.15v-1.78c-1.07.1-2.16.16-3.26.16-13.77 0-25.73-7.89-31.61-19.38Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M414.47 295.98c0 14.97 12.18 27.15 27.15 27.15 1.1 0 2.19-.07 3.26-.2v-6.62c-1.06.17-2.15.26-3.26.26-11.37 0-20.58-9.22-20.58-20.58s9.22-20.58 20.58-20.58c1.11 0 2.2.09 3.26.26v-6.62c-1.07-.13-2.15-.2-3.26-.2-14.97 0-27.15 12.18-27.15 27.15Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M412.69 295.98c0 15.98 12.95 28.93 28.93 28.93 1.1 0 2.19-.07 3.26-.19v-1.79c-1.07.13-2.15.2-3.26.2-14.97 0-27.15-12.18-27.15-27.15s12.18-27.15 27.15-27.15c1.1 0 2.19.07 3.26.2v-1.79c-1.07-.12-2.15-.19-3.26-.19-15.98 0-28.93 12.95-28.93 28.93Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M422.81 295.98c0 10.37 8.44 18.8 18.8 18.8 1.11 0 2.2-.12 3.26-.3v-6.72c-1.04.29-2.13.45-3.26.45-6.76 0-12.24-5.48-12.24-12.24s5.48-12.24 12.24-12.24c1.13 0 2.22.17 3.26.45v-6.72c-1.06-.19-2.14-.3-3.26-.3-10.37 0-18.8 8.44-18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M421.03 295.98c0 11.37 9.22 20.58 20.58 20.58 1.11 0 2.2-.09 3.26-.26v-1.82c-1.06.19-2.14.3-3.26.3-10.37 0-18.8-8.44-18.8-18.8s8.44-18.8 18.8-18.8c1.11 0 2.2.12 3.26.3v-1.82c-1.06-.17-2.15-.26-3.26-.26-11.37 0-20.58 9.22-20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M431.16 295.98c0 5.77 4.69 10.46 10.46 10.46 1.14 0 2.23-.19 3.26-.53v-7.81a3.888 3.888 0 0 1-7.15-2.12 3.888 3.888 0 0 1 7.15-2.12v-7.81c-1.03-.34-2.12-.53-3.26-.53-5.77 0-10.46 4.69-10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M429.38 295.98c0 6.76 5.48 12.24 12.24 12.24 1.13 0 2.22-.17 3.26-.45v-1.85c-1.03.34-2.12.53-3.26.53-5.77 0-10.46-4.69-10.46-10.46s4.69-10.46 10.46-10.46c1.14 0 2.23.19 3.26.53v-1.85c-1.04-.29-2.13-.45-3.26-.45-6.76 0-12.24 5.48-12.24 12.24Z"
        />
        <circle className="cls-Venn3-3-3-3" cx={441.62} cy={295.98} r={2.11} />
        <path
          className="cls-Venn3-3-4"
          d="M444.87 293.86v4.23a3.888 3.888 0 0 1-7.15-2.12 3.888 3.888 0 0 1 7.15-2.12Zm-1.15 2.12c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M402.16 431.02c-22.22 0-40.76-15.77-45.05-36.72-1.59.95-3.25 1.8-4.97 2.53 8.79 7.43 15.17 17.62 17.81 29.23 1.87 1.47 3.83 2.81 5.89 4.02a41.075 41.075 0 0 1 25.23 7.12c.36 0 .72.03 1.09.03 17.64 0 33.26-8.81 42.71-22.25v-12.91c-6.77 16.96-23.33 28.95-42.71 28.95ZM402.16 332.85c-.64 0-1.27.03-1.9.05-1.9 2.48-4 4.79-6.29 6.91 2.66-.48 5.39-.74 8.19-.74 19.37 0 35.94 11.99 42.71 28.95v-12.91c-9.45-13.44-25.07-22.25-42.71-22.25Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M370.49 428.73c.77.56 1.56 1.1 2.36 1.62.99-.12 1.99-.21 3-.26a51.82 51.82 0 0 1-5.89-4.02c.2.88.38 1.77.53 2.67ZM443.13 349.92c-.51-.59-1.03-1.17-1.56-1.74-2.38-2.54-5-4.85-7.83-6.9a51.813 51.813 0 0 0-4.33-2.82 53.292 53.292 0 0 0-11.06-4.91c-1.63-.51-3.3-.95-5-1.3-3.34-.7-6.79-1.09-10.32-1.15-.29 0-.58-.02-.87-.02-.19 0-.38.01-.58.01-.43.61-.87 1.22-1.32 1.81.63-.02 1.26-.05 1.9-.05 17.64 0 33.26 8.81 42.71 22.25v-3.04c-.3-.38-.59-.77-.9-1.14-.28-.34-.56-.67-.85-1ZM402.16 437.23c-.36 0-.72-.02-1.09-.03.82.56 1.62 1.16 2.39 1.77.28 0 .57-.01.85-.02.66-.03 1.32-.07 1.98-.12a54.356 54.356 0 0 0 10.43-1.83c11.32-3.17 21.14-9.93 28.14-18.98v-3.04c-9.45 13.44-25.07 22.25-42.71 22.25Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M402.16 340.84c-3.8 0-7.49.48-11.01 1.39-4.4 3.53-9.35 6.39-14.72 8.42.44 2.18.7 4.42.78 6.7 6.61-5.96 15.35-9.59 24.95-9.59 20.59 0 37.28 16.69 37.28 37.28s-16.69 37.28-37.28 37.28-35.55-15.03-37.14-34.15a41.06 41.06 0 0 1-6.28 5.1c3.87 20.45 21.86 35.97 43.42 35.97 20.44 0 37.67-13.95 42.71-32.82v-22.76c-5.03-18.87-22.27-32.82-42.71-32.82Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M402.16 339.06c-2.8 0-5.53.26-8.19.74-.91.84-1.85 1.65-2.82 2.43a43.94 43.94 0 0 1 11.01-1.39c20.44 0 37.67 13.95 42.71 32.82v-5.65c-6.77-16.96-23.33-28.95-42.71-28.95ZM402.16 429.24c-21.56 0-39.55-15.52-43.42-35.97-.53.35-1.08.7-1.63 1.02 4.28 20.96 22.82 36.72 45.05 36.72 19.37 0 35.94-11.99 42.71-28.95v-5.65c-5.03 18.87-22.27 32.82-42.71 32.82Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M366.7 386.41a41.07 41.07 0 0 0 10.53-26.6c6.42-6.34 15.23-10.26 24.94-10.26 19.57 0 35.5 15.92 35.5 35.5s-15.92 35.5-35.5 35.5-34.74-15.19-35.46-34.13Zm6.53-1.37c0 15.98 12.95 28.93 28.93 28.93s28.93-12.95 28.93-28.93-12.95-28.93-28.93-28.93-28.93 12.95-28.93 28.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M377.25 358.87c0 .31-.02.62-.02.93 6.42-6.34 15.23-10.26 24.94-10.26 19.57 0 35.5 15.92 35.5 35.5s-15.92 35.5-35.5 35.5-34.74-15.19-35.46-34.13c-.54.6-1.1 1.19-1.67 1.76 1.59 19.12 17.6 34.15 37.14 34.15 20.59 0 37.28-16.69 37.28-37.28s-16.69-37.28-37.28-37.28c-9.6 0-18.34 3.63-24.95 9.59.02.51.04 1.01.04 1.52Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M375.01 385.04c0-14.97 12.18-27.15 27.15-27.15s27.15 12.18 27.15 27.15-12.18 27.15-27.15 27.15-27.15-12.18-27.15-27.15Zm6.57 0c0 11.37 9.22 20.58 20.58 20.58s20.58-9.22 20.58-20.58-9.22-20.58-20.58-20.58-20.58 9.22-20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M373.23 385.04c0-15.98 12.95-28.93 28.93-28.93s28.93 12.95 28.93 28.93-12.95 28.93-28.93 28.93-28.93-12.95-28.93-28.93Zm1.78 0c0 14.97 12.18 27.15 27.15 27.15s27.15-12.18 27.15-27.15-12.18-27.15-27.15-27.15-27.15 12.18-27.15 27.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M383.36 385.04c0-10.37 8.44-18.8 18.8-18.8s18.8 8.44 18.8 18.8-8.44 18.8-18.8 18.8-18.8-8.44-18.8-18.8Zm6.57 0c0 6.76 5.48 12.24 12.24 12.24s12.24-5.48 12.24-12.24-5.48-12.24-12.24-12.24-12.24 5.48-12.24 12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M381.58 385.04c0-11.37 9.22-20.58 20.58-20.58s20.58 9.22 20.58 20.58-9.22 20.58-20.58 20.58-20.58-9.22-20.58-20.58Zm1.78 0c0 10.37 8.44 18.8 18.8 18.8s18.8-8.44 18.8-18.8-8.44-18.8-18.8-18.8-18.8 8.44-18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M391.71 385.04c0-5.77 4.69-10.46 10.46-10.46s10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46-10.46-4.69-10.46-10.46Zm6.57 0c0 2.15 1.74 3.89 3.89 3.89s3.89-1.74 3.89-3.89-1.74-3.89-3.89-3.89-3.89 1.74-3.89 3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M389.93 385.04c0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24-12.24-5.48-12.24-12.24Zm1.78 0c0 5.77 4.69 10.46 10.46 10.46s10.46-4.69 10.46-10.46-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M402.16 387.15c1.16 0 2.11-.95 2.11-2.11s-.95-2.11-2.11-2.11-2.11.95-2.11 2.11.95 2.11 2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M398.28 385.04c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89-1.74 3.89-3.89 3.89-3.89-1.74-3.89-3.89Zm1.78 0c0 1.16.95 2.11 2.11 2.11s2.11-.95 2.11-2.11-.95-2.11-2.11-2.11-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M355.7 114.68c-.31-.72-.6-1.45-.87-2.19a42.62 42.62 0 0 1-4.19 2.69c.39.35.78.72 1.16 1.08a40.06 40.06 0 0 1 3.89-1.58ZM413.62 46.87c-1.09 1.9-2.32 3.7-3.69 5.4C429.55 57.52 444 75.41 444 96.68s-15.09 39.94-35.36 44.73c.61 1.96 1.07 3.98 1.38 6.05 15.32-3.62 28.06-14 34.86-27.82V73.71c-6.26-12.72-17.55-22.54-31.25-26.84Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M444.22 68.77a54.375 54.375 0 0 0-5.94-8.02c-1.23-1.38-2.53-2.7-3.89-3.94a53.905 53.905 0 0 0-13.99-9.23c-1.92-.87-3.89-1.65-5.92-2.3-.28.54-.57 1.07-.87 1.59 13.71 4.3 24.99 14.12 31.25 26.84V69.9c-.22-.38-.43-.76-.65-1.13ZM410.02 147.46c.09.59.16 1.18.23 1.78.67-.16 1.34-.32 2.01-.5 3.71-1.01 7.25-2.41 10.59-4.14 3.98-2.07 7.67-4.61 11-7.56 2.84-2.52 5.41-5.34 7.67-8.4.45-.61.89-1.23 1.32-1.86.73-1.08 1.4-2.19 2.05-3.32v-3.81c-6.8 13.82-19.53 24.2-34.86 27.82Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M374.83 67.5c-1.68-.12-3.33-.34-4.95-.65.43 1.37.79 2.78 1.07 4.21 1.21-1.27 2.5-2.46 3.87-3.55ZM364.35 112.69a37.306 37.306 0 0 1-2.45-6.76 41.892 41.892 0 0 1-5.57 5.41c.33.94.69 1.87 1.08 2.78 2.24-.67 4.56-1.15 6.94-1.43ZM435.3 96.68c0 18.17-13 33.29-30.2 36.6 1.16 2.04 2.16 4.19 2.96 6.44 19.55-4.56 34.16-22.12 34.16-43.03s-14.32-38.13-33.57-42.9a41.173 41.173 0 0 1-6.44 5.86c18.61 2.08 33.09 17.87 33.09 37.04Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M357.41 114.12c-.39-.91-.75-1.84-1.08-2.78-.49.39-.99.78-1.5 1.15.27.74.56 1.47.87 2.19.57-.2 1.14-.38 1.71-.56ZM442.22 96.68c0 20.92-14.61 38.47-34.16 43.03.2.56.39 1.13.57 1.7 20.27-4.79 35.36-23 35.36-44.73s-14.45-39.16-34.07-44.41c-.42.51-.84 1.02-1.28 1.51 19.25 4.77 33.57 22.19 33.57 42.9Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M371.34 73.3a40.831 40.831 0 0 1-.09 12.41c4.32-10.54 14.68-17.96 26.77-17.96 15.98 0 28.93 12.95 28.93 28.93s-12.19 28.15-27.5 28.89c1.74 1.87 3.3 3.9 4.67 6.07 16.68-2.9 29.4-17.47 29.4-34.96 0-18.95-14.93-34.48-33.64-35.45a41.047 41.047 0 0 1-22.04 6.39h-.17a36.009 36.009 0 0 0-6.33 5.68ZM369.24 112.39c1.58 0 3.13.1 4.66.27a28.796 28.796 0 0 1-4.82-15.98c0-1.15.08-2.29.21-3.41a41.086 41.086 0 0 1-5.97 10.9c.63 2.93 1.63 5.72 2.94 8.34.99-.07 1.98-.12 2.98-.12Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M370.96 71.05c.15.74.27 1.49.38 2.25 1.87-2.14 4-4.05 6.33-5.68-.96 0-1.9-.05-2.84-.12a37.181 37.181 0 0 0-3.87 3.55ZM366.26 112.51c-1.31-2.61-2.3-5.41-2.94-8.34-.46.6-.93 1.19-1.42 1.76.6 2.35 1.43 4.61 2.45 6.76.63-.07 1.27-.13 1.91-.18ZM433.52 96.68c0 17.49-12.72 32.06-29.4 34.96.34.54.67 1.08.98 1.63 17.2-3.31 30.2-18.43 30.2-36.6 0-19.17-14.47-34.95-33.09-37.04-.76.56-1.54 1.08-2.33 1.59 18.71.97 33.64 16.5 33.64 35.45Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M398.02 123.83h-.3c-5.78-5.52-13.16-9.38-21.37-10.81a27.003 27.003 0 0 1-5.48-16.34c0-14.97 12.18-27.15 27.15-27.15s27.15 12.18 27.15 27.15-12.18 27.15-27.15 27.15Zm0-6.57c11.37 0 20.58-9.22 20.58-20.58s-9.22-20.58-20.58-20.58-20.58 9.22-20.58 20.58 9.22 20.58 20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M373.91 112.66c.82.09 1.64.21 2.44.35a27.003 27.003 0 0 1-5.48-16.34c0-14.97 12.18-27.15 27.15-27.15s27.15 12.18 27.15 27.15-12.18 27.15-27.15 27.15h-.3c.59.57 1.17 1.15 1.73 1.75 15.31-.75 27.5-13.4 27.5-28.89S414 67.75 398.02 67.75c-12.09 0-22.45 7.42-26.77 17.96-.42 2.61-1.07 5.14-1.95 7.56-.13 1.12-.21 2.25-.21 3.41 0 5.91 1.78 11.4 4.82 15.98Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M398.02 77.88c10.37 0 18.8 8.44 18.8 18.8s-8.44 18.8-18.8 18.8-18.8-8.44-18.8-18.8 8.44-18.8 18.8-18.8Zm-12.24 18.8c0 6.76 5.48 12.24 12.24 12.24s12.24-5.48 12.24-12.24-5.48-12.24-12.24-12.24-12.24 5.48-12.24 12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M398.02 117.26c-11.37 0-20.58-9.22-20.58-20.58s9.22-20.58 20.58-20.58 20.58 9.22 20.58 20.58-9.22 20.58-20.58 20.58Zm0-39.39c-10.37 0-18.8 8.44-18.8 18.8s8.44 18.8 18.8 18.8 18.8-8.44 18.8-18.8-8.44-18.8-18.8-18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M398.02 86.22c5.77 0 10.46 4.69 10.46 10.46s-4.69 10.46-10.46 10.46-10.46-4.69-10.46-10.46 4.69-10.46 10.46-10.46Zm-3.89 10.46c0 2.15 1.74 3.89 3.89 3.89s3.89-1.74 3.89-3.89-1.74-3.89-3.89-3.89-3.89 1.74-3.89 3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M385.78 96.68c0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24-12.24-5.48-12.24-12.24Zm12.24-10.46c-5.77 0-10.46 4.69-10.46 10.46s4.69 10.46 10.46 10.46 10.46-4.69 10.46-10.46-4.69-10.46-10.46-10.46Z"
        />
        <circle className="cls-Venn3-3-3-3" cx={398.02} cy={96.68} r={2.11} />
        <path
          className="cls-Venn3-3-4"
          d="M394.13 96.68c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89-1.74 3.89-3.89 3.89-3.89-1.74-3.89-3.89Zm1.78 0c0 1.16.95 2.11 2.11 2.11s2.11-.95 2.11-2.11-.95-2.11-2.11-2.11-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M38.85 166.08c0-20.77 13.78-38.32 32.69-44.02a53.008 53.008 0 0 1-7.08-4.03c-18.69 7.95-31.82 26.5-31.82 48.05 0 17.39 8.56 32.81 21.68 42.3a54.36 54.36 0 0 1 6.08-3.36c-12.94-8.14-21.55-22.53-21.55-38.94ZM122.67 201.97c-.85-2.26-1.55-4.59-2.09-6.99a46.364 46.364 0 0 1-12.67 10.86c2.08 1.06 4.08 2.25 5.98 3.57 3.19-2.15 6.13-4.65 8.77-7.44Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M54.32 208.38c-13.12-9.49-21.68-24.91-21.68-42.3 0-21.56 13.14-40.1 31.82-48.05-.57-.39-1.13-.79-1.69-1.2a54.204 54.204 0 0 0-20.78 16.44l-.23.29a53.852 53.852 0 0 0-3.69 5.58c-.34.59-.67 1.18-.99 1.78a53.694 53.694 0 0 0-4.31 10.94c-1.24 4.53-1.91 9.3-1.91 14.22a54.15 54.15 0 0 0 1.97 14.45c.8 2.89 1.84 5.69 3.09 8.37.29.62.59 1.23.9 1.83.52 1.02 1.07 2.02 1.66 3 .47.79.96 1.56 1.47 2.33a54.09 54.09 0 0 0 6.43 7.88 54.27 54.27 0 0 0 6.32 5.5c.53-.37 1.07-.72 1.61-1.06ZM122.94 202.65c-.09-.23-.18-.45-.27-.68a52.88 52.88 0 0 1-8.77 7.44c.21.15.42.3.64.45a41.66 41.66 0 0 1 8.4-7.21Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M47.55 166.08c0-20.59 16.69-37.28 37.28-37.28 2.68 0 5.29.29 7.8.82-.05-.81-.09-1.62-.09-2.45-6.5-.25-12.7-1.64-18.41-3.98-19.22 4.8-33.5 22.2-33.5 42.88 0 16.18 8.74 30.35 21.75 38.05 3.08-1.3 6.31-2.31 9.66-3.03-14.29-5.22-24.48-18.93-24.48-35.02ZM120.11 192.67a54.23 54.23 0 0 1-.88-9.69c0-.9.02-1.8.07-2.69-4.14 10.02-12.51 17.83-22.88 21.22 3.32.83 6.51 1.96 9.56 3.37a44.448 44.448 0 0 0 14.14-12.22Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M120.58 194.98c-.17-.76-.33-1.53-.47-2.31-3.78 5.01-8.6 9.19-14.14 12.22.66.3 1.3.62 1.95.95 4.86-2.83 9.15-6.51 12.67-10.86ZM40.63 166.08c0-20.68 14.28-38.08 33.5-42.88-.87-.36-1.74-.74-2.59-1.14-18.91 5.7-32.69 23.25-32.69 44.02 0 16.41 8.61 30.81 21.55 38.94.65-.31 1.31-.61 1.98-.89-13-7.7-21.75-21.87-21.75-38.05Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M120.33 166.08v-.17c-2.35-.81-4.61-1.83-6.74-3.03.12 1.05.18 2.12.18 3.2 0 15.98-12.95 28.93-28.93 28.93s-28.93-12.95-28.93-28.93 12.95-28.93 28.93-28.93c3.3 0 6.47.56 9.43 1.58-.7-2.33-1.19-4.75-1.47-7.24-2.56-.59-5.22-.91-7.96-.91-19.57 0-35.5 15.92-35.5 35.5 0 16.48 11.29 30.38 26.55 34.35 2.44-.34 4.92-.52 7.45-.52 3.16 0 6.24.29 9.25.81 15.86-3.55 27.75-17.72 27.75-34.63Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M119.29 180.29c.24-4.83 1.12-9.48 2.54-13.89-.51-.15-1.01-.32-1.51-.49v.17c0 16.91-11.89 31.09-27.75 34.63 1.29.22 2.57.49 3.83.8a37.334 37.334 0 0 0 22.88-21.22ZM49.33 166.08c0-19.57 15.92-35.5 35.5-35.5 2.74 0 5.4.32 7.96.91-.07-.62-.12-1.24-.16-1.87-2.52-.54-5.13-.82-7.8-.82-20.59 0-37.28 16.69-37.28 37.28 0 16.09 10.2 29.8 24.48 35.02 1.27-.27 2.55-.49 3.85-.67-15.26-3.98-26.55-17.87-26.55-34.35Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M84.83 138.93c3.58 0 7 .7 10.14 1.97 3.11 8.64 9.03 15.94 16.66 20.81.23 1.43.36 2.89.36 4.38 0 14.97-12.18 27.15-27.15 27.15s-27.15-12.18-27.15-27.15 12.18-27.15 27.15-27.15Zm0 6.57c-11.37 0-20.58 9.22-20.58 20.58s9.22 20.58 20.58 20.58 20.58-9.22 20.58-20.58-9.22-20.58-20.58-20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M111.62 161.7c.23 1.43.36 2.89.36 4.38 0 14.97-12.18 27.15-27.15 27.15s-27.15-12.18-27.15-27.15 12.18-27.15 27.15-27.15c3.58 0 7 .7 10.14 1.97-.26-.71-.49-1.44-.71-2.17a28.885 28.885 0 0 0-9.43-1.58c-15.98 0-28.93 12.95-28.93 28.93s12.95 28.93 28.93 28.93 28.93-12.95 28.93-28.93c0-1.08-.06-2.15-.18-3.2-.66-.37-1.32-.77-1.96-1.18Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M103.63 166.08c0 10.37-8.44 18.8-18.8 18.8s-18.8-8.44-18.8-18.8 8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8Zm-6.57 0c0-6.76-5.48-12.24-12.24-12.24s-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24 12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M84.83 145.5c11.37 0 20.58 9.22 20.58 20.58s-9.22 20.58-20.58 20.58-20.58-9.22-20.58-20.58 9.22-20.58 20.58-20.58Zm18.8 20.58c0-10.37-8.44-18.8-18.8-18.8s-18.8 8.44-18.8 18.8 8.44 18.8 18.8 18.8 18.8-8.44 18.8-18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M95.29 166.08c0 5.77-4.69 10.46-10.46 10.46s-10.46-4.69-10.46-10.46 4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46Zm-6.57 0c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M97.07 166.08c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-1.78 0c0-5.77-4.69-10.46-10.46-10.46s-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46 10.46-4.69 10.46-10.46Z"
        />
        <circle
          className="cls-Venn3-3-3-3"
          cx={84.83}
          cy={166.08}
          r={2.11}
          transform="rotate(-13.28 84.837 166.116)"
        />
        <path
          className="cls-Venn3-3-4"
          d="M88.72 166.08c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M92.3 343.71c-.57.49-1.15.97-1.74 1.43 1.43.14 2.84.35 4.23.62-.85-.66-1.68-1.34-2.49-2.05ZM138.21 390.9c0-12.92-4.73-24.76-12.54-33.88-3.47-.14-6.86-.6-10.14-1.37 10.06 8.43 16.47 21.09 16.47 35.25 0 10.24-3.35 19.69-9.01 27.33 1.47 1.4 2.84 2.91 4.1 4.51a53.696 53.696 0 0 1 11.05-29.15c.05-.89.07-1.79.07-2.7ZM40.04 390.9c0-15.47 7.65-29.15 19.37-37.49-2.64-.37-5.19-1-7.64-1.85-10.98 9.58-17.94 23.65-17.94 39.33 0 17.62 8.79 33.22 22.2 42.68.75-1.98 1.64-3.88 2.67-5.7-11.32-8.38-18.66-21.82-18.66-36.98Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M139.82 386.83c-.06-.81-.14-1.62-.23-2.42-.36-2.98-.96-5.88-1.78-8.69a53.307 53.307 0 0 0-7.9-16.22c-.47-.65-.95-1.3-1.45-1.93-.13-.17-.27-.34-.4-.51h-.24c-.72 0-1.44-.03-2.15-.05 7.81 9.13 12.54 20.96 12.54 33.88 0 .9-.02 1.8-.07 2.7.59-.77 1.21-1.52 1.84-2.25 0-.15.01-.29.01-.44 0-1.37-.07-2.73-.17-4.07ZM33.83 390.9c0-15.68 6.96-29.76 17.94-39.33-.65-.22-1.29-.46-1.92-.72-.27.24-.53.49-.79.74a53.445 53.445 0 0 0-3.4 3.5 51.49 51.49 0 0 0-3.94 5.01 55.2 55.2 0 0 0-3.36 5.49c-.29.54-.57 1.09-.84 1.65-.73 1.49-1.39 3.02-1.98 4.58-.26.69-.5 1.39-.74 2.09a53.123 53.123 0 0 0-2.26 9.82c-.1.73-.19 1.47-.25 2.21-.15 1.64-.24 3.29-.24 4.96v.04c0 .61.03 1.22.05 1.83.05 1.57.17 3.12.36 4.65.07.59.14 1.19.23 1.78.24 1.53.54 3.03.91 4.51a54.706 54.706 0 0 0 1.97 6.36c.21.56.43 1.12.66 1.68a55.908 55.908 0 0 0 2.75 5.6c.59 1.05 1.22 2.09 1.88 3.09 1.49 2.27 3.13 4.42 4.94 6.43.74.83 1.51 1.64 2.3 2.42 1.92 1.9 3.99 3.65 6.17 5.24.38.28.76.55 1.15.82.19-.6.4-1.19.62-1.77-13.42-9.45-22.2-25.05-22.2-42.68Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M130.22 390.9c0-15.2-7.71-28.63-19.43-36.59a53.631 53.631 0 0 1-12-5.73 43.902 43.902 0 0 0-10.44-1.83 41.077 41.077 0 0 1-23.1 7.07c-1.03 0-2.06-.05-3.07-.13-12.24 7.87-20.36 21.6-20.36 37.19 0 14.48 7 27.36 17.8 35.43 1.22-1.96 2.61-3.81 4.13-5.53-9.11-6.8-15.01-17.66-15.01-29.9 0-20.59 16.69-37.28 37.28-37.28s37.28 16.69 37.28 37.28c0 8.24-2.68 15.85-7.21 22.03 1.97 1.21 3.83 2.58 5.56 4.09 5.38-7.32 8.57-16.35 8.57-26.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.79 345.77c-1.39-.27-2.8-.48-4.23-.62-.72.56-1.45 1.1-2.21 1.62 3.61.19 7.11.82 10.44 1.83-1.38-.88-2.71-1.82-4-2.82ZM132 390.9c0-14.16-6.4-26.82-16.47-35.25-1.61-.37-3.19-.82-4.74-1.33 11.72 7.96 19.43 21.39 19.43 36.59 0 9.76-3.19 18.79-8.57 26.11.46.4.9.8 1.34 1.22a45.744 45.744 0 0 0 9.01-27.33ZM41.82 390.9c0-15.6 8.13-29.32 20.36-37.19-.93-.07-1.86-.16-2.77-.29-11.72 8.33-19.37 22.01-19.37 37.49s7.34 28.61 18.66 36.98c.3-.52.6-1.04.92-1.55-10.8-8.07-17.8-20.94-17.8-35.43Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M86.02 355.4c-19.57 0-35.5 15.92-35.5 35.5 0 11.71 5.7 22.1 14.46 28.57 1.56-1.62 3.25-3.11 5.06-4.45-7.81-5.18-12.95-14.05-12.95-24.12 0-15.98 12.95-28.93 28.93-28.93s28.93 12.95 28.93 28.93c0 6.97-2.47 13.36-6.57 18.36 2.14.76 4.19 1.68 6.15 2.76a35.309 35.309 0 0 0 6.99-21.12c0-19.57-15.92-35.5-35.5-35.5Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M86.02 353.62c-20.59 0-37.28 16.69-37.28 37.28 0 12.24 5.9 23.1 15.01 29.9.4-.45.81-.89 1.23-1.33-8.76-6.47-14.46-16.87-14.46-28.57 0-19.57 15.92-35.5 35.5-35.5s35.5 15.92 35.5 35.5c0 7.91-2.6 15.22-6.99 21.12.53.29 1.05.59 1.56.9a37.083 37.083 0 0 0 7.21-22.03c0-20.59-16.69-37.28-37.28-37.28Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M86.02 363.75c-14.97 0-27.15 12.18-27.15 27.15 0 9.68 5.1 18.19 12.75 23 2.2-1.48 4.54-2.75 7.01-3.79-7.72-2.97-13.19-10.45-13.19-19.21 0-11.37 9.22-20.58 20.58-20.58s20.58 9.22 20.58 20.58c0 6.58-3.09 12.43-7.89 16.2 2.69.27 5.31.79 7.83 1.55a27.004 27.004 0 0 0 6.63-17.75c0-14.97-12.18-27.15-27.15-27.15Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M86.02 361.97c-15.98 0-28.93 12.95-28.93 28.93 0 10.07 5.15 18.94 12.95 24.12.52-.38 1.04-.76 1.58-1.12-7.65-4.81-12.75-13.32-12.75-23 0-14.97 12.18-27.15 27.15-27.15s27.15 12.18 27.15 27.15c0 6.78-2.51 12.99-6.63 17.75.62.19 1.23.39 1.84.6a28.78 28.78 0 0 0 6.57-18.36c0-15.98-12.95-28.93-28.93-28.93Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M95.81 406.93c-.4-.01-.79-.03-1.19-.03-4.64 0-9.1.78-13.27 2.2-8.11-2.09-14.13-9.44-14.13-18.19 0-10.37 8.44-18.8 18.8-18.8s18.8 8.44 18.8 18.8c0 6.78-3.62 12.72-9.02 16.03Zm2.45-16.03c0-6.76-5.48-12.24-12.24-12.24s-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24 12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M106.6 390.9c0-11.37-9.22-20.58-20.58-20.58s-20.58 9.22-20.58 20.58c0 8.76 5.48 16.24 13.19 19.21.89-.37 1.8-.71 2.71-1.02-8.11-2.09-14.13-9.44-14.13-18.19 0-10.37 8.44-18.8 18.8-18.8s18.8 8.44 18.8 18.8c0 6.78-3.62 12.72-9.02 16.03.98.03 1.95.08 2.9.17 4.8-3.77 7.89-9.62 7.89-16.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M96.48 390.9c0 5.77-4.69 10.46-10.46 10.46s-10.46-4.69-10.46-10.46 4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46Zm-6.57 0c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M98.26 390.9c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-1.78 0c0-5.77-4.69-10.46-10.46-10.46s-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46 10.46-4.69 10.46-10.46Z"
        />
        <circle className="cls-Venn3-3-3-3" cx={86.02} cy={390.9} r={2.11} />
        <path
          className="cls-Venn3-3-4"
          d="M89.91 390.9c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M37.35 253.88c0-25.39 20.59-45.98 45.98-45.98 9.87 0 19 3.12 26.49 8.41 1.05-1.78 2.24-3.48 3.54-5.08-8.5-6-18.85-9.54-30.03-9.54-28.78 0-52.19 23.41-52.19 52.19a51.9 51.9 0 0 0 7.62 27.13c1.59-1.33 3.27-2.55 5.05-3.63a45.744 45.744 0 0 1-6.46-23.5Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M38.77 281.01a51.9 51.9 0 0 1-7.62-27.13c0-28.78 23.41-52.19 52.19-52.19 11.17 0 21.53 3.54 30.03 9.54.38-.47.77-.92 1.17-1.37-.21-.15-.42-.3-.64-.45-1.91-1.31-3.91-2.5-5.98-3.57-.64-.33-1.29-.65-1.95-.95a52.851 52.851 0 0 0-13.39-4.17c-3.01-.52-6.09-.81-9.25-.81a55.363 55.363 0 0 0-11.3 1.19c-3.34.71-6.58 1.73-9.66 3.03-.67.28-1.33.58-1.98.89a54.36 54.36 0 0 0-6.08 3.36c-.54.35-1.08.7-1.61 1.06-.45.31-.89.63-1.33.95a53.148 53.148 0 0 0-5.1 4.27 52.33 52.33 0 0 0-3.2 3.29c-.4.45-.79.9-1.18 1.36a53.399 53.399 0 0 0-3.85 5.23 52.762 52.762 0 0 0-3.59 6.46 53.62 53.62 0 0 0-3.79 11.12 54.92 54.92 0 0 0-.86 5c-.08.61-.15 1.22-.2 1.83a54.35 54.35 0 0 0-.21 4.05c0 .29-.02.58-.02.87 0 .48.02.95.04 1.43.08 3.21.43 6.36 1.05 9.41.27 1.32.6 2.61.96 3.89 1.37 4.84 3.39 9.41 5.98 13.6.45-.41.9-.81 1.37-1.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M46.06 253.88c0-20.59 16.69-37.28 37.28-37.28 8.6 0 16.51 2.92 22.82 7.81.74-2.27 1.67-4.45 2.78-6.53a43.91 43.91 0 0 0-25.6-8.2c-24.37 0-44.2 19.83-44.2 44.2 0 8.25 2.28 15.98 6.24 22.6 2.02-1.12 4.15-2.06 6.37-2.83a37.132 37.132 0 0 1-5.68-19.77Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M39.13 253.88c0-24.37 19.83-44.2 44.2-44.2 9.54 0 18.37 3.05 25.6 8.2.28-.53.58-1.06.89-1.57a45.752 45.752 0 0 0-26.49-8.41c-25.39 0-45.98 20.59-45.98 45.98 0 8.59 2.36 16.62 6.46 23.5.51-.31 1.03-.61 1.55-.9a43.89 43.89 0 0 1-6.24-22.6Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M54.4 253.88c0-15.98 12.95-28.93 28.93-28.93 8.23 0 15.65 3.44 20.91 8.95.21-2.63.67-5.18 1.35-7.64-6.09-4.92-13.84-7.88-22.26-7.88-19.57 0-35.5 15.92-35.5 35.5 0 7.07 2.09 13.66 5.67 19.2 2.26-.67 4.61-1.15 7.01-1.43a28.757 28.757 0 0 1-6.11-17.77Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M47.84 253.88c0-19.57 15.92-35.5 35.5-35.5 8.42 0 16.17 2.96 22.26 7.88.17-.62.36-1.24.56-1.85a37.12 37.12 0 0 0-22.82-7.81c-20.59 0-37.28 16.69-37.28 37.28 0 7.26 2.09 14.04 5.68 19.77.58-.2 1.17-.39 1.76-.57a35.266 35.266 0 0 1-5.67-19.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M80.38 274.25c.49.19.97.39 1.45.6.09-.14.18-.28.27-.43-.58-.03-1.15-.09-1.72-.17ZM62.75 253.88c0-11.37 9.22-20.58 20.58-20.58s20.58 9.22 20.58 20.58c0 .28-.01.56-.02.84 1.11-.55 2.24-1.06 3.4-1.54a41.164 41.164 0 0 1-3.19-15.92c0-.27.02-.55.02-.82-4.98-5.93-12.45-9.71-20.79-9.71-14.97 0-27.15 12.18-27.15 27.15 0 6.7 2.45 12.84 6.49 17.58.86-.05 1.72-.09 2.59-.09 3.11 0 6.14.36 9.05 1.01-6.84-3.35-11.56-10.37-11.56-18.5Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M56.18 253.88c0-14.97 12.18-27.15 27.15-27.15 8.34 0 15.8 3.78 20.79 9.71.02-.85.06-1.7.13-2.54-5.27-5.51-12.69-8.95-20.91-8.95-15.98 0-28.93 12.95-28.93 28.93 0 6.7 2.29 12.87 6.11 17.77.71-.08 1.43-.15 2.15-.19-4.04-4.74-6.49-10.88-6.49-17.58Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M102.14 253.88c0 .6-.03 1.2-.09 1.79-7.54 4.1-13.98 9.96-18.81 17.01-10.33-.05-18.71-8.46-18.71-18.8s8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8Zm-6.57 0c0-6.76-5.48-12.24-12.24-12.24s-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24 12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M83.24 272.68c-10.33-.05-18.71-8.46-18.71-18.8s8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8c0 .6-.03 1.2-.09 1.79.61-.33 1.23-.64 1.85-.95.01-.28.02-.56.02-.84 0-11.37-9.22-20.58-20.58-20.58s-20.58 9.22-20.58 20.58c0 8.13 4.71 15.16 11.56 18.5 2.09.47 4.12 1.1 6.08 1.87.57.08 1.14.14 1.72.17.37-.59.74-1.17 1.14-1.74Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M83.33 264.34c-5.77 0-10.46-4.69-10.46-10.46s4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46Zm3.89-10.46c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M95.57 253.88c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-12.24 10.46c5.77 0 10.46-4.69 10.46-10.46s-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M83.33 251.77c-1.16 0-2.11.95-2.11 2.11s.95 2.11 2.11 2.11 2.11-.95 2.11-2.11-.95-2.11-2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M87.22 253.88c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M309.39 43.79c3.69-2.21 7.75-3.85 12.07-4.82-1.36.12-2.73.19-4.12.19-10.64 0-20.42-3.62-28.21-9.68-.09 2.4-.38 4.75-.87 7.02.96.65 1.95 1.25 2.95 1.83 6.48.74 12.6 2.63 18.17 5.45ZM330.53 37.96c2.72 0 5.38.27 7.95.77a41.26 41.26 0 0 1-1.07-4.18 45.61 45.61 0 0 1-10.28 3.55c1.12-.09 2.25-.15 3.4-.15Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M291.21 38.34c-1-.58-1.99-1.19-2.95-1.83-.11.52-.23 1.04-.36 1.55 1.11.06 2.22.16 3.31.29Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M337.09 32.71c-.32-2.06-.48-4.17-.48-6.32 0-.44.02-.87.03-1.31-5.63 3.42-12.24 5.38-19.3 5.38-12.46 0-23.48-6.12-30.25-15.5 1.27 3.86 1.99 7.97 2.06 12.24 7.66 6.35 17.48 10.18 28.19 10.18 7.1 0 13.8-1.69 19.75-4.68ZM280.69 0h-2.39c1.24 1.35 2.39 2.79 3.44 4.29-.44-1.4-.78-2.83-1.05-4.29Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M321.46 38.97c1.85-.42 3.75-.71 5.68-.86 3.6-.78 7.05-1.98 10.28-3.55-.12-.61-.23-1.23-.33-1.85a43.873 43.873 0 0 1-19.75 4.68c-10.7 0-20.53-3.83-28.19-10.18 0 .23.02.45.02.68 0 .54-.02 1.07-.04 1.6 7.79 6.06 17.57 9.68 28.21 9.68 1.39 0 2.76-.07 4.12-.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M346.16 0h-.71c-.11.45-.23.9-.36 1.35.35-.46.71-.91 1.07-1.35ZM317.34 28.68c7.17 0 13.84-2.14 19.43-5.81.31-3.71 1.11-7.28 2.34-10.64-5.3 6.06-13.09 9.89-21.77 9.89-13.63 0-25.05-9.43-28.12-22.12h-6.72c3.19 16.32 17.59 28.68 34.83 28.68Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M287.09 14.96c6.77 9.39 17.79 15.5 30.25 15.5 7.07 0 13.67-1.97 19.3-5.38.02-.74.06-1.48.13-2.21a35.277 35.277 0 0 1-19.43 5.81c-17.24 0-31.65-12.36-34.83-28.68h-1.81c.27 1.46.62 2.9 1.05 4.29 2.27 3.25 4.08 6.83 5.35 10.67Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M317.34 20.34c12.62 0 23.25-8.65 26.28-20.34h-6.86c-2.81 8.02-10.44 13.77-19.42 13.77S300.73 8.02 297.92 0h-6.86c3.03 11.68 13.67 20.34 26.28 20.34Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M317.34 22.12c8.68 0 16.46-3.83 21.77-9.89 1.44-3.95 3.48-7.6 5.99-10.88.13-.45.25-.89.36-1.35h-1.83c-3.03 11.68-13.67 20.34-26.28 20.34S294.1 11.69 291.07 0h-1.83c3.07 12.69 14.49 22.12 28.12 22.12Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M317.34 11.99c7.96 0 14.77-4.98 17.51-11.99h-7.34c-2.2 3.27-5.93 5.42-10.16 5.42S309.38 3.27 307.19 0h-7.34c2.74 7.01 9.54 11.99 17.51 11.99Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M317.34 13.77c8.98 0 16.61-5.75 19.42-13.77h-1.92c-2.74 7.01-9.54 11.99-17.51 11.99S302.56 7.01 299.82 0h-1.92c2.81 8.02 10.44 13.77 19.42 13.77Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M317.34 3.64c3.16 0 5.99-1.42 7.91-3.64h-15.83c1.92 2.23 4.75 3.64 7.91 3.64Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M317.34 5.42c4.23 0 7.97-2.15 10.16-5.42h-2.25c-1.92 2.23-4.75 3.64-7.91 3.64s-5.99-1.42-7.91-3.64h-2.25c2.2 3.27 5.93 5.42 10.16 5.42Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M212.81 27.88c0-.95.05-1.89.12-2.82-1.27.94-2.58 1.82-3.93 2.65 1.29.29 2.57.62 3.83 1 0-.28-.02-.55-.02-.83ZM283.06 27.88c0 3.55-.53 6.98-1.51 10.21 1.15-.07 2.3-.12 3.47-.12.35 0 .7.02 1.05.03.86-3.23 1.32-6.62 1.32-10.12 0-10.88-4.43-20.74-11.57-27.88h-6.53c8.37 6.42 13.78 16.52 13.78 27.88Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M287.39 27.88c0 3.5-.46 6.89-1.32 10.12.61.01 1.23.02 1.83.05a41.584 41.584 0 0 0 1.23-8.57c.02-.53.04-1.06.04-1.6 0-.23-.01-.45-.02-.68-.07-4.27-.79-8.38-2.06-12.24a41.214 41.214 0 0 0-5.35-10.67c-1.05-1.51-2.2-2.94-3.44-4.29h-2.48c7.14 7.14 11.57 17.01 11.57 27.88Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M214.63 29.29c1.72.59 3.4 1.26 5.04 2.01-.13-1.12-.21-2.26-.21-3.42 0-3.82.76-7.47 2.13-10.8a55.344 55.344 0 0 1-6.7 6.47c-.18 1.42-.29 2.86-.29 4.33 0 .47.02.94.04 1.41ZM242.14 0h-10.46a53.11 53.11 0 0 1-3.15 7.05c3.74-3.48 8.41-5.98 13.61-7.05ZM276.41 27.88c0 3.97-.82 7.75-2.29 11.19 1.8-.37 3.64-.65 5.5-.83 1.07-3.26 1.66-6.74 1.66-10.36 0-11.65-6.01-21.92-15.09-27.88h-12.45c12.95 2.68 22.68 14.15 22.68 27.88Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M212.83 28.71c.6.18 1.2.38 1.79.58-.02-.47-.04-.94-.04-1.41 0-1.47.11-2.91.29-4.33-.64.52-1.29 1.02-1.95 1.51-.07.93-.12 1.87-.12 2.82 0 .28.01.55.02.83ZM281.28 27.88c0 3.62-.59 7.1-1.66 10.36.64-.06 1.28-.11 1.92-.15.98-3.23 1.51-6.66 1.51-10.21 0-11.37-5.4-21.46-13.78-27.88h-3.1c9.08 5.96 15.09 16.23 15.09 27.88Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M251.45 49.71c-1.14.18-2.32.28-3.51.28-2.41 0-4.73-.39-6.9-1.11 1.26 1.75 2.43 3.58 3.47 5.47.55.07 1.1.13 1.65.16 1.65-1.72 3.42-3.32 5.29-4.81ZM225.83 27.88c0-12.21 9.9-22.1 22.1-22.1s22.1 9.9 22.1 22.1c0 5.27-1.84 10.1-4.92 13.9 2.2-.88 4.48-1.62 6.81-2.2 1.73-3.53 2.71-7.5 2.71-11.7 0-14.72-11.98-26.7-26.7-26.7s-26.7 11.98-26.7 26.7c0 1.48.13 2.93.36 4.35 1.93.98 3.79 2.06 5.58 3.25-.87-2.37-1.34-4.93-1.34-7.6Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M221.58 17.09a28.335 28.335 0 0 0-2.13 10.8c0 1.16.08 2.3.21 3.42.65.3 1.29.61 1.93.93-.23-1.42-.36-2.87-.36-4.35 0-14.72 11.98-26.7 26.7-26.7s26.7 11.98 26.7 26.7c0 4.19-.97 8.16-2.71 11.7.73-.18 1.46-.36 2.2-.51 1.47-3.44 2.29-7.22 2.29-11.19 0-13.74-9.73-25.21-22.68-27.88h-11.6c-5.2 1.08-9.87 3.57-13.61 7.05a53.986 53.986 0 0 1-6.94 10.03ZM246.16 54.52c-.56-.04-1.11-.09-1.65-.16.22.4.43.81.64 1.22.33-.36.67-.71 1.01-1.06Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M247.94 48.21c-3.25 0-6.32-.77-9.05-2.13-2.6-3.16-5.56-6-8.81-8.5a20.178 20.178 0 0 1-2.46-9.69c0-11.21 9.12-20.32 20.32-20.32s20.32 9.12 20.32 20.32c0 6.52-3.09 12.33-7.88 16.05-1.63.84-3.22 1.76-4.75 2.76-2.37.98-4.97 1.52-7.69 1.52Zm0-4.6c8.68 0 15.72-7.04 15.72-15.72s-7.04-15.72-15.72-15.72-15.72 7.04-15.72 15.72 7.04 15.72 15.72 15.72Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M251.45 49.71c1.34-1.07 2.74-2.08 4.18-3.02-2.37.98-4.97 1.52-7.69 1.52-3.25 0-6.32-.77-9.05-2.13.75.91 1.46 1.85 2.15 2.81 2.17.71 4.49 1.11 6.9 1.11 1.19 0 2.37-.1 3.51-.28ZM247.94 7.56c11.21 0 20.32 9.12 20.32 20.32 0 6.52-3.09 12.33-7.88 16.05 1.54-.79 3.12-1.51 4.74-2.15a22.04 22.04 0 0 0 4.92-13.9c0-12.21-9.9-22.1-22.1-22.1s-22.1 9.9-22.1 22.1c0 2.67.47 5.23 1.34 7.6.99.66 1.96 1.36 2.9 2.09a20.178 20.178 0 0 1-2.46-9.69c0-11.21 9.12-20.32 20.32-20.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M233.99 27.88c0-7.69 6.26-13.95 13.95-13.95s13.95 6.26 13.95 13.95-6.26 13.95-13.95 13.95-13.95-6.26-13.95-13.95Zm4.6 0c0 5.16 4.19 9.35 9.35 9.35s9.35-4.19 9.35-9.35-4.19-9.35-9.35-9.35-9.35 4.19-9.35 9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M247.94 43.61c-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72 15.72 7.04 15.72 15.72-7.04 15.72-15.72 15.72Zm-13.95-15.72c0 7.69 6.26 13.95 13.95 13.95s13.95-6.26 13.95-13.95-6.26-13.95-13.95-13.95-13.95 6.26-13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M240.37 27.88c0-4.17 3.4-7.57 7.57-7.57s7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57-7.57-3.4-7.57-7.57Zm4.6 0a2.97 2.97 0 1 0 5.94 0 2.97 2.97 0 0 0-5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M238.59 27.88c0-5.16 4.19-9.35 9.35-9.35s9.35 4.19 9.35 9.35-4.19 9.35-9.35 9.35-9.35-4.19-9.35-9.35Zm1.78 0c0 4.17 3.4 7.57 7.57 7.57s7.57-3.4 7.57-7.57-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M247.94 29.08c.66 0 1.19-.53 1.19-1.19s-.53-1.19-1.19-1.19-1.19.53-1.19 1.19.53 1.19 1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M244.96 27.88a2.97 2.97 0 1 1 5.94 0 2.97 2.97 0 0 1-5.94 0Zm1.78 0c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M87.91 339.44a34.983 34.983 0 0 1-22.66 8.29c-19.4 0-35.13-15.73-35.13-35.13s15.73-35.13 35.13-35.13c4.85 0 9.47.98 13.67 2.76.61-1.31 1.28-2.59 1.99-3.83a39.195 39.195 0 0 0-15.67-3.25c-21.75 0-39.45 17.7-39.45 39.45s17.7 39.45 39.45 39.45c9.81 0 18.79-3.61 25.7-9.56-1.05-.98-2.07-2-3.04-3.06Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M51.77 351.57c2.45.85 5 1.47 7.64 1.85.91.13 1.84.23 2.77.29 1.01.07 2.04.13 3.07.13 8.56 0 16.51-2.61 23.1-7.07.76-.51 1.49-1.06 2.21-1.62.59-.46 1.17-.94 1.74-1.43-.45-.4-.9-.8-1.34-1.21-6.91 5.95-15.89 9.56-25.7 9.56-21.75 0-39.45-17.7-39.45-39.45s17.7-39.45 39.45-39.45c5.56 0 10.86 1.16 15.67 3.25.3-.52.6-1.04.91-1.55a41.566 41.566 0 0 0-7.53-2.47c-2.91-.65-5.94-1.01-9.05-1.01a44.502 44.502 0 0 0-4.74.28c-2.41.28-4.75.76-7.01 1.43-.59.18-1.18.37-1.76.57a41.29 41.29 0 0 0-6.37 2.83 41.133 41.133 0 0 0-6.6 4.53c-.46.39-.92.79-1.37 1.2-1.61 1.48-3.1 3.09-4.47 4.81-.48.61-.95 1.24-1.4 1.87-4.72 6.71-7.51 14.88-7.51 23.71 0 1.9.14 3.76.39 5.58.11.79.23 1.56.38 2.34a40.3 40.3 0 0 0 2.26 7.59c.24.59.5 1.18.76 1.76a41.98 41.98 0 0 0 3.08 5.53c.33.5.68 1 1.04 1.49a41.13 41.13 0 0 0 6.58 7.1 41.22 41.22 0 0 0 11.33 6.87c.63.26 1.27.5 1.92.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M65.25 279.26c-18.39 0-33.35 14.96-33.35 33.35s14.96 33.35 33.35 33.35c8.18 0 15.67-2.96 21.48-7.87a53.164 53.164 0 0 1-2.98-3.84c-4.98 4.26-11.44 6.84-18.5 6.84-15.73 0-28.48-12.75-28.48-28.48s12.75-28.48 28.48-28.48c3.99 0 7.78.82 11.23 2.3.5-1.55 1.07-3.07 1.71-4.56a33.188 33.188 0 0 0-12.94-2.61Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M65.25 277.48c-19.4 0-35.13 15.73-35.13 35.13s15.73 35.13 35.13 35.13c8.64 0 16.54-3.12 22.66-8.29-.4-.44-.79-.9-1.18-1.35-5.81 4.9-13.3 7.87-21.48 7.87-18.39 0-33.35-14.96-33.35-33.35s14.96-33.35 33.35-33.35c4.59 0 8.96.93 12.94 2.61.24-.55.48-1.09.74-1.63-4.2-1.78-8.82-2.76-13.67-2.76Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M65.25 285.91c-14.72 0-26.7 11.98-26.7 26.7s11.98 26.7 26.7 26.7c6.68 0 12.79-2.47 17.48-6.54-.85-1.3-1.66-2.63-2.41-4a22.01 22.01 0 0 1-15.07 5.95c-12.21 0-22.1-9.9-22.1-22.1s9.9-22.1 22.1-22.1c3.44 0 6.7.79 9.61 2.19.3-1.54.67-3.05 1.1-4.54-3.28-1.44-6.9-2.25-10.71-2.25Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M36.78 312.6c0 15.73 12.75 28.48 28.48 28.48 7.06 0 13.52-2.58 18.5-6.84-.35-.49-.69-.98-1.02-1.49-4.69 4.07-10.8 6.54-17.48 6.54-14.72 0-26.7-11.98-26.7-26.7s11.98-26.7 26.7-26.7c3.81 0 7.43.81 10.71 2.25.17-.58.34-1.15.52-1.72-3.45-1.48-7.24-2.3-11.23-2.3-15.73 0-28.48 12.75-28.48 28.48Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M44.93 312.6c0 11.21 9.12 20.32 20.32 20.32 5.53 0 10.56-2.23 14.22-5.83-.74-1.49-1.41-3.02-2.02-4.59a15.685 15.685 0 0 1-12.21 5.82c-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72c3.23 0 6.23.98 8.73 2.65.11-1.69.29-3.35.55-4.99a20.129 20.129 0 0 0-9.28-2.25c-11.21 0-20.32 9.12-20.32 20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M43.15 312.6c0 12.21 9.9 22.1 22.1 22.1 5.83 0 11.13-2.26 15.07-5.95-.3-.55-.57-1.1-.85-1.66-3.67 3.6-8.69 5.83-14.22 5.83-11.21 0-20.32-9.12-20.32-20.32s9.12-20.32 20.32-20.32c3.34 0 6.49.82 9.28 2.25.1-.62.21-1.23.33-1.84a22.08 22.08 0 0 0-9.61-2.19c-12.21 0-22.1 9.9-22.1 22.1Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M51.31 312.6c0 7.69 6.26 13.95 13.95 13.95 4.76 0 8.96-2.4 11.48-6.05-.95-2.79-1.68-5.69-2.17-8.66.02.25.04.51.04.76 0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35c4.47 0 8.2 3.14 9.12 7.33-.34-2.45-.53-4.94-.53-7.48 0-.48.02-.95.04-1.43a13.868 13.868 0 0 0-8.63-3.01c-7.69 0-13.95 6.26-13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M65.25 296.88c-8.68 0-15.72 7.04-15.72 15.72s7.04 15.72 15.72 15.72c4.93 0 9.32-2.27 12.21-5.82-.26-.66-.5-1.33-.73-2.01-2.52 3.65-6.72 6.05-11.48 6.05-7.69 0-13.95-6.26-13.95-13.95s6.26-13.95 13.95-13.95c3.26 0 6.25 1.13 8.63 3.01.02-.72.05-1.44.09-2.15-2.5-1.67-5.5-2.65-8.73-2.65Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M72.82 312.6c0 4.17-3.4 7.57-7.57 7.57s-7.57-3.4-7.57-7.57 3.4-7.57 7.57-7.57 7.57 3.4 7.57 7.57Zm-4.6 0a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M74.38 310.58c.06.42.12.84.19 1.26.02.25.04.51.04.76 0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35c4.47 0 8.2 3.14 9.12 7.33Zm-1.55 2.02c0-4.17-3.4-7.57-7.57-7.57s-7.57 3.4-7.57 7.57 3.4 7.57 7.57 7.57 7.57-3.4 7.57-7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M65.25 311.41c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M68.23 312.6a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M179.7 244.52c-1.78 8.48-6.62 15.83-13.31 20.85 1.01 1.03 1.98 2.09 2.9 3.2.8-.61 1.57-1.25 2.32-1.92 2.02-2.9 4.32-5.59 6.86-8.04 1.33-2.05 2.47-4.23 3.4-6.52-.9-2.45-1.63-4.98-2.18-7.58ZM125.51 208.26a56.49 56.49 0 0 1-1.89-3.92c-10.68 7.07-17.74 19.18-17.74 32.92 0 5.41 1.1 10.57 3.08 15.27 1.35-.5 2.73-.95 4.12-1.34a34.938 34.938 0 0 1-2.87-13.92c0-12.04 6.06-22.67 15.3-29Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M181.88 252.09c-.93 2.29-2.07 4.47-3.4 6.52 1.29-1.24 2.63-2.41 4.04-3.52.1-.21.19-.43.29-.64-.33-.78-.64-1.56-.93-2.35ZM109.82 216.31c-.31.52-.6 1.04-.89 1.57a40.549 40.549 0 0 0-2.78 6.53c-.2.61-.38 1.22-.56 1.85a40.98 40.98 0 0 0-1.35 7.64c-.07.84-.11 1.68-.13 2.54 0 .27-.02.54-.02.82 0 5.64 1.14 11.02 3.19 15.92.55-.23 1.1-.45 1.66-.66-1.98-4.7-3.08-9.86-3.08-15.27 0-13.74 7.06-25.85 17.74-32.92-.24-.56-.46-1.12-.69-1.69a41.389 41.389 0 0 0-9.57 8.58c-1.3 1.6-2.48 3.3-3.54 5.08ZM169.88 269.29c.55-.9 1.14-1.78 1.74-2.64-.75.67-1.52 1.31-2.32 1.92.2.24.39.48.58.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M178.68 237.26c0-.19-.01-.37-.01-.56v-.02c-1.6.16-3.22.24-4.86.26v.32c0 9.79-4.94 18.43-12.47 23.55 1.3 1.04 2.56 2.13 3.76 3.28 8.23-6.08 13.58-15.84 13.58-26.83ZM116.85 237.26c0-9.65 4.8-18.17 12.14-23.32-.93-1.32-1.8-2.69-2.61-4.1-8.69 6.02-14.4 16.07-14.4 27.42 0 4.79 1.02 9.34 2.84 13.45 1.61-.4 3.24-.73 4.9-.98-1.84-3.77-2.87-8-2.87-12.48Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M179.7 244.52c-.53-2.54-.88-5.15-1.04-7.82 0 .19.01.37.01.56 0 10.99-5.35 20.76-13.58 26.83.44.42.87.84 1.3 1.28 6.69-5.02 11.53-12.37 13.31-20.85ZM126.39 209.84c-.3-.52-.6-1.04-.88-1.58-9.24 6.33-15.3 16.95-15.3 29 0 4.95 1.03 9.65 2.87 13.92.58-.16 1.16-.33 1.75-.47a33.21 33.21 0 0 1-2.84-13.45c0-11.35 5.71-21.4 14.4-27.42Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M172.03 237.26v-.33c-1.55-.03-3.09-.12-4.6-.28 0 .2.02.41.02.61 0 8.5-4.8 15.87-11.83 19.57 1.46.88 2.87 1.82 4.23 2.83 7.33-4.76 12.19-13.02 12.19-22.4ZM132.96 218.94c-1.02-1.14-2-2.32-2.92-3.55-6.89 4.83-11.41 12.83-11.41 21.87 0 4.41 1.08 8.57 2.98 12.24 1.68-.19 3.38-.3 5.1-.34a22.01 22.01 0 0 1-3.48-11.9c0-7.62 3.86-14.34 9.73-18.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M173.81 237.26v-.32c-.2 0-.4.02-.6.02-.39 0-.78-.02-1.18-.03v.33c0 9.38-4.86 17.63-12.19 22.4.51.38 1.01.77 1.5 1.16 7.53-5.12 12.47-13.76 12.47-23.55ZM130.04 215.39c-.36-.48-.7-.97-1.04-1.45-7.34 5.15-12.14 13.67-12.14 23.32 0 4.47 1.03 8.71 2.87 12.48.62-.09 1.26-.17 1.89-.24-1.9-3.67-2.98-7.83-2.98-12.24 0-9.04 4.52-17.03 11.41-21.87Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M165.65 237.26c0-.28 0-.56-.02-.84-1.58-.22-3.14-.52-4.67-.87.06.56.1 1.13.1 1.71 0 7.97-5.93 14.53-13.61 15.57 2.17.85 4.27 1.82 6.29 2.93 7.02-3.2 11.91-10.29 11.91-18.49ZM129.61 237.26c0-5.88 3.24-11 8.02-13.7a53.28 53.28 0 0 1-3.43-3.29c-5.53 3.64-9.19 9.89-9.19 16.99 0 4.44 1.44 8.55 3.86 11.9 2.33.04 4.64.23 6.89.56-3.74-2.87-6.15-7.38-6.15-12.46Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M167.43 237.26c0-.21 0-.41-.02-.61-.6-.06-1.19-.14-1.79-.23.01.28.02.56.02.84 0 8.21-4.89 15.29-11.91 18.49.63.35 1.25.7 1.87 1.07 7.03-3.7 11.83-11.07 11.83-19.57ZM125.01 237.26c0-7.1 3.66-13.35 9.19-16.99-.42-.44-.83-.88-1.24-1.33-5.87 3.97-9.73 10.69-9.73 18.32 0 4.38 1.28 8.46 3.48 11.9.37 0 .74-.03 1.11-.03.35 0 .7.02 1.05.03a20.194 20.194 0 0 1-3.86-11.9Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M154.68 237.26c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35c0-4.56 3.26-8.35 7.58-9.18a53.423 53.423 0 0 1-4.47-3.28c-4.56 2.3-7.7 7.01-7.7 12.45 0 7.69 6.26 13.95 13.95 13.95s13.95-6.26 13.95-13.95c0-.74-.08-1.47-.19-2.18a54.98 54.98 0 0 1-5.27-1.72c.55 1.19.86 2.51.86 3.9Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M161.06 237.26c0-.58-.04-1.15-.1-1.71-.63-.15-1.25-.3-1.87-.46.11.71.19 1.44.19 2.18 0 7.69-6.26 13.95-13.95 13.95s-13.95-6.26-13.95-13.95c0-5.44 3.14-10.16 7.7-12.45-.5-.41-.98-.83-1.46-1.25-4.78 2.7-8.02 7.82-8.02 13.7 0 5.08 2.42 9.58 6.15 12.46 4.06.6 7.97 1.66 11.69 3.11 7.68-1.04 13.61-7.6 13.61-15.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M145.33 229.69c.32 0 .64.03.95.07 1.49.86 3.02 1.65 4.59 2.36a7.516 7.516 0 0 1 2.03 5.14c0 4.17-3.4 7.57-7.57 7.57s-7.57-3.4-7.57-7.57 3.4-7.57 7.57-7.57Zm0 4.6a2.97 2.97 0 1 0 0 5.94 2.97 2.97 0 0 0 0-5.94Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M135.98 237.26c0 5.16 4.19 9.35 9.35 9.35s9.35-4.19 9.35-9.35c0-1.39-.31-2.71-.86-3.9-1-.38-1.98-.8-2.95-1.24a7.516 7.516 0 0 1 2.03 5.14c0 4.17-3.4 7.57-7.57 7.57s-7.57-3.4-7.57-7.57 3.4-7.57 7.57-7.57c.32 0 .64.03.95.07a58.25 58.25 0 0 1-2.72-1.67c-4.31.83-7.58 4.62-7.58 9.18Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M145.33 236.07c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M145.33 234.29a2.97 2.97 0 1 1 0 5.94 2.97 2.97 0 0 1 0-5.94Zm1.19 2.97c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M369.24 118.5c19.4 0 35.13 15.73 35.13 35.13 0 4.79-.96 9.35-2.7 13.51 1.17.98 2.28 2.03 3.33 3.13 2.37-5.06 3.7-10.7 3.7-16.65 0-21.75-17.7-39.45-39.45-39.45-5.72 0-11.15 1.23-16.06 3.43a54.87 54.87 0 0 1 3.07 3.39c4.02-1.6 8.4-2.49 13-2.49Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M408.06 139.71c-.8-2.24-1.8-4.39-2.96-6.44-.31-.55-.64-1.1-.98-1.63-1.37-2.17-2.93-4.2-4.67-6.07-.56-.6-1.14-1.18-1.73-1.75-5.78-5.52-13.16-9.38-21.37-10.81a42.108 42.108 0 0 0-7.1-.62c-1 0-2 .05-2.98.12-.64.05-1.28.1-1.91.18-2.38.28-4.7.76-6.94 1.43-.58.17-1.15.36-1.71.56-1.33.46-2.63.99-3.89 1.58.46.44.93.89 1.37 1.34 4.91-2.2 10.34-3.43 16.06-3.43 21.75 0 39.45 17.7 39.45 39.45 0 5.95-1.33 11.58-3.7 16.65.45.47.88.95 1.3 1.43a41.209 41.209 0 0 0 2.51-6.5 41.27 41.27 0 0 0 1.66-11.57v-.39c-.01-1.35-.09-2.68-.22-3.99-.06-.6-.14-1.19-.23-1.78a40.556 40.556 0 0 0-1.95-7.75Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M369.24 120.28c-4.17 0-8.16.78-11.84 2.18 1.03 1.33 1.99 2.71 2.89 4.13a28.49 28.49 0 0 1 8.95-1.44c15.73 0 28.48 12.75 28.48 28.48 0 3.35-.58 6.57-1.65 9.56 1.44.85 2.82 1.78 4.14 2.79 1.53-3.82 2.38-7.98 2.38-12.35 0-18.39-14.96-33.35-33.35-33.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M369.24 118.5c-4.59 0-8.98.89-13 2.49.4.48.78.97 1.16 1.46 3.68-1.4 7.67-2.18 11.84-2.18 18.39 0 33.35 14.96 33.35 33.35 0 4.36-.85 8.52-2.38 12.35.5.38.98.77 1.46 1.17 1.74-4.16 2.7-8.72 2.7-13.51 0-19.4-15.73-35.13-35.13-35.13Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M369.24 126.93c-2.79 0-5.47.43-8 1.22.79 1.35 1.53 2.73 2.2 4.15 1.85-.5 3.79-.77 5.8-.77 12.21 0 22.1 9.9 22.1 22.1 0 2.35-.37 4.61-1.05 6.74 1.44.57 2.84 1.21 4.19 1.93.94-2.72 1.45-5.63 1.45-8.67 0-14.72-11.98-26.7-26.7-26.7Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M369.24 125.15c-3.13 0-6.13.51-8.95 1.44.33.51.64 1.03.95 1.56 2.53-.79 5.21-1.22 8-1.22 14.72 0 26.7 11.98 26.7 26.7 0 3.03-.52 5.95-1.45 8.67.54.28 1.06.58 1.58.89a28.53 28.53 0 0 0 1.65-9.56c0-15.73-12.75-28.48-28.48-28.48Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M369.24 133.3c-1.74 0-3.44.22-5.05.64.61 1.42 1.16 2.86 1.66 4.34 1.09-.24 2.23-.38 3.4-.38 8.68 0 15.72 7.04 15.72 15.72 0 1.7-.28 3.33-.78 4.86 1.51.34 2.99.76 4.43 1.25.61-1.93.94-3.99.94-6.12 0-11.21-9.12-20.32-20.32-20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M391.35 153.62c0-12.21-9.9-22.1-22.1-22.1-2.01 0-3.95.27-5.8.77.26.54.5 1.09.74 1.64 1.62-.42 3.31-.64 5.05-.64 11.21 0 20.32 9.12 20.32 20.32 0 2.13-.33 4.19-.94 6.12.56.19 1.12.4 1.67.62.68-2.12 1.05-4.39 1.05-6.74Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M369.24 139.68c-.98 0-1.93.1-2.86.3.44 1.46.82 2.95 1.13 4.47.56-.1 1.13-.17 1.72-.17 5.16 0 9.35 4.19 9.35 9.35 0 1.41-.32 2.75-.88 3.95 1.6.1 3.17.29 4.72.56.49-1.42.76-2.93.76-4.51 0-7.69-6.26-13.95-13.95-13.95Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M369.24 137.9c-1.17 0-2.3.14-3.4.38.19.56.37 1.13.54 1.7.92-.19 1.88-.3 2.86-.3 7.69 0 13.95 6.26 13.95 13.95 0 1.58-.28 3.09-.76 4.51.59.11 1.18.22 1.77.35.5-1.53.78-3.16.78-4.86 0-8.68-7.04-15.72-15.72-15.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M369.24 146.06c-.48 0-.95.05-1.4.14.26 1.51.46 3.04.59 4.59.26-.08.53-.13.82-.13a2.97 2.97 0 0 1 0 5.94c-.22 0-.43-.03-.63-.07-.01.5-.01 1-.04 1.49 2.15-.35 4.35-.53 6.6-.53.19 0 .38.01.56.01a7.54 7.54 0 0 0 1.08-3.88c0-4.17-3.4-7.57-7.57-7.57Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M369.24 144.28c-.59 0-1.16.06-1.72.17.12.58.22 1.17.32 1.75.46-.09.92-.14 1.4-.14 4.17 0 7.57 3.4 7.57 7.57 0 1.42-.4 2.74-1.08 3.88.66 0 1.32.03 1.98.07.56-1.2.88-2.53.88-3.95 0-5.16-4.19-9.35-9.35-9.35Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M370.44 153.62c0-.66-.53-1.19-1.19-1.19-.26 0-.48.1-.67.24.03.64.04 1.29.05 1.94.18.12.39.2.63.2.66 0 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M372.22 153.62a2.97 2.97 0 0 0-2.97-2.97c-.29 0-.56.05-.82.13.05.63.11 1.25.14 1.89.19-.14.42-.24.67-.24.66 0 1.19.53 1.19 1.19s-.53 1.19-1.19 1.19c-.23 0-.44-.09-.63-.2 0 .26.02.53.02.79 0 .38-.02.75-.03 1.12.2.04.42.07.63.07a2.97 2.97 0 0 0 2.97-2.97Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M412.96 26.39c0 19.4-15.73 35.13-35.13 35.13-3.82 0-7.49-.62-10.93-1.74.88 1.64 1.65 3.34 2.3 5.11 2.78.62 5.66.96 8.63.96 21.75 0 39.45-17.7 39.45-39.45 0-10.14-3.85-19.39-10.15-26.39H401c7.33 6.44 11.96 15.87 11.96 26.39ZM338.39 26.39c0 4.48.76 8.78 2.14 12.79 1.83.46 3.61 1.04 5.33 1.73a35.039 35.039 0 0 1-3.14-14.52c0-10.52 4.63-19.95 11.96-26.39h-6.13c-6.31 7-10.15 16.25-10.15 26.39Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M339.1 12.23a41.218 41.218 0 0 0-2.34 10.64c-.06.73-.1 1.47-.13 2.21-.01.44-.03.87-.03 1.31 0 2.15.17 4.26.48 6.32.1.62.21 1.24.33 1.85.29 1.42.64 2.81 1.07 4.18.69.13 1.37.28 2.04.45a39.271 39.271 0 0 1-2.14-12.79c0-10.14 3.85-19.39 10.15-26.39h-2.38c-.37.44-.72.89-1.07 1.35a41.47 41.47 0 0 0-5.99 10.88ZM417.29 26.39c0 21.75-17.7 39.45-39.45 39.45-2.96 0-5.85-.34-8.63-.96.24.65.47 1.3.67 1.97 1.62.32 3.27.53 4.95.65.94.07 1.88.11 2.84.12h.17c8.11 0 15.66-2.35 22.04-6.39.8-.5 1.58-1.03 2.33-1.59a41.173 41.173 0 0 0 7.72-7.37c1.37-1.69 2.6-3.5 3.69-5.4.3-.52.59-1.06.87-1.59.59-1.15 1.13-2.33 1.62-3.54.28-.71.55-1.43.79-2.15 1.4-4.14 2.17-8.58 2.17-13.19 0-.41-.02-.81-.03-1.21-.08-2.9-.46-5.72-1.12-8.44a41.083 41.083 0 0 0-5.87-13.37 44.133 44.133 0 0 0-2.53-3.36h-2.38c6.31 7 10.15 16.25 10.15 26.39Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M344.49 26.39c0 5.64 1.41 10.96 3.9 15.62 7.06 3.4 13 8.75 17.12 15.35 3.82 1.52 7.97 2.37 12.33 2.37 18.39 0 33.35-14.96 33.35-33.35 0-10.73-5.1-20.29-13-26.39h-9.64c10.42 4.23 17.77 14.45 17.77 26.39 0 15.73-12.75 28.48-28.48 28.48s-28.48-12.75-28.48-28.48c0-11.94 7.35-22.16 17.77-26.39h-9.64c-7.9 6.1-13 15.66-13 26.39Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M342.71 26.39c0 5.18 1.13 10.09 3.14 14.52.86.34 1.71.71 2.53 1.1a33.17 33.17 0 0 1-3.9-15.62c0-10.73 5.1-20.29 13-26.39h-2.82c-7.33 6.44-11.96 15.87-11.96 26.39ZM411.19 26.39c0 18.39-14.96 33.35-33.35 33.35-4.35 0-8.51-.85-12.33-2.37.49.79.96 1.59 1.4 2.41 3.44 1.13 7.11 1.74 10.93 1.74 19.4 0 35.13-15.73 35.13-35.13 0-10.52-4.63-19.95-11.96-26.39h-2.82c7.9 6.1 13 15.66 13 26.39Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M373.77 0h8.13c12.8 1.96 22.63 13.05 22.63 26.39 0 14.72-11.98 26.7-26.7 26.7s-26.7-11.98-26.7-26.7c0-13.34 9.83-24.43 22.63-26.39Zm4.06 4.29c-12.21 0-22.1 9.9-22.1 22.1s9.9 22.1 22.1 22.1 22.1-9.9 22.1-22.1-9.9-22.1-22.1-22.1Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M349.36 26.39c0 15.73 12.75 28.48 28.48 28.48s28.48-12.75 28.48-28.48c0-11.94-7.35-22.16-17.77-26.39h-6.64c12.8 1.96 22.63 13.05 22.63 26.39 0 14.72-11.98 26.7-26.7 26.7s-26.7-11.98-26.7-26.7c0-13.34 9.83-24.43 22.63-26.39h-6.64c-10.42 4.23-17.77 14.45-17.77 26.39Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M377.84 46.71c-11.21 0-20.32-9.12-20.32-20.32s9.12-20.32 20.32-20.32 20.32 9.12 20.32 20.32-9.12 20.32-20.32 20.32Zm0-4.6c8.68 0 15.72-7.04 15.72-15.72s-7.04-15.72-15.72-15.72-15.72 7.04-15.72 15.72 7.04 15.72 15.72 15.72Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M377.84 4.29c12.21 0 22.1 9.9 22.1 22.1s-9.9 22.1-22.1 22.1-22.1-9.9-22.1-22.1 9.9-22.1 22.1-22.1Zm20.32 22.1c0-11.21-9.12-20.32-20.32-20.32s-20.32 9.12-20.32 20.32 9.12 20.32 20.32 20.32 20.32-9.12 20.32-20.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M363.89 26.39c0-7.69 6.26-13.95 13.95-13.95s13.95 6.26 13.95 13.95-6.26 13.95-13.95 13.95-13.95-6.26-13.95-13.95Zm4.6 0c0 5.16 4.19 9.35 9.35 9.35s9.35-4.19 9.35-9.35-4.19-9.35-9.35-9.35-9.35 4.19-9.35 9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M377.84 42.11c-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72 15.72 7.04 15.72 15.72-7.04 15.72-15.72 15.72Zm-13.95-15.72c0 7.69 6.26 13.95 13.95 13.95s13.95-6.26 13.95-13.95-6.26-13.95-13.95-13.95-13.95 6.26-13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M370.27 26.39c0-4.17 3.4-7.57 7.57-7.57s7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57-7.57-3.4-7.57-7.57Zm4.6 0a2.97 2.97 0 1 0 5.94 0 2.97 2.97 0 0 0-5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M368.49 26.39c0-5.16 4.19-9.35 9.35-9.35s9.35 4.19 9.35 9.35-4.19 9.35-9.35 9.35-9.35-4.19-9.35-9.35Zm1.78 0c0 4.17 3.4 7.57 7.57 7.57s7.57-3.4 7.57-7.57-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M377.84 27.58c.66 0 1.19-.53 1.19-1.19s-.53-1.19-1.19-1.19-1.19.53-1.19 1.19.53 1.19 1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M374.87 26.39a2.97 2.97 0 1 1 5.94 0 2.97 2.97 0 0 1-5.94 0Zm1.78 0c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M121.14 26.27c1.03.58 2.03 1.19 3.01 1.84 1.83-5.45 4.76-10.4 8.55-14.57.49-1.83.86-3.7 1.08-5.63a54.291 54.291 0 0 1-3.62-7.9h-.58c.1 1.07.16 2.16.16 3.26 0 8.8-3.25 16.84-8.6 23.01ZM64.37 28.56c1.2-.81 2.45-1.57 3.71-2.29-5.35-6.16-8.6-14.21-8.6-23.01 0-1.1.06-2.18.16-3.26H55.3c-.09 1.08-.15 2.16-.15 3.26 0 9.62 3.47 18.44 9.21 25.3Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M135.09 11.12c.05-.26.1-.52.14-.79-.5-.8-.98-1.61-1.45-2.43-.23 1.92-.59 3.8-1.08 5.63.76-.84 1.56-1.64 2.39-2.41ZM59.19 24.35c.44.73.9 1.45 1.38 2.16.73 1.07 1.51 2.1 2.34 3.1.49-.35.97-.71 1.47-1.05-5.74-6.85-9.21-15.68-9.21-25.3 0-1.1.06-2.18.15-3.26h-1.78c-.08 1.08-.14 2.16-.14 3.26 0 7.71 2.12 14.92 5.8 21.09Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M114.89 23.24c1.58.64 3.13 1.36 4.63 2.14 5.24-5.89 8.43-13.65 8.43-22.13 0-1.1-.06-2.18-.16-3.26h-4.9a28.377 28.377 0 0 1-8.01 23.25ZM69.7 25.39c1.51-.78 3.05-1.5 4.63-2.14A28.377 28.377 0 0 1 66.32 0h-4.9c-.1 1.07-.16 2.16-.16 3.26 0 8.49 3.19 16.24 8.43 22.13Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M59.49 3.26c0 8.8 3.25 16.84 8.6 23.01.53-.3 1.07-.59 1.61-.88-5.24-5.89-8.43-13.64-8.43-22.13 0-1.1.06-2.18.16-3.26h-1.78c-.1 1.07-.16 2.16-.16 3.26ZM119.53 25.39c.54.28 1.08.58 1.61.88 5.35-6.16 8.6-14.21 8.6-23.01 0-1.1-.06-2.18-.16-3.26h-1.78c.1 1.07.16 2.16.16 3.26 0 8.49-3.19 16.24-8.43 22.13Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M107.88 20.94c1.76.45 3.5.98 5.19 1.59 5.08-4.86 8.25-11.7 8.25-19.27 0-1.1-.08-2.19-.21-3.26h-4.63c.16 1.06.24 2.15.24 3.26 0 7.23-3.47 13.65-8.84 17.68ZM67.92 3.26c0 7.57 3.17 14.41 8.25 19.27 1.69-.61 3.42-1.15 5.19-1.59-5.37-4.03-8.84-10.45-8.84-17.68 0-1.11.08-2.19.24-3.26h-4.63a26.91 26.91 0 0 0-.21 3.26Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M66.14 3.26c0 7.79 3.13 14.85 8.2 19.99.61-.25 1.21-.49 1.83-.72-5.08-4.86-8.25-11.7-8.25-19.27 0-1.1.08-2.19.21-3.26h-1.79c-.12 1.07-.19 2.15-.19 3.26ZM113.06 22.53c.62.22 1.22.47 1.83.72A28.377 28.377 0 0 0 122.9 0h-1.79c.13 1.07.21 2.15.21 3.26 0 7.57-3.17 14.41-8.25 19.27Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M83.71 20.39c3.52-.72 7.17-1.1 10.9-1.1s7.38.38 10.9 1.1c5.66-3.61 9.42-9.94 9.42-17.14 0-1.11-.09-2.2-.26-3.26H110c.22 1.05.34 2.14.34 3.26 0 8.68-7.04 15.72-15.72 15.72S78.9 11.93 78.9 3.25c0-1.12.12-2.21.34-3.26h-4.67c-.17 1.06-.26 2.15-.26 3.26 0 7.2 3.76 13.52 9.42 17.14Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M81.35 20.94c.78-.2 1.57-.38 2.36-.54-5.66-3.61-9.42-9.94-9.42-17.14 0-1.11.09-2.2.26-3.26h-1.8c-.16 1.06-.24 2.15-.24 3.26 0 7.23 3.47 13.65 8.84 17.68ZM105.52 20.39c.79.16 1.58.35 2.36.54 5.37-4.03 8.84-10.45 8.84-17.68 0-1.11-.08-2.19-.24-3.26h-1.8c.17 1.06.26 2.15.26 3.26 0 7.2-3.76 13.52-9.42 17.14Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 17.2c7.69 0 13.95-6.26 13.95-13.95 0-1.12-.15-2.21-.4-3.26h-4.79c.38 1.02.59 2.11.59 3.26 0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35c0-1.15.22-2.24.59-3.26h-4.79c-.25 1.05-.4 2.13-.4 3.26 0 7.69 6.26 13.95 13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 18.98c8.68 0 15.72-7.04 15.72-15.72 0-1.12-.12-2.21-.34-3.26h-1.84c.25 1.05.4 2.13.4 3.26 0 7.69-6.26 13.95-13.95 13.95S80.66 10.95 80.66 3.26c0-1.12.15-2.21.4-3.26h-1.84a15.88 15.88 0 0 0-.34 3.26c0 8.68 7.04 15.72 15.72 15.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M87.05 3.26c0-1.17.27-2.27.75-3.26h13.64c.47.99.75 2.09.75 3.26 0 4.17-3.4 7.57-7.57 7.57s-7.57-3.4-7.57-7.57Zm4.6 0a2.97 2.97 0 1 0 5.94 0 2.97 2.97 0 0 0-5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 12.6c5.16 0 9.35-4.19 9.35-9.35 0-1.15-.22-2.24-.59-3.26h-1.93c.47.99.75 2.09.75 3.26 0 4.17-3.4 7.57-7.57 7.57s-7.57-3.4-7.57-7.57c0-1.17.27-2.27.75-3.26h-1.93a9.287 9.287 0 0 0-.59 3.26c0 5.16 4.19 9.35 9.35 9.35Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 4.45c.66 0 1.19-.53 1.19-1.19s-.53-1.19-1.19-1.19-1.19.53-1.19 1.19.53 1.19 1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M91.64 3.26a2.97 2.97 0 1 1 5.94 0 2.97 2.97 0 0 1-5.94 0Zm1.78 0c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M350.24 397.58c-2.47.91-5.06 1.58-7.73 2.01 11.14 7.3 18.91 19.31 20.5 33.2a40.75 40.75 0 0 1 6.03-1.81c-1.83-13.42-8.77-25.23-18.79-33.4Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M350.24 397.58c10.03 8.17 16.97 19.98 18.79 33.4.58-.13 1.16-.24 1.75-.34a57.277 57.277 0 0 0-.83-4.59c-2.64-11.61-9.01-21.8-17.81-29.23-.63.27-1.26.52-1.91.76Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M277.25 443.77c.36.36.71.73 1.05 1.1h2.39c-.11-.61-.21-1.23-.29-1.86-1.04.28-2.08.54-3.14.76ZM339.66 399.93c-1.2.1-2.41.17-3.64.17-5.69 0-11.1-1.15-16.03-3.23-.15 1.34-.35 2.66-.59 3.97 19.35 1.06 34.76 16.86 35.19 36.37 2.1-1.44 4.35-2.68 6.71-3.72-1.47-14.31-9.81-26.6-21.64-33.56Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M342.51 399.59c-.94.15-1.89.26-2.85.34 11.83 6.96 20.17 19.25 21.64 33.56.56-.25 1.13-.48 1.71-.7-1.58-13.89-9.36-25.9-20.5-33.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M352.82 438.49c0-.15.01-.29.01-.44 0-19-15.01-34.56-33.79-35.45-.49 2.23-1.12 4.41-1.88 6.53h.17c15.98 0 28.93 12.95 28.93 28.93 0 2.35-.29 4.63-.82 6.82h.71c1.97-2.37 4.21-4.5 6.66-6.38ZM282.13 442.52c.1.79.22 1.57.37 2.35h6.72c-.37-1.54-.62-3.13-.73-4.75-2.06.93-4.18 1.74-6.36 2.4Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M319.4 400.84c-.11.59-.23 1.18-.36 1.76 18.79.89 33.79 16.45 33.79 35.45 0 .15 0 .29-.01.44.58-.44 1.17-.87 1.77-1.28-.44-19.51-15.85-35.32-35.19-36.37ZM280.4 443.02c.08.62.18 1.24.29 1.86h1.81c-.15-.77-.27-1.56-.37-2.35-.57.17-1.15.34-1.73.49Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M317.34 410.91c-.29 0-.58.01-.86.02-.97 2.42-2.11 4.76-3.4 6.99 1.38-.29 2.8-.45 4.27-.45 11.37 0 20.58 9.22 20.58 20.58 0 2.39-.41 4.68-1.16 6.82h6.86c.57-2.18.87-4.46.87-6.82 0-14.97-12.18-27.15-27.15-27.15ZM296.93 435.37a52.986 52.986 0 0 1-6.72 3.94c.09 1.91.37 3.77.84 5.56h6.86c-.75-2.13-1.16-4.43-1.16-6.82 0-.91.07-1.81.18-2.69Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M317.34 409.13h-.17c-.22.61-.45 1.2-.69 1.8.29 0 .57-.02.86-.02 14.97 0 27.15 12.18 27.15 27.15 0 2.35-.3 4.64-.87 6.82h1.83c.53-2.19.82-4.47.82-6.82 0-15.98-12.95-28.93-28.93-28.93ZM288.49 440.12c.11 1.62.36 3.21.73 4.75h1.83c-.46-1.79-.75-3.65-.84-5.56-.57.28-1.15.55-1.73.81Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M317.34 419.25c-1.95 0-3.83.3-5.6.85a54.39 54.39 0 0 1-12.71 13.75 18.78 18.78 0 0 0-.49 4.2c0 2.41.47 4.7 1.3 6.82h7.34a12.17 12.17 0 0 1-2.07-6.82c0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24c0 2.52-.76 4.87-2.07 6.82h7.34c.83-2.12 1.3-4.41 1.3-6.82 0-10.37-8.44-18.8-18.8-18.8Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M317.34 417.47c-1.46 0-2.89.16-4.27.45-.43.74-.88 1.47-1.34 2.19 1.77-.55 3.65-.85 5.6-.85 10.37 0 18.8 8.44 18.8 18.8 0 2.41-.47 4.7-1.3 6.82h1.92c.75-2.13 1.16-4.43 1.16-6.82 0-11.37-9.22-20.58-20.58-20.58ZM299.02 433.86c-.68.52-1.38 1.03-2.09 1.51a21.7 21.7 0 0 0-.18 2.69c0 2.39.41 4.68 1.16 6.82h1.92c-.83-2.12-1.3-4.41-1.3-6.82 0-1.45.18-2.85.49-4.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M327.79 438.06c0 2.6-.96 4.98-2.54 6.82h-15.83a10.397 10.397 0 0 1-2.54-6.82c0-5.77 4.69-10.46 10.46-10.46s10.46 4.69 10.46 10.46Zm-6.57 0c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M317.34 425.82c-6.76 0-12.24 5.48-12.24 12.24 0 2.52.76 4.87 2.07 6.82h2.25a10.397 10.397 0 0 1-2.54-6.82c0-5.77 4.69-10.46 10.46-10.46s10.46 4.69 10.46 10.46c0 2.6-.96 4.98-2.54 6.82h2.25a12.17 12.17 0 0 0 2.07-6.82c0-6.76-5.48-12.24-12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M317.34 435.95c-1.16 0-2.11.95-2.11 2.11s.95 2.11 2.11 2.11 2.11-.95 2.11-2.11-.95-2.11-2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M321.23 438.06c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M232.9 441.01a34.93 34.93 0 0 1 7.78-2.62c-1.74-.94-3.42-1.99-5.04-3.11-.53.17-1.06.36-1.58.55-.31 1.76-.7 3.49-1.17 5.18ZM269.19 444.8s.06.05.1.07h6.53c-.24-.24-.5-.47-.75-.71-1.93.31-3.89.53-5.88.64Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M275.07 444.16c.25.24.5.47.75.71h2.48c-.34-.37-.69-.74-1.05-1.1-.72.15-1.45.27-2.18.39ZM234.07 435.83c.52-.2 1.05-.38 1.58-.55-.45-.31-.89-.62-1.33-.94-.07.5-.16 1-.25 1.5Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M243.36 439.73c-3.99.55-7.76 1.8-11.18 3.64-.16.5-.33 1-.51 1.5h10.46c1.87-.39 3.81-.59 5.8-.59s3.93.21 5.8.59h12.45c-8.16-.03-15.9-1.87-22.82-5.14Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.18 443.38c3.41-1.84 7.18-3.09 11.18-3.64-.91-.43-1.8-.87-2.68-1.35a34.93 34.93 0 0 0-7.78 2.62c-.22.8-.46 1.59-.72 2.37ZM266.36 444.87h2.93s-.06-.05-.1-.07c-.94.05-1.88.07-2.84.07ZM266.19 444.87h.16-.17ZM242.14 444.87h11.6c-1.87-.39-3.81-.59-5.8-.59s-3.93.21-5.8.59Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M377.84 431.81c-11.62 0-22.07 5.05-29.3 13.06h6.13c6.18-5.43 14.29-8.74 23.17-8.74s16.98 3.3 23.17 8.74h6.13c-7.23-8.01-17.68-13.06-29.3-13.06Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M405.55 440.73c-.47-.43-.95-.84-1.44-1.24-.21-.17-.43-.34-.64-.51a40.95 40.95 0 0 0-2.39-1.77 41.075 41.075 0 0 0-28.23-6.86c-.69.08-1.38.18-2.06.3a40.75 40.75 0 0 0-7.78 2.15c-.57.22-1.14.45-1.71.7a40.984 40.984 0 0 0-8.48 5 41.037 41.037 0 0 0-6.66 6.38h2.38c7.23-8.01 17.68-13.06 29.3-13.06s22.07 5.05 29.3 13.06h2.38a40.874 40.874 0 0 0-3.97-4.14Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M357.49 444.87h9.64c3.31-1.34 6.92-2.09 10.71-2.09s7.4.75 10.71 2.09h9.64c-5.64-4.36-12.69-6.96-20.35-6.96s-14.72 2.6-20.35 6.96Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M354.67 444.87h2.82c5.64-4.36 12.69-6.96 20.35-6.96s14.72 2.6 20.35 6.96h2.82c-6.18-5.43-14.29-8.74-23.17-8.74s-16.98 3.3-23.17 8.74Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M373.77 444.87h8.13c-1.33-.2-2.68-.31-4.06-.31s-2.74.11-4.06.31Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M367.13 444.87h6.64c1.33-.2 2.68-.31 4.06-.31s2.74.11 4.06.31h6.64c-3.31-1.34-6.92-2.09-10.71-2.09s-7.4.75-10.71 2.09Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M129.59 444.87h.58c-.37-1.02-.7-2.06-1.01-3.11.19 1.02.33 2.06.43 3.11ZM94.62 413c15.62 0 28.86 10.2 33.42 24.3-.71-3.49-1.09-7.11-1.09-10.82 0-.3.02-.6.02-.89-7.14-10.22-18.98-16.92-32.36-16.92-20.66 0-37.64 15.96-39.31 36.2h4.34C61.28 427 76.31 413 94.61 413Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 408.68c13.38 0 25.22 6.7 32.36 16.92.02-.96.06-1.91.12-2.85-1.26-1.6-2.63-3.11-4.1-4.51a42.089 42.089 0 0 0-6.9-5.31c-.51-.31-1.03-.61-1.56-.9a40.486 40.486 0 0 0-6.15-2.76c-.61-.22-1.22-.42-1.84-.6-2.52-.76-5.13-1.29-7.83-1.55-.96-.09-1.93-.15-2.9-.17-.4-.01-.79-.03-1.19-.03-4.64 0-9.1.78-13.27 2.2-.92.31-1.83.65-2.71 1.02A41.162 41.162 0 0 0 65 419.5c-.42.43-.83.87-1.23 1.33a40.583 40.583 0 0 0-4.13 5.53c-.32.51-.62 1.03-.92 1.55a41.146 41.146 0 0 0-2.67 5.7c-.22.58-.43 1.17-.62 1.77-.08.24-.16.47-.23.71-.25.82-.47 1.65-.67 2.49-.49 2.05-.82 4.17-.99 6.33h1.78c1.66-20.23 18.65-36.2 39.31-36.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 414.78c-17.29 0-31.54 13.23-33.19 30.09h4.9c1.62-14.19 13.66-25.22 28.29-25.22s26.67 11.03 28.29 25.22h4.9c-1.64-16.86-15.9-30.09-33.19-30.09Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M128.04 437.31c-4.56-14.1-17.8-24.3-33.42-24.3-18.3 0-33.33 14-34.97 31.87h1.78c1.64-16.86 15.9-30.09 33.19-30.09s31.54 13.23 33.19 30.09h1.78c-.1-1.05-.24-2.09-.43-3.11a54.85 54.85 0 0 1-1.12-4.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 421.43c-13.62 0-24.88 10.25-26.49 23.44h4.63c1.57-10.66 10.76-18.85 21.86-18.85s20.29 8.18 21.86 18.85h4.63c-1.61-13.19-12.87-23.44-26.49-23.44Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 419.65c-14.63 0-26.67 11.03-28.29 25.22h1.79c1.61-13.19 12.87-23.44 26.49-23.44s24.88 10.25 26.49 23.44h1.79c-1.62-14.19-13.66-25.22-28.29-25.22Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 427.81c-10.1 0-18.49 7.4-20.06 17.07h4.67c1.5-7.12 7.82-12.47 15.38-12.47s13.88 5.35 15.38 12.47h4.67c-1.56-9.66-9.96-17.07-20.06-17.07Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 426.03c-11.1 0-20.29 8.18-21.86 18.85h1.8c1.56-9.66 9.96-17.07 20.06-17.07s18.49 7.4 20.06 17.07h1.8c-1.57-10.66-10.76-18.85-21.86-18.85Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 434.18c-6.57 0-12.07 4.57-13.55 10.69h4.79c1.32-3.55 4.74-6.09 8.75-6.09s7.43 2.54 8.75 6.09h4.79c-1.47-6.12-6.98-10.69-13.55-10.69Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 432.4c-7.57 0-13.88 5.35-15.38 12.47h1.84c1.47-6.12 6.98-10.69 13.55-10.69s12.07 4.57 13.55 10.69h1.84c-1.5-7.12-7.82-12.47-15.38-12.47Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 440.56c-3.01 0-5.6 1.77-6.82 4.31h13.64c-1.22-2.55-3.82-4.31-6.82-4.31Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 438.78c-4.02 0-7.43 2.54-8.75 6.09h1.93c1.22-2.55 3.82-4.31 6.82-4.31s5.6 1.77 6.82 4.31h1.93c-1.32-3.55-4.74-6.09-8.75-6.09Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M239.67 62.7c1.28-1.98 2.68-3.87 4.21-5.67-7.23-14.38-20.9-24.97-37.21-27.97-1.15.63-2.33 1.2-3.53 1.74.35 1.34.63 2.71.85 4.1 16.17 2.38 29.59 13.18 35.69 27.8ZM191.55 132.22c.73-2.07 1.61-4.07 2.64-5.97a45.671 45.671 0 0 1-19.74-5.92c.35 2.15.53 4.36.53 6.6 0 .22-.01.43-.02.65 1.36.65 2.76 1.24 4.18 1.77 4.3.47 8.45 1.45 12.39 2.87ZM152.28 90.09c-.65-3.04-1.01-6.18-1.03-9.41-1.03-.31-2.05-.67-3.05-1.06-.33 2.78-.86 5.49-1.6 8.13 1.96.64 3.85 1.43 5.67 2.34Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M179.16 129.35a49.87 49.87 0 0 1-4.18-1.77c0 .49-.01.99-.04 1.47 1.42.05 2.83.14 4.22.29ZM243.88 57.03c.42-.49.84-.98 1.27-1.46-.21-.41-.42-.82-.64-1.22a54.33 54.33 0 0 0-3.47-5.47c-.69-.96-1.4-1.9-2.15-2.81-2.6-3.16-5.56-6-8.81-8.5a49.53 49.53 0 0 0-2.9-2.09 53.393 53.393 0 0 0-5.58-3.25c-.64-.32-1.28-.63-1.93-.93a55.09 55.09 0 0 0-6.83-2.59 53.76 53.76 0 0 0-3.83-1c-.77.47-1.54.92-2.33 1.35 16.31 3 29.98 13.59 37.21 27.97Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M230.44 104.69c.72 0 1.43.02 2.15.06-.71-2.92-1.18-5.94-1.39-9.03a37.156 37.156 0 0 1-6.03 9.32c1.73-.22 3.49-.35 5.27-.35ZM174.01 117.97a43.98 43.98 0 0 0 21.18 6.56c1.54-2.54 3.36-4.89 5.4-7.03-1.11.1-2.23.16-3.37.16-19.94 0-36.22-15.65-37.23-35.33-2.38-.19-4.7-.56-6.95-1.13.06 3.45.51 6.82 1.33 10.03 9.84 5.69 17.11 15.33 19.64 26.75ZM234 74.34c1.18-3.42 2.69-6.68 4.49-9.74-5.56-14.5-18.54-25.34-34.27-27.86.16 1.48.25 2.97.25 4.49 0 .86-.04 1.72-.09 2.57 15.21 2.96 27.11 15.17 29.62 30.54Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M195.19 124.53a43.874 43.874 0 0 1-21.18-6.56c.17.78.33 1.56.45 2.35a45.671 45.671 0 0 0 19.74 5.92c.32-.58.65-1.16.99-1.72ZM154.37 91.22c-.82-3.22-1.27-6.58-1.33-10.03-.6-.15-1.2-.32-1.79-.5.02 3.23.38 6.37 1.03 9.41.71.36 1.41.74 2.09 1.13ZM238.5 64.6c.38-.64.77-1.27 1.18-1.9-6.1-14.62-19.52-25.41-35.69-27.8.09.61.17 1.22.24 1.84 15.73 2.52 28.71 13.36 34.27 27.86Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M197.22 115.88c1.85 0 3.66-.14 5.43-.42 5.42-4.94 12.15-8.46 19.62-9.96 4-3.99 7.05-8.92 8.79-14.43.07-4.24.62-8.35 1.61-12.3-.74-16.45-12.73-30.02-28.44-33.19-.23 2.21-.64 4.36-1.21 6.45 13.19 2.69 23.12 14.36 23.12 28.35 0 15.98-12.95 28.93-28.93 28.93s-27.96-12.02-28.87-27.17c-1.67.21-3.38.33-5.11.33-.49 0-.97-.02-1.45-.04 1.06 18.62 16.55 33.45 35.43 33.45Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M197.22 117.66c1.14 0 2.26-.06 3.37-.16.67-.7 1.35-1.38 2.07-2.04-1.77.27-3.59.42-5.43.42-18.89 0-34.37-14.83-35.43-33.45-.6-.02-1.2-.06-1.79-.1 1.01 19.68 17.29 35.33 37.23 35.33ZM222.28 105.5c.95-.19 1.92-.34 2.89-.46 2.45-2.77 4.48-5.91 6.03-9.32-.09-1.25-.15-2.5-.15-3.77 0-.3.02-.59.02-.88a35.527 35.527 0 0 1-8.79 14.43ZM232.68 78.77c.38-1.5.83-2.98 1.33-4.43-2.51-15.37-14.41-27.58-29.62-30.54-.04.6-.08 1.19-.14 1.78 15.71 3.16 27.7 16.73 28.44 33.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M197.22 107.53c14.97 0 27.15-12.18 27.15-27.15 0-13.15-9.4-24.15-21.84-26.63-.69 2.16-1.55 4.24-2.57 6.23 10.07 1.34 17.85 9.96 17.85 20.4 0 11.37-9.22 20.58-20.58 20.58s-20.58-9.22-20.58-20.58v-.16c-2.1.72-4.28 1.28-6.53 1.66.78 14.28 12.64 25.65 27.11 25.65Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M197.22 109.31c15.98 0 28.93-12.95 28.93-28.93 0-13.99-9.93-25.66-23.12-28.35-.16.58-.32 1.15-.5 1.72 12.44 2.48 21.84 13.47 21.84 26.63 0 14.97-12.18 27.15-27.15 27.15s-26.33-11.37-27.11-25.65c-.58.1-1.17.18-1.77.25.91 15.16 13.48 27.17 28.87 27.17Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M197.22 99.18c10.37 0 18.8-8.44 18.8-18.8 0-9.75-7.46-17.79-16.98-18.71a41.162 41.162 0 0 1-4.94 6.89c1-.26 2.04-.42 3.12-.42 6.76 0 12.24 5.48 12.24 12.24s-5.48 12.24-12.24 12.24-12.24-5.48-12.24-12.24c0-1.68.34-3.27.95-4.73a41.737 41.737 0 0 1-7.47 3.9c-.01.28-.04.55-.04.83 0 10.37 8.44 18.8 18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M197.22 100.96c11.37 0 20.58-9.22 20.58-20.58 0-10.44-7.77-19.06-17.85-20.4-.29.57-.6 1.13-.91 1.69 9.52.92 16.98 8.96 16.98 18.71 0 10.37-8.44 18.8-18.8 18.8s-18.8-8.44-18.8-18.8c0-.28.03-.55.04-.83-.6.24-1.2.46-1.82.67v.16c0 11.37 9.22 20.58 20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M186.76 80.38c0-5.77 4.69-10.46 10.46-10.46s10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46-10.46-4.69-10.46-10.46Zm6.57 0c0 2.15 1.74 3.89 3.89 3.89s3.89-1.74 3.89-3.89-1.74-3.89-3.89-3.89-3.89 1.74-3.89 3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M184.98 80.38c0-1.68.34-3.27.95-4.73 3.03-2 5.77-4.39 8.17-7.09 1-.26 2.04-.42 3.12-.42 6.76 0 12.24 5.48 12.24 12.24s-5.48 12.24-12.24 12.24-12.24-5.48-12.24-12.24Zm1.78 0c0 5.77 4.69 10.46 10.46 10.46s10.46-4.69 10.46-10.46-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M197.22 82.49c1.16 0 2.11-.95 2.11-2.11s-.95-2.11-2.11-2.11-2.11.95-2.11 2.11.95 2.11 2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M193.33 80.38c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89-1.74 3.89-3.89 3.89-3.89-1.74-3.89-3.89Zm1.78 0c0 1.16.95 2.11 2.11 2.11s2.11-.95 2.11-2.11-.95-2.11-2.11-2.11-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M285.87 349.27c3.27 1.53 6.32 3.44 9.11 5.67.2-2.11.56-4.18 1.06-6.18-2.33.41-4.73.64-7.18.64-1.01 0-2-.05-2.99-.12ZM312.29 392.58c-.89 24.61-21.11 44.3-45.94 44.3-12.2 0-23.29-4.76-31.52-12.52.03.71.05 1.41.05 2.13 0 1.95-.11 3.88-.31 5.77 8.81 6.78 19.82 10.83 31.77 10.83 27.02 0 49.31-20.65 51.93-46.99-2.1-1-4.11-2.19-5.99-3.52Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M269.19 444.8c1.99-.1 3.95-.32 5.88-.64.73-.12 1.46-.24 2.18-.39 1.06-.22 2.11-.48 3.14-.76.58-.16 1.16-.32 1.73-.49a55.806 55.806 0 0 0 8.09-3.21 54.948 54.948 0 0 0 8.81-5.45 54.223 54.223 0 0 0 14.05-15.94 53.09 53.09 0 0 0 3.4-6.99c.24-.6.47-1.19.69-1.8a54.967 54.967 0 0 0 2.24-8.29c.24-1.31.45-2.63.59-3.97-.58-.24-1.14-.5-1.7-.77-2.62 26.35-24.91 46.99-51.93 46.99-11.95 0-22.97-4.05-31.77-10.83-.07.69-.16 1.38-.26 2.07.44.32.88.63 1.33.94 1.62 1.12 3.3 2.17 5.04 3.11.88.48 1.77.92 2.68 1.35a53.789 53.789 0 0 0 22.82 5.14h.17c.95 0 1.9-.03 2.84-.07Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M266.36 435.1c24.25 0 43.99-19.63 44.19-43.82a41.782 41.782 0 0 1-7.67-7.88c.5 2.43.76 4.94.76 7.51 0 20.59-16.69 37.28-37.28 37.28-16.68 0-30.8-10.96-35.56-26.06-.6-.03-1.2-.08-1.79-.13 3.07 6 5.04 12.65 5.66 19.69 8.04 8.28 19.27 13.43 31.69 13.43ZM295.84 368.11c-.68-2.97-1.05-6.06-1.05-9.24 0-.58.02-1.16.04-1.74-6.43-5.43-14.42-9.06-23.2-10.11a41.26 41.26 0 0 1 1.88 7.29c9.04 1.76 16.91 6.78 22.33 13.79Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M266.36 436.88c24.83 0 45.05-19.69 45.94-44.3a44 44 0 0 1-1.75-1.3c-.2 24.2-19.94 43.82-44.19 43.82-12.42 0-23.65-5.16-31.69-13.43.08.89.14 1.79.17 2.69 8.23 7.76 19.31 12.52 31.52 12.52ZM294.98 354.93a46.344 46.344 0 0 0-9.11-5.67c-5.31-.38-10.34-1.75-14.9-3.96.23.57.45 1.14.66 1.72 8.78 1.05 16.77 4.68 23.2 10.11.03-.74.08-1.47.15-2.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M266.36 426.4c19.57 0 35.5-15.92 35.5-35.5 0-4.81-.97-9.41-2.71-13.6-.4-.8-.79-1.62-1.14-2.45-4.79-9.41-13.65-16.41-24.24-18.67.18 1.56.28 3.14.28 4.75 0 .69-.02 1.38-.05 2.06 12.27 3.35 21.3 14.57 21.3 27.9 0 15.98-12.95 28.93-28.93 28.93-12.19 0-22.61-7.54-26.87-18.21-2.17.35-4.4.55-6.67.55h-.11c4.72 14.07 18.02 24.24 33.66 24.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M266.36 428.18c20.59 0 37.28-16.69 37.28-37.28 0-2.57-.26-5.08-.76-7.51a42.472 42.472 0 0 1-3.74-6.09c1.74 4.19 2.71 8.78 2.71 13.6 0 19.57-15.92 35.5-35.5 35.5-15.64 0-28.94-10.17-33.66-24.24-.64 0-1.27-.02-1.9-.05 4.76 15.11 18.88 26.06 35.56 26.06ZM298.01 374.86c-.91-2.16-1.63-4.42-2.16-6.75-5.43-7.01-13.3-12.03-22.33-13.79.1.62.18 1.24.25 1.87 10.6 2.26 19.45 9.26 24.24 18.67Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M266.36 418.05c14.97 0 27.15-12.18 27.15-27.15 0-12.37-8.32-22.83-19.65-26.09-.21 2.23-.59 4.4-1.14 6.51 8.25 2.68 14.22 10.43 14.22 19.58 0 11.37-9.22 20.58-20.58 20.58-8.33 0-15.5-4.95-18.74-12.07a40.99 40.99 0 0 1-6.34 1.88c4.09 9.83 13.79 16.76 25.08 16.76Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M266.36 419.83c15.98 0 28.93-12.95 28.93-28.93 0-13.33-9.02-24.56-21.3-27.9-.03.61-.08 1.21-.14 1.82 11.33 3.26 19.65 13.72 19.65 26.09 0 14.97-12.18 27.15-27.15 27.15-11.29 0-20.99-6.93-25.08-16.76-.59.12-1.19.23-1.79.33 4.26 10.67 14.68 18.21 26.87 18.21Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M266.36 409.71c10.37 0 18.8-8.44 18.8-18.8 0-8.32-5.43-15.38-12.93-17.85a41.14 41.14 0 0 1-2.43 6.11c5.08 1.49 8.8 6.17 8.8 11.74 0 6.76-5.48 12.24-12.24 12.24-5.08 0-9.44-3.1-11.29-7.51a41.211 41.211 0 0 1-5.79 3.1c2.98 6.46 9.5 10.97 17.07 10.97Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M266.36 411.48c11.37 0 20.58-9.22 20.58-20.58 0-9.15-5.97-16.9-14.22-19.58-.15.58-.31 1.15-.49 1.72 7.5 2.47 12.93 9.54 12.93 17.85 0 10.37-8.44 18.8-18.8 18.8-7.57 0-14.1-4.51-17.07-10.97-.55.24-1.1.47-1.67.68 3.24 7.12 10.41 12.07 18.74 12.07Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M266.36 401.36c5.77 0 10.46-4.69 10.46-10.46 0-4.87-3.35-8.96-7.86-10.12a42.151 42.151 0 0 1-4.77 6.9 3.888 3.888 0 0 1 6.07 3.22c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89c0-.67.19-1.3.49-1.85a42.111 42.111 0 0 1-6.36 5.56c1.5 3.94 5.31 6.75 9.77 6.75Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M266.36 403.14c6.76 0 12.24-5.48 12.24-12.24 0-5.56-3.72-10.25-8.8-11.74-.27.55-.55 1.09-.84 1.62 4.51 1.16 7.86 5.25 7.86 10.12 0 5.77-4.69 10.46-10.46 10.46-4.46 0-8.27-2.81-9.77-6.75-.5.35-1.01.69-1.52 1.03 1.85 4.41 6.21 7.51 11.29 7.51Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M266.36 393.01c1.16 0 2.11-.95 2.11-2.11s-.95-2.11-2.11-2.11-2.11.95-2.11 2.11.95 2.11 2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M262.47 390.9c0-.67.19-1.3.49-1.85.42-.45.82-.91 1.22-1.37a3.888 3.888 0 0 1 6.07 3.22c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89Zm1.78 0c0 1.16.95 2.11 2.11 2.11s2.11-.95 2.11-2.11-.95-2.11-2.11-2.11-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M144.89 87.23c.75-2.69 1.3-5.47 1.6-8.32a40.96 40.96 0 0 1-5.96-3.27c-.18 3.58-.79 7.05-1.74 10.37 2.09.25 4.13.66 6.1 1.22ZM93.27 119.21c-24.77-.72-44.63-21-44.63-45.95s20.59-45.98 45.98-45.98c10.39 0 19.97 3.45 27.67 9.26.26-2.28.71-4.5 1.32-6.66a51.918 51.918 0 0 0-28.99-8.81c-28.78 0-52.19 23.41-52.19 52.19s22.31 51.06 50.15 52.14c.08-2.11.31-4.18.69-6.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M64.46 118.03a53.008 53.008 0 0 0 9.67 5.17 53.506 53.506 0 0 0 18.41 3.98v-.25c0-.51.02-1.02.04-1.53-27.83-1.08-50.15-24.05-50.15-52.14s23.41-52.19 52.19-52.19c10.72 0 20.69 3.25 28.99 8.81.17-.6.36-1.19.55-1.78a65.32 65.32 0 0 0-3.01-1.84c-.53-.3-1.07-.59-1.61-.88-1.51-.79-3.05-1.5-4.63-2.14-.61-.25-1.21-.49-1.83-.72-1.69-.61-3.42-1.15-5.19-1.59-.78-.2-1.57-.38-2.36-.54-3.52-.72-7.17-1.1-10.9-1.1s-7.38.38-10.9 1.1c-.79.16-1.58.35-2.36.54-1.76.45-3.5.98-5.19 1.59-.62.22-1.22.47-1.83.72-1.58.64-3.13 1.36-4.63 2.14-.54.28-1.08.58-1.61.88-1.27.72-2.51 1.47-3.71 2.29-.5.34-.98.69-1.47 1.05-1.5 1.09-2.93 2.25-4.31 3.48a58.99 58.99 0 0 0-3.39 3.32c-3.4 3.63-6.3 7.73-8.6 12.2-.73 1.41-1.4 2.86-2 4.34-.09.22-.17.43-.26.65a52.01 52.01 0 0 0-1.91 5.81c-.15.57-.29 1.15-.42 1.74-.36 1.58-.66 3.18-.88 4.81-.08.6-.17 1.2-.23 1.81-.16 1.57-.24 3.17-.26 4.78 0 .24-.02.48-.02.73 0 .4.02.8.03 1.21.04 1.82.17 3.63.39 5.4.1.82.22 1.63.35 2.44.86 5.12 2.44 9.99 4.63 14.51.36.75.74 1.48 1.13 2.21.86 1.59 1.81 3.12 2.82 4.6.36.53.74 1.05 1.12 1.57a59.47 59.47 0 0 0 3 3.72 56.153 56.153 0 0 0 4.73 4.75c.45.4.9.79 1.36 1.18.83.69 1.69 1.34 2.56 1.98.56.41 1.12.82 1.69 1.2ZM144.89 87.23c.58.16 1.15.33 1.71.52a54.38 54.38 0 0 0 1.6-8.13c-.57-.23-1.14-.46-1.7-.71-.31 2.85-.85 5.63-1.6 8.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M129.69 85.9c1.34-.13 2.7-.2 4.07-.2 1.08 0 2.16.05 3.22.14a43.88 43.88 0 0 0 1.8-11.41 41.617 41.617 0 0 1-7.34-6.94c.29 1.88.45 3.81.45 5.78 0 4.44-.78 8.69-2.2 12.64ZM94.62 29.06c-24.37 0-44.2 19.83-44.2 44.2s19.3 43.66 43.23 44.18c.57-2.4 1.34-4.72 2.3-6.93-.44.02-.88.03-1.33.03-20.59 0-37.28-16.69-37.28-37.28s16.69-37.28 37.28-37.28c11.21 0 21.26 4.96 28.09 12.79-.45-2.45-.7-4.97-.7-7.54 0-.86.03-1.71.09-2.55-7.55-6.01-17.1-9.62-27.48-9.62Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 27.28c-25.39 0-45.98 20.59-45.98 45.98s19.86 45.23 44.63 45.95c.11-.59.24-1.18.38-1.77-23.92-.52-43.23-20.13-43.23-44.18s19.83-44.2 44.2-44.2c10.38 0 19.93 3.61 27.48 9.62.04-.72.11-1.43.19-2.14a45.777 45.777 0 0 0-27.67-9.26ZM138.79 86.02c.96-3.32 1.56-6.79 1.74-10.37-.59-.39-1.18-.8-1.75-1.22-.1 3.95-.72 7.78-1.8 11.41.61.05 1.21.11 1.81.18Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 37.76c-19.57 0-35.5 15.92-35.5 35.5s15.92 35.5 35.5 35.5c.73 0 1.45-.03 2.17-.07 1.29-2.6 2.84-5.05 4.63-7.31-2.18.53-4.46.81-6.8.81-15.98 0-28.93-12.95-28.93-28.93s12.95-28.93 28.93-28.93 28.93 12.95 28.93 28.93c0 5.51-1.54 10.66-4.21 15.04 2.66-1 5.46-1.72 8.35-2.15 1.56-4 2.43-8.34 2.43-12.89 0-3.19-.43-6.28-1.22-9.23-2.24-3.37-4-7.1-5.18-11.07-6.42-9.18-17.07-15.2-29.1-15.2Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 35.98c-20.59 0-37.28 16.69-37.28 37.28s16.69 37.28 37.28 37.28c.45 0 .89-.02 1.33-.03.27-.61.55-1.22.84-1.82-.72.04-1.44.07-2.17.07-19.57 0-35.5-15.92-35.5-35.5s15.92-35.5 35.5-35.5c12.03 0 22.67 6.02 29.1 15.2a39.3 39.3 0 0 1-1-4.19c-6.83-7.83-16.88-12.79-28.09-12.79ZM127.68 86.15c.66-.1 1.33-.18 2.01-.25 1.42-3.95 2.2-8.2 2.2-12.64 0-1.97-.15-3.9-.45-5.78a43.36 43.36 0 0 1-2.56-3.45 35.56 35.56 0 0 1 1.22 9.23c0 4.55-.87 8.89-2.43 12.89Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M121.77 73.26c0 6.12-2.04 11.77-5.47 16.32a41.392 41.392 0 0 0-12.76 9.32c-2.8.98-5.8 1.51-8.92 1.51-14.97 0-27.15-12.18-27.15-27.15s12.18-27.15 27.15-27.15 27.15 12.18 27.15 27.15Zm-6.57 0c0-11.37-9.22-20.58-20.58-20.58S74.04 61.9 74.04 73.26s9.22 20.58 20.58 20.58 20.58-9.22 20.58-20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M94.62 44.33c-15.98 0-28.93 12.95-28.93 28.93s12.95 28.93 28.93 28.93c2.34 0 4.62-.29 6.8-.81.68-.85 1.38-1.68 2.12-2.48-2.8.98-5.8 1.51-8.92 1.51-14.97 0-27.15-12.18-27.15-27.15s12.18-27.15 27.15-27.15 27.15 12.18 27.15 27.15c0 6.12-2.04 11.77-5.47 16.32.99-.46 2-.89 3.03-1.28 2.67-4.38 4.21-9.53 4.21-15.04 0-15.98-12.95-28.93-28.93-28.93Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M113.42 73.26c0 10.37-8.44 18.8-18.8 18.8s-18.8-8.44-18.8-18.8 8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8Zm-6.57 0c0-6.76-5.48-12.24-12.24-12.24S82.37 66.5 82.37 73.26 87.85 85.5 94.61 85.5s12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M115.2 73.26c0 11.37-9.22 20.58-20.58 20.58s-20.58-9.22-20.58-20.58 9.22-20.58 20.58-20.58 20.58 9.22 20.58 20.58Zm-1.78 0c0-10.37-8.44-18.8-18.8-18.8s-18.8 8.44-18.8 18.8 8.44 18.8 18.8 18.8 18.8-8.44 18.8-18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M105.07 73.26c0 5.77-4.69 10.46-10.46 10.46s-10.46-4.69-10.46-10.46S88.84 62.8 94.61 62.8s10.46 4.69 10.46 10.46Zm-6.57 0c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M106.85 73.26c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-1.78 0c0-5.77-4.69-10.46-10.46-10.46s-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46 10.46-4.69 10.46-10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M94.62 71.15c-1.16 0-2.11.95-2.11 2.11s.95 2.11 2.11 2.11 2.11-.95 2.11-2.11-.95-2.11-2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M98.51 73.26c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M123.79 41.23c0 21.75 17.7 39.45 39.45 39.45s39.45-17.7 39.45-39.45c0-3.35-.42-6.6-1.21-9.71-1.34.55-2.7 1.05-4.09 1.5.63 2.63.97 5.38.97 8.21 0 19.4-15.73 35.13-35.13 35.13S128.1 60.63 128.1 41.23c0-10.05 4.22-19.1 10.99-25.5-.91-1.12-1.78-2.28-2.6-3.47-7.81 7.21-12.71 17.53-12.71 28.97Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M123.6 29.88c-.62 2.15-1.06 4.37-1.32 6.66-.08.71-.14 1.42-.19 2.14-.05.84-.09 1.69-.09 2.55 0 2.58.25 5.1.7 7.54.26 1.42.6 2.82 1 4.19 1.18 3.97 2.93 7.7 5.18 11.07a38.96 38.96 0 0 0 2.56 3.45c2.15 2.6 4.62 4.93 7.34 6.94.57.42 1.15.83 1.75 1.22a40.96 40.96 0 0 0 12.5 5.54c2.25.57 4.57.95 6.95 1.13.59.05 1.19.08 1.79.1.48.02.97.04 1.45.04 1.73 0 3.43-.12 5.11-.33.59-.07 1.18-.16 1.77-.25 2.24-.38 4.42-.94 6.53-1.66.61-.21 1.22-.44 1.82-.67 2.64-1.05 5.14-2.36 7.47-3.9 3.03-2 5.77-4.39 8.17-7.09 1.87-2.11 3.53-4.42 4.94-6.89.32-.55.62-1.12.91-1.69 1.02-1.99 1.88-4.07 2.57-6.23.18-.57.35-1.14.5-1.72.57-2.09.98-4.24 1.21-6.45.06-.59.11-1.19.14-1.78.05-.85.09-1.7.09-2.57 0-1.52-.09-3.02-.25-4.49-.07-.62-.14-1.23-.24-1.84-.21-1.39-.5-2.76-.85-4.1-.55.25-1.1.49-1.66.72.79 3.11 1.21 6.36 1.21 9.71 0 21.75-17.7 39.45-39.45 39.45s-39.45-17.7-39.45-39.45c0-11.44 4.9-21.76 12.71-28.97-.35-.5-.68-1.01-1.01-1.52-.14.12-.27.26-.4.38-.83.77-1.63 1.57-2.39 2.41a41.42 41.42 0 0 0-8.55 14.57c-.2.59-.38 1.18-.55 1.78Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M129.89 41.23c0 18.39 14.96 33.35 33.35 33.35s33.35-14.96 33.35-33.35c0-2.65-.32-5.23-.91-7.7-1.55.44-3.13.81-4.73 1.12.5 2.11.77 4.32.77 6.59 0 15.73-12.75 28.48-28.48 28.48s-28.48-12.75-28.48-28.48c0-8.12 3.4-15.44 8.86-20.63a52.832 52.832 0 0 1-3.35-3.51c-6.38 6.08-10.37 14.65-10.37 24.14Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M128.11 41.23c0 19.4 15.73 35.13 35.13 35.13s35.13-15.73 35.13-35.13c0-2.83-.34-5.58-.97-8.21-.57.18-1.14.35-1.71.51.59 2.47.91 5.05.91 7.7 0 18.39-14.96 33.35-33.35 33.35S129.9 59.62 129.9 41.23c0-9.49 3.99-18.06 10.37-24.14-.39-.45-.79-.9-1.17-1.37-6.76 6.4-10.99 15.46-10.99 25.5Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M136.54 41.23c0 14.72 11.98 26.7 26.7 26.7s26.7-11.98 26.7-26.7c0-2.16-.26-4.26-.75-6.27-1.52.23-3.06.39-4.61.49.5 1.84.77 3.78.77 5.78 0 12.21-9.9 22.1-22.1 22.1s-22.1-9.9-22.1-22.1c0-6.54 2.84-12.42 7.36-16.46-1.23-.93-2.42-1.91-3.57-2.94-5.16 4.87-8.39 11.77-8.39 19.4Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M134.76 41.23c0 15.73 12.75 28.48 28.48 28.48s28.48-12.75 28.48-28.48c0-2.27-.27-4.47-.77-6.59-.58.11-1.17.22-1.76.31.49 2.01.75 4.11.75 6.27 0 14.72-11.98 26.7-26.7 26.7s-26.7-11.98-26.7-26.7c0-7.64 3.23-14.53 8.39-19.4-.45-.4-.88-.81-1.31-1.22-5.45 5.19-8.86 12.51-8.86 20.63Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M142.92 41.23c0 11.21 9.12 20.32 20.32 20.32s20.32-9.12 20.32-20.32c0-1.97-.29-3.88-.82-5.69-.61.02-1.21.05-1.82.05-1.02 0-2.04-.03-3.05-.09.7 1.78 1.09 3.71 1.09 5.73 0 8.68-7.04 15.72-15.72 15.72s-15.72-7.04-15.72-15.72c0-5.27 2.61-9.93 6.59-12.78-1.41-.81-2.78-1.68-4.11-2.61-4.33 3.73-7.08 9.24-7.08 15.39Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M141.14 41.23c0 12.21 9.9 22.1 22.1 22.1s22.1-9.9 22.1-22.1c0-2-.27-3.94-.77-5.78-.61.04-1.22.07-1.83.09.53 1.81.82 3.71.82 5.69 0 11.21-9.12 20.32-20.32 20.32s-20.32-9.12-20.32-20.32c0-6.15 2.75-11.66 7.08-15.39-.5-.35-1-.7-1.49-1.07-4.52 4.05-7.36 9.92-7.36 16.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M149.29 41.23c0 7.69 6.26 13.95 13.95 13.95s13.95-6.26 13.95-13.95c0-2.1-.48-4.09-1.32-5.88-2.13-.2-4.22-.52-6.27-.96a9.308 9.308 0 0 1 2.99 6.84c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35c0-4.62 3.35-8.44 7.75-9.2-1.98-.76-3.91-1.63-5.78-2.61-3.94 2.47-6.57 6.83-6.57 11.81Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M147.52 41.23c0 8.68 7.04 15.72 15.72 15.72s15.72-7.04 15.72-15.72c0-2.03-.4-3.95-1.09-5.73-.67-.04-1.34-.09-2.01-.15.84 1.79 1.32 3.78 1.32 5.88 0 7.69-6.26 13.95-13.95 13.95s-13.95-6.26-13.95-13.95c0-4.98 2.63-9.35 6.57-11.81-.59-.31-1.18-.64-1.76-.97-3.98 2.85-6.59 7.51-6.59 12.78Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M155.67 41.23c0-4.17 3.4-7.57 7.57-7.57s7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57-7.57-3.4-7.57-7.57Zm4.6 0a2.97 2.97 0 1 0 5.94 0 2.97 2.97 0 0 0-5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M161.64 32.03c2.56.98 5.22 1.78 7.95 2.36a9.308 9.308 0 0 1 2.99 6.84c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35c0-4.62 3.35-8.44 7.75-9.2Zm-5.97 9.2c0 4.17 3.4 7.57 7.57 7.57s7.57-3.4 7.57-7.57-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M162.05 41.23c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M163.24 38.26a2.97 2.97 0 1 1 0 5.94 2.97 2.97 0 0 1 0-5.94Zm0 1.78c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M180.92 33.81c22.31 0 41.39-14.08 48.84-33.81h-6.69c-7.09 16.24-23.29 27.6-42.15 27.6S145.87 16.24 138.77 0h-6.69c7.45 19.73 26.53 33.81 48.84 33.81Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M135.23 10.33c.09.14.17.27.26.41a54.995 54.995 0 0 0 3.61 4.99c.38.46.77.92 1.17 1.37 1.06 1.22 2.18 2.39 3.35 3.51.43.41.87.83 1.31 1.22 1.15 1.03 2.34 2.01 3.57 2.94.49.37.99.72 1.49 1.07a54.827 54.827 0 0 0 5.87 3.58c1.86.98 3.79 1.85 5.78 2.61 2.56.98 5.22 1.78 7.95 2.36 2.05.44 4.14.76 6.27.96.67.06 1.33.12 2.01.15 1.01.06 2.03.09 3.05.09.61 0 1.22-.03 1.82-.05.61-.02 1.22-.05 1.83-.09 1.56-.1 3.1-.26 4.61-.49.59-.09 1.17-.2 1.76-.31 1.6-.3 3.18-.68 4.73-1.12a48.732 48.732 0 0 0 5.8-2.01c.56-.23 1.11-.48 1.66-.72 1.2-.54 2.38-1.11 3.53-1.74.79-.43 1.57-.88 2.33-1.35 1.35-.82 2.66-1.71 3.93-2.65a53.918 53.918 0 0 0 8.65-7.98A53.986 53.986 0 0 0 231.66 0h-1.92c-7.45 19.73-26.53 33.81-48.84 33.81S139.53 19.73 132.08 0h-1.92a52.79 52.79 0 0 0 3.62 7.9c.46.82.94 1.63 1.45 2.43Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M180.92 25.82c17.81 0 33.19-10.6 40.18-25.82h-7.76c-6.41 11.28-18.52 18.9-32.43 18.9S154.89 11.29 148.48 0h-7.76c6.99 15.22 22.37 25.82 40.18 25.82Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M180.92 27.6c18.86 0 35.05-11.36 42.15-27.6h-1.97c-6.99 15.22-22.37 25.82-40.18 25.82S147.73 15.22 140.74 0h-1.97c7.09 16.24 23.29 27.6 42.15 27.6Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M180.92 17.12c12.85 0 24.13-6.86 30.36-17.12h-8.02c-5.31 6.44-13.34 10.55-22.34 10.55S163.89 6.44 158.58 0h-8.02c6.23 10.25 17.51 17.12 30.36 17.12Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M180.92 18.9c13.9 0 26.02-7.61 32.43-18.9h-2.07c-6.23 10.25-17.51 17.12-30.36 17.12S156.79 10.26 150.56 0h-2.07c6.41 11.28 18.52 18.9 32.43 18.9Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M180.92 8.77c7.89 0 15-3.38 19.96-8.77h-10.7c-2.78 1.41-5.93 2.2-9.26 2.2s-6.48-.8-9.26-2.2h-10.7c4.96 5.39 12.07 8.77 19.96 8.77Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M180.92 10.55c9 0 17.03-4.11 22.34-10.55h-2.38c-4.96 5.39-12.07 8.77-19.96 8.77s-15-3.38-19.96-8.77h-2.38c5.31 6.44 13.34 10.55 22.34 10.55Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M184.86 0h-7.88c1.27.27 2.59.42 3.94.42s2.67-.15 3.94-.42Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M190.18 0h-5.32c-1.27.27-2.59.42-3.94.42s-2.67-.15-3.94-.42h-5.32c2.78 1.41 5.93 2.2 9.26 2.2s6.48-.8 9.26-2.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M134.94 426.49c0-25.39 20.59-45.98 45.98-45.98 6.3 0 12.29 1.27 17.76 3.56a41.323 41.323 0 0 1-4.21-7.98 52.175 52.175 0 0 0-13.55-1.79c-28.78 0-52.19 23.41-52.19 52.19 0 6.47 1.19 12.66 3.35 18.38h6.69a45.773 45.773 0 0 1-3.83-18.38ZM233.11 426.49c0-8.95-2.27-17.39-6.26-24.76-3.04-.44-5.97-1.21-8.75-2.28 5.53 7.59 8.8 16.93 8.8 27.04 0 6.54-1.37 12.75-3.83 18.38h6.69a51.907 51.907 0 0 0 3.35-18.38Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.9 441.01c.47-1.69.86-3.42 1.17-5.18.09-.5.17-.99.25-1.5a55.027 55.027 0 0 0 .57-7.84c0-.71-.03-1.42-.05-2.13a53.636 53.636 0 0 0-5.83-22.38c-.72-.07-1.44-.15-2.15-.25a51.874 51.874 0 0 1 6.26 24.76c0 6.47-1.19 12.66-3.35 18.38h1.92c.18-.49.34-1 .51-1.5.26-.78.5-1.57.72-2.37ZM128.73 426.49c0-28.78 23.41-52.19 52.19-52.19 4.69 0 9.22.63 13.55 1.79-.26-.67-.51-1.35-.74-2.03-1.21-.29-2.44-.55-3.68-.76-.59-.1-1.18-.19-1.78-.27a53.75 53.75 0 0 0-6.83-.5c-.18 0-.35-.01-.53-.01-1.52 0-3.01.08-4.5.2a53.04 53.04 0 0 0-12.52 2.55c-1.8.6-3.55 1.28-5.26 2.06a52.638 52.638 0 0 0-9.27 5.38c-.57.41-1.12.83-1.68 1.26a51.52 51.52 0 0 0-3.8 3.27c-.46.43-.91.86-1.35 1.31-.89.9-1.74 1.84-2.57 2.8-.63.74-1.25 1.49-1.84 2.25a53.666 53.666 0 0 0-11.05 29.15c-.06.94-.11 1.9-.12 2.85 0 .3-.02.59-.02.89 0 3.7.37 7.32 1.09 10.82.31 1.51.69 2.99 1.12 4.46.31 1.05.64 2.09 1.01 3.11h1.92a51.907 51.907 0 0 1-3.35-18.38Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M225.12 426.49c0-10.88-3.96-20.84-10.5-28.55-5.3-2.61-9.95-6.31-13.67-10.83a43.84 43.84 0 0 0-20.04-4.82c-24.37 0-44.2 19.83-44.2 44.2 0 6.56 1.45 12.78 4.02 18.38h7.76a37.141 37.141 0 0 1-4.85-18.38c0-20.59 16.69-37.28 37.28-37.28s37.28 16.69 37.28 37.28c0 6.69-1.77 12.96-4.85 18.38h7.76c2.57-5.6 4.02-11.82 4.02-18.38Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M226.9 426.49c0-10.11-3.27-19.45-8.8-27.04a41.51 41.51 0 0 1-3.48-1.51c6.54 7.71 10.5 17.67 10.5 28.55 0 6.56-1.45 12.78-4.02 18.38h1.97a45.773 45.773 0 0 0 3.83-18.38ZM180.92 382.29c7.21 0 14.02 1.75 20.04 4.82-.8-.98-1.56-1.99-2.28-3.04a45.812 45.812 0 0 0-17.76-3.56c-25.39 0-45.98 20.59-45.98 45.98 0 6.54 1.37 12.75 3.83 18.38h1.97a43.947 43.947 0 0 1-4.02-18.38c0-24.37 19.83-44.2 44.2-44.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M180.92 390.99c-19.57 0-35.5 15.92-35.5 35.5 0 6.72 1.88 13.02 5.14 18.38h8.02c-4.12-5-6.59-11.4-6.59-18.38 0-15.98 12.95-28.93 28.93-28.93s28.93 12.95 28.93 28.93c0 6.98-2.47 13.38-6.59 18.38h8.02a35.28 35.28 0 0 0 5.14-18.38c0-19.57-15.92-35.5-35.5-35.5Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M180.92 389.21c-20.59 0-37.28 16.69-37.28 37.28 0 6.69 1.77 12.96 4.85 18.38h2.07a35.28 35.28 0 0 1-5.14-18.38c0-19.57 15.92-35.5 35.5-35.5s35.5 15.92 35.5 35.5c0 6.72-1.88 13.02-5.14 18.38h2.07c3.08-5.43 4.85-11.7 4.85-18.38 0-20.59-16.69-37.28-37.28-37.28Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M180.92 399.34c-14.97 0-27.15 12.18-27.15 27.15 0 7.08 2.73 13.54 7.19 18.38h10.7c-6.72-3.39-11.32-10.35-11.32-18.38 0-11.37 9.22-20.58 20.58-20.58s20.58 9.22 20.58 20.58c0 8.04-4.61 14.99-11.32 18.38h10.7c4.46-4.84 7.19-11.3 7.19-18.38 0-14.97-12.18-27.15-27.15-27.15Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M180.92 397.56c-15.98 0-28.93 12.95-28.93 28.93 0 6.98 2.47 13.38 6.59 18.38h2.38c-4.46-4.84-7.19-11.3-7.19-18.38 0-14.97 12.18-27.15 27.15-27.15s27.15 12.18 27.15 27.15c0 7.08-2.73 13.54-7.19 18.38h2.38c4.12-5 6.59-11.4 6.59-18.38 0-15.98-12.95-28.93-28.93-28.93Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M184.86 444.87h-7.88c-8.48-1.82-14.87-9.36-14.87-18.38 0-10.37 8.44-18.8 18.8-18.8s18.8 8.44 18.8 18.8c0 9.02-6.38 16.57-14.87 18.38Zm8.3-18.38c0-6.76-5.48-12.24-12.24-12.24s-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24 12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M201.5 426.49c0-11.37-9.22-20.58-20.58-20.58s-20.58 9.22-20.58 20.58c0 8.04 4.61 14.99 11.32 18.38h5.32c-8.48-1.82-14.87-9.36-14.87-18.38 0-10.37 8.44-18.8 18.8-18.8s18.8 8.44 18.8 18.8c0 9.02-6.38 16.57-14.87 18.38h5.32c6.72-3.39 11.32-10.35 11.32-18.38Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M191.38 426.49c0 5.77-4.69 10.46-10.46 10.46s-10.46-4.69-10.46-10.46 4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46Zm-6.57 0c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M193.16 426.49c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-1.78 0c0-5.77-4.69-10.46-10.46-10.46s-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46 10.46-4.69 10.46-10.46Z"
        />
        <circle
          className="cls-Venn3-3-3-3"
          cx={180.92}
          cy={426.49}
          r={2.11}
          transform="rotate(-6.6 180.83 426.393)"
        />
        <path
          className="cls-Venn3-3-4"
          d="M184.81 426.49c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M98.64 126.93c0-19.4 15.73-35.13 35.13-35.13s35.13 15.73 35.13 35.13c0 .77-.03 1.52-.08 2.27 1.44-.12 2.88-.19 4.35-.19.04-.69.06-1.38.06-2.08 0-21.75-17.7-39.45-39.45-39.45s-39.45 17.7-39.45 39.45c0 17.81 11.86 32.89 28.09 37.78.49-1.37 1.05-2.71 1.65-4.02-14.67-4.22-25.41-17.73-25.41-33.75Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M92.79 131.49c.27 2.49.77 4.9 1.47 7.24.22.73.45 1.46.71 2.17 3.11 8.64 9.03 15.94 16.66 20.81.64.41 1.29.8 1.96 1.18a41.283 41.283 0 0 0 8.25 3.52c.18-.57.37-1.13.57-1.69-16.23-4.89-28.09-19.97-28.09-37.78 0-21.75 17.7-39.45 39.45-39.45s39.45 17.7 39.45 39.45c0 .7-.02 1.39-.06 2.08h.04c.59 0 1.16.03 1.74.04.02-.49.03-.98.04-1.47 0-.22.02-.43.02-.65 0-2.25-.19-4.45-.53-6.6-.13-.79-.28-1.58-.45-2.35-2.53-11.42-9.8-21.06-19.64-26.75-.69-.4-1.38-.77-2.09-1.13-1.82-.92-3.71-1.7-5.67-2.34-.56-.18-1.14-.36-1.71-.52-1.98-.55-4.02-.96-6.1-1.22-.6-.07-1.2-.13-1.81-.18-1.06-.08-2.13-.14-3.22-.14-1.37 0-2.73.07-4.07.2a41.609 41.609 0 0 0-10.36 2.4 41.392 41.392 0 0 0-15.79 10.6c-.74.8-1.45 1.63-2.12 2.48a41.05 41.05 0 0 0-4.63 7.31c-.3.6-.58 1.2-.84 1.82-.96 2.22-1.73 4.53-2.3 6.93-.14.59-.27 1.17-.38 1.77-.38 2.02-.62 4.09-.69 6.19-.02.51-.04 1.02-.04 1.53v.25c0 .82.04 1.64.09 2.45.04.63.09 1.25.16 1.87Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M133.76 93.58c-18.39 0-33.35 14.96-33.35 33.35 0 15.29 10.35 28.2 24.4 32.12.75-1.51 1.56-2.97 2.44-4.4-12.59-2.94-21.97-14.23-21.97-27.72 0-15.73 12.75-28.48 28.48-28.48s28.48 12.75 28.48 28.48c0 1.1-.07 2.18-.19 3.24 1.63-.34 3.28-.6 4.96-.79.06-.81.1-1.62.1-2.45 0-18.39-14.96-33.35-33.35-33.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M133.76 91.8c-19.4 0-35.13 15.73-35.13 35.13 0 16.03 10.74 29.54 25.41 33.75.25-.55.5-1.1.77-1.64-14.06-3.92-24.4-16.83-24.4-32.12 0-18.39 14.96-33.35 33.35-33.35s33.35 14.96 33.35 33.35c0 .82-.04 1.64-.1 2.45.6-.07 1.2-.12 1.8-.17.05-.75.08-1.51.08-2.27 0-19.4-15.73-35.13-35.13-35.13Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M133.76 100.23c-14.72 0-26.7 11.98-26.7 26.7 0 12.84 9.12 23.59 21.22 26.13.96-1.44 1.99-2.83 3.08-4.16-11.07-1.2-19.69-10.58-19.69-21.97 0-12.21 9.9-22.1 22.1-22.1s22.1 9.9 22.1 22.1c0 1.77-.21 3.5-.61 5.15 1.62-.57 3.27-1.07 4.95-1.48.17-1.2.26-2.42.26-3.67 0-14.72-11.98-26.7-26.7-26.7Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M133.76 98.45c-15.73 0-28.48 12.75-28.48 28.48 0 13.49 9.38 24.78 21.97 27.72.33-.54.67-1.07 1.02-1.59-12.1-2.54-21.22-13.29-21.22-26.13 0-14.72 11.98-26.7 26.7-26.7s26.7 11.98 26.7 26.7c0 1.24-.09 2.47-.26 3.67.61-.15 1.23-.3 1.85-.43.12-1.06.19-2.14.19-3.24 0-15.73-12.75-28.48-28.48-28.48Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M133.76 106.61c-11.21 0-20.32 9.12-20.32 20.32s8.59 19.78 19.34 20.3c1.63-1.84 3.39-3.57 5.26-5.18-1.36.38-2.79.6-4.28.6-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72 15.72 7.04 15.72 15.72c0 3.4-1.09 6.55-2.93 9.12 2.13-1.21 4.35-2.28 6.65-3.2.57-1.88.88-3.86.88-5.92 0-11.21-9.12-20.32-20.32-20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M133.76 104.83c-12.21 0-22.1 9.9-22.1 22.1 0 11.39 8.62 20.77 19.69 21.97.46-.57.94-1.12 1.43-1.67-10.75-.51-19.34-9.42-19.34-20.3s9.12-20.32 20.32-20.32 20.32 9.12 20.32 20.32c0 2.06-.31 4.05-.88 5.92.68-.27 1.36-.53 2.05-.78.39-1.65.61-3.38.61-5.15 0-12.21-9.9-22.1-22.1-22.1Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M147.71 126.93c0 7.69-6.26 13.95-13.95 13.95s-13.95-6.26-13.95-13.95 6.26-13.95 13.95-13.95 13.95 6.26 13.95 13.95Zm-4.6 0c0-5.16-4.19-9.35-9.35-9.35s-9.35 4.19-9.35 9.35 4.19 9.35 9.35 9.35 9.35-4.19 9.35-9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M149.49 126.93c0 3.4-1.09 6.55-2.93 9.12a54.58 54.58 0 0 0-8.51 6c-1.36.38-2.79.6-4.28.6-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72 15.72 7.04 15.72 15.72Zm-1.78 0c0-7.69-6.26-13.95-13.95-13.95s-13.95 6.26-13.95 13.95 6.26 13.95 13.95 13.95 13.95-6.26 13.95-13.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M141.33 126.93c0 4.17-3.4 7.57-7.57 7.57s-7.57-3.4-7.57-7.57 3.4-7.57 7.57-7.57 7.57 3.4 7.57 7.57Zm-4.6 0a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M143.11 126.93c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35 9.35 4.19 9.35 9.35Zm-1.78 0c0-4.17-3.4-7.57-7.57-7.57s-7.57 3.4-7.57 7.57 3.4 7.57 7.57 7.57 7.57-3.4 7.57-7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M133.76 125.74c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M136.74 126.93a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M239.38 179.89c2.74.35 5.42.9 8.01 1.64.75-.36 1.48-.74 2.21-1.15 3.4-4.67 7.76-8.59 12.79-11.49-.51-1.97-.89-3.99-1.18-6.04-4.59 8.32-12.44 14.57-21.84 17.04ZM230.44 106.47c-17.18 0-31.82 11.04-37.22 26.4 1.35.54 2.67 1.13 3.96 1.77 4.7-13.86 17.81-23.84 33.26-23.84 1.38 0 2.75.09 4.09.24-.55-1.46-1.04-2.95-1.47-4.47-.87-.06-1.74-.1-2.63-.1Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M225.17 105.03c-.97.12-1.94.27-2.89.46a41.093 41.093 0 0 0-19.62 9.96c-.71.65-1.4 1.34-2.07 2.04a41.036 41.036 0 0 0-6.39 8.75c-1.03 1.9-1.92 3.9-2.64 5.97.56.2 1.11.43 1.67.65 5.4-15.36 20.04-26.4 37.22-26.4.88 0 1.76.04 2.63.1-.17-.6-.33-1.21-.48-1.82-.71-.04-1.43-.06-2.15-.06-1.79 0-3.55.13-5.27.35ZM248.57 181.87c.33-.5.68-.99 1.03-1.48-.72.4-1.46.79-2.21 1.15.39.11.79.21 1.17.33Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M230.44 174.4c-1.36 0-2.7-.1-4.01-.29.27 1.64.46 3.3.58 4.98 1.13.12 2.27.18 3.43.18 13.54 0 25.2-8.11 30.43-19.72-.1-1.37-.17-2.74-.17-4.14 0-4.97.69-9.78 1.94-14.35-1.6-.73-3.15-1.53-4.66-2.41.61 2.32.94 4.76.94 7.27 0 15.73-12.75 28.48-28.48 28.48ZM198.79 135.46c1.47.8 2.9 1.66 4.29 2.58 3.42-11.89 14.37-20.6 27.37-20.6 2.62 0 5.16.36 7.58 1.03a54.816 54.816 0 0 1-2.74-5.54c-1.58-.23-3.19-.35-4.84-.35-14.73 0-27.26 9.61-31.66 22.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M230.44 110.79c-15.45 0-28.56 9.98-33.26 23.84.54.27 1.08.54 1.61.82 4.4-13.28 16.92-22.89 31.66-22.89 1.64 0 3.26.12 4.84.35-.26-.62-.51-1.26-.75-1.89-1.34-.16-2.71-.24-4.09-.24ZM232.53 179.44c2.32 0 4.61.16 6.85.45 9.4-2.47 17.25-8.72 21.84-17.04-.15-1.09-.27-2.2-.35-3.31-5.22 11.61-16.89 19.72-30.43 19.72-1.16 0-2.3-.06-3.43-.18.02.21.04.42.05.63 1.8-.18 3.62-.27 5.46-.27Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M230.44 119.22c-12.37 0-22.8 8.46-25.82 19.9 1.39 1 2.73 2.06 4.02 3.18 1.73-10.49 10.83-18.49 21.8-18.49 12.21 0 22.1 9.89 22.1 22.1s-9.9 22.1-22.1 22.1c-1.93 0-3.81-.25-5.6-.72.49 1.62.91 3.27 1.25 4.96 1.41.23 2.87.36 4.34.36 14.72 0 26.7-11.98 26.7-26.7 0-3.04-.52-5.96-1.46-8.68a54.22 54.22 0 0 1-16.29-16.47c-2.8-1-5.82-1.55-8.96-1.55Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M230.44 117.44c-12.99 0-23.95 8.71-27.37 20.6.52.35 1.04.71 1.55 1.08 3.01-11.44 13.45-19.9 25.82-19.9 3.14 0 6.15.55 8.96 1.55-.48-.76-.94-1.52-1.38-2.3-2.41-.66-4.95-1.03-7.58-1.03ZM230.44 172.62c-1.48 0-2.93-.13-4.34-.36.12.61.23 1.23.33 1.85 1.31.19 2.65.29 4.01.29 15.73 0 28.48-12.75 28.48-28.48 0-2.51-.33-4.95-.94-7.27-.78-.45-1.54-.92-2.3-1.41a26.53 26.53 0 0 1 1.46 8.68c0 14.72-11.98 26.7-26.7 26.7Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M250.77 145.92c0-11.21-9.12-20.32-20.32-20.32-10.47 0-19.12 7.96-20.2 18.15 1.69 1.6 3.29 3.3 4.76 5.11-.18-.95-.28-1.93-.28-2.94 0-8.68 7.04-15.72 15.72-15.72s15.72 7.04 15.72 15.72-7.04 15.72-15.72 15.72c-3.35 0-6.45-1.06-9-2.84 1.04 2.07 1.95 4.22 2.72 6.44 1.98.64 4.08 1 6.27 1 11.21 0 20.32-9.12 20.32-20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M210.24 143.75c1.09-10.19 9.73-18.15 20.2-18.15 11.21 0 20.32 9.12 20.32 20.32s-9.12 20.32-20.32 20.32c-2.19 0-4.3-.35-6.27-1 .24.68.47 1.37.68 2.06 1.79.47 3.66.72 5.6.72 12.21 0 22.1-9.89 22.1-22.1s-9.9-22.1-22.1-22.1c-10.97 0-20.08 8-21.8 18.49.54.47 1.07.96 1.6 1.45Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M216.5 145.92c0-7.69 6.26-13.95 13.95-13.95s13.95 6.26 13.95 13.95-6.26 13.95-13.95 13.95-13.95-6.26-13.95-13.95Zm4.6 0c0 5.16 4.19 9.35 9.35 9.35s9.35-4.19 9.35-9.35-4.19-9.35-9.35-9.35-9.35 4.19-9.35 9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M230.44 161.64c-3.35 0-6.45-1.06-9-2.84a54.651 54.651 0 0 0-6.44-9.95c-.18-.95-.28-1.93-.28-2.94 0-8.68 7.04-15.72 15.72-15.72s15.72 7.04 15.72 15.72-7.04 15.72-15.72 15.72Zm-13.95-15.72c0 7.69 6.26 13.95 13.95 13.95s13.95-6.26 13.95-13.95-6.26-13.95-13.95-13.95-13.95 6.26-13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M222.88 145.92c0-4.17 3.4-7.57 7.57-7.57s7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57-7.57-3.4-7.57-7.57Zm4.6 0a2.97 2.97 0 1 0 5.94 0 2.97 2.97 0 0 0-5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M221.1 145.92c0-5.16 4.19-9.35 9.35-9.35s9.35 4.19 9.35 9.35-4.19 9.35-9.35 9.35-9.35-4.19-9.35-9.35Zm1.78 0c0 4.17 3.4 7.57 7.57 7.57s7.57-3.4 7.57-7.57-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M230.44 147.11c.66 0 1.19-.53 1.19-1.19s-.53-1.19-1.19-1.19-1.19.53-1.19 1.19.53 1.19 1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M227.47 145.92a2.97 2.97 0 1 1 5.94 0 2.97 2.97 0 0 1-5.94 0Zm1.78 0c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M375.17 159.26c-2.29 0-4.54.21-6.73.59-.12 1.52-.31 3.02-.55 4.5 2.35-.5 4.79-.76 7.28-.76 16.69 0 30.66 11.65 34.23 27.26 1.56.22 3.09.52 4.59.9-3.3-18.44-19.45-32.49-38.82-32.49Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M405 170.27a41.075 41.075 0 0 0-8.93-7.09c-.52-.31-1.05-.6-1.58-.89a41.83 41.83 0 0 0-4.19-1.93c-.55-.22-1.11-.42-1.67-.62-1.44-.5-2.92-.92-4.43-1.25-.58-.13-1.17-.25-1.77-.35a40.561 40.561 0 0 0-6.7-.63c-.19 0-.37-.01-.56-.01-2.25 0-4.45.19-6.6.53-.03.62-.08 1.22-.13 1.83 2.19-.38 4.43-.59 6.73-.59 19.38 0 35.53 14.05 38.82 32.49.64.17 1.28.35 1.91.54a40.871 40.871 0 0 0-9.6-20.59c-.42-.49-.86-.97-1.3-1.43Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M375.17 165.37c-2.62 0-5.17.31-7.62.89-.38 1.86-.86 3.67-1.42 5.45 2.84-.95 5.88-1.48 9.05-1.48 12.86 0 23.73 8.53 27.26 20.24.4-.01.8-.03 1.21-.03 1.31 0 2.6.07 3.88.19-3.62-14.49-16.75-25.26-32.35-25.26Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M375.17 163.59c-2.5 0-4.93.27-7.28.76-.11.64-.21 1.28-.34 1.91 2.45-.58 5-.89 7.62-.89 15.6 0 28.73 10.77 32.35 25.26.63.06 1.26.13 1.88.22-3.57-15.61-17.54-27.26-34.23-27.26Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M364.06 220.12c.19-.66.4-1.3.62-1.94-.59-.32-1.16-.66-1.71-1.03.39.98.76 1.97 1.09 2.98ZM348.94 197.1c1.52 1.36 2.95 2.81 4.31 4.34a22.147 22.147 0 0 1 .77-9.11 55.754 55.754 0 0 1-5.07 4.77ZM375.17 172.02c-3.45 0-6.75.67-9.78 1.86a53.31 53.31 0 0 1-3.06 6.85 21.99 21.99 0 0 1 12.84-4.12c9.56 0 17.69 6.07 20.78 14.56 1.52-.29 3.07-.49 4.64-.6-3.45-10.75-13.54-18.55-25.42-18.55Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M375.17 170.24c-3.16 0-6.2.52-9.05 1.48-.23.73-.47 1.45-.73 2.17 3.03-1.2 6.33-1.86 9.78-1.86 11.88 0 21.97 7.8 25.42 18.55.61-.04 1.22-.08 1.84-.09-3.54-11.71-14.4-20.24-27.26-20.24Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M375.17 178.39c-11.21 0-20.32 9.12-20.32 20.32 0 1.95.28 3.84.8 5.63a54.12 54.12 0 0 1 5.81 9.35c1.17 1.07 2.47 2.01 3.86 2.79.57-1.44 1.21-2.84 1.94-4.19-4.66-2.73-7.81-7.78-7.81-13.57 0-8.68 7.04-15.72 15.72-15.72 6.61 0 12.25 4.08 14.58 9.86 1.44-.52 2.92-.95 4.43-1.3-2.9-7.68-10.33-13.15-19.01-13.15Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M375.17 176.61c-4.79 0-9.22 1.53-12.84 4.12a54.026 54.026 0 0 1-8.32 11.59 22.096 22.096 0 0 0-.77 9.11c.84.94 1.64 1.91 2.41 2.91-.52-1.79-.8-3.67-.8-5.63 0-11.21 9.12-20.32 20.32-20.32 8.68 0 16.11 5.47 19.01 13.15.58-.14 1.17-.26 1.77-.38-3.08-8.49-11.22-14.56-20.78-14.56ZM362.97 217.14c.55.37 1.13.71 1.71 1.03.2-.57.41-1.14.63-1.7a20.08 20.08 0 0 1-3.86-2.79c.55 1.13 1.05 2.28 1.52 3.45Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M375.17 184.77c-7.69 0-13.95 6.26-13.95 13.95 0 5.12 2.78 9.59 6.9 12.02.78-1.32 1.63-2.6 2.55-3.82a9.344 9.344 0 0 1-4.85-8.2c0-5.16 4.19-9.35 9.35-9.35 4.01 0 7.43 2.54 8.75 6.09 1.34-.73 2.74-1.39 4.17-1.97-2.07-5.1-7.08-8.72-12.92-8.72Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M375.17 182.99c-8.68 0-15.72 7.04-15.72 15.72 0 5.79 3.14 10.84 7.81 13.57.28-.53.57-1.04.88-1.55-4.12-2.43-6.9-6.9-6.9-12.02 0-7.69 6.26-13.95 13.95-13.95 5.84 0 10.85 3.61 12.92 8.72.55-.22 1.1-.44 1.66-.64-2.33-5.78-7.97-9.86-14.58-9.86Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M375.17 191.15c-4.17 0-7.57 3.4-7.57 7.57 0 2.96 1.71 5.52 4.2 6.77 1.11-1.35 2.31-2.62 3.58-3.82-.07 0-.14.02-.21.02a2.97 2.97 0 1 1 2.97-2.97c0 .22-.03.42-.07.63a40.88 40.88 0 0 1 4.28-2.98c-.99-3.02-3.84-5.21-7.19-5.21Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M375.17 189.37c-5.16 0-9.35 4.19-9.35 9.35 0 3.53 1.96 6.61 4.85 8.2.36-.48.74-.96 1.12-1.43-2.48-1.24-4.2-3.8-4.2-6.77 0-4.17 3.4-7.57 7.57-7.57 3.35 0 6.19 2.19 7.19 5.21.51-.31 1.04-.61 1.57-.9-1.32-3.55-4.74-6.09-8.75-6.09Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M375.17 197.52c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M378.14 198.72c0 .22-.03.42-.07.63-.93.74-1.83 1.51-2.69 2.32-.07 0-.14.02-.21.02a2.97 2.97 0 1 1 2.97-2.97Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M256.23 333.34c-.24.27-.48.53-.72.8 7.6 6.44 12.43 16.05 12.43 26.8 0 19.4-15.73 35.13-35.13 35.13s-35.13-15.73-35.13-35.13c0-4.19.74-8.21 2.08-11.94-1.38-.43-2.73-.93-4.06-1.47-1.52 4.19-2.35 8.7-2.35 13.41 0 21.75 17.7 39.45 39.45 39.45s39.45-17.7 39.45-39.45c0-6.08-1.38-11.84-3.85-16.98a41.51 41.51 0 0 1-12.19-10.61Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M200.96 387.11c3.71 4.51 8.37 8.22 13.67 10.83 1.13.56 2.29 1.06 3.48 1.51 2.79 1.06 5.71 1.84 8.75 2.28.71.1 1.43.19 2.15.25.6.05 1.19.1 1.79.13.63.03 1.26.05 1.9.05h.11c2.27 0 4.5-.19 6.67-.55.6-.1 1.2-.2 1.79-.33 2.18-.46 4.3-1.09 6.34-1.88.56-.22 1.12-.44 1.67-.68 2.02-.88 3.95-1.92 5.79-3.1.51-.33 1.02-.67 1.52-1.03a40.57 40.57 0 0 0 6.36-5.56c.42-.45.82-.91 1.22-1.37a40.728 40.728 0 0 0 5.61-8.52c.97-1.96 1.79-4 2.43-6.11.17-.57.34-1.14.49-1.72.55-2.11.93-4.29 1.14-6.51a36.209 36.209 0 0 0 .19-3.88c0-1.61-.1-3.19-.28-4.75-.07-.63-.16-1.25-.25-1.87a41.26 41.26 0 0 0-2.54-9.01c-.87-.42-1.72-.88-2.56-1.36a39.306 39.306 0 0 1 3.85 16.98c0 21.75-17.7 39.45-39.45 39.45s-39.45-17.7-39.45-39.45c0-4.71.83-9.22 2.35-13.41-.55-.22-1.1-.46-1.65-.7-.02.04-.03.09-.04.13-.31.87-.59 1.75-.84 2.65a41.13 41.13 0 0 0-1.59 11.33c0 4.59.76 9 2.15 13.13.23.69.48 1.36.74 2.03 1.12 2.82 2.53 5.5 4.21 7.98.71 1.05 1.47 2.07 2.28 3.04Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M232.81 394.28c18.39 0 33.35-14.96 33.35-33.35 0-10.22-4.63-19.38-11.89-25.5a53.804 53.804 0 0 1-3.6 3.32c6.48 5.22 10.62 13.21 10.62 22.18 0 15.73-12.75 28.48-28.48 28.48s-28.48-12.75-28.48-28.48c0-3.66.7-7.15 1.95-10.36-1.62-.29-3.22-.66-4.79-1.09a33.183 33.183 0 0 0-2.02 11.45c0 18.39 14.96 33.35 33.35 33.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M254.27 335.43c7.26 6.12 11.89 15.28 11.89 25.5 0 18.39-14.96 33.35-33.35 33.35s-33.35-14.96-33.35-33.35c0-4.02.72-7.88 2.02-11.45-.58-.16-1.15-.32-1.72-.49a35.01 35.01 0 0 0-2.08 11.94c0 19.4 15.73 35.13 35.13 35.13s35.13-15.73 35.13-35.13c0-10.74-4.83-20.36-12.43-26.8-.41.44-.82.87-1.24 1.3Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M232.81 387.63c14.72 0 26.7-11.98 26.7-26.7 0-8.53-4.03-16.13-10.27-21.02-1.26.99-2.57 1.93-3.91 2.81 5.79 3.98 9.59 10.66 9.59 18.22 0 12.21-9.9 22.1-22.1 22.1s-22.1-9.9-22.1-22.1c0-3.43.78-6.68 2.18-9.57-1.62-.09-3.22-.25-4.8-.48-1.27 3.11-1.97 6.5-1.97 10.06 0 14.72 11.98 26.7 26.7 26.7Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.81 389.41c15.73 0 28.48-12.75 28.48-28.48 0-8.97-4.15-16.96-10.62-22.18-.47.39-.95.78-1.43 1.16 6.25 4.89 10.27 12.49 10.27 21.02 0 14.72-11.98 26.7-26.7 26.7s-26.7-11.98-26.7-26.7c0-3.56.7-6.95 1.97-10.06-.6-.09-1.2-.19-1.8-.3-1.25 3.21-1.95 6.7-1.95 10.36 0 15.73 12.75 28.48 28.48 28.48Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M232.81 381.26c11.21 0 20.32-9.12 20.32-20.32 0-7.22-3.78-13.56-9.47-17.17-1.59.95-3.23 1.82-4.92 2.61 5.74 2.34 9.79 7.97 9.79 14.56 0 8.68-7.04 15.72-15.72 15.72s-15.72-7.04-15.72-15.72c0-3.66 1.26-7.02 3.36-9.69-1.5.12-3.01.2-4.54.2-.35 0-.7-.02-1.05-.03a20.21 20.21 0 0 0-2.37 9.51c0 11.21 9.12 20.32 20.32 20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.81 383.03c12.21 0 22.1-9.9 22.1-22.1 0-7.56-3.8-14.23-9.59-18.22-.55.36-1.1.71-1.66 1.05 5.68 3.61 9.47 9.95 9.47 17.17 0 11.21-9.12 20.32-20.32 20.32s-20.32-9.12-20.32-20.32c0-3.43.86-6.67 2.37-9.51-.66-.01-1.32-.03-1.97-.06-1.39 2.9-2.18 6.14-2.18 9.57 0 12.21 9.9 22.1 22.1 22.1Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M223.08 350.96c4.62-.61 9.05-1.81 13.22-3.51 6 1.56 10.45 7 10.45 13.48 0 7.69-6.26 13.95-13.95 13.95s-13.95-6.26-13.95-13.95c0-3.91 1.62-7.44 4.22-9.97Zm.38 9.97c0 5.16 4.19 9.35 9.35 9.35s9.35-4.19 9.35-9.35-4.19-9.35-9.35-9.35-9.35 4.19-9.35 9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.81 376.66c8.68 0 15.72-7.04 15.72-15.72 0-6.58-4.05-12.21-9.79-14.56-.8.38-1.62.73-2.45 1.07 6 1.56 10.45 7 10.45 13.48 0 7.69-6.26 13.95-13.95 13.95s-13.95-6.26-13.95-13.95c0-3.91 1.62-7.44 4.22-9.97-.87.12-1.76.21-2.64.29-2.1 2.67-3.36 6.03-3.36 9.69 0 8.68 7.04 15.72 15.72 15.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M232.81 353.37c4.17 0 7.57 3.4 7.57 7.57s-3.4 7.57-7.57 7.57-7.57-3.4-7.57-7.57 3.4-7.57 7.57-7.57Zm0 4.6a2.97 2.97 0 1 0 0 5.94 2.97 2.97 0 0 0 0-5.94Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.81 351.59c5.16 0 9.35 4.19 9.35 9.35s-4.19 9.35-9.35 9.35-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35Zm0 1.78c-4.17 0-7.57 3.4-7.57 7.57s3.4 7.57 7.57 7.57 7.57-3.4 7.57-7.57-3.4-7.57-7.57-7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M231.62 360.93c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.81 357.96a2.97 2.97 0 1 1 0 5.94 2.97 2.97 0 0 1 0-5.94Zm-1.19 2.97c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M360.65 155.4c0 14.98-7.17 28.28-18.25 36.67 1.79 1.17 3.52 2.45 5.15 3.82 11.77-9.58 19.31-24.17 19.31-40.49s-6.95-29.74-17.93-39.32c-2.1 1.05-4.3 1.92-6.58 2.61 11.11 8.39 18.3 21.71 18.3 36.71ZM262.47 155.4c0 4.35.54 8.57 1.55 12.61 1.86-.96 3.79-1.78 5.8-2.46-.74-3.27-1.14-6.66-1.14-10.15 0-4 .51-7.88 1.47-11.57a52.82 52.82 0 0 1-5.87-2.04 51.866 51.866 0 0 0-1.81 13.62Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M260.87 159.54c.08 1.11.2 2.22.35 3.31.28 2.05.67 4.07 1.18 6.04.53-.31 1.08-.6 1.63-.89a51.91 51.91 0 0 1-1.55-12.61c0-4.71.64-9.27 1.81-13.62-.56-.23-1.1-.48-1.65-.73a53.91 53.91 0 0 0-1.94 14.35c0 1.39.07 2.77.17 4.14ZM366.86 155.4c0 16.32-7.54 30.92-19.31 40.49.47.39.93.79 1.39 1.2a54.47 54.47 0 0 0 5.07-4.77c3.26-3.47 6.06-7.36 8.32-11.59 1.17-2.2 2.2-4.49 3.06-6.85.26-.71.5-1.44.73-2.17.56-1.78 1.04-3.6 1.42-5.45.13-.63.23-1.27.34-1.91.25-1.48.43-2.98.55-4.5.05-.61.1-1.22.13-1.83.02-.49.03-.99.04-1.49 0-.37.03-.75.03-1.12 0-.27-.02-.53-.02-.79 0-.65-.02-1.3-.05-1.94-.03-.63-.09-1.26-.14-1.89-.13-1.55-.33-3.08-.59-4.59-.1-.59-.2-1.17-.32-1.75-.31-1.51-.7-3-1.13-4.47-.17-.57-.35-1.14-.54-1.7-.49-1.47-1.04-2.92-1.66-4.34-.24-.55-.49-1.1-.74-1.64-.67-1.42-1.41-2.8-2.2-4.15-.31-.53-.63-1.05-.95-1.56-.9-1.42-1.87-2.8-2.89-4.13-.38-.49-.76-.99-1.16-1.46-.97-1.17-2-2.3-3.07-3.39-.45-.46-.91-.9-1.37-1.34-.38-.36-.77-.73-1.16-1.08-.56.31-1.13.62-1.71.9 10.97 9.57 17.93 23.65 17.93 39.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M351.94 155.4c0 13.63-7.32 25.55-18.24 32.05 2.45 1.03 4.81 2.23 7.07 3.59 10.96-8.05 18.1-21.03 18.1-35.64s-7.42-28.1-18.76-36.11c-2.97.71-6.06 1.09-9.23 1.12-.23.37-.46.73-.7 1.09 12.84 5.89 21.77 18.85 21.77 33.9ZM270.47 155.4c0 3.3.38 6.52 1.07 9.62 2.19-.63 4.46-1.09 6.79-1.35-.6-2.66-.93-5.43-.93-8.27 0-3.42.47-6.72 1.33-9.86-2.33-.27-4.61-.68-6.84-1.24-.92 3.55-1.41 7.27-1.41 11.1Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M268.69 155.4c0 3.49.4 6.88 1.14 10.15.56-.19 1.13-.37 1.71-.53-.69-3.1-1.07-6.31-1.07-9.62 0-3.83.49-7.55 1.41-11.1-.58-.14-1.15-.31-1.72-.47-.96 3.7-1.47 7.58-1.47 11.57ZM358.87 155.4c0 14.61-7.13 27.59-18.1 35.64.55.33 1.09.68 1.62 1.03 11.09-8.4 18.25-21.7 18.25-36.67s-7.19-28.32-18.3-36.71c-.74.22-1.49.42-2.24.6 11.34 8.01 18.76 21.21 18.76 36.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M280.12 163.52c.93-.06 1.87-.11 2.82-.11 1.34 0 2.67.07 3.98.2-.77-2.6-1.19-5.35-1.19-8.2 0-3.35.57-6.55 1.62-9.54-.77.03-1.55.06-2.33.06-1.52 0-3.01-.08-4.5-.2a35.22 35.22 0 0 0-1.35 9.69c0 2.79.33 5.51.95 8.11ZM343.6 155.4c0 14.08-10.06 25.81-23.38 28.39 3.93.53 7.74 1.48 11.36 2.82 11.07-6.02 18.6-17.75 18.6-31.21 0-14.42-8.64-26.84-21.02-32.4a53.291 53.291 0 0 1-4.27 5.33c10.94 4.13 18.72 14.69 18.72 27.07Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M278.32 163.67c.59-.07 1.19-.12 1.79-.16-.61-2.61-.95-5.32-.95-8.11 0-3.36.48-6.6 1.35-9.69-.6-.05-1.21-.11-1.8-.18-.86 3.14-1.33 6.45-1.33 9.86 0 2.84.33 5.61.93 8.27ZM350.16 155.4c0 13.46-7.53 25.19-18.6 31.21.72.27 1.43.55 2.13.84 10.92-6.5 18.24-18.42 18.24-32.05 0-15.05-8.93-28.01-21.77-33.9-.33.51-.68 1.01-1.02 1.5 12.37 5.55 21.02 17.98 21.02 32.4Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M335.25 155.4c0 11.37-9.22 20.58-20.58 20.58-.75 0-1.48-.04-2.21-.12 1.94 1.99 3.68 4.17 5.18 6.52 13.58-1.49 24.18-13.02 24.18-26.98 0-11.87-7.66-21.97-18.29-25.66-1.87 1.9-3.87 3.67-6 5.28 10.01 1.4 17.72 9.99 17.72 20.38ZM288.87 163.84c2.79.4 5.49 1.08 8.07 2.01-1.81-3.06-2.85-6.63-2.85-10.45s1.14-7.72 3.11-10.88c-2.57.59-5.2 1-7.89 1.21a27.005 27.005 0 0 0-1.79 9.67c0 2.95.48 5.78 1.35 8.44Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M286.92 163.61c.65.06 1.3.14 1.94.23-.87-2.66-1.35-5.49-1.35-8.44 0-3.41.64-6.66 1.79-9.67-.65.05-1.29.1-1.95.13-1.04 2.99-1.62 6.2-1.62 9.54 0 2.85.42 5.6 1.19 8.2ZM341.82 155.4c0 13.97-10.6 25.5-24.18 26.98.25.39.5.78.73 1.18.62.06 1.23.15 1.84.23 13.32-2.59 23.38-14.31 23.38-28.39 0-12.38-7.78-22.94-18.72-27.07-.44.48-.89.95-1.35 1.41 10.63 3.68 18.29 13.79 18.29 25.66Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M295.86 155.4c0-4.36 1.51-8.38 4.01-11.57a53.728 53.728 0 0 0 15.42-7.2c10.08.34 18.17 8.62 18.17 18.77s-8.44 18.8-18.8 18.8c-1.58 0-3.12-.22-4.59-.59a41.359 41.359 0 0 0-10.12-6.53c-2.56-3.21-4.1-7.27-4.1-11.69Zm6.57 0c0 6.76 5.48 12.24 12.24 12.24s12.24-5.48 12.24-12.24-5.48-12.24-12.24-12.24-12.24 5.48-12.24 12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M296.93 165.85c1.03.37 2.04.79 3.03 1.24-2.56-3.21-4.1-7.27-4.1-11.69s1.51-8.38 4.01-11.57c-.88.25-1.78.48-2.68.69-1.97 3.16-3.11 6.88-3.11 10.88s1.04 7.38 2.85 10.45ZM333.47 155.4c0 10.37-8.44 18.8-18.8 18.8-1.58 0-3.12-.22-4.59-.59.82.72 1.61 1.47 2.38 2.25.73.08 1.46.12 2.21.12 11.37 0 20.58-9.22 20.58-20.58 0-10.39-7.71-18.98-17.72-20.38-.73.55-1.48 1.09-2.24 1.61 10.08.34 18.17 8.62 18.17 18.77Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M304.21 155.4c0-5.77 4.69-10.46 10.46-10.46s10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46-10.46-4.69-10.46-10.46Zm6.57 0c0 2.15 1.74 3.89 3.89 3.89s3.89-1.74 3.89-3.89-1.74-3.89-3.89-3.89-3.89 1.74-3.89 3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M302.43 155.4c0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24-12.24-5.48-12.24-12.24Zm1.78 0c0 5.77 4.69 10.46 10.46 10.46s10.46-4.69 10.46-10.46-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M314.67 157.51c1.16 0 2.11-.95 2.11-2.11s-.95-2.11-2.11-2.11-2.11.95-2.11 2.11.95 2.11 2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M310.78 155.4c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89-1.74 3.89-3.89 3.89-3.89-1.74-3.89-3.89Zm1.78 0c0 1.16.95 2.11 2.11 2.11s2.11-.95 2.11-2.11-.95-2.11-2.11-2.11-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M371.15 358.87c0 19.4-15.73 35.13-35.13 35.13s-35.13-15.73-35.13-35.13c0-4.2.74-8.23 2.09-11.97a41.52 41.52 0 0 1-4.99 1.46c-.93 3.35-1.43 6.87-1.43 10.51 0 21.75 17.7 39.45 39.45 39.45s39.45-17.7 39.45-39.45c0-2.61-.26-5.15-.75-7.62-1.37.46-2.76.88-4.17 1.24.38 2.07.59 4.2.59 6.38Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M374.73 351.25c.48 2.47.75 5.01.75 7.62 0 21.75-17.7 39.45-39.45 39.45s-39.45-17.7-39.45-39.45c0-3.64.51-7.16 1.43-10.51-.65.15-1.3.28-1.96.39-.51 2.01-.86 4.07-1.06 6.18-.07.73-.12 1.46-.15 2.2-.02.58-.04 1.15-.04 1.74 0 3.18.37 6.26 1.05 9.24.53 2.33 1.25 4.59 2.16 6.75.35.83.73 1.65 1.14 2.45a40.75 40.75 0 0 0 3.74 6.09 41.194 41.194 0 0 0 9.42 9.18c1.89 1.33 3.89 2.52 5.99 3.52.56.27 1.13.53 1.7.77a41.116 41.116 0 0 0 16.03 3.23c1.23 0 2.44-.06 3.64-.17.96-.08 1.91-.2 2.85-.34a40.634 40.634 0 0 0 9.64-2.77c1.72-.73 3.38-1.58 4.97-2.53.55-.33 1.09-.67 1.63-1.02 2.26-1.5 4.36-3.2 6.28-5.1.58-.57 1.13-1.16 1.67-1.76a41.07 41.07 0 0 0 10.53-26.6c0-.31.02-.62.02-.93 0-.51-.02-1.02-.04-1.52-.08-2.29-.34-4.53-.78-6.7-.56.21-1.13.41-1.7.6Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M364.5 358.87c0 15.73-12.75 28.48-28.48 28.48s-28.48-12.75-28.48-28.48c0-6.84 2.41-13.11 6.43-18.02a41.26 41.26 0 0 1-8.7 5.14 33.133 33.133 0 0 0-2.59 12.88c0 18.39 14.96 33.35 33.35 33.35s33.35-14.96 33.35-33.35c0-2.04-.19-4.04-.55-5.98-1.57.34-3.18.61-4.8.81.31 1.68.48 3.41.48 5.17Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M369.37 358.87c0 18.39-14.96 33.35-33.35 33.35s-33.35-14.96-33.35-33.35c0-4.57.92-8.92 2.59-12.88-.75.32-1.51.63-2.28.91a35.188 35.188 0 0 0-2.09 11.97c0 19.4 15.73 35.13 35.13 35.13s35.13-15.73 35.13-35.13c0-2.18-.21-4.31-.59-6.38-.58.14-1.16.28-1.74.4.35 1.94.55 3.94.55 5.98Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M309.32 358.87c0 14.72 11.98 26.7 26.7 26.7s26.7-11.98 26.7-26.7c0-1.7-.17-3.37-.47-4.98-1.53.14-3.08.22-4.64.22.34 1.53.52 3.12.52 4.76 0 12.21-9.9 22.1-22.1 22.1s-22.1-9.9-22.1-22.1c0-7.13 3.38-13.47 8.62-17.51-1.18-1-2.33-2.04-3.42-3.14-5.98 4.9-9.8 12.34-9.8 20.65Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M313.97 340.85a28.337 28.337 0 0 0-6.43 18.02c0 15.73 12.75 28.48 28.48 28.48s28.48-12.75 28.48-28.48c0-1.77-.17-3.49-.48-5.17-.59.07-1.18.14-1.77.19.31 1.61.47 3.28.47 4.98 0 14.72-11.98 26.7-26.7 26.7s-26.7-11.98-26.7-26.7c0-8.31 3.82-15.75 9.8-20.65-.34-.34-.66-.69-.98-1.03-1.3 1.31-2.7 2.54-4.17 3.67Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M315.7 358.87c0 11.21 9.12 20.32 20.32 20.32s20.32-9.12 20.32-20.32c0-1.65-.2-3.25-.58-4.79a56.47 56.47 0 0 1-4.9-.36c.56 1.62.88 3.35.88 5.16 0 8.68-7.04 15.72-15.72 15.72s-15.72-7.04-15.72-15.72c0-5.73 3.08-10.73 7.66-13.48a51.71 51.71 0 0 1-3.99-2.87c-5.01 3.7-8.27 9.65-8.27 16.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M313.92 358.87c0 12.21 9.9 22.1 22.1 22.1s22.1-9.9 22.1-22.1c0-1.63-.18-3.22-.52-4.76h-.23c-.54 0-1.07-.02-1.61-.04.37 1.54.58 3.14.58 4.79 0 11.21-9.12 20.32-20.32 20.32s-20.32-9.12-20.32-20.32c0-6.7 3.26-12.64 8.27-16.35-.48-.38-.96-.76-1.43-1.16-5.24 4.04-8.62 10.38-8.62 17.51Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M322.08 358.87c0 7.69 6.26 13.95 13.95 13.95s13.95-6.26 13.95-13.95c0-1.92-.39-3.76-1.1-5.42-2.23-.35-4.42-.85-6.55-1.47a9.316 9.316 0 0 1 3.05 6.9c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35 3.97-9.12 8.95-9.33c-2.05-.91-4.04-1.93-5.95-3.07-4.5 2.31-7.6 7-7.6 12.4Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M320.3 358.87c0 8.68 7.04 15.72 15.72 15.72s15.72-7.04 15.72-15.72c0-1.81-.32-3.54-.88-5.16-.67-.08-1.33-.16-2-.27.71 1.67 1.1 3.5 1.1 5.42 0 7.69-6.26 13.95-13.95 13.95s-13.95-6.26-13.95-13.95c0-5.4 3.09-10.09 7.6-12.4-.58-.35-1.15-.71-1.72-1.08-4.58 2.75-7.66 7.75-7.66 13.48Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M328.45 358.87c0-4.17 3.4-7.57 7.57-7.57s7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57-7.57-3.4-7.57-7.57Zm4.6 0a2.97 2.97 0 1 0 5.94 0 2.97 2.97 0 0 0-5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M335.63 349.54c2.16.95 4.39 1.76 6.69 2.43a9.316 9.316 0 0 1 3.05 6.9c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35 3.97-9.12 8.95-9.33Zm-7.17 9.33c0 4.17 3.4 7.57 7.57 7.57s7.57-3.4 7.57-7.57-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M336.02 360.06c.66 0 1.19-.53 1.19-1.19s-.53-1.19-1.19-1.19-1.19.53-1.19 1.19.53 1.19 1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M333.05 358.87a2.97 2.97 0 1 1 5.94 0 2.97 2.97 0 0 1-5.94 0Zm1.78 0c0 .66.53 1.19 1.19 1.19s1.19-.53 1.19-1.19-.53-1.19-1.19-1.19-1.19.53-1.19 1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M369.99 79.19c0-21.75-17.7-39.45-39.45-39.45-7 0-13.57 1.84-19.28 5.05 1.46.82 2.89 1.7 4.27 2.64a35.05 35.05 0 0 1 15.01-3.36c19.4 0 35.13 15.73 35.13 35.13 0 18.15-13.77 33.09-31.44 34.93-.69 1.53-1.45 3.03-2.28 4.48 21.1-.75 38.04-18.13 38.04-39.42Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M327.14 38.11c-1.94.16-3.83.45-5.68.86-4.32.97-8.38 2.61-12.07 4.82.63.32 1.26.65 1.87.99 5.7-3.21 12.28-5.05 19.28-5.05 21.75 0 39.45 17.7 39.45 39.45s-16.94 38.67-38.04 39.42c-.35.61-.7 1.21-1.07 1.81a41.272 41.272 0 0 0 11.47-1.72c2.28-.68 4.49-1.56 6.58-2.61.58-.29 1.15-.59 1.71-.9a40.852 40.852 0 0 0 5.69-3.84c2.02-1.62 3.89-3.44 5.57-5.41.49-.57.97-1.16 1.42-1.76 2.51-3.28 4.53-6.95 5.97-10.9.88-2.42 1.54-4.95 1.95-7.56a40.936 40.936 0 0 0 .09-12.41c-.11-.76-.23-1.5-.38-2.25-.29-1.43-.64-2.84-1.07-4.21a40.465 40.465 0 0 0-4.37-9.49A41.435 41.435 0 0 0 348.39 42c-.83-.4-1.68-.76-2.53-1.1-1.72-.69-3.5-1.27-5.33-1.73-.67-.17-1.35-.32-2.04-.45-2.57-.5-5.23-.77-7.95-.77-1.14 0-2.28.06-3.4.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M363.88 79.19c0-18.39-14.96-33.35-33.35-33.35-4.73 0-9.24 1-13.32 2.79 1.49 1.11 2.92 2.3 4.29 3.55a28.39 28.39 0 0 1 9.03-1.47c15.73 0 28.48 12.75 28.48 28.48 0 13.55-9.47 24.88-22.15 27.76-.52 1.8-1.13 3.56-1.82 5.27 16.26-2.21 28.84-16.18 28.84-33.03Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M365.66 79.19c0-19.4-15.73-35.13-35.13-35.13a35.05 35.05 0 0 0-15.01 3.36c.57.39 1.13.79 1.69 1.2 4.08-1.79 8.59-2.79 13.32-2.79 18.39 0 33.35 14.96 33.35 33.35 0 16.86-12.58 30.83-28.84 33.03-.26.64-.53 1.27-.82 1.9 17.66-1.84 31.44-16.78 31.44-34.93Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M357.23 79.19c0-14.72-11.98-26.7-26.7-26.7-2.62 0-5.16.39-7.56 1.1 1.24 1.23 2.43 2.52 3.55 3.87 1.3-.24 2.64-.37 4.01-.37 12.21 0 22.1 9.89 22.1 22.1 0 9.43-5.91 17.48-14.22 20.65-.26 1.75-.6 3.46-1.02 5.15 11.41-3.04 19.84-13.45 19.84-25.8Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M359.01 79.19c0-15.73-12.75-28.48-28.48-28.48-3.16 0-6.19.52-9.03 1.47.5.46.99.93 1.47 1.4 2.4-.71 4.93-1.1 7.56-1.1 14.72 0 26.7 11.98 26.7 26.7 0 12.35-8.43 22.76-19.84 25.8-.16.66-.35 1.31-.53 1.96 12.68-2.88 22.15-14.21 22.15-27.76Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M350.86 79.19c0-11.21-9.12-20.32-20.32-20.32-.93 0-1.84.07-2.74.19 1.09 1.42 2.12 2.9 3.07 4.42 8.53.18 15.39 7.14 15.39 15.71 0 5.57-2.91 10.46-7.28 13.25-.02 1.81-.12 3.6-.31 5.37 7.17-3.14 12.19-10.3 12.19-18.62Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M352.64 79.19c0-12.21-9.9-22.1-22.1-22.1-1.37 0-2.71.13-4.01.37.44.52.85 1.06 1.27 1.6.9-.12 1.81-.19 2.74-.19 11.21 0 20.32 9.12 20.32 20.32 0 8.31-5.02 15.47-12.19 18.62-.07.68-.16 1.36-.26 2.03 8.31-3.17 14.22-11.22 14.22-20.65Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M344.48 79.19c0-7.21-5.5-13.16-12.52-13.87 1.01 1.78 1.94 3.62 2.74 5.51a9.338 9.338 0 0 1 5.18 8.36c0 1.84-.54 3.55-1.45 4.99.29 2 .46 4.04.52 6.1 3.35-2.55 5.53-6.57 5.53-11.09Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M346.26 79.19c0-8.57-6.86-15.53-15.39-15.71.38.6.73 1.22 1.09 1.83 7.02.72 12.52 6.66 12.52 13.87 0 4.53-2.18 8.54-5.53 11.09.02.55.04 1.11.04 1.67 0 .16-.01.33-.01.49 4.37-2.79 7.28-7.68 7.28-13.25Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M335.86 73.82c.83 2.32 1.51 4.71 2.01 7.17.14-.58.23-1.18.23-1.8 0-2.1-.86-4-2.24-5.37Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M334.7 70.83c.42.98.8 1.98 1.16 2.99a7.548 7.548 0 0 1 2.24 5.37c0 .62-.08 1.22-.23 1.8.22 1.05.4 2.12.55 3.19a9.254 9.254 0 0 0 1.45-4.99c0-3.66-2.11-6.83-5.18-8.36Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M366.86 237.26c0 2.47-.18 4.9-.5 7.28.3.87.62 1.73.98 2.57 1.84.34 3.65.78 5.41 1.3a35.015 35.015 0 0 1-4.24-16.74c0-19.4 15.73-35.13 35.13-35.13s35.13 15.73 35.13 35.13-15.73 35.13-35.13 35.13c-1.36 0-2.71-.09-4.03-.24 1.16 1.46 2.24 2.97 3.25 4.54.26 0 .52.02.78.02 21.75 0 39.45-17.7 39.45-39.45s-17.7-39.45-39.45-39.45c-18.93 0-34.78 13.4-38.58 31.21 1.17 4.41 1.8 9.05 1.8 13.83Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M444.53 226.46a41.02 41.02 0 0 0-1.97-8.39c-.26-.74-.53-1.48-.83-2.2a39.18 39.18 0 0 0-2.24-4.55c-.32-.57-.66-1.13-1.01-1.68 0 0 0-.01-.01-.02-.43-.68-.88-1.34-1.34-1.99-5.17-7.18-12.58-12.64-21.22-15.33-.63-.2-1.27-.38-1.91-.54-1.5-.39-3.03-.68-4.59-.9-.62-.09-1.25-.16-1.88-.22-1.28-.12-2.57-.19-3.88-.19-.41 0-.81.02-1.21.03-.62.02-1.23.05-1.84.09-1.57.12-3.12.32-4.64.6-.59.11-1.18.24-1.77.38-1.51.35-2.99.79-4.43 1.3-.56.2-1.11.42-1.66.64-1.43.58-2.82 1.24-4.17 1.97-.53.29-1.05.59-1.57.9-1.49.9-2.92 1.9-4.28 2.98-.93.74-1.83 1.51-2.69 2.32-1.27 1.2-2.47 2.47-3.58 3.82-.39.47-.76.95-1.12 1.43-.92 1.22-1.77 2.5-2.55 3.82-.3.51-.6 1.03-.88 1.55-.72 1.35-1.37 2.76-1.94 4.19a42.63 42.63 0 0 0-1.25 3.64c.36 1.09.7 2.19 1 3.31 3.8-17.81 19.65-31.21 38.58-31.21 21.75 0 39.45 17.7 39.45 39.45s-17.7 39.45-39.45 39.45c-.26 0-.52-.01-.78-.02.38.59.74 1.19 1.1 1.79a41.103 41.103 0 0 0 12.66-2.09 41.061 41.061 0 0 0 15.04-8.89c.85-.78 1.66-1.6 2.44-2.45a41.969 41.969 0 0 0 6.47-9.43 40.33 40.33 0 0 0 2.99-7.97c.26-1.02.48-2.05.67-3.1.42-2.37.65-4.8.65-7.29 0-1.77-.12-3.5-.34-5.21ZM367.34 247.11c-.36-.84-.68-1.7-.98-2.57-.11.78-.23 1.56-.37 2.33.45.07.9.16 1.35.24Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M397.89 264.52a54.013 54.013 0 0 0-22.58-15.27 33.123 33.123 0 0 1-5.02-17.57c0-18.39 14.96-33.35 33.35-33.35s33.35 14.96 33.35 33.35-14.96 33.35-33.35 33.35c-1.96 0-3.88-.18-5.75-.51Zm5.75-4.36c15.73 0 28.48-12.75 28.48-28.48s-12.75-28.48-28.48-28.48-28.48 12.75-28.48 28.48 12.75 28.48 28.48 28.48Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M375.32 249.24a33.123 33.123 0 0 1-5.02-17.57c0-18.39 14.96-33.35 33.35-33.35S437 213.28 437 231.67s-14.96 33.35-33.35 33.35c-1.96 0-3.88-.18-5.75-.51.59.67 1.16 1.35 1.71 2.05 1.32.15 2.67.24 4.03.24 19.4 0 35.13-15.73 35.13-35.13s-15.73-35.13-35.13-35.13-35.13 15.73-35.13 35.13c0 6.06 1.53 11.76 4.24 16.74.86.26 1.72.53 2.56.83Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M376.94 231.67c0-14.72 11.98-26.7 26.7-26.7s26.7 11.98 26.7 26.7-11.98 26.7-26.7 26.7-26.7-11.98-26.7-26.7Zm4.6 0c0 12.21 9.9 22.1 22.1 22.1s22.1-9.89 22.1-22.1-9.9-22.1-22.1-22.1-22.1 9.9-22.1 22.1Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M375.16 231.67c0-15.73 12.75-28.48 28.48-28.48s28.48 12.75 28.48 28.48-12.75 28.48-28.48 28.48-28.48-12.75-28.48-28.48Zm28.48 26.7c14.72 0 26.7-11.98 26.7-26.7s-11.98-26.7-26.7-26.7-26.7 11.98-26.7 26.7 11.98 26.7 26.7 26.7Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M403.64 211.35c11.21 0 20.32 9.12 20.32 20.32s-9.12 20.32-20.32 20.32-20.32-9.12-20.32-20.32 9.12-20.32 20.32-20.32Zm-15.72 20.32c0 8.68 7.04 15.72 15.72 15.72s15.72-7.04 15.72-15.72-7.04-15.72-15.72-15.72-15.72 7.04-15.72 15.72Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M381.54 231.67c0-12.21 9.9-22.1 22.1-22.1s22.1 9.9 22.1 22.1-9.9 22.1-22.1 22.1-22.1-9.89-22.1-22.1Zm1.78 0c0 11.21 9.12 20.32 20.32 20.32s20.32-9.12 20.32-20.32-9.12-20.32-20.32-20.32-20.32 9.12-20.32 20.32Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M403.64 217.73c7.69 0 13.95 6.26 13.95 13.95s-6.26 13.95-13.95 13.95-13.95-6.26-13.95-13.95 6.26-13.95 13.95-13.95Zm-9.35 13.95c0 5.16 4.19 9.35 9.35 9.35s9.35-4.19 9.35-9.35-4.19-9.35-9.35-9.35-9.35 4.19-9.35 9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M403.64 215.95c8.68 0 15.72 7.04 15.72 15.72s-7.04 15.72-15.72 15.72-15.72-7.04-15.72-15.72 7.04-15.72 15.72-15.72Zm-13.95 15.72c0 7.69 6.26 13.95 13.95 13.95s13.95-6.26 13.95-13.95-6.26-13.95-13.95-13.95-13.95 6.26-13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M403.64 239.24c-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57 7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57Zm2.97-7.57a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M403.64 222.33c5.16 0 9.35 4.19 9.35 9.35s-4.19 9.35-9.35 9.35-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35Zm0 16.92c4.17 0 7.57-3.4 7.57-7.57s-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57 3.4 7.57 7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M403.64 230.48c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M406.61 231.67a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M261.71 268.94c1.1 1.77 2.11 3.61 3.01 5.5 1.81-1.43 3.53-2.96 5.14-4.61a51.95 51.95 0 0 1-3.52-5.27 45.666 45.666 0 0 1-4.63 4.38ZM180.43 236.46c.35 6.06 1.74 11.86 3.99 17.2a54.58 54.58 0 0 1 5.33-3.39 45.769 45.769 0 0 1-3.15-14.99c-2.01.51-4.07.9-6.16 1.18ZM232.53 181.22c-1.82 0-3.62.1-5.39.28.01.49.04.99.04 1.48 0 1.62-.08 3.22-.22 4.8 1.83-.22 3.69-.35 5.58-.35 4.23 0 8.33.58 12.22 1.66.8-1.96 1.74-3.85 2.82-5.65a51.987 51.987 0 0 0-15.05-2.22Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M239.38 179.89a53.49 53.49 0 0 0-12.31-.18c.04.59.05 1.19.07 1.78a51.987 51.987 0 0 1 20.44 1.94c.32-.53.65-1.06 1-1.58-.39-.12-.78-.22-1.17-.33-2.6-.74-5.27-1.3-8.01-1.64ZM264.72 274.45c.03.07.07.15.1.22 1.89-1.36 3.9-2.57 6.01-3.6-.33-.41-.65-.82-.97-1.23a52.199 52.199 0 0 1-5.14 4.61ZM180.43 236.46c-.59.08-1.17.16-1.77.22v.02c.16 2.66.51 5.27 1.04 7.82a52.3 52.3 0 0 0 2.18 7.58c.29.79.6 1.58.93 2.35.04.1.09.2.14.31.48-.37.98-.74 1.47-1.1a51.923 51.923 0 0 1-3.99-17.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M195.28 232.23c-2.23 1-4.54 1.86-6.92 2.55.16 5.15 1.2 10.08 2.98 14.64 2.04-1.05 4.16-1.97 6.34-2.76a37.107 37.107 0 0 1-2.43-13.25c0-.4.02-.79.03-1.19ZM226.75 189.61c-.3 2.46-.76 4.88-1.38 7.23 2.32-.45 4.71-.69 7.16-.69 3.4 0 6.69.46 9.82 1.32.4-2.3.99-4.54 1.76-6.69-3.69-1-7.57-1.55-11.58-1.55-1.96 0-3.88.14-5.78.39ZM256.51 261.95c1.52 1.73 2.93 3.55 4.22 5.47 1.66-1.38 3.22-2.88 4.67-4.49a53.83 53.83 0 0 1-3.16-7c-1.68 2.21-3.6 4.23-5.72 6.02Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M226.94 187.79c-.05.61-.12 1.22-.19 1.82 1.89-.25 3.82-.39 5.78-.39 4.01 0 7.89.55 11.58 1.55.2-.56.42-1.12.64-1.67a46.036 46.036 0 0 0-12.22-1.66c-1.89 0-3.75.13-5.58.35ZM260.73 267.42c.34.5.66 1.01.98 1.53 1.64-1.35 3.19-2.82 4.63-4.38-.32-.54-.64-1.09-.94-1.64-1.44 1.61-3 3.11-4.67 4.49ZM186.59 235.28c.21 5.28 1.31 10.32 3.15 14.99.52-.29 1.06-.57 1.59-.84a43.999 43.999 0 0 1-2.98-14.64c-.59.17-1.17.34-1.77.5Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M250.51 256.07c1.69 1.41 3.29 2.94 4.8 4.54 2.35-1.97 4.44-4.25 6.22-6.76-1-3.11-1.72-6.33-2.15-9.66a29.058 29.058 0 0 1-8.86 11.88ZM224.82 198.77c-.82 2.69-1.85 5.3-3.06 7.8 3.33-1.34 6.96-2.08 10.77-2.08 3.22 0 6.32.54 9.22 1.51-.01-.45-.03-.9-.03-1.35 0-1.83.13-3.64.36-5.41a35.404 35.404 0 0 0-17.26-.46ZM204.3 227.08a54.444 54.444 0 0 1-7.21 4.3 35.346 35.346 0 0 0 2.29 14.72c2.08-.67 4.22-1.21 6.41-1.63a28.847 28.847 0 0 1-2.19-11.05c0-2.18.25-4.3.7-6.34Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M225.37 196.83c-.17.65-.35 1.3-.55 1.94a35.404 35.404 0 0 1 17.26.46c.08-.6.17-1.19.27-1.78-3.13-.85-6.42-1.32-9.82-1.32-2.45 0-4.84.24-7.16.69ZM197.09 231.38c-.6.3-1.2.58-1.81.85-.01.39-.03.79-.03 1.19 0 4.67.87 9.13 2.43 13.25.56-.2 1.13-.39 1.7-.57a35.346 35.346 0 0 1-2.29-14.72ZM255.31 260.61c.41.44.81.88 1.2 1.33 2.12-1.79 4.04-3.8 5.72-6.02-.25-.68-.48-1.38-.71-2.08a35.976 35.976 0 0 1-6.22 6.76Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M253.1 233.08v.34c0 7.43-3.93 13.93-9.83 17.55a54.35 54.35 0 0 1 5.8 3.95c4.89-3.77 8.46-9.17 9.9-15.38-.02-.46-.03-.92-.03-1.38-2.1-1.51-4.06-3.21-5.84-5.08ZM232.53 212.83c3.94 0 7.61 1.11 10.74 3.02a40.72 40.72 0 0 1-1.41-7.94 26.953 26.953 0 0 0-9.32-1.65c-4.35 0-8.46 1.03-12.11 2.86a54.28 54.28 0 0 1-13.82 16.24c-.79 2.54-1.22 5.25-1.22 8.05 0 3.81.79 7.44 2.21 10.73 2.29-.35 4.64-.55 7.02-.61a20.499 20.499 0 0 1-2.66-10.12c0-11.37 9.22-20.58 20.58-20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M221.75 206.57c-.42.87-.87 1.72-1.34 2.56 3.65-1.83 7.76-2.86 12.11-2.86 3.27 0 6.41.58 9.32 1.65-.05-.64-.09-1.28-.11-1.92-2.9-.97-5.99-1.51-9.22-1.51-3.81 0-7.44.74-10.77 2.08ZM206.59 225.37c-.75.59-1.51 1.16-2.29 1.71-.46 2.04-.7 4.16-.7 6.34 0 3.91.78 7.65 2.19 11.05.6-.11 1.2-.23 1.8-.32a27.021 27.021 0 0 1-2.21-10.73c0-2.8.43-5.51 1.22-8.05ZM249.08 254.92c.48.38.97.76 1.44 1.15 3.9-3.1 6.98-7.18 8.86-11.88-.2-1.53-.34-3.09-.4-4.66a27.206 27.206 0 0 1-9.9 15.38Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M251.33 233.42c0-.86-.08-1.7-.19-2.53a41.119 41.119 0 0 1-6.98-12.22c-3.2-2.53-7.24-4.06-11.63-4.06-10.37 0-18.8 8.44-18.8 18.8 0 3.72 1.1 7.19 2.97 10.11 4.16.06 8.21.59 12.09 1.54-4.93-1.58-8.5-6.2-8.5-11.65 0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24c-.82 0-1.62-.08-2.4-.24 3.99 1.09 7.79 2.61 11.36 4.53 5.86-3.19 9.85-9.4 9.85-16.53Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M253.11 233.42v-.34c-.68-.71-1.34-1.44-1.96-2.19.11.83.19 1.67.19 2.53 0 7.13-3.99 13.34-9.85 16.53.61.33 1.2.67 1.79 1.02 5.9-3.62 9.83-10.13 9.83-17.55ZM232.53 214.61c4.39 0 8.43 1.53 11.63 4.06-.33-.92-.63-1.87-.9-2.82-3.13-1.92-6.8-3.02-10.74-3.02-11.37 0-20.58 9.22-20.58 20.58 0 3.68.97 7.13 2.66 10.12.43-.01.86-.03 1.3-.03.27 0 .53.02.79.02a18.699 18.699 0 0 1-2.97-10.11c0-10.37 8.44-18.8 18.8-18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M232.53 222.96c5.77 0 10.46 4.69 10.46 10.46s-4.69 10.46-10.46 10.46-10.46-4.69-10.46-10.46 4.69-10.46 10.46-10.46Zm-3.89 10.46c0 2.15 1.74 3.89 3.89 3.89s3.89-1.74 3.89-3.89-1.74-3.89-3.89-3.89-3.89 1.74-3.89 3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M232.53 245.65c-.82 0-1.62-.08-2.4-.24-.45-.12-.89-.24-1.34-.35-4.93-1.58-8.5-6.2-8.5-11.65 0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24Zm0-22.69c-5.77 0-10.46 4.69-10.46 10.46s4.69 10.46 10.46 10.46 10.46-4.69 10.46-10.46-4.69-10.46-10.46-10.46Z"
        />
        <circle
          className="cls-Venn3-3-3-3"
          cx={232.53}
          cy={233.42}
          r={2.11}
          transform="rotate(-76.67 232.544 233.418)"
        />
        <path
          className="cls-Venn3-3-4"
          d="M228.64 233.42c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89-1.74 3.89-3.89 3.89-3.89-1.74-3.89-3.89Zm1.78 0c0 1.16.95 2.11 2.11 2.11s2.11-.95 2.11-2.11-.95-2.11-2.11-2.11-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M282.94 165.19c-21.75 0-39.45 17.7-39.45 39.45 0 12.73 6.07 24.07 15.46 31.29.04-1.78.17-3.54.38-5.28-7.08-6.43-11.52-15.7-11.52-26.01 0-19.4 15.73-35.13 35.13-35.13 11.39 0 21.5 5.42 27.92 13.82.67-.02 1.35-.05 2.03-.05 1.1 0 2.19.04 3.27.11-7.02-10.94-19.28-18.21-33.22-18.21Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M317.64 182.39a41.917 41.917 0 0 0-5.18-6.52c-.76-.78-1.55-1.53-2.38-2.25a41.359 41.359 0 0 0-10.12-6.53c-.99-.45-2-.87-3.03-1.24a40.906 40.906 0 0 0-10.01-2.24c-1.31-.13-2.64-.2-3.98-.2-.95 0-1.89.04-2.82.11a41.22 41.22 0 0 0-8.58 1.51c-.57.17-1.14.34-1.71.53-2.01.67-3.95 1.5-5.8 2.46a41.405 41.405 0 0 0-14.42 12.38c-.35.49-.7.98-1.03 1.48-.34.52-.68 1.04-1 1.58-1.08 1.8-2.02 3.69-2.82 5.65a40.355 40.355 0 0 0-2.4 8.36c-.1.59-.19 1.18-.27 1.78-.23 1.77-.36 3.57-.36 5.41 0 .45.02.9.03 1.35.02.64.06 1.28.11 1.92.21 2.73.69 5.38 1.41 7.94.27.95.56 1.89.9 2.82 1.62 4.48 4 8.61 6.98 12.22.63.76 1.28 1.48 1.96 2.19 1.78 1.87 3.73 3.57 5.84 5.08 0-.3-.02-.6-.02-.9 0-.45.02-.89.03-1.33-9.39-7.22-15.46-18.56-15.46-31.29 0-21.75 17.7-39.45 39.45-39.45 13.93 0 26.19 7.27 33.22 18.21.74.04 1.49.09 2.22.17-.24-.4-.48-.79-.73-1.18Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M282.94 171.3c-18.39 0-33.35 14.96-33.35 33.35 0 9.33 3.85 17.77 10.05 23.83.32-1.96.75-3.88 1.28-5.77a28.375 28.375 0 0 1-6.46-18.06c0-15.73 12.75-28.48 28.48-28.48 7.73 0 14.73 3.08 19.86 8.08 1.93-.36 3.89-.62 5.88-.78-6.12-7.43-15.39-12.17-25.74-12.17Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M282.94 169.52c-19.4 0-35.13 15.73-35.13 35.13 0 10.31 4.44 19.59 11.52 26.01.09-.73.19-1.46.31-2.19-6.19-6.06-10.05-14.5-10.05-23.83 0-18.39 14.96-33.35 33.35-33.35 10.35 0 19.62 4.74 25.74 12.17.72-.06 1.45-.1 2.18-.13-6.42-8.4-16.53-13.82-27.92-13.82Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M282.94 177.94c-14.72 0-26.7 11.98-26.7 26.7 0 5.98 1.98 11.5 5.31 15.96.59-1.81 1.27-3.57 2.03-5.29-1.75-3.16-2.74-6.8-2.74-10.67 0-12.21 9.9-22.1 22.1-22.1 4.54 0 8.76 1.37 12.28 3.72 1.77-.61 3.59-1.14 5.44-1.57-4.72-4.2-10.93-6.75-17.72-6.75Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M282.94 176.16c-15.73 0-28.48 12.75-28.48 28.48 0 6.85 2.42 13.14 6.46 18.06.2-.71.4-1.41.63-2.1a26.564 26.564 0 0 1-5.31-15.96c0-14.72 11.98-26.7 26.7-26.7 6.8 0 13 2.56 17.72 6.75.71-.16 1.42-.32 2.14-.45-5.13-5-12.13-8.08-19.86-8.08Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M262.62 204.64c0 3.08.69 6 1.93 8.62.91-1.83 1.92-3.6 3.03-5.31a15.78 15.78 0 0 1-.36-3.32c0-8.68 7.04-15.72 15.72-15.72 1.6 0 3.15.24 4.61.69 1.79-.95 3.65-1.8 5.55-2.56a20.222 20.222 0 0 0-10.15-2.73c-11.21 0-20.32 9.12-20.32 20.32Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M282.94 182.54c-12.21 0-22.1 9.89-22.1 22.1 0 3.87 1 7.51 2.74 10.67.31-.69.63-1.37.96-2.05a20.235 20.235 0 0 1-1.93-8.62c0-11.21 9.12-20.32 20.32-20.32 3.7 0 7.16 1 10.15 2.73.7-.28 1.41-.54 2.12-.79a21.988 21.988 0 0 0-12.28-3.72Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M268.99 204.64c0 .39.03.77.06 1.15 4.32-6 9.83-11.08 16.19-14.89a13.5 13.5 0 0 0-2.31-.21c-7.69 0-13.95 6.26-13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M282.94 188.92c-8.68 0-15.72 7.04-15.72 15.72 0 1.14.13 2.25.36 3.32.48-.74.97-1.46 1.48-2.17-.03-.38-.06-.76-.06-1.15 0-7.69 6.26-13.95 13.95-13.95.79 0 1.55.08 2.31.21.75-.45 1.52-.88 2.3-1.3-1.46-.45-3-.69-4.61-.69Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M267.15 280.55c2.42-1.91 5.1-3.51 7.98-4.74a51.805 51.805 0 0 1-3.12-3.33 39.033 39.033 0 0 0-6.44 3.85c.59 1.38 1.11 2.78 1.58 4.22ZM288.86 343.28c-11.87 0-22.36-5.89-28.72-14.91-.86 1.23-1.76 2.43-2.72 3.58 7.21 9.5 18.62 15.65 31.44 15.65 10.97 0 20.9-4.5 28.06-11.75-.96-1.08-1.88-2.2-2.75-3.36-6.39 6.64-15.36 10.78-25.31 10.78Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M285.87 349.27c.99.07 1.99.12 2.99.12 2.45 0 4.85-.23 7.18-.64.66-.12 1.31-.25 1.96-.39 1.7-.39 3.37-.87 4.99-1.46.77-.28 1.53-.59 2.28-.91a41.26 41.26 0 0 0 8.7-5.14c1.47-1.13 2.86-2.35 4.17-3.67-.41-.44-.82-.88-1.22-1.33-7.16 7.25-17.09 11.75-28.06 11.75-12.82 0-24.23-6.15-31.44-15.65-.39.47-.79.93-1.2 1.38 3.32 4.29 7.46 7.91 12.19 10.61.84.48 1.68.94 2.56 1.36 4.56 2.2 9.59 3.57 14.9 3.96ZM264.82 274.67c.26.55.51 1.11.75 1.67 2.01-1.48 4.17-2.77 6.44-3.85-.4-.47-.8-.94-1.19-1.42a41.372 41.372 0 0 0-6.01 3.6Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M268.99 287.77c3.32-3.24 7.42-5.66 12-6.98a52.959 52.959 0 0 1-4.43-3.62c-3.22 1.28-6.2 3.05-8.84 5.22.51 1.76.93 3.55 1.27 5.38ZM288.86 336.63c-10.7 0-20.02-5.91-24.89-14.63-.84 1.64-1.76 3.24-2.76 4.78 6 8.87 16.15 14.72 27.65 14.72 9.55 0 18.16-4.04 24.25-10.49-.96-1.38-1.86-2.8-2.69-4.26-5.22 6.05-12.94 9.88-21.56 9.88Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M267.72 282.39a33.57 33.57 0 0 1 8.84-5.22c-.48-.44-.95-.9-1.42-1.36a34.83 34.83 0 0 0-7.98 4.74c.2.61.39 1.22.57 1.83ZM288.86 341.5c-11.49 0-21.65-5.85-27.65-14.72-.35.54-.7 1.07-1.07 1.59 6.36 9.01 16.85 14.91 28.72 14.91 9.94 0 18.92-4.14 25.31-10.78-.37-.49-.71-.99-1.06-1.49-6.08 6.45-14.7 10.49-24.25 10.49Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M304.27 290.54c-.94-.15-1.87-.33-2.79-.53.89.62 1.74 1.31 2.53 2.05.08-.51.17-1.02.27-1.53ZM269.34 289.97c.31 2.26.49 4.56.52 6.9 3.85-6.48 10.92-10.82 19-10.82.34 0 .67.01 1 .03a53.977 53.977 0 0 1-6.96-3.94 26.675 26.675 0 0 0-13.56 7.83ZM288.86 330.26c-10.34 0-19.01-7.1-21.43-16.68-.69 2.21-1.53 4.35-2.49 6.43 4.37 8.79 13.45 14.85 23.92 14.85 8.31 0 15.74-3.82 20.64-9.78-.8-1.54-1.54-3.13-2.19-4.75-3.95 5.99-10.74 9.94-18.45 9.94Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M269.34 289.97c3.58-3.84 8.27-6.62 13.56-7.83-.65-.43-1.28-.89-1.91-1.35a28.442 28.442 0 0 0-12 6.98c.13.73.25 1.46.36 2.2ZM288.86 334.85c-10.47 0-19.54-6.06-23.92-14.85-.31.67-.63 1.34-.97 2 4.87 8.73 14.18 14.63 24.89 14.63 8.62 0 16.34-3.83 21.56-9.88-.32-.56-.62-1.12-.92-1.68-4.9 5.97-12.33 9.78-20.64 9.78Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M288.86 287.83c-8.81 0-16.33 5.64-19.14 13.5-.19 2.7-.58 5.35-1.15 7.92.57 10.7 9.45 19.22 20.29 19.22 7.55 0 14.14-4.14 17.65-10.26-.89-2.5-1.6-5.09-2.11-7.74-1.12 7.58-7.64 13.41-15.54 13.41-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72c6.62 0 12.28 4.1 14.59 9.89-.03-.72-.05-1.44-.05-2.17 0-1.97.11-3.92.32-5.84-3.71-3.98-9-6.48-14.86-6.48Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M269.86 296.87c0 .2.02.4.02.61 0 1.3-.06 2.58-.15 3.85 2.81-7.86 10.33-13.5 19.14-13.5 5.86 0 11.15 2.5 14.86 6.48.08-.75.17-1.5.29-2.25-.79-.74-1.63-1.43-2.53-2.05a53.29 53.29 0 0 1-11.61-3.93c-.33-.01-.67-.03-1-.03-8.08 0-15.15 4.34-19 10.82ZM288.86 328.48c-10.84 0-19.72-8.53-20.29-19.22-.33 1.46-.7 2.91-1.14 4.32 2.42 9.59 11.09 16.68 21.43 16.68 7.71 0 14.5-3.95 18.45-9.94-.28-.7-.55-1.4-.8-2.11-3.5 6.13-10.1 10.26-17.65 10.26Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M288.86 322.1c-7.69 0-13.95-6.26-13.95-13.95s6.26-13.95 13.95-13.95 13.95 6.26 13.95 13.95-6.26 13.95-13.95 13.95Zm9.35-13.95c0-5.16-4.19-9.35-9.35-9.35s-9.35 4.19-9.35 9.35 4.19 9.35 9.35 9.35 9.35-4.19 9.35-9.35Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M303.46 302.32c.11 2.77.43 5.5.94 8.15-1.12 7.58-7.64 13.41-15.54 13.41-8.68 0-15.72-7.04-15.72-15.72s7.04-15.72 15.72-15.72c6.62 0 12.28 4.1 14.59 9.89Zm-14.59 19.78c7.69 0 13.95-6.26 13.95-13.95s-6.26-13.95-13.95-13.95-13.95 6.26-13.95 13.95 6.26 13.95 13.95 13.95Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M288.86 315.72c-4.17 0-7.57-3.4-7.57-7.57s3.4-7.57 7.57-7.57 7.57 3.4 7.57 7.57-3.4 7.57-7.57 7.57Zm2.97-7.57a2.97 2.97 0 1 0-5.94 0 2.97 2.97 0 0 0 5.94 0Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M298.21 308.15c0 5.16-4.19 9.35-9.35 9.35s-9.35-4.19-9.35-9.35 4.19-9.35 9.35-9.35 9.35 4.19 9.35 9.35Zm-9.35 7.57c4.17 0 7.57-3.4 7.57-7.57s-3.4-7.57-7.57-7.57-7.57 3.4-7.57 7.57 3.4 7.57 7.57 7.57Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M288.86 306.96c-.66 0-1.19.53-1.19 1.19s.53 1.19 1.19 1.19 1.19-.53 1.19-1.19-.53-1.19-1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M291.84 308.15a2.97 2.97 0 1 1-5.94 0 2.97 2.97 0 0 1 5.94 0Zm-1.78 0c0-.66-.53-1.19-1.19-1.19s-1.19.53-1.19 1.19.53 1.19 1.19 1.19 1.19-.53 1.19-1.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M215.91 251.5c25.39 0 45.98 20.59 45.98 45.98s-20.59 45.98-45.98 45.98c-15.82 0-29.77-7.99-38.04-20.15-.88 2.18-1.9 4.28-3.04 6.3 9.56 12.2 24.42 20.06 41.08 20.06 28.78 0 52.19-23.41 52.19-52.19s-23.41-52.19-52.19-52.19c-19.04 0-35.72 10.25-44.84 25.52 1.4 1.87 2.67 3.85 3.82 5.9 7.59-14.95 23.1-25.2 41.01-25.2Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M201.49 349.49c1.57.43 3.17.8 4.79 1.09.6.11 1.2.21 1.8.3 1.58.23 3.18.39 4.8.48.65.04 1.31.05 1.97.06.35 0 .7.03 1.05.03 1.53 0 3.04-.08 4.54-.2.89-.07 1.77-.17 2.64-.29a53.564 53.564 0 0 0 15.67-4.58c1.69-.79 3.33-1.66 4.92-2.61a57.808 57.808 0 0 0 5.57-3.86c.48-.38.96-.76 1.43-1.16 1.25-1.05 2.45-2.16 3.6-3.32.42-.43.83-.86 1.24-1.3.24-.26.48-.53.72-.8.41-.46.81-.91 1.2-1.38.96-1.15 1.86-2.35 2.72-3.58.37-.52.72-1.05 1.07-1.59a53.891 53.891 0 0 0 3.73-6.78c.96-2.08 1.8-4.22 2.49-6.43.44-1.41.81-2.86 1.14-4.32.57-2.58.96-5.22 1.15-7.92.09-1.27.15-2.56.15-3.85 0-.2-.01-.4-.02-.61-.03-2.34-.2-4.64-.52-6.9-.1-.74-.22-1.47-.36-2.2-.33-1.83-.75-3.62-1.27-5.38-.18-.62-.37-1.23-.57-1.83-.47-1.43-.99-2.84-1.58-4.22-.24-.56-.5-1.12-.75-1.67-.03-.07-.07-.15-.1-.22-.9-1.9-1.9-3.73-3.01-5.5-.32-.51-.64-1.03-.98-1.53-1.29-1.92-2.7-3.74-4.22-5.47a52.797 52.797 0 0 0-6-5.87c-.47-.39-.95-.77-1.44-1.15a52.403 52.403 0 0 0-5.8-3.95c-.59-.35-1.19-.7-1.79-1.02a53.769 53.769 0 0 0-11.36-4.53c-.45-.12-.89-.24-1.34-.35-3.88-.95-7.93-1.48-12.09-1.54-.26 0-.53-.02-.79-.02-.44 0-.87.02-1.3.03-2.38.06-4.72.25-7.02.61-.61.09-1.2.21-1.8.32-2.19.41-4.33.96-6.41 1.63-.57.18-1.13.37-1.7.57-2.18.78-4.3 1.71-6.34 2.76-.53.27-1.07.55-1.59.84a54.58 54.58 0 0 0-6.8 4.49c-.14.11-.29.22-.43.34-1.4 1.11-2.75 2.28-4.04 3.52a54.274 54.274 0 0 0-6.86 8.04c-.6.86-1.19 1.74-1.74 2.64.4.5.81 1 1.19 1.51 9.12-15.27 25.8-25.52 44.84-25.52 28.78 0 52.19 23.41 52.19 52.19s-23.41 52.19-52.19 52.19c-16.66 0-31.52-7.86-41.08-20.06-.32.56-.64 1.12-.98 1.67 1.49 1.86 3.11 3.61 4.84 5.25a54.132 54.132 0 0 0 15.25 10.23c.04.02.09.04.13.06.55.24 1.1.48 1.65.7 1.33.54 2.68 1.03 4.06 1.47.57.18 1.14.33 1.72.49Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M215.91 341.68c24.37 0 44.2-19.83 44.2-44.2s-19.83-44.2-44.2-44.2c-17.64 0-32.89 10.39-39.98 25.37 1.57 3.09 2.86 6.35 3.82 9.75 4.06-16.2 18.7-28.19 36.16-28.19 20.59 0 37.28 16.69 37.28 37.28s-16.69 37.28-37.28 37.28c-15.78 0-29.27-9.82-34.71-23.67-.52 3.51-1.38 6.9-2.54 10.15 7.86 12.28 21.61 20.44 37.24 20.44Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M215.91 343.46c25.39 0 45.98-20.59 45.98-45.98s-20.59-45.98-45.98-45.98c-17.91 0-33.43 10.25-41.01 25.2.36.64.7 1.29 1.04 1.94 7.08-14.98 22.34-25.37 39.98-25.37 24.37 0 44.2 19.83 44.2 44.2s-19.83 44.2-44.2 44.2c-15.63 0-29.38-8.16-37.24-20.44-.25.7-.52 1.39-.79 2.08 8.27 12.16 22.22 20.15 38.04 20.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M181.65 306.78c.08-1.22.14-2.44.14-3.68 0-3.61-.36-7.13-1.04-10.54 2.4-17.25 17.25-30.58 35.15-30.58 19.57 0 35.5 15.92 35.5 35.5s-15.92 35.5-35.5 35.5c-16.35 0-30.15-11.12-34.25-26.19Zm5.32-9.3c0 15.98 12.95 28.93 28.93 28.93s28.93-12.95 28.93-28.93-12.95-28.93-28.93-28.93-28.93 12.95-28.93 28.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M215.91 334.76c20.59 0 37.28-16.69 37.28-37.28s-16.69-37.28-37.28-37.28c-17.45 0-32.1 12-36.16 28.19.39 1.37.73 2.75 1.01 4.16 2.4-17.25 17.25-30.58 35.15-30.58 19.57 0 35.5 15.92 35.5 35.5s-15.92 35.5-35.5 35.5c-16.35 0-30.15-11.12-34.25-26.19-.1 1.45-.24 2.88-.45 4.3 5.44 13.86 18.92 23.67 34.71 23.67Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M215.91 270.33c14.97 0 27.15 12.18 27.15 27.15s-12.18 27.15-27.15 27.15-27.15-12.18-27.15-27.15 12.18-27.15 27.15-27.15Zm0 6.57c-11.37 0-20.58 9.22-20.58 20.58s9.22 20.58 20.58 20.58 20.58-9.22 20.58-20.58-9.22-20.58-20.58-20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M186.97 297.48c0-15.98 12.95-28.93 28.93-28.93s28.93 12.95 28.93 28.93-12.95 28.93-28.93 28.93-28.93-12.95-28.93-28.93Zm28.93-27.15c-14.97 0-27.15 12.18-27.15 27.15s12.18 27.15 27.15 27.15 27.15-12.18 27.15-27.15-12.18-27.15-27.15-27.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M215.91 316.28c-10.37 0-18.8-8.44-18.8-18.8s8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8-8.44 18.8-18.8 18.8Zm12.24-18.8c0-6.76-5.48-12.24-12.24-12.24s-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24 12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M236.49 297.48c0 11.37-9.22 20.58-20.58 20.58s-20.58-9.22-20.58-20.58 9.22-20.58 20.58-20.58 20.58 9.22 20.58 20.58Zm-20.58 18.8c10.37 0 18.8-8.44 18.8-18.8s-8.44-18.8-18.8-18.8-18.8 8.44-18.8 18.8 8.44 18.8 18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M215.91 307.93c-5.77 0-10.46-4.69-10.46-10.46s4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46Zm3.89-10.46c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M228.14 297.48c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-12.24 10.46c5.77 0 10.46-4.69 10.46-10.46s-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M215.91 295.37c-1.16 0-2.11.95-2.11 2.11s.95 2.11 2.11 2.11 2.11-.95 2.11-2.11-.95-2.11-2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M219.79 297.48c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M305.18 300.15c0 28.78 23.41 52.19 52.19 52.19s52.19-23.41 52.19-52.19c0-25.96-19.06-47.55-43.92-51.53-.44 2.06-1 4.07-1.67 6.03 22.26 3.2 39.37 22.35 39.37 45.5 0 25.39-20.59 45.98-45.98 45.98s-45.98-20.59-45.98-45.98c0-3.06.31-6.04.87-8.93-2.11-.02-4.19-.17-6.23-.43-.55 3.04-.86 6.17-.86 9.36Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M397.89 264.52a54.013 54.013 0 0 0-22.58-15.27c-.85-.3-1.7-.57-2.56-.83-1.77-.52-3.58-.96-5.41-1.3-.45-.08-.9-.17-1.35-.24-.11.59-.21 1.17-.34 1.75 24.86 3.98 43.92 25.57 43.92 51.53 0 28.78-23.41 52.19-52.19 52.19s-52.19-23.41-52.19-52.19c0-3.2.3-6.32.86-9.36-.59-.07-1.18-.15-1.77-.24-.09.51-.19 1.01-.27 1.53-.11.74-.2 1.49-.29 2.25a53.58 53.58 0 0 0-.32 5.84c0 .73.03 1.45.05 2.17.11 2.77.43 5.5.94 8.15.51 2.66 1.22 5.24 2.11 7.74.25.71.52 1.41.8 2.11.66 1.62 1.39 3.21 2.19 4.75.3.57.6 1.13.92 1.68.83 1.46 1.73 2.89 2.69 4.26.35.5.7 1 1.06 1.49a55.153 55.153 0 0 0 3.97 4.69c.33.35.65.7.98 1.03a53.436 53.436 0 0 0 4.85 4.3c1.28 1.01 2.61 1.97 3.99 2.87.57.37 1.13.73 1.72 1.08a53.903 53.903 0 0 0 12.64 5.5c2.13.62 4.32 1.12 6.55 1.47.66.1 1.33.19 2 .27 1.61.19 3.25.32 4.9.36.54.02 1.07.04 1.61.04h.23a54.617 54.617 0 0 0 11.21-1.22c.58-.13 1.16-.26 1.74-.4 1.41-.36 2.8-.77 4.17-1.24.57-.19 1.14-.39 1.7-.6a53.927 53.927 0 0 0 14.72-8.42c.97-.78 1.91-1.58 2.82-2.43a54.25 54.25 0 0 0 6.29-6.91c.45-.59.89-1.2 1.32-1.81.21-.3.43-.61.64-.92a54.513 54.513 0 0 0 4.19-7.49 53.68 53.68 0 0 0 2.94-8.02c.24-.84.45-1.7.64-2.56a53.93 53.93 0 0 0-1.44-29.04c-.28-.83-.58-1.66-.89-2.48a52.825 52.825 0 0 0-3.72-7.68c-.35-.61-.72-1.2-1.1-1.79a54.209 54.209 0 0 0-3.25-4.54c-.55-.7-1.13-1.38-1.71-2.05Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M313.17 300.15c0 24.37 19.83 44.2 44.2 44.2s44.2-19.83 44.2-44.2c0-22.34-16.66-40.85-38.21-43.78-.87 2.29-1.87 4.51-3.03 6.63 19.2 1.51 34.31 17.56 34.31 37.15 0 20.59-16.69 37.28-37.28 37.28s-37.28-16.69-37.28-37.28c0-3.31.44-6.52 1.25-9.58-2.37.37-4.8.58-7.26.63-.6 2.89-.91 5.88-.91 8.95Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M311.4 300.15c0 25.39 20.59 45.98 45.98 45.98s45.98-20.59 45.98-45.98c0-23.15-17.11-42.3-39.37-45.5-.2.58-.4 1.15-.61 1.72 21.55 2.94 38.21 21.44 38.21 43.78 0 24.37-19.83 44.2-44.2 44.2s-44.2-19.83-44.2-44.2c0-3.06.31-6.06.91-8.95-.4 0-.8.03-1.2.03-.21 0-.41-.01-.62-.02-.57 2.89-.87 5.88-.87 8.93Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M321.88 300.15c0 19.57 15.92 35.5 35.5 35.5s35.5-15.92 35.5-35.5-14.86-34.41-33.52-35.44a52.768 52.768 0 0 1-4.63 6.63c.87-.08 1.75-.13 2.65-.13 15.98 0 28.93 12.95 28.93 28.93s-12.95 28.93-28.93 28.93-28.93-12.95-28.93-28.93c0-4.31.95-8.39 2.64-12.07-2.51.9-5.11 1.62-7.78 2.14a35.36 35.36 0 0 0-1.42 9.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M320.1 300.15c0 20.59 16.69 37.28 37.28 37.28s37.28-16.69 37.28-37.28-15.11-35.64-34.31-37.15c-.31.58-.65 1.14-.98 1.71 18.66 1.03 33.52 16.53 33.52 35.44s-15.92 35.5-35.5 35.5-35.5-15.92-35.5-35.5c0-3.45.5-6.78 1.42-9.93-.65.13-1.3.25-1.95.35a37.39 37.39 0 0 0-1.25 9.58Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M330.22 300.15c0-4.72 1.21-9.16 3.34-13.03a54.167 54.167 0 0 0 19.45-13.77c1.42-.23 2.88-.36 4.37-.36 14.97 0 27.15 12.18 27.15 27.15s-12.18 27.15-27.15 27.15-27.15-12.18-27.15-27.15Zm6.57 0c0 11.37 9.22 20.58 20.58 20.58s20.58-9.22 20.58-20.58-9.22-20.58-20.58-20.58-20.58 9.22-20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M328.44 300.15c0 15.98 12.95 28.93 28.93 28.93s28.93-12.95 28.93-28.93-12.95-28.93-28.93-28.93c-.89 0-1.77.05-2.65.13-.56.68-1.13 1.35-1.72 2.01 1.42-.23 2.88-.36 4.37-.36 14.97 0 27.15 12.18 27.15 27.15s-12.18 27.15-27.15 27.15-27.15-12.18-27.15-27.15c0-4.72 1.21-9.16 3.34-13.03-.82.34-1.64.66-2.48.96a28.792 28.792 0 0 0-2.64 12.07Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M338.57 300.15c0-10.37 8.44-18.8 18.8-18.8s18.8 8.44 18.8 18.8-8.44 18.8-18.8 18.8-18.8-8.44-18.8-18.8Zm6.57 0c0 6.76 5.48 12.24 12.24 12.24s12.24-5.48 12.24-12.24-5.48-12.24-12.24-12.24-12.24 5.48-12.24 12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M336.79 300.15c0-11.37 9.22-20.58 20.58-20.58s20.58 9.22 20.58 20.58-9.22 20.58-20.58 20.58-20.58-9.22-20.58-20.58Zm1.78 0c0 10.37 8.44 18.8 18.8 18.8s18.8-8.44 18.8-18.8-8.44-18.8-18.8-18.8-18.8 8.44-18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M346.92 300.15c0-5.77 4.69-10.46 10.46-10.46s10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46-10.46-4.69-10.46-10.46Zm6.57 0c0 2.15 1.74 3.89 3.89 3.89s3.89-1.74 3.89-3.89-1.74-3.89-3.89-3.89-3.89 1.74-3.89 3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M345.14 300.15c0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24-12.24-5.48-12.24-12.24Zm1.78 0c0 5.77 4.69 10.46 10.46 10.46s10.46-4.69 10.46-10.46-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M357.37 302.26c1.16 0 2.11-.95 2.11-2.11s-.95-2.11-2.11-2.11-2.11.95-2.11 2.11.95 2.11 2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M353.49 300.15c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89-1.74 3.89-3.89 3.89-3.89-1.74-3.89-3.89Zm1.78 0c0 1.16.95 2.11 2.11 2.11s2.11-.95 2.11-2.11-.95-2.11-2.11-2.11-2.11.95-2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M337.21 91.95c0 28.78-23.41 52.19-52.19 52.19s-52.19-23.41-52.19-52.19 23.41-52.19 52.19-52.19 52.19 23.41 52.19 52.19Zm-98.17 0c0 25.39 20.59 45.98 45.98 45.98S331 117.34 331 91.95s-20.59-45.98-45.98-45.98-45.98 20.59-45.98 45.98Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M265.12 41.78c2.2-.88 4.48-1.62 6.81-2.2.73-.18 1.46-.36 2.2-.51a52.432 52.432 0 0 1 7.42-.98c1.15-.07 2.3-.12 3.47-.12.35 0 .7.02 1.05.03.61.01 1.23.02 1.83.05 1.11.06 2.22.16 3.31.29 6.48.74 12.6 2.63 18.17 5.45.63.32 1.26.65 1.87.99 1.46.82 2.89 1.7 4.27 2.64.57.39 1.13.79 1.69 1.2 1.49 1.11 2.92 2.3 4.29 3.55a54.253 54.253 0 0 1 5.02 5.27c.44.52.85 1.06 1.27 1.6 1.09 1.42 2.12 2.9 3.07 4.42.38.6.73 1.22 1.09 1.83 1.01 1.78 1.94 3.62 2.74 5.51.42.98.8 1.98 1.16 2.99a52.051 52.051 0 0 1 2.56 10.36c.29 2 .46 4.04.52 6.1.02.55.04 1.11.04 1.67 0 .16-.01.33-.01.49-.02 1.81-.12 3.6-.31 5.37a54.522 54.522 0 0 1-1.28 7.18c-.16.66-.35 1.31-.53 1.96-.52 1.8-1.13 3.56-1.82 5.27-.26.64-.53 1.27-.82 1.9-.69 1.53-1.45 3.03-2.28 4.48-.35.61-.7 1.21-1.07 1.81-.23.37-.46.73-.7 1.09-.33.51-.68 1.01-1.02 1.5a53.291 53.291 0 0 1-4.27 5.33c-.44.48-.89.95-1.35 1.41a54.744 54.744 0 0 1-8.24 6.89 53.728 53.728 0 0 1-25.99 9.1c-.65.05-1.29.1-1.95.13-.77.03-1.55.06-2.33.06a55.819 55.819 0 0 1-6.3-.38c-2.33-.27-4.61-.68-6.84-1.24-.58-.14-1.15-.31-1.72-.47a52.82 52.82 0 0 1-5.87-2.04c-.56-.23-1.1-.48-1.65-.73-1.6-.73-3.15-1.53-4.66-2.41a54.22 54.22 0 0 1-19.97-20.18 54.816 54.816 0 0 1-2.74-5.54 55.627 55.627 0 0 1-2.7-8.18c-.71-2.92-1.18-5.94-1.39-9.03-.09-1.25-.15-2.5-.15-3.77 0-.3.02-.59.02-.88.07-4.24.62-8.35 1.61-12.3.38-1.5.83-2.98 1.33-4.43 1.18-3.42 2.69-6.68 4.49-9.74.38-.64.77-1.27 1.18-1.9 1.28-1.98 2.68-3.87 4.21-5.67.42-.49.84-.98 1.27-1.46.33-.36.67-.71 1.01-1.06 1.65-1.72 3.42-3.32 5.29-4.81 1.34-1.07 2.74-2.08 4.18-3.02a53.381 53.381 0 0 1 9.49-4.91Zm19.9-2.03c-28.78 0-52.19 23.41-52.19 52.19s23.41 52.19 52.19 52.19 52.19-23.41 52.19-52.19-23.41-52.19-52.19-52.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M285.02 47.75c24.37 0 44.2 19.83 44.2 44.2s-19.83 44.2-44.2 44.2-44.2-19.83-44.2-44.2 19.83-44.2 44.2-44.2Zm0 6.92c-20.59 0-37.28 16.69-37.28 37.28s16.69 37.28 37.28 37.28 37.28-16.69 37.28-37.28-16.69-37.28-37.28-37.28Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M285.02 45.97c25.39 0 45.98 20.59 45.98 45.98s-20.59 45.98-45.98 45.98-45.98-20.59-45.98-45.98 20.59-45.98 45.98-45.98Zm0 1.78c-24.37 0-44.2 19.83-44.2 44.2s19.83 44.2 44.2 44.2 44.2-19.83 44.2-44.2-19.83-44.2-44.2-44.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M320.52 91.95c0 19.57-15.92 35.5-35.5 35.5s-35.5-15.92-35.5-35.5 15.92-35.5 35.5-35.5 35.5 15.92 35.5 35.5Zm-6.57 0c0-15.98-12.95-28.93-28.93-28.93s-28.93 12.95-28.93 28.93 12.95 28.93 28.93 28.93 28.93-12.95 28.93-28.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M285.02 54.67c20.59 0 37.28 16.69 37.28 37.28s-16.69 37.28-37.28 37.28-37.28-16.69-37.28-37.28 16.69-37.28 37.28-37.28Zm0 1.78c-19.57 0-35.5 15.92-35.5 35.5s15.92 35.5 35.5 35.5 35.5-15.92 35.5-35.5-15.92-35.5-35.5-35.5Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M285.02 119.1c-14.97 0-27.15-12.18-27.15-27.15s12.18-27.15 27.15-27.15 27.15 12.18 27.15 27.15-12.18 27.15-27.15 27.15Zm0-6.57c11.37 0 20.58-9.22 20.58-20.58s-9.22-20.58-20.58-20.58-20.58 9.22-20.58 20.58 9.22 20.58 20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M285.02 120.88c-15.98 0-28.93-12.95-28.93-28.93s12.95-28.93 28.93-28.93 28.93 12.95 28.93 28.93-12.95 28.93-28.93 28.93Zm27.15-28.93c0-14.97-12.18-27.15-27.15-27.15s-27.15 12.18-27.15 27.15 12.18 27.15 27.15 27.15 27.15-12.18 27.15-27.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M285.02 110.75c-10.37 0-18.8-8.44-18.8-18.8s8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8-8.44 18.8-18.8 18.8Zm0-6.57c6.76 0 12.24-5.48 12.24-12.24s-5.48-12.24-12.24-12.24-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M285.02 112.53c-11.37 0-20.58-9.22-20.58-20.58s9.22-20.58 20.58-20.58 20.58 9.22 20.58 20.58-9.22 20.58-20.58 20.58Zm18.8-20.58c0-10.37-8.44-18.8-18.8-18.8s-18.8 8.44-18.8 18.8 8.44 18.8 18.8 18.8 18.8-8.44 18.8-18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M285.02 102.4c-5.77 0-10.46-4.69-10.46-10.46s4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46Zm3.89-10.46c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M285.02 104.18c-6.76 0-12.24-5.48-12.24-12.24s5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24Zm10.46-12.24c0-5.77-4.69-10.46-10.46-10.46s-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46 10.46-4.69 10.46-10.46Z"
        />
        <circle
          className="cls-Venn3-3-3-3"
          cx={285.02}
          cy={91.95}
          r={2.11}
          transform="rotate(-76.76 285.02 91.945)"
        />
        <path
          className="cls-Venn3-3-4"
          d="M288.91 91.95c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M121.01 182.98c0-28.78 23.41-52.19 52.19-52.19s52.19 23.41 52.19 52.19-23.41 52.19-52.19 52.19-52.19-23.41-52.19-52.19Zm52.19 45.98c25.39 0 45.98-20.59 45.98-45.98S198.59 137 173.2 137s-45.98 20.59-45.98 45.98 20.59 45.98 45.98 45.98Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M125.51 208.26a56.49 56.49 0 0 1-1.89-3.92c-.24-.56-.46-1.12-.69-1.69-.09-.23-.18-.45-.27-.68-.85-2.26-1.55-4.59-2.09-6.99-.17-.76-.33-1.53-.47-2.31a54.23 54.23 0 0 1-.88-9.69c0-.9.02-1.8.07-2.69.24-4.83 1.12-9.48 2.54-13.89.18-.57.37-1.13.57-1.69.49-1.37 1.05-2.71 1.65-4.02.25-.55.5-1.1.77-1.64.75-1.51 1.56-2.97 2.44-4.4a52.585 52.585 0 0 1 5.53-7.42c1.63-1.84 3.39-3.57 5.26-5.18a54.58 54.58 0 0 1 8.51-6c2.13-1.21 4.35-2.28 6.65-3.2.68-.27 1.36-.53 2.05-.78 1.62-.57 3.27-1.07 4.95-1.48.61-.15 1.23-.3 1.85-.43 1.63-.34 3.28-.6 4.96-.79.6-.07 1.2-.12 1.8-.17 1.44-.12 2.88-.19 4.35-.19h.04c.59 0 1.16.03 1.74.04 1.42.05 2.83.14 4.22.29 4.3.47 8.45 1.45 12.39 2.87.56.2 1.11.43 1.67.65 1.35.54 2.67 1.13 3.96 1.77.54.27 1.08.54 1.61.82 1.47.8 2.9 1.66 4.29 2.58.52.35 1.04.71 1.55 1.08 1.39 1 2.73 2.06 4.02 3.18.54.47 1.07.96 1.6 1.45 1.69 1.6 3.29 3.3 4.76 5.11 2.5 3.05 4.66 6.39 6.44 9.95 1.04 2.07 1.95 4.22 2.72 6.44.24.68.47 1.37.68 2.06.49 1.62.91 3.27 1.25 4.96.12.61.23 1.23.33 1.85.27 1.64.46 3.3.58 4.98.02.21.04.42.05.63.04.59.05 1.19.07 1.78.01.49.04.99.04 1.48 0 1.62-.08 3.22-.22 4.8a53.977 53.977 0 0 1-2.12 10.99c-.82 2.69-1.85 5.3-3.06 7.8-.42.87-.87 1.72-1.34 2.56a54.28 54.28 0 0 1-23.32 22.25c-.6.3-1.2.58-1.81.85-2.23 1-4.54 1.86-6.92 2.55-.59.17-1.17.34-1.77.5-2.01.51-4.07.9-6.16 1.18-.59.08-1.17.16-1.77.22-1.6.16-3.22.24-4.86.26-.2 0-.4.02-.6.02-.39 0-.78-.02-1.18-.03-1.55-.03-3.09-.12-4.6-.28-.6-.06-1.19-.14-1.79-.23-1.58-.22-3.14-.52-4.67-.87-.63-.15-1.25-.3-1.87-.46a54.98 54.98 0 0 1-5.27-1.72c-1-.38-1.98-.8-2.95-1.24a55.15 55.15 0 0 1-7.31-4.03 53.423 53.423 0 0 1-4.47-3.28c-.5-.41-.98-.83-1.46-1.25a53.28 53.28 0 0 1-3.43-3.29c-.42-.44-.83-.88-1.24-1.33-1.02-1.14-2-2.32-2.92-3.55-.36-.48-.7-.97-1.04-1.45-.93-1.32-1.8-2.69-2.61-4.1-.3-.52-.6-1.04-.88-1.58Zm47.69 26.92c28.78 0 52.19-23.41 52.19-52.19s-23.41-52.19-52.19-52.19-52.19 23.41-52.19 52.19 23.41 52.19 52.19 52.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M129 182.98c0-24.37 19.83-44.2 44.2-44.2s44.2 19.83 44.2 44.2-19.83 44.2-44.2 44.2-44.2-19.83-44.2-44.2Zm6.92 0c0 20.59 16.69 37.28 37.28 37.28s37.28-16.69 37.28-37.28-16.69-37.28-37.28-37.28-37.28 16.69-37.28 37.28Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M127.22 182.98c0-25.39 20.59-45.98 45.98-45.98s45.98 20.59 45.98 45.98-20.59 45.98-45.98 45.98-45.98-20.59-45.98-45.98Zm1.78 0c0 24.37 19.83 44.2 44.2 44.2s44.2-19.83 44.2-44.2-19.83-44.2-44.2-44.2-44.2 19.83-44.2 44.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M173.2 147.49c19.57 0 35.5 15.92 35.5 35.5s-15.92 35.5-35.5 35.5-35.5-15.92-35.5-35.5 15.92-35.5 35.5-35.5Zm-28.93 35.5c0 15.98 12.95 28.93 28.93 28.93s28.93-12.95 28.93-28.93-12.95-28.93-28.93-28.93-28.93 12.95-28.93 28.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M173.2 145.71c20.59 0 37.28 16.69 37.28 37.28s-16.69 37.28-37.28 37.28-37.28-16.69-37.28-37.28 16.69-37.28 37.28-37.28Zm-35.5 37.28c0 19.57 15.92 35.5 35.5 35.5s35.5-15.92 35.5-35.5-15.92-35.5-35.5-35.5-35.5 15.92-35.5 35.5Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M146.05 182.98c0-14.97 12.18-27.15 27.15-27.15s27.15 12.18 27.15 27.15-12.18 27.15-27.15 27.15-27.15-12.18-27.15-27.15Zm27.15 20.58c11.37 0 20.58-9.22 20.58-20.58s-9.22-20.58-20.58-20.58-20.58 9.22-20.58 20.58 9.22 20.58 20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M173.2 154.05c15.98 0 28.93 12.95 28.93 28.93s-12.95 28.93-28.93 28.93-28.93-12.95-28.93-28.93 12.95-28.93 28.93-28.93Zm0 56.08c14.97 0 27.15-12.18 27.15-27.15s-12.18-27.15-27.15-27.15-27.15 12.18-27.15 27.15 12.18 27.15 27.15 27.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M154.39 182.98c0-10.37 8.44-18.8 18.8-18.8s18.8 8.44 18.8 18.8-8.44 18.8-18.8 18.8-18.8-8.44-18.8-18.8Zm6.57 0c0 6.76 5.48 12.24 12.24 12.24s12.24-5.48 12.24-12.24-5.48-12.24-12.24-12.24-12.24 5.48-12.24 12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M152.61 182.98c0-11.37 9.22-20.58 20.58-20.58s20.58 9.22 20.58 20.58-9.22 20.58-20.58 20.58-20.58-9.22-20.58-20.58Zm1.78 0c0 10.37 8.44 18.8 18.8 18.8s18.8-8.44 18.8-18.8-8.44-18.8-18.8-18.8-18.8 8.44-18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M162.74 182.98c0-5.77 4.69-10.46 10.46-10.46s10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46-10.46-4.69-10.46-10.46Zm6.57 0c0 2.15 1.74 3.89 3.89 3.89s3.89-1.74 3.89-3.89-1.74-3.89-3.89-3.89-3.89 1.74-3.89 3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M160.96 182.98c0-6.76 5.48-12.24 12.24-12.24s12.24 5.48 12.24 12.24-5.48 12.24-12.24 12.24-12.24-5.48-12.24-12.24Zm1.78 0c0 5.77 4.69 10.46 10.46 10.46s10.46-4.69 10.46-10.46-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M173.2 185.09c1.16 0 2.11-.95 2.11-2.11s-.95-2.11-2.11-2.11-2.11.95-2.11 2.11.95 2.11 2.11 2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M169.31 182.98c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89-1.74 3.89-3.89 3.89-3.89-1.74-3.89-3.89Zm3.89-2.11c-1.16 0-2.11.95-2.11 2.11s.95 2.11 2.11 2.11 2.11-.95 2.11-2.11"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M365.08 237.26c0 28.78-23.41 52.19-52.19 52.19s-52.19-23.41-52.19-52.19 23.41-52.19 52.19-52.19 52.19 23.41 52.19 52.19Zm-6.21 0c0-25.39-20.59-45.98-45.98-45.98s-45.98 20.59-45.98 45.98 20.59 45.98 45.98 45.98 45.98-20.59 45.98-45.98Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M362.97 217.14c.39.98.76 1.97 1.09 2.98.36 1.09.7 2.19 1 3.31 1.17 4.41 1.8 9.05 1.8 13.83a54.603 54.603 0 0 1-.87 9.61c-.11.59-.21 1.17-.34 1.75-.44 2.06-1 4.07-1.67 6.03-.2.58-.4 1.15-.61 1.72-.87 2.29-1.87 4.51-3.03 6.63-.31.58-.65 1.14-.98 1.71a52.768 52.768 0 0 1-4.63 6.63c-.56.68-1.13 1.35-1.72 2.01a54.167 54.167 0 0 1-21.93 14.73c-2.51.9-5.11 1.62-7.78 2.14-.65.13-1.3.25-1.95.35-2.37.37-4.8.58-7.26.63-.4 0-.8.03-1.2.03-.21 0-.41-.01-.62-.02-2.11-.02-4.19-.17-6.23-.43-.59-.07-1.18-.15-1.77-.24-.94-.15-1.87-.33-2.79-.53a53.29 53.29 0 0 1-11.61-3.93 53.977 53.977 0 0 1-6.96-3.94c-.65-.43-1.28-.89-1.91-1.35a52.959 52.959 0 0 1-4.43-3.62c-.48-.44-.95-.9-1.42-1.36a51.805 51.805 0 0 1-3.12-3.33c-.4-.47-.8-.94-1.19-1.42-.33-.41-.65-.82-.97-1.23a51.95 51.95 0 0 1-3.52-5.27c-.32-.54-.64-1.09-.94-1.64a53.83 53.83 0 0 1-3.16-7c-.25-.68-.48-1.38-.71-2.08-1-3.11-1.72-6.33-2.15-9.66-.2-1.53-.34-3.09-.4-4.66-.02-.46-.03-.92-.03-1.38 0-.3-.02-.6-.02-.9 0-.45.02-.89.03-1.33a54.203 54.203 0 0 1 .69-7.47c.32-1.96.75-3.88 1.28-5.77.2-.71.4-1.41.63-2.1.59-1.81 1.27-3.57 2.03-5.29.31-.69.63-1.37.96-2.05.91-1.83 1.92-3.6 3.03-5.31.48-.74.97-1.46 1.48-2.17 4.32-6 9.83-11.08 16.19-14.89.75-.45 1.52-.88 2.3-1.3 1.79-.95 3.65-1.8 5.55-2.56.7-.28 1.41-.54 2.12-.79 1.77-.61 3.59-1.14 5.44-1.57.71-.16 1.42-.32 2.14-.45a56.925 56.925 0 0 1 8.06-.91c.67-.02 1.35-.05 2.03-.05 1.1 0 2.19.04 3.27.11.74.04 1.49.09 2.22.17.62.06 1.23.15 1.84.23 3.93.53 7.74 1.48 11.36 2.82.72.27 1.43.55 2.13.84 2.45 1.03 4.81 2.23 7.07 3.59.55.33 1.09.68 1.62 1.03 1.79 1.17 3.52 2.45 5.15 3.82.47.39.93.79 1.39 1.2 1.52 1.36 2.95 2.81 4.31 4.34.84.94 1.64 1.91 2.41 2.91a54.12 54.12 0 0 1 5.81 9.35c.55 1.13 1.05 2.28 1.52 3.45Zm-50.08-32.08c-28.78 0-52.19 23.41-52.19 52.19s23.41 52.19 52.19 52.19 52.19-23.41 52.19-52.19-23.41-52.19-52.19-52.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M312.89 281.46c-24.37 0-44.2-19.83-44.2-44.2s19.83-44.2 44.2-44.2 44.2 19.83 44.2 44.2-19.83 44.2-44.2 44.2Zm0-6.92c20.59 0 37.28-16.69 37.28-37.28s-16.69-37.28-37.28-37.28-37.28 16.69-37.28 37.28 16.69 37.28 37.28 37.28Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M358.87 237.26c0 25.39-20.59 45.98-45.98 45.98s-45.98-20.59-45.98-45.98 20.59-45.98 45.98-45.98 45.98 20.59 45.98 45.98Zm-1.78 0c0-24.37-19.83-44.2-44.2-44.2s-44.2 19.83-44.2 44.2 19.83 44.2 44.2 44.2 44.2-19.83 44.2-44.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M277.39 237.26c0-19.57 15.92-35.5 35.5-35.5s35.5 15.92 35.5 35.5-15.92 35.5-35.5 35.5-35.5-15.92-35.5-35.5Zm35.5-28.93c-15.98 0-28.93 12.95-28.93 28.93s12.95 28.93 28.93 28.93 28.93-12.95 28.93-28.93-12.95-28.93-28.93-28.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M275.61 237.26c0-20.59 16.69-37.28 37.28-37.28s37.28 16.69 37.28 37.28-16.69 37.28-37.28 37.28-37.28-16.69-37.28-37.28Zm37.28 35.5c19.57 0 35.5-15.92 35.5-35.5s-15.92-35.5-35.5-35.5-35.5 15.92-35.5 35.5 15.92 35.5 35.5 35.5Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M312.89 264.41c-14.97 0-27.15-12.18-27.15-27.15s12.18-27.15 27.15-27.15 27.15 12.18 27.15 27.15-12.18 27.15-27.15 27.15Zm0-6.57c11.37 0 20.58-9.22 20.58-20.58s-9.22-20.58-20.58-20.58-20.58 9.22-20.58 20.58 9.22 20.58 20.58 20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M341.82 237.26c0 15.98-12.95 28.93-28.93 28.93s-28.93-12.95-28.93-28.93 12.95-28.93 28.93-28.93 28.93 12.95 28.93 28.93Zm-28.93 27.15c14.97 0 27.15-12.18 27.15-27.15s-12.18-27.15-27.15-27.15-27.15 12.18-27.15 27.15 12.18 27.15 27.15 27.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M312.89 256.06c-10.37 0-18.8-8.44-18.8-18.8s8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8-8.44 18.8-18.8 18.8Zm12.24-18.8c0-6.76-5.48-12.24-12.24-12.24s-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24 12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M312.89 257.84c-11.37 0-20.58-9.22-20.58-20.58s9.22-20.58 20.58-20.58 20.58 9.22 20.58 20.58-9.22 20.58-20.58 20.58Zm0-1.78c10.37 0 18.8-8.44 18.8-18.8s-8.44-18.8-18.8-18.8-18.8 8.44-18.8 18.8 8.44 18.8 18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M312.89 247.72c-5.77 0-10.46-4.69-10.46-10.46s4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46Zm3.89-10.46c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M325.12 237.26c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-12.24 10.46c5.77 0 10.46-4.69 10.46-10.46s-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M312.89 235.15c-1.16 0-2.11.95-2.11 2.11s.95 2.11 2.11 2.11 2.11-.95 2.11-2.11-.95-2.11-2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M316.78 237.26c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M127.82 355.29c-28.78 0-52.19-23.41-52.19-52.19s23.41-52.19 52.19-52.19 52.19 23.41 52.19 52.19-23.41 52.19-52.19 52.19Zm45.98-52.19c0-25.39-20.59-45.98-45.98-45.98s-45.98 20.59-45.98 45.98 20.59 45.98 45.98 45.98 45.98-20.59 45.98-45.98Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M173.85 331.29c-.29.47-.59.93-.89 1.39-.33.5-.67 1-1.02 1.49a54.41 54.41 0 0 1-3.03 3.91c-.4.47-.8.94-1.22 1.4a55.025 55.025 0 0 1-3.5 3.5c-.5.46-1.02.9-1.54 1.34-1.54 1.3-3.14 2.52-4.82 3.65-.92.62-1.88 1.2-2.84 1.77a55.03 55.03 0 0 1-8.6 4.07c-1.03.38-2.09.72-3.15 1.03a54.666 54.666 0 0 1-7.9 1.71c-1.62.23-3.27.39-4.93.47-.61.03-1.22.04-1.84.05-.17 0-.35.01-.52.01h-.24c-.72 0-1.44-.03-2.15-.05-3.47-.14-6.86-.6-10.14-1.37-1.61-.37-3.19-.82-4.74-1.33a53.631 53.631 0 0 1-12-5.73 54.171 54.171 0 0 1-7.83-6.08c-1.05-.98-2.07-2-3.04-3.06-.4-.44-.79-.9-1.18-1.35a53.164 53.164 0 0 1-2.98-3.84c-.35-.49-.69-.98-1.02-1.49-.85-1.3-1.66-2.63-2.41-4-.3-.55-.57-1.1-.85-1.66-.74-1.49-1.41-3.02-2.02-4.59a54.316 54.316 0 0 1-3.09-11.93c-.34-2.45-.53-4.94-.53-7.48 0-.48.02-.95.04-1.43.02-.72.05-1.44.09-2.15.11-1.69.29-3.35.55-4.99a54.305 54.305 0 0 1 1.43-6.38c.17-.58.34-1.15.52-1.72.5-1.55 1.07-3.07 1.71-4.56.24-.55.48-1.09.74-1.63.61-1.31 1.28-2.59 1.99-3.83.3-.52.6-1.04.91-1.55.09-.14.18-.28.27-.43.37-.59.74-1.17 1.14-1.74a54.28 54.28 0 0 1 18.81-17.01c.61-.33 1.23-.64 1.85-.95 1.11-.55 2.24-1.06 3.4-1.54.55-.23 1.1-.45 1.66-.66 1.35-.5 2.73-.95 4.12-1.34.58-.16 1.16-.33 1.75-.47 1.61-.4 3.24-.73 4.9-.98.62-.09 1.26-.17 1.89-.24 1.68-.19 3.38-.3 5.1-.34.37 0 .74-.03 1.11-.03.35 0 .7.02 1.05.03 2.33.04 4.64.23 6.89.56 4.06.6 7.97 1.66 11.69 3.11a53.73 53.73 0 0 1 8.16 4c1.46.88 2.87 1.82 4.23 2.83.51.38 1.01.77 1.5 1.16a55.862 55.862 0 0 1 5.06 4.56c1.01 1.03 1.98 2.09 2.9 3.2.2.24.39.48.58.72.4.5.81 1 1.19 1.51 1.4 1.87 2.67 3.85 3.82 5.9.36.64.7 1.29 1.04 1.94 1.57 3.09 2.86 6.35 3.82 9.75.39 1.37.73 2.75 1.01 4.16.68 3.41 1.04 6.93 1.04 10.54 0 1.24-.06 2.46-.14 3.68-.1 1.45-.24 2.88-.45 4.3-.52 3.51-1.38 6.9-2.54 10.15-.25.7-.52 1.39-.79 2.08-.88 2.18-1.9 4.28-3.04 6.3-.32.56-.64 1.12-.98 1.67Zm6.16-28.19c0-28.78-23.41-52.19-52.19-52.19s-52.19 23.41-52.19 52.19 23.41 52.19 52.19 52.19 52.19-23.41 52.19-52.19Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M83.62 303.1c0-24.37 19.83-44.2 44.2-44.2s44.2 19.83 44.2 44.2-19.83 44.2-44.2 44.2-44.2-19.83-44.2-44.2Zm44.2 37.28c20.59 0 37.28-16.69 37.28-37.28s-16.69-37.28-37.28-37.28-37.28 16.69-37.28 37.28 16.69 37.28 37.28 37.28Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M127.82 349.08c-25.39 0-45.98-20.59-45.98-45.98s20.59-45.98 45.98-45.98 45.98 20.59 45.98 45.98-20.59 45.98-45.98 45.98Zm0-1.78c24.37 0 44.2-19.83 44.2-44.2s-19.83-44.2-44.2-44.2-44.2 19.83-44.2 44.2 19.83 44.2 44.2 44.2Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M92.32 303.1c0-19.57 15.92-35.5 35.5-35.5s35.5 15.92 35.5 35.5-15.92 35.5-35.5 35.5-35.5-15.92-35.5-35.5Zm35.5-28.93c-15.98 0-28.93 12.95-28.93 28.93s12.95 28.93 28.93 28.93 28.93-12.95 28.93-28.93-12.95-28.93-28.93-28.93Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M90.54 303.1c0-20.59 16.69-37.28 37.28-37.28s37.28 16.69 37.28 37.28-16.69 37.28-37.28 37.28-37.28-16.69-37.28-37.28Zm37.28 35.5c19.57 0 35.5-15.92 35.5-35.5s-15.92-35.5-35.5-35.5-35.5 15.92-35.5 35.5 15.92 35.5 35.5 35.5Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M154.97 303.1c0 14.97-12.18 27.15-27.15 27.15s-27.15-12.18-27.15-27.15 12.18-27.15 27.15-27.15 27.15 12.18 27.15 27.15Zm-6.57 0c0-11.37-9.22-20.58-20.58-20.58s-20.58 9.22-20.58 20.58 9.22 20.58 20.58 20.58 20.58-9.22 20.58-20.58Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M156.75 303.1c0 15.98-12.95 28.93-28.93 28.93s-28.93-12.95-28.93-28.93 12.95-28.93 28.93-28.93 28.93 12.95 28.93 28.93Zm-1.78 0c0-14.97-12.18-27.15-27.15-27.15s-27.15 12.18-27.15 27.15 12.18 27.15 27.15 27.15 27.15-12.18 27.15-27.15Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M127.82 321.9c-10.37 0-18.8-8.44-18.8-18.8s8.44-18.8 18.8-18.8 18.8 8.44 18.8 18.8-8.44 18.8-18.8 18.8Zm12.24-18.8c0-6.76-5.48-12.24-12.24-12.24s-12.24 5.48-12.24 12.24 5.48 12.24 12.24 12.24 12.24-5.48 12.24-12.24Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M148.4 303.1c0 11.37-9.22 20.58-20.58 20.58s-20.58-9.22-20.58-20.58 9.22-20.58 20.58-20.58 20.58 9.22 20.58 20.58Zm-20.58 18.8c10.37 0 18.8-8.44 18.8-18.8s-8.44-18.8-18.8-18.8-18.8 8.44-18.8 18.8 8.44 18.8 18.8 18.8Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M127.82 313.56c-5.77 0-10.46-4.69-10.46-10.46s4.69-10.46 10.46-10.46 10.46 4.69 10.46 10.46-4.69 10.46-10.46 10.46Zm3.89-10.46c0-2.15-1.74-3.89-3.89-3.89s-3.89 1.74-3.89 3.89 1.74 3.89 3.89 3.89 3.89-1.74 3.89-3.89Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M140.06 303.1c0 6.76-5.48 12.24-12.24 12.24s-12.24-5.48-12.24-12.24 5.48-12.24 12.24-12.24 12.24 5.48 12.24 12.24Zm-12.24 10.46c5.77 0 10.46-4.69 10.46-10.46s-4.69-10.46-10.46-10.46-10.46 4.69-10.46 10.46 4.69 10.46 10.46 10.46Z"
        />
        <path
          className="cls-Venn3-3-3-3"
          d="M127.82 300.99c-1.16 0-2.11.95-2.11 2.11s.95 2.11 2.11 2.11 2.11-.95 2.11-2.11-.95-2.11-2.11-2.11Z"
        />
        <path
          className="cls-Venn3-3-4"
          d="M131.71 303.1c0 2.15-1.74 3.89-3.89 3.89s-3.89-1.74-3.89-3.89 1.74-3.89 3.89-3.89 3.89 1.74 3.89 3.89Zm-1.78 0c0-1.16-.95-2.11-2.11-2.11s-2.11.95-2.11 2.11.95 2.11 2.11 2.11 2.11-.95 2.11-2.11Z"
        />
      </pattern>
      <style>
        {".cls-Venn3-3-1{fill:none}.cls-Venn3-3-3-3{fill:#4980c1}.cls-Venn3-3-4{fill:#2babe2}"}
      </style>
    </defs>
    <g id="Venn3_Layer_1-2"
    style={{
        opacity: props.deepOpacity
    }}
     onMouseOut={(e) => {
     console.debug("mouseout");
        if (props.isMobile || props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
        
        console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);

        if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial")}
     }
     onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennState("rightHover")}
        }>
      <path
        className="cls-Venn3-3-3-3"
        d={ props.vennState === 1 ? "M 196.56, 303.78 m -303.78, 0 a 303.78,303.78 0 1,0 607.56,0 a 303.78,303.78 0 1,0 -607.56,0" : "M196.22 0C121.41 0 52.94 27.06 0 71.89c65.79 55.72 107.56 138.92 107.56 231.89S65.79 479.94 0 535.67c52.94 44.84 121.41 71.89 196.22 71.89C363.99 607.56 500 471.55 500 303.78S363.99 0 196.22 0Z" }
      />
      <g
        style={{
//           clipPath: "url(#clippath)",
        }}
      >
      { !props.isMobile && (props.vennState === 1 || props.selectedModal === "m_use--without-permission") && <image  className={'vennInnerGraphic'} href={circleBlue}
      x={props.selectedModal === "m_use--without-permission" ? "-100" : "32"}
      y={props.selectedModal === "m_use--without-permission" ? "0" : "138"}
      height={props.selectedModal === "m_use--without-permission" ? "600" : "330"}
      width={props.selectedModal === "m_use--without-permission" ? "600" : "330"}></image> }
        <path
          d="M196.22 0C121.41 0 52.94 27.06 0 71.89c65.79 55.72 107.56 138.92 107.56 231.89S65.79 479.94 0 535.67c52.94 44.84 121.41 71.89 196.22 71.89C363.99 607.56 500 471.55 500 303.78S363.99 0 196.22 0Z"
          style={{
            opacity:  (!props.isMobile && (props.vennState === 1 || props.selectedModal === "m_use--without-permission")) ? 0 : 1,
        visibility: !(!props.isMobile && (props.vennState === 1 || props.selectedModal === "m_use--without-permission")) ? 'visible' : 'hidden',
            fill: props.selectedModal === "m_use--with-permission" ? "lightgrey" : "url(#New_Pattern_Swatch_8)",
          }}
        />
      </g>
    </g>
          { props.vennState === 1 && <>
          <defs>
      <style>
        {
          ".notOnHover{opacity:.05}.cls-3-none-3{fill:none;stroke:#000;stroke-miterlimit:10}.cls-3-none-4{opacity:.2}.cls-3-none-5{opacity:.3}"
        }
      </style>
    </defs>
    { !props.isMobile && <g transform="translate(-106 0)">
      <path
        onMouseOut={(e) => {
            console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
            if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
            if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

        }}
        onMouseOver={() => {
            if (props.selectedModal === "m_exploitation") return;
            props.updateVennActive("ex");
            }
        }
        style={{
          fill: props.vennActive === "ex" ? "rgb(193 235 255)" : "initial",
          opacity: props.vennActive === "ex" ? 1 : .05 }}
        className="cls-3-none-4"
        onClick={()=>props.dispatch(props.setSelectedModal("m_exceptions"))}
        d="M303.78 9c39.8 0 78.4 7.79 114.74 23.16 35.1 14.85 66.63 36.1 93.7 63.18 27.07 27.07 48.33 58.6 63.18 93.7 3.01 7.11 5.72 14.32 8.14 21.59l8.57-2.71C551.97 87.13 438.06 0 303.78 0c-.6 0-1.19.02-1.78.02l-.07 9c.62 0 1.24-.02 1.86-.02Z" />

        <path
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
                onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("de")}}
        style={{
          fill: props.vennActive === "de" ? "rgb(193 235 255)" : "initial",
          opacity: props.vennActive === "de" ? 1 : .05 }}

          className="notOnHover"
          d="M32.16 189.04c14.85-35.1 36.1-66.63 63.18-93.7 27.07-27.08 58.6-48.33 93.7-63.18 35.77-15.13 73.74-22.9 112.88-23.14l.07-9C167.22.8 53.25 89.34 14.32 211.39l8.53 2.85c2.71-8.51 5.8-16.92 9.31-25.2Z" />

        <path
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
                onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("ou")}}
        style={{
          fill: props.vennActive === "ou" ? "rgb(193 235 255)" : "initial",
          opacity: props.vennActive === "ou" ? 1 : .05 }}
          className="notOnHover"
          d="M418.52 575.4c-36.34 15.37-74.94 23.16-114.74 23.16s-78.4-7.79-114.74-23.16a293.587 293.587 0 0 1-57.08-32.05l-5.33 7.22c49.87 35.86 111.04 57 177.16 57s129.79-22.01 180.2-59.21l-5.21-7.3a293.201 293.201 0 0 1-60.25 34.36Z"
      />
      <path
      onClick={()=>props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"))}
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
                onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("op")}}
        style={{
          fill: props.vennActive === "op" ? "rgb(193 235 255)" : "initial",
          opacity: props.vennActive === "op" ? 1 : .05 }}
        className="notOnHover"
        d="M607.56 303.78c0-33.5-5.44-65.72-15.45-95.85l-8.57 2.71c9.97 29.9 15.02 61.14 15.02 93.14 0 39.8-7.79 78.4-23.16 114.74-14.85 35.1-36.1 66.63-63.18 93.7a296.139 296.139 0 0 1-33.46 28.82l5.21 7.3c74.95-55.32 123.58-144.26 123.58-244.57Z" />

        <path
      onClick={()=>props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"))}
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
        onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("id")}}
        style={{
          fill: props.vennActive === "id" ? "rgb(193 235 255)" : "initial",
          opacity: props.vennActive === "id" ? 1 : .05 }}

          className="notOnHover"d="M95.34 512.22c-27.08-27.08-48.33-58.6-63.18-93.7C16.79 382.18 9 343.58 9 303.78c0-30.73 4.66-60.73 13.85-89.54l-8.53-2.85C5.03 240.53 0 271.56 0 303.78c0 101.66 49.94 191.64 126.62 246.79l5.33-7.22a295.818 295.818 0 0 1-36.62-31.13Z"
      />
      <path
        onClick={()=> {
            window.location.hash = "de-minimis";
            props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"))
        }}
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
        onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("de")}}
        style={{
          fill: props.vennActive === "de" ? "rgb(29 182 255)" : "initial",
          opacity: props.vennActive === "de" ? 1 : .05 }}
        className="notOnHover"
        d="M157.88 222.98c28.01-50.47 81.44-84.86 143-85.92l1.02-125.03c-38.67.24-76.25 7.95-111.73 22.96-34.76 14.7-65.95 35.72-92.7 62.48-18.64 18.64-34.5 39.45-47.37 62.11-5.6 9.86-10.65 20.06-15.1 30.59-3.48 8.23-6.55 16.59-9.25 25.03l118.6 39.61c3.42-11.15 7.97-21.81 13.53-31.83Z"
      />
      <path
//         onClick={()=>props.dispatch(props.setSelectedModal("COPYRIGHT EXCEPTIONS"))}
        onClick={()=>props.dispatch(props.setSelectedModal("m_exceptions"))}
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
        onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("ex")}}
        style={{
          fill: props.vennActive === "ex" ? "rgb(29 182 255)" : "initial",
          opacity: props.vennActive === "ex" ? 1 : .05 }}
        className="notOnHover"
        d="M303.78 137.02c60.51 0 113.49 32.23 142.72 80.46 6.03 9.95 11.03 20.59 14.89 31.75l119.24-37.67a290.761 290.761 0 0 0-25.35-55.71c-12.48-21.21-27.59-40.76-45.2-58.37-26.75-26.75-57.94-47.77-92.7-62.48-36.05-15.25-74.27-22.98-113.61-22.98-.63 0-1.25.02-1.88.02l-1.02 125.03c.97-.02 1.93-.05 2.9-.05Z" />

      <path
        onClick={()=>props.dispatch(props.setSelectedModal("m_copyright-duration"))}
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
        onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("ou")}}
        style={{
          fill: props.vennActive === "ou" ? "rgb(29 182 255)" : "initial",
          opacity: props.vennActive === "ou" ? 1 : .05 }}
          className="notOnHover"d="M306.79 470.51c-1 .02-2.01.04-3.01.04-35.61 0-68.61-11.17-95.7-30.19l-74.32 100.56a290.637 290.637 0 0 0 56.42 31.65c36.05 15.25 74.27 22.98 113.61 22.98.67 0 1.33-.02 1.99-.02 38.63-.26 76.17-7.96 111.61-22.96 21.28-9 41.21-20.38 59.62-33.98l-72.67-101.77c-27.21 20.6-60.93 33.04-97.54 33.69Z"
      />
      <path
        onClick={()=>{
         window.location.hash = "open-licences";
         props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"));
          }}
        className="notOnHover"
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
        onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("op")}}
        style={{
          fill: props.vennActive === "op" ? "rgb(29 182 255)" : "initial",
          opacity: props.vennActive === "op" ? 1 : .05 }}
        d="M461.39 249.23c5.92 17.1 9.15 35.45 9.15 54.56 0 54.34-25.99 102.59-66.21 133.04L477 538.6a293.471 293.471 0 0 0 33.08-28.49c26.75-26.75 47.78-57.94 62.48-92.7 15.25-36.05 22.98-74.27 22.98-113.61 0-31.65-5.01-62.57-14.91-92.23l-119.24 37.67Z" />

        <path
        onClick={()=>{

                window.location.hash = "idea-expression";
        props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"))}}
        onMouseOut={(e) => {
                    console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
                    if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "cls-3-none-3" || e.relatedTarget.className.baseVal === "mid--label") return;
                    if ((e.relatedTarget.className.baseVal || "").indexOf("notOnHover") === -1) props.updateVennState("initial");

                }}
        onMouseOver={() => {
        if (props.selectedModal === "m_exploitation") return;
        props.updateVennActive("id")}}
        style={{
          fill: props.vennActive === "id" ? "rgb(29 182 255)" : "initial",
          opacity: props.vennActive === "id" ? 1 : .05 }}
          className="notOnHover"d="M137.02 303.78c0-17.04 2.58-33.48 7.33-48.96l-118.6-39.61c-9.11 28.54-13.73 58.22-13.73 88.57 0 39.33 7.73 77.56 22.98 113.61 14.7 34.76 35.72 65.95 62.48 92.7a293.048 293.048 0 0 0 36.29 30.83l74.32-100.56c-42.97-30.17-71.06-80.09-71.06-136.58Z"
      />
      <g className="cls-3-none-5">
        <path
          className="cls-3-none-3"
          d="m301.92 9.02.08-9M300.88 137.07l1.02-125.03"
        />
      </g>
      <g className="cls-3-none-5">
        <path
          className="cls-3-none-3"
          d="m22.85 214.24-8.53-2.85M144.35 254.82l-118.6-39.61"
        />
      </g>
      <g className="cls-3-none-5">
        <path
          className="cls-3-none-3"
          d="m131.96 543.35-5.34 7.22M208.08 440.36l-74.32 100.55"
        />
      </g>
      <g className="cls-3-none-5">
        <path
          className="cls-3-none-3"
          d="m478.76 541.04 5.22 7.31M404.33 436.82l72.68 101.76"
        />
      </g>
      <g className="cls-3-none-5">
        <path
          className="cls-3-none-3"
          d="m583.54 210.64 8.57-2.71M461.39 249.23l117.45-37.11 1.79-.56"
        />
      </g>
    </g>}
          </>
          }
  </svg>
  </>
)}

export default SvgComponent
