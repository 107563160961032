// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { qlMapping } from '../../App'

const initialState = {
  value: '',
  previousValue: '',
  previousPreviousValue: '',
};

export const qmn = {
    "20": "Protection",
    "21": "Use",
    "22": "Exploitation",
    "23": "Enforcement",
}

const counterSlice = createSlice({
  name: 'selectedModal',
  initialState,
  reducers: {
    setSelectedModal(state, action) {

    let newValue = action.payload;
    if (newValue === "m_") {
        newValue = ""
    }

       if (((state.value || "").indexOf("m_") > -1 || state.value == "") && state.value !== state.previousValue && state.value !== state.previousValue) state.previousPreviousValue = state.previousValue;
       if (((state.value || "").indexOf("m_") > -1 || state.value == "") && state.value !== newValue) state.previousValue = state.value;

      state.value = newValue;


    },
    // decrement
    // reset
  },
});

export const { setSelectedModal } = counterSlice.actions;
export default counterSlice.reducer;
