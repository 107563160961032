import * as React from "react"
import { getTranslationMap } from '../../features/translationMap';
import PieRB from './PiesB';

const SvgComponent = (props) => {
    const { selectedTrans, selectedModal, styleCircleVenn, isMobile } = props;
  const [hoverSelected, setHoverSelected] = React.useState("");
  const [initialize, setInitialized] = React.useState(false);
  const [initialize2, setInitialized2] = React.useState(false);

  // on mount wait 200ms then setInitialized to true
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setInitialized(true);

            const timer2 = setTimeout(() => {
                        setInitialized2(true);
                    }, 50);
                    return () => clearTimeout(timer2);

        }, 10);
        return () => clearTimeout(timer);
    })

return (<div style={{ overflow: 'hidden' }}>
        { !isMobile && <><PieRB
            initialize={initialize2}
            className={ initialize  ?  "pies--explode--fast" : ""}
            selectedTrans={props.selectedTrans}
            style={{
                width: isMobile ? styleCircleVenn.width * (isMobile ? 1.65 : 1) : '100%',
                height: styleCircleVenn.height * (isMobile ? 1.65 : 1),
                opacity: !initialize2 ? '0' : '.6',
                visibility: initialize ? 'visible' : 'hidden',
                pointerEvents: 'none',
                transition: 'all 1s ease-in-out',
                margin: isMobile ? 'initial' : '0% auto 0% -25%',
                left: isMobile ? styleCircleVenn.width * .08 : '',//'auto',
                transform: !initialize2 ? "rotate(0deg)" : "scale(1.0725) rotate(35deg)" ,
                 top: 'auto', position: 'absolute',
//                  left: isMobile ? '4%' : 'calc(3% + 250px)',
            }}
        />
        <PieRB
            initialize={initialize2}
            className={ initialize  ?  "pies--explode--fast" : ""}
            selectedTrans={props.selectedTrans}
            style={{
                width: isMobile ? styleCircleVenn.width * (isMobile ? 1.65 : 1) : '100%',
                height: styleCircleVenn.height * (isMobile ? 1.65 : 1),
                opacity: !initialize2 ? '0' : '.7',
                visibility: initialize ? 'visible' : 'hidden',
                pointerEvents: 'none',
                transition: 'all 1s ease-in-out',
                margin: isMobile ? 'initial' : !initialize2 ? '0% auto 0% -25%' : '5% auto 0% -25%',
                left: isMobile ? styleCircleVenn.width * .08 : '',//'auto',
                transform: !initialize2 ? "rotate(0deg)" : "scale(1.025) rotate(40deg)" ,
                 top: 'auto', position: 'absolute',
            }}
        />
        <PieRB
            initialize={initialize2}
            className={ initialize  ?  "pies--explode--fast" : ""}
            selectedTrans={props.selectedTrans}
            style={{
                width: isMobile ? styleCircleVenn.width * (isMobile ? 1.65 : 1) : '100%',
                height: styleCircleVenn.height * (isMobile ? 1.65 : 1),
                opacity: !initialize2 ? '0' : '.8',
                visibility: initialize ? 'visible' : 'hidden',
                pointerEvents: 'none',
                transition: 'all 1s ease-in-out',
                margin: isMobile ? 'initial' : !initialize2 ? '0% auto 0% -25%' : '10% auto 0% -25%',
                left: isMobile ? styleCircleVenn.width * .08 : '',//'auto',
                transform: !initialize2 ? "rotate(0deg)" : "scale(.85) rotate(50deg)" ,
                 top: 'auto', position: 'absolute',
            }}
        />
        <PieRB
            initialize={initialize2}
            className={ initialize  ?  "pies--explode--fast" : ""}
            selectedTrans={props.selectedTrans}
            style={{
                width: isMobile ? styleCircleVenn.width * (isMobile ? 1.65 : 1) : '100%',
                height: styleCircleVenn.height * (isMobile ? 1.65 : 1),
                opacity: !initialize2 ? '0' : '.9',
                visibility: initialize ? 'visible' : 'hidden',
                pointerEvents: 'none',
                transition: 'all 1s ease-in-out',
                margin: isMobile ? 'initial'  : !initialize2 ? '0% auto 0% -25%' :  '15% auto 0% -25%',
                left: isMobile ? styleCircleVenn.width * .08 : '',//'auto',
                transform: !initialize2 ? "rotate(0deg)" : "scale(.75) rotate(55deg)" ,
                top: 'auto', position: 'absolute',
            }}
        />
        <PieRB
            initialize={initialize2}
            className={ initialize  ?  "pies--explode--fast" : ""}
            selectedTrans={props.selectedTrans}
            style={{
                width: isMobile ? styleCircleVenn.width * (isMobile ? 1.65 : 1) : '100%',
                height: styleCircleVenn.height * (isMobile ? 1.65 : 1),
                opacity: !initialize2 ? '0' : '1',
                visibility: initialize ? 'visible' : 'hidden',
                pointerEvents: 'none',
                transition: 'all 1s ease-in-out',
                margin: isMobile ? 'initial' : !initialize2 ? '0% auto 0% -25%' : '20% auto 0% -25%',
                left: isMobile ? styleCircleVenn.width * .08 : '',//'auto',
                transform: !initialize2 ? "rotate(0deg)" : "scale(.65) rotate(60deg)" ,
                top: 'auto', position: 'absolute',
            }}
        />
        </> }
      <svg
        viewBox={"150 200 1840 1240"}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <defs>
          <clipPath clipPathUnits="userSpaceOnUse" id="d">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="e">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="f">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="g">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="h">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="i">
            <path d="M242 309h230v220H242Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="k">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="l">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="m">
            <path d="M0 1226h1380V0H0Z" />
          </clipPath>
           <pattern
                  patternTransform="matrix(.85103 0 0 .85103 2100.67 -3400.78)"
                  patternUnits="userSpaceOnUse"
                  x={0}
                  y={0}
                  width={179.4}
                  height={310.5}
                  id="a"
                >
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 17.249)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 17.249)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.1 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(104.638 250.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(119.576 241.495)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(134.514 232.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(149.452 224.246)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(164.39 215.621)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.979 94.338)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(84.979 146.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.421 94.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 327.749)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 301.588)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 327.749)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(85.279 249.588)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 301.588)"
                  />
                </pattern>
                <pattern
                  patternTransform="matrix(.85103 0 0 .85103 1972.02 -3400.78)"
                  patternUnits="userSpaceOnUse"
                  x={0}
                  y={0}
                  width={179.4}
                  height={310.5}
                  id="b"
                >
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 17.249)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 17.249)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.1 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(104.638 250.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(119.576 241.495)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(134.514 232.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(149.452 224.246)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(164.39 215.621)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.979 94.338)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(84.979 146.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.421 94.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 327.749)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 301.588)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 327.749)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(85.279 249.588)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 301.588)"
                  />
                </pattern>
                <pattern
                  patternTransform="matrix(.85103 0 0 .85103 1843.37 -3400.78)"
                  patternUnits="userSpaceOnUse"
                  x={0}
                  y={0}
                  width={179.4}
                  height={310.5}
                  id="c"
                >
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 17.249)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 17.249)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.1 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(104.638 250.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(119.576 241.495)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(134.514 232.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(149.452 224.246)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(164.39 215.621)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.979 94.338)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(84.979 146.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.421 94.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 327.749)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 301.588)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 327.749)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(85.279 249.588)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#d4155b",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#d4155b",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 301.588)"
                  />
                </pattern>
                <pattern
                  patternTransform="matrix(.85103 0 0 .85103 2350.37 -2579.043)"
                  patternUnits="userSpaceOnUse"
                  x={0}
                  y={0}
                  width={179.4}
                  height={310.5}
                  id="j"
                >
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 17.248)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 103.494)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 94.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 86.245)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 77.62)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 68.996)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 60.371)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 51.747)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 34.498)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 17.248)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 -.035)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 -8.912)"
                  />
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.1 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.748v-103.494"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 258.744)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(104.638 250.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(119.576 241.495)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(134.514 232.87)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(149.452 224.246)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(164.39 215.621)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.328 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.329 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.7 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.979 94.338)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(107.7 155.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(84.979 146.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 206.997)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-89.7 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 189.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 172.499)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 155.25)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 138)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 120.751)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(-.071 103.502)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18.3 103.215)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.421 94.338)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(194.338 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(209.276 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(224.214 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(239.152 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(254.09 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(179.4 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 327.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(269.029 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(197.4 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(174.679 301.587)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 413.994)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(14.938 405.37)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(29.876 396.745)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(44.814 388.12)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(59.752 379.496)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(74.69 370.871)"
                  />
                  <path
                    d="m0 0-89.629-51.747v-103.495"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 362.247)"
                  />
                  <path
                    d="m0 0 89.629 51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(0 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 344.998)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 327.748)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 310.5)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 293.25)"
                  />
                  <path
                    d="m0 0-89.629-51.748"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 276.001)"
                  />
                  <path
                    d="m0 0-89.629-51.747"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeOpacity: 1,
                      strokeWidth: 3,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(89.629 258.752)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(108 258.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(85.279 249.588)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
                    style={{
                      fill: "#e45b83",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
                    style={{
                      fill: "none",
                      stroke: "#e45b83",
                      strokeOpacity: 1,
                      strokeWidth: 1,
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      strokeDasharray: "none",
                    }}
                    transform="translate(18 310.465)"
                  />
                  <path
                    d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
                    style={{
                      fill: "#000",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    transform="translate(-4.721 301.587)"
                  />
                </pattern>
        </defs>
        <path
          d="M1340 818.167h-230v220h230z"
            className={ hoverSelected === "topRight" ? "shadowSvgElement" : "" }
          onMouseOver={() => setHoverSelected("topRight")}
          onMouseOut={() => setHoverSelected("")}
          style={{
            fill: "rgb(220, 220, 220)",
            fillOpacity: 1,pointerEvents: "all",
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="matrix(1.33333 0 0 -1.33333 0 1634.667)"
        />
        <path
          d="M1110 818.167h230v220h-230z"
          style={{
            fill: hoverSelected === "topRight" ? "url(#a)" : "rgb(238, 238, 238)" ,
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="matrix(1.33333 0 0 -1.33333 0 1634.667)"
        />
        <path
            className={ hoverSelected === "topMid" ? "shadowSvgElement" : "" }
          onMouseOver={() => setHoverSelected("topMid")}
          onMouseOut={() => setHoverSelected("")}
          d="M906 818.167H676v220h230z"
          style={{
            fill: "rgb(220, 220, 220)",
            fillOpacity: 1,pointerEvents: "all",
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="matrix(1.33333 0 0 -1.33333 0 1634.667)"
        />
        <path
          d="M676 818.167h230v220H676Z"
          style={{
            fill: hoverSelected === "topMid" ? "url(#b)" : "rgb(238, 238, 238)",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="matrix(1.33333 0 0 -1.33333 0 1634.667)"
        />
        <path
          d="M472 818.167H242v220h230z"
          className={ hoverSelected === "topLeft" ? "shadowSvgElement" : "" }
          style={{
            fill: "rgb(220, 220, 220)",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="matrix(1.33333 0 0 -1.33333 0 1634.667)"
        />
        <path
        onMouseOver={() => setHoverSelected("topLeft")}
        onMouseOut={() => setHoverSelected("")}
          d="M242 818.167h230v220H242Z"
          style={{
            fill:  hoverSelected === "topLeft" ? "url(#c)" : "rgb(238, 238, 238)" ,
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            pointerEvents: "all",
          }}
          transform="matrix(1.33333 0 0 -1.33333 0 1634.667)"
        />
        <path
          d="M1266.5 970.507h-83v28.5h83z"
          style={{
            fill: "rgb(220, 220, 220)",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="matrix(1.33333 0 0 -1.33333 0 1634.667)"
        />
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 1601.003 330.025)"
          style={{

            fontWeight: 700,

            fontSize: 20,
            writingMode: "lr-tb",
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan y={0} className={"notranslate"} >
            {getTranslationMap(selectedTrans, "State")}
          </tspan>
        </text>
        <g clipPath="url(#d)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M0 0c-23.379 0-42.4 19.021-42.4 42.4 0 23.38 19.021 42.4 42.4 42.4 23.379 0 42.4-19.02 42.4-42.4C42.4 19.021 23.379 0 0 0"
            style={{
              fill: "#000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1225 862.306)"
          />
          <path
            d="M0 0c-23.931 0-43.4-19.469-43.4-43.4 0-23.931 19.469-43.4 43.4-43.4 23.931 0 43.4 19.469 43.4 43.4C43.4-19.469 23.931 0 0 0m0-2c22.865 0 41.4-18.535 41.4-41.4S22.865-84.8 0-84.8s-41.4 18.535-41.4 41.4S-22.865-2 0-2"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1225 948.106)"
          />
          <path
            d="M0 0h-30.64a.665.665 0 0 1-.68-.68v-2.675c0-.389.293-.68.68-.68H-.05c.39 0 .68.293.68.68V-.68C.68-.293.34 0 0 0Z"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1240.32 888.616)"
          />
          <path
            d="M0 0h-2.772c-1.119 5.447-5.739 9.63-11.379 10.116v4.621h5.836c.437 0 .68.486.437.875l-1.896 2.529 1.896 2.529c.243.34 0 .876-.437.876h-7.297a.854.854 0 0 1-.827-.827V10.166C-22.081 9.68-26.701 5.496-27.818.05h-2.772a.665.665 0 0 1-.68-.681v-2.674c0-.39.293-.681.68-.681H0c.39 0 .68.293.68.681v2.674C.68-.34.34 0 0 0Z"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1240.32 909.285)"
          />
          <path
            d="M1233.657 903.449h4.037v-13.082h-4.037zM1226.556 903.449h4.037v-13.082h-4.037zM1219.407 903.449h4.037v-13.082h-4.037zM1212.305 903.449h4.037v-13.082h-4.037z"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
          <path
            d="M941 340.022H641v286.957h300z"
            style={{
              fill: "#000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 964.178 881.793)"
          style={{

            fontWeight: 700,

            fontSize: "31.1441px",
            writingMode: "lr-tb",
            fill: "#fff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan
            className={"notranslate"}
            y={0}
          >
            {getTranslationMap(selectedTrans, "Infringers")}
          </tspan>
        </text>
        <g clipPath="url(#e)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M241.5 167.5h1098v73h-1098z"
            style={{
              fill: "#eee",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "#dcdcdc",
              strokeWidth: 1,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
          />
        </g>
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 955.814 1369.504)"
          style={{

            fontWeight: 500,

            fontSize: 16,
            writingMode: "lr-tb",
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan className={"notranslate"} y={0}>
            {getTranslationMap(selectedTrans, "Stop")}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 812.22 1371.213)"
          style={{

            fontWeight: 500,

            fontSize: 20,
            writingMode: "lr-tb",
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan className={"notranslate"} y={0}>
            {getTranslationMap(selectedTrans, "Key")}
          </tspan>
        </text>
        <g clipPath="url(#f)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M0 0c-8.821 0-16-7.179-16-16 0-8.826 7.179-16 16-16s16 7.174 16 16C16-7.179 8.821 0 0 0Z"
            style={{
              fill: "none",
              stroke: "gray",
              strokeWidth: 1.194,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(694.3 220)"
          />
          <path
            d="M0 0a1.668 1.668 0 0 0-1.179-2.851c-.429 0-.855.163-1.185.487l-3.53 3.534-3.53-3.534a1.666 1.666 0 0 0-1.179-.487A1.668 1.668 0 0 0-11.787 0l3.529 3.534-3.529 3.529a1.668 1.668 0 0 0 0 2.364 1.677 1.677 0 0 0 2.363 0l3.53-3.533 3.53 3.533a1.679 1.679 0 0 0 2.364 0 1.668 1.668 0 0 0 0-2.364l-3.53-3.529Z"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(700.194 200.467)"
          />
          <path
            d="M0 0c-.303.27-.776.27-1.046-.034-1.584-1.585-3.607-2.428-5.63-2.428-2.968 0-5.799 1.821-7.418 4.822h7.688c.438 0 .776.337.776.776v2.63a.764.764 0 0 1-.776.776h-9.037c-.067.506-.067 1.012-.067 1.483 0 .472.034.977.102 1.517h9.002c.438 0 .776.337.776.776v2.63a.764.764 0 0 1-.776.776l-7.688-.001c1.619 2.967 4.418 4.822 7.418 4.822 2.057 0 4.046-.878 5.63-2.462.303-.303.776-.303 1.046-.034l2.933 2.697c.169.134.236.337.27.573a.909.909 0 0 1-.203.573c-2.663 2.697-6.069 4.18-9.644 4.18-5.9 0-11.26-4.147-13.385-10.318h-3.237a.763.763 0 0 1-.776-.776v-2.63c0-.438.337-.776.776-.776h2.36c-.034-.54-.067-1.045-.067-1.517 0-.471.034-.977.067-1.483h-2.36a.763.763 0 0 1-.776-.776v-2.63c0-.438.337-.776.776-.776h3.237c2.124-6.17 7.485-10.318 13.385-10.318C-3.069-7.928.37-6.445 3-3.748a.815.815 0 0 1 .236.573c0 .203-.101.405-.27.573z"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(800.704 195.928)"
          />
          <path
            d="M0 0a4.668 4.668 0 0 0-4.666 4.666A4.668 4.668 0 0 0 0 9.332a4.668 4.668 0 0 0 4.666-4.666A4.67 4.67 0 0 0 0 0Zm3.334 11.94V13a1.674 1.674 0 0 1-1.668 1.666h-3.332A1.674 1.674 0 0 1-3.334 13v-1.054A8.007 8.007 0 0 1-5.951-.678 8.003 8.003 0 0 1 6.88.572a9.958 9.958 0 0 0 0 8.188 8.044 8.044 0 0 1-3.546 3.18z"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(888.47 191.334)"
          />
          <path
            d="M0 0a1.34 1.34 0 0 1 1.334 1.334v2a1.333 1.333 0 0 1-2.668 0v-2A1.34 1.34 0 0 1 0 0"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(888.47 208)"
          />
          <path
            d="m0 0 1.378 1.375c.248.246.388.58.388.93a1.313 1.313 0 0 1-2.245.925l-1.377-1.376A1.31 1.31 0 0 1 0 0"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(891.376 214.379)"
          />
          <path
            d="M0 0v-2a1.334 1.334 0 0 1 2.667 0v2A1.333 1.333 0 0 1 0 0"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(903.136 211.334)"
          />
          <path
            d="M0 0a1.31 1.31 0 0 1 1.85-.004 1.307 1.307 0 0 1-.001 1.85L.459 3.235a1.315 1.315 0 0 1-1.852.002 1.31 1.31 0 0 1 .003-1.848Z"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(901.576 214.382)"
          />
          <path
            d="M0 0a1.335 1.335 0 0 1-1.334 1.334h-1.332a1.333 1.333 0 1 1 0-2.666h1.333-.001C-.597-1.332 0-.736 0 0"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(898.47 218.666)"
          />
          <path
            d="M0 0a4.668 4.668 0 0 0-4.666 4.666A4.668 4.668 0 0 0 0 9.332a4.668 4.668 0 0 0 4.666-4.666A4.67 4.67 0 0 0 0 0Zm3.334 11.946V13a1.672 1.672 0 0 1-1.668 1.666h-3.332A1.672 1.672 0 0 1-3.334 13v-1.054A8.004 8.004 0 0 1-6.286-.275 8.001 8.001 0 0 1 7.776 6.553a8 8 0 0 1-4.442 5.393Z"
            style={{
              fill: "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(904.47 191.334)"
          />
        </g>
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 1080.622 1369.504)"
          style={{

            fontWeight: 500,

            fontSize: 16,
            writingMode: "lr-tb",
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan
            className={"notranslate"}
            y={0}
          >
            {getTranslationMap(selectedTrans, "Money")}
          </tspan>
        </text>

        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 1230.622 1369.504)"
          style={{

            fontWeight: 500,

            fontSize: 16,
            writingMode: "lr-tb",
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan
            className={"notranslate"} y={0}
          >
            {getTranslationMap(selectedTrans, "Penalty")}
          </tspan>
        </text>
        <g clipPath="url(#g)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M0 0h153.236"
            style={{
              fill: "none",
              stroke: hoverSelected === "bottomLeft" ? "rgb(212, 21, 91)" : "#c8c8c8",
              strokeWidth: 12,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(472 419)"
          />
          <path
            d="m0 0 12.952 7.478L0 14.958Z"
            style={{
              fill: hoverSelected === "bottomLeft" ? "rgb(212, 21, 91)" : "#c8c8c8",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(623.048 411.521)"
          />
          <path
            d="M0 0h188.236"
            style={{
              fill: "none",
              stroke: hoverSelected === "topLeft" ? "rgb(212, 21, 91)" : "#c8c8c8",
              strokeWidth: 12,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(472 928.167)"
          />
          <path
            d="m0 0 12.952 7.478L0 14.958Z"
            style={{
              fill: hoverSelected === "topLeft" ? "rgb(212, 21, 91)" : "#c8c8c8",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(658.048 920.688)"
          />
          <path
            d="M0 0h-188.236"
            style={{
              fill: "none",
              stroke: hoverSelected === "topRight" ? "rgb(212, 21, 91)" : "#c8c8c8",
              strokeWidth: 12,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(1110 928.167)"
          />
          <path
            d="m0 0-12.952-7.478L0-14.958Z"
            style={{
              fill: hoverSelected === "topRight" ? "rgb(212, 21, 91)" : "#c8c8c8",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(923.952 935.645)"
          />
          <path
            d="M0 0c0-27.614 22.386-50 50-50s50 22.386 50 50-22.386 50-50 50S0 27.614 0 0"
            style={{
              fill: hoverSelected === "topRight" ? "rgb(212, 21, 91)" :  "#e2e2e2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(966.5 928.167)"
          />
          <path
            d="M0 0c-6.806 0-12.346-5.539-12.346-12.346 0-6.81 5.54-12.345 12.346-12.345s12.346 5.535 12.346 12.345C12.346-5.539 6.806 0 0 0Z"
            style={{
              fill: "none",
              stroke: hoverSelected === "topRight" ? "white" : "gray",
              strokeWidth: 1.194,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(999.356 940.512)"
          />
          <path
            d="M0 0a1.287 1.287 0 0 0-.91-2.2 1.3 1.3 0 0 0-.914.376L-4.548.903l-2.723-2.727a1.291 1.291 0 0 0-1.824 0 1.286 1.286 0 0 0 0 1.824l2.723 2.727-2.723 2.723a1.286 1.286 0 0 0 0 1.824 1.295 1.295 0 0 0 1.824 0l2.723-2.726 2.724 2.726a1.295 1.295 0 0 0 1.824 0A1.287 1.287 0 0 0 0 5.45l-2.724-2.723Z"
            style={{
              fill: hoverSelected === "topRight" ? "white" :"gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1003.904 925.44)"
          />
          <path
            d="M0 0c-.234.208-.599.208-.807-.026C-2.03-1.249-3.59-1.899-5.151-1.899c-2.29 0-4.475 1.404-5.724 3.72h5.932a.59.59 0 0 1 .599.599v2.029a.59.59 0 0 1-.599.599h-6.973c-.051.39-.051.78-.051 1.144 0 .364.026.754.078 1.171h6.946a.59.59 0 0 1 .599.598v2.03a.59.59 0 0 1-.599.599l-5.932-.001c1.249 2.289 3.409 3.72 5.724 3.72 1.587 0 3.121-.677 4.344-1.899.234-.234.599-.234.807-.027l2.263 2.082c.13.103.182.26.208.442a.7.7 0 0 1-.156.442C.26 17.43-2.368 18.574-5.126 18.574c-4.553 0-8.689-3.2-10.328-7.961h-2.499a.589.589 0 0 1-.598-.599V7.985c0-.339.26-.599.598-.599h1.822a18.583 18.583 0 0 1-.052-1.171c0-.363.026-.754.052-1.144h-1.822a.589.589 0 0 1-.598-.599V2.443a.59.59 0 0 1 .598-.599h2.499c1.639-4.761 5.775-7.961 10.328-7.961 2.758 0 5.411 1.144 7.441 3.225a.63.63 0 0 1 .182.442.636.636 0 0 1-.209.442z"
            style={{
              fill: hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1045.69 921.938)"
          />
          <path
            className="leftTopNode"
            d="M0 0c0-27.614-22.386-50-50-50s-50 22.386-50 50 22.386 50 50 50S0 27.614 0 0"
            style={{
              fill: hoverSelected === "topLeft" ? "rgb(212, 21, 91)" : "#e2e2e2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(616 928.167)"
          />
          <path
            className="leftTopNode_child"
            d="M0 0c-6.806 0-12.346-5.539-12.346-12.346 0-6.81 5.54-12.345 12.346-12.345s12.346 5.535 12.346 12.345C12.346-5.539 6.806 0 0 0Z"
            style={{
              fill: "none",
              stroke: hoverSelected === "topLeft" ? "white" : "gray",
              strokeWidth: 1.194,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(546.66 940.512)"
          />
          <path
            className="leftTopNode_child"
            d="M0 0a1.287 1.287 0 0 0-.91-2.2 1.3 1.3 0 0 0-.914.376L-4.548.903l-2.723-2.727a1.291 1.291 0 0 0-1.824 0 1.287 1.287 0 0 0 0 1.824l2.723 2.727-2.723 2.723a1.286 1.286 0 0 0 0 1.824 1.295 1.295 0 0 0 1.824 0l2.723-2.726 2.724 2.726a1.295 1.295 0 0 0 1.824 0A1.287 1.287 0 0 0 0 5.45l-2.724-2.723Z"
            style={{
              fill:  hoverSelected === "topLeft" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(551.207 925.44)"
          />
          <path
            className="leftTopNode_child"
            d="M0 0c-.234.208-.599.208-.807-.026-1.222-1.223-2.783-1.873-4.344-1.873-2.29 0-4.475 1.404-5.724 3.72h5.932a.59.59 0 0 1 .599.599v2.029a.59.59 0 0 1-.599.599h-6.973c-.051.39-.051.78-.051 1.144 0 .364.026.754.078 1.171h6.946c.338 0 .598.26.598.598v2.03a.59.59 0 0 1-.598.599l-5.932-.001c1.249 2.289 3.409 3.72 5.724 3.72 1.587 0 3.121-.677 4.344-1.899.234-.234.599-.234.807-.027l2.263 2.082c.13.103.182.26.208.442a.7.7 0 0 1-.156.442C.26 17.43-2.368 18.574-5.126 18.574c-4.553 0-8.689-3.2-10.328-7.961h-2.498a.589.589 0 0 1-.599-.599V7.985c0-.339.26-.599.599-.599h1.821a18.583 18.583 0 0 1-.052-1.171c0-.363.026-.754.052-1.144h-1.821a.589.589 0 0 1-.599-.599V2.443a.59.59 0 0 1 .599-.599h2.498c1.639-4.761 5.775-7.961 10.328-7.961 2.758 0 5.411 1.144 7.441 3.225a.63.63 0 0 1 .182.442.639.639 0 0 1-.208.442z"
            style={{
              fill:  hoverSelected === "topLeft" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(592.992 921.938)"
          />
          <path
            d="M0 0v-370c0-11.046 8.954-20 20-20h38.736"
            style={{
              fill: "none",
              stroke: hoverSelected === "topLeft" ? "rgb(212, 21, 91)" : "#c8c8c8",
              strokeWidth: 12,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(566.5 878)"
          />
          <path
            d="m0 0 12.952 7.478L0 14.958Z"
            style={{
              fill: hoverSelected === "topLeft" ? "rgb(212, 21, 91)" : "#c8c8c8",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(623.048 480.521)"
          />
          <path
            d="M398.5 970.507h-83v28.5h83z"
            style={{
              fill: "rgb(212, 21, 91)",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
          <path
            d="M870 970.507H710v28.5h160z"
            style={{
              fill: "rgb(220, 220, 220)",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 437.452 330.025)"
          style={{

            fontWeight: 700,

            fontSize: 20,
            writingMode: "lr-tb",
            fill: "#fff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan className={"notranslate"} y={0}>
            {getTranslationMap(selectedTrans, "Owner")}
          </tspan>
        </text>
        <g clipPath="url(#h)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M0 0c-23.379 0-42.4 19.021-42.4 42.4 0 23.38 19.021 42.4 42.4 42.4 23.379 0 42.4-19.02 42.4-42.4C42.4 19.021 23.379 0 0 0"
            style={{
              fill: "rgb(212, 21, 91)",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(357 862.306)"
          />
          <path
            d="M0 0c-23.931 0-43.4-19.469-43.4-43.4 0-23.931 19.469-43.4 43.4-43.4 23.931 0 43.4 19.469 43.4 43.4C43.4-19.469 23.931 0 0 0m0-2c22.865 0 41.4-18.535 41.4-41.4S22.865-84.8 0-84.8s-41.4 18.535-41.4 41.4S-22.865-2 0-2"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(357 948.106)"
          />
          <path
            d="M0 0h-17.238c-1.646 0-2.722 1.03-2.749 2.666a62.62 62.62 0 0 0 .018 3.494c.055 1.379.726 2.436 1.922 3.089 3.925 2.142 7.87 4.238 11.795 6.371.285.157.46.01.672-.101 3.355-1.756 6.776-1.903 10.269-.441.101.037.211.064.294.138.726.616 1.379.368 2.133-.046 3.521-1.94 7.07-3.843 10.619-5.737 1.536-.819 2.289-2.051 2.262-3.788-.019-.984 0-1.959 0-2.942C19.988 1.075 18.903 0 17.285 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(357.002 885.706)"
          />
          <path
            d="M0 0c-3.236.11-5.921 1.71-7.897 4.551-3.237 4.661-2.85 11.281.855 15.528 4.027 4.606 10.701 4.579 14.691-.055 4.239-4.928 3.972-12.706-.616-17.174C5.158 1.021 2.915.037 0 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(356.718 902.154)"
          />
          <path
            d="M472 309H242v220h230z"
            className={ hoverSelected === "bottomLeft" ? "shadowSvgElement" : "" }
            onMouseOver={() => setHoverSelected("bottomLeft")}
            onMouseOut={() => setHoverSelected("")}

            style={{
              fill: "rgb(220, 220, 220)",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",pointerEvents: "all",
            }}
          />
        </g>
        <g clipPath="url(#i)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M0 0h-497v497L0 487Z"
            style={{
              fill: hoverSelected === "bottomLeft" ? "url(#j)" : "rgb(238, 238, 238)",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(679 119.333)"
          />
        </g>
        <g clipPath="url(#k)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M398.5 458.5h-83V487h83z"
            style={{
              fill: "#e45b83",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 434.926 1012.08)"
          style={{

            fontWeight: 700,

            fontSize: 20,
            writingMode: "lr-tb",
            fill: "#fff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan className={"notranslate"} y={0}>
            {getTranslationMap(selectedTrans, "Author")}
          </tspan>
        </text>
        <g clipPath="url(#l)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M0 0c-23.379 0-42.4 19.021-42.4 42.4 0 23.379 19.021 42.4 42.4 42.4 23.379 0 42.4-19.021 42.4-42.4C42.4 19.021 23.379 0 0 0"
            style={{
              fill: "#e45b8a",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(357 353.765)"
          />
          <path
            d="M0 0c-23.931 0-43.4-19.469-43.4-43.4 0-23.931 19.469-43.4 43.4-43.4 23.931 0 43.4 19.469 43.4 43.4C43.4-19.469 23.931 0 0 0m0-2c22.865 0 41.4-18.535 41.4-41.4S22.865-84.8 0-84.8s-41.4 18.535-41.4 41.4S-22.865-2 0-2"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(357 439.565)"
          />
          <path
            d="M0 0h-17.238c-1.646 0-2.722 1.03-2.749 2.666a62.62 62.62 0 0 0 .018 3.494c.055 1.379.726 2.436 1.922 3.089 3.925 2.142 7.87 4.238 11.795 6.371.285.157.46.01.672-.101 3.355-1.756 6.776-1.903 10.269-.441.101.037.211.064.294.138.726.616 1.379.368 2.133-.046 3.521-1.94 7.07-3.843 10.619-5.737 1.536-.819 2.289-2.051 2.262-3.788-.019-.984 0-1.959 0-2.942C19.988 1.075 18.903 0 17.285 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(357.002 377.165)"
          />
          <path
            d="M0 0c-3.236.11-5.921 1.71-7.897 4.551-3.237 4.661-2.85 11.281.855 15.528 4.027 4.606 10.701 4.579 14.691-.055 4.239-4.928 3.972-12.706-.616-17.174C5.158 1.021 2.915.037 0 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(356.718 393.613)"
          />
        </g>
        <text
          xmlSpace="preserve"
          transform="matrix(1.33333 0 0 1.33333 962.402 330.025)"
          style={{

            fontWeight: 700,

            fontSize: 20,
            writingMode: "lr-tb",
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        >
          <tspan
            className={"notranslate"}
            y={0}
          >
            {getTranslationMap(selectedTrans, "Online Platform")}
          </tspan>
        </text>
        <g clipPath="url(#m)" transform="matrix(1.33333 0 0 -1.33333 0 1634.667)">
          <path
            d="M0 0c-23.379 0-42.4 19.021-42.4 42.4 0 23.38 19.021 42.4 42.4 42.4 23.379 0 42.4-19.02 42.4-42.4C42.4 19.021 23.379 0 0 0"
            style={{
              fill: "#000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none"
            }}
            transform="translate(791 862.306)"
          />
          <path
            d="M0 0c-23.931 0-43.4-19.469-43.4-43.4 0-23.931 19.469-43.4 43.4-43.4 23.931 0 43.4 19.469 43.4 43.4C43.4-19.469 23.931 0 0 0m0-2c22.865 0 41.4-18.535 41.4-41.4S22.865-84.8 0-84.8s-41.4 18.535-41.4 41.4S-22.865-2 0-2"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(791 948.106)"
          />
          <path
            d="M0 0a9.006 9.006 0 0 1 9.007 9.005c0 4.979-4.025 9.021-9.007 9.021-4.979 0-9.021-4.042-9.021-9.021 0-.777.131-1.518.317-2.238l-10.385-5.3a10.65 10.65 0 0 1-8.143 3.778c-5.912 0-10.71-4.796-10.71-10.708 0-5.894 4.798-10.692 10.71-10.692 2.999 0 5.704 1.238 7.643 3.223l6.829-4.057c-.291-.885-.488-1.813-.488-2.797 0-4.965 4.042-9.007 9.021-9.007 4.979 0 9.007 4.041 9.007 9.007 0 4.979-4.026 9.021-9.007 9.021-2.588 0-4.906-1.105-6.55-2.852l-6.629 3.938a10.642 10.642 0 0 1 .261 7.744L-6.911 3.29C-5.258 1.297-2.792 0 0 0Z"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(804.669 910.293)"
          />
          <path
            d="M0 0v-175.425"
            style={{
              fill: "none",
              stroke: hoverSelected.indexOf("top") > -1 ? "rgb(212, 21, 91)" :  "#c8c8c8",
              strokeWidth: 12,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(791 818.167)"
          />
          <path
            d="m0 0 7.478-12.952L14.958 0Z"
            style={{
              fill: hoverSelected.indexOf("top") > -1 ? "rgb(212, 21, 91)" : "#c8c8c8",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(783.521 644.93)"
          />
          <path
            d="M0 0c0-27.614-22.386-50-50-50s-50 22.386-50 50 22.386 50 50 50S0 27.614 0 0"
            style={{
              fill: hoverSelected.indexOf("top") > -1 ? "rgb(212, 21, 91)" : "#e2e2e2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(841 729.072)"
          />
          <path
            d="M0 0c-6.806 0-12.346-5.539-12.346-12.346 0-6.81 5.54-12.345 12.346-12.345s12.346 5.535 12.346 12.345C12.346-5.539 6.806 0 0 0Z"
            style={{
              fill: "none",
              stroke: hoverSelected.indexOf("top") > -1 ? "#fff" : "gray",
              strokeWidth: 1.194,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(771.66 741.418)"
          />
          <path
            d="M0 0a1.287 1.287 0 0 0-.91-2.2 1.3 1.3 0 0 0-.914.376L-4.548.903l-2.723-2.727a1.291 1.291 0 0 0-1.824 0 1.287 1.287 0 0 0 0 1.824l2.723 2.727-2.723 2.723a1.286 1.286 0 0 0 0 1.824 1.295 1.295 0 0 0 1.824 0l2.723-2.726 2.724 2.726a1.295 1.295 0 0 0 1.824 0A1.287 1.287 0 0 0 0 5.45l-2.724-2.723Z"
            style={{
              fill: hoverSelected.indexOf("top") > -1 ? "#fff" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(776.207 726.346)"
          />
          <path
            d="M0 0c-.234.208-.599.208-.807-.026-1.222-1.223-2.783-1.873-4.344-1.873-2.29 0-4.475 1.404-5.724 3.72h5.932a.59.59 0 0 1 .599.599v2.029a.59.59 0 0 1-.599.599h-6.973c-.051.39-.051.78-.051 1.144 0 .364.026.754.078 1.171h6.946c.338 0 .598.26.598.598v2.03c0 .338-.26.598-.598.598h-5.932c1.249 2.289 3.409 3.72 5.724 3.72 1.587 0 3.121-.677 4.344-1.899.234-.234.599-.234.807-.027l2.263 2.082c.13.103.182.26.208.442a.7.7 0 0 1-.156.442C.26 17.43-2.368 18.574-5.126 18.574c-4.553 0-8.689-3.2-10.328-7.961h-2.498a.589.589 0 0 1-.599-.599V7.985c0-.339.26-.599.599-.599h1.821a18.583 18.583 0 0 1-.052-1.171c0-.363.026-.754.052-1.144h-1.821a.589.589 0 0 1-.599-.599V2.443a.59.59 0 0 1 .599-.599h2.498c1.639-4.761 5.775-7.961 10.328-7.961 2.758 0 5.411 1.144 7.441 3.225a.63.63 0 0 1 .182.442.639.639 0 0 1-.208.442z"
            style={{
              fill: hoverSelected.indexOf("top") > -1 ? "#fff" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(817.992 722.844)"
          />
          <path
            d="M0 0c0-27.614-22.386-50-50-50s-50 22.386-50 50 22.386 50 50 50S0 27.614 0 0"
            style={{
              fill: hoverSelected === "bottomLeft" ? "rgb(212, 21, 91)" : "#e2e2e2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(598 419)"
          />
          <path
            d="M0 0c-6.806 0-12.346-5.539-12.346-12.346 0-6.81 5.54-12.345 12.346-12.345s12.346 5.535 12.346 12.345C12.346-5.539 6.806 0 0 0Z"
            style={{
              fill: "none",
              stroke:  hoverSelected === "bottomLeft" ? "#fff" : "gray",
              strokeWidth: 1.194,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(528.66 431.346)"
          />
          <path
            d="M0 0a1.287 1.287 0 0 0-.91-2.2 1.3 1.3 0 0 0-.914.376L-4.548.903l-2.723-2.727a1.291 1.291 0 0 0-1.824 0 1.287 1.287 0 0 0 0 1.824l2.723 2.727-2.723 2.723a1.286 1.286 0 0 0 0 1.824 1.295 1.295 0 0 0 1.824 0l2.723-2.726 2.724 2.726a1.295 1.295 0 0 0 1.824 0A1.287 1.287 0 0 0 0 5.45l-2.724-2.723Z"
            style={{
              fill: hoverSelected === "bottomLeft" ? "#fff" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke:  "none",
            }}
            transform="translate(533.207 416.274)"
          />
          <path
            d="M0 0c-.234.208-.599.208-.807-.026-1.222-1.223-2.783-1.873-4.344-1.873-2.29 0-4.475 1.404-5.724 3.72h5.932a.59.59 0 0 1 .599.599v2.029a.59.59 0 0 1-.599.599h-6.973c-.051.39-.051.78-.051 1.144 0 .364.026.754.078 1.171h6.946c.338 0 .598.26.598.598v2.03a.59.59 0 0 1-.598.599l-5.932-.001c1.249 2.289 3.409 3.72 5.724 3.72 1.587 0 3.121-.677 4.344-1.899.234-.234.599-.234.807-.027l2.263 2.082c.13.103.182.26.208.442a.7.7 0 0 1-.156.442C.26 17.43-2.368 18.574-5.126 18.574c-4.553 0-8.689-3.2-10.328-7.961h-2.498a.589.589 0 0 1-.599-.599V7.985c0-.339.26-.599.599-.599h1.821a18.583 18.583 0 0 1-.052-1.171c0-.363.026-.754.052-1.144h-1.821a.589.589 0 0 1-.599-.599V2.443a.59.59 0 0 1 .599-.599h2.498c1.639-4.761 5.775-7.961 10.328-7.961 2.758 0 5.411 1.144 7.441 3.225a.63.63 0 0 1 .182.442.643.643 0 0 1-.208.443z"
            style={{
              fill: hoverSelected === "bottomLeft" ? "#fff" :"gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke:  "none",
            }}
            transform="translate(574.992 412.772)"
          />
          <path
            d="M0 0v-337.167c0-11.045-8.954-20-20-20h-248.236"
            style={{
              fill: "none",
              stroke: hoverSelected === "topRight" ? "rgb(212, 21, 91)" : "#c8c8c8",
              strokeWidth: 12,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(1225 818.167)"
          />
          <path
            d="m0 0-12.952-7.478L0-14.958Z"
            style={{
              fill: hoverSelected === "topRight" ? "rgb(212, 21, 91)" : "#c8c8c8",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(958.952 468.479)"
          />
          <path
            d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0"
            style={{
              fill: hoverSelected === "topRight" ? "rgb(212, 21, 91)" : "#e2e2e2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1164 461.5)"
          />
          <path
            d="M0 0c-10.21 0-18.519-8.309-18.519-18.519 0-10.215 8.309-18.518 18.519-18.518 10.209 0 18.518 8.303 18.518 18.518C18.518-8.309 10.209 0 0 0Z"
            style={{
              fill: "none",
              stroke:  hoverSelected === "topRight" ? "white" :  "gray",
              strokeWidth: 1.194,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(1059.99 505.771)"
          />
          <path
            d="M0 0a1.93 1.93 0 0 0-1.365-3.299c-.496 0-.989.187-1.371.563l-4.085 4.09-4.086-4.09a1.926 1.926 0 0 0-1.365-.563A1.93 1.93 0 0 0-13.643 0l4.086 4.09-4.086 4.085a1.93 1.93 0 0 0 0 2.736 1.941 1.941 0 0 0 2.736 0l4.085-4.09 4.086 4.09a1.941 1.941 0 0 0 2.736 0 1.93 1.93 0 0 0 0-2.736L-4.086 4.09Z"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1066.81 483.163)"
          />
          <path
            d="M0 0c-.351.312-.898.312-1.21-.04-1.834-1.833-4.176-2.809-6.517-2.809-3.434 0-6.712 2.107-8.585 5.581h8.898c.507 0 .897.39.897.898v3.044c0 .508-.39.898-.897.898h-10.459c-.078.585-.078 1.171-.078 1.716 0 .546.04 1.131.117 1.756h10.419c.508 0 .898.391.898.898v3.044c0 .508-.39.898-.898.898l-8.898-.001c1.874 3.434 5.113 5.581 8.586 5.581 2.381 0 4.683-1.016 6.516-2.849.351-.351.898-.351 1.211-.04l3.395 3.122c.195.155.272.39.312.663 0 .235-.078.468-.235.663-3.082 3.122-7.024 4.838-11.161 4.838-6.83 0-13.034-4.8-15.493-11.942h-3.747a.884.884 0 0 1-.898-.898v-3.044c0-.508.391-.898.898-.898h2.732c-.04-.625-.078-1.21-.078-1.756s.04-1.131.078-1.716h-2.732a.884.884 0 0 1-.898-.898V3.664c0-.507.391-.897.898-.897h3.747c2.459-7.142 8.663-11.943 15.493-11.943 4.137 0 8.117 1.717 11.161 4.839a.94.94 0 0 1 .273.663c0 .234-.117.468-.312.663z"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1129.489 477.91)"
          />
          <path
            d="M0 0a5.403 5.403 0 0 0-5.401 5.401A5.401 5.401 0 1 0 0 0Zm3.858 13.819v1.227a1.935 1.935 0 0 1-1.93 1.928h-3.856a1.936 1.936 0 0 1-1.931-1.928v-1.22a9.27 9.27 0 0 1-5.208-6.539A9.264 9.264 0 0 1 7.963.663a11.522 11.522 0 0 0 0 9.476 9.304 9.304 0 0 1-4.105 3.68z"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1079.74 417.615)"
          />
          <path
            d="M0 0a1.55 1.55 0 0 1 1.544 1.544v2.314a1.543 1.543 0 0 1-3.088 0V1.544A1.55 1.55 0 0 1 0 0"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1079.74 436.905)"
          />
          <path
            d="m0 0 1.594 1.591A1.518 1.518 0 1 1-.554 3.738l-1.594-1.592A1.517 1.517 0 0 1 0 0"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1083.104 444.288)"
          />
          <path
            d="M0 0v-2.315a1.544 1.544 0 0 1 3.087 0V0A1.542 1.542 0 0 1 0 0"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1096.715 440.763)"
          />
          <path
            d="M0 0a1.514 1.514 0 1 1 2.14 2.137L.531 3.744c-.592.589-1.55.59-2.143.003a1.516 1.516 0 0 1 .003-2.14z"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1094.91 444.292)"
          />
          <path
            d="M0 0a1.546 1.546 0 0 1-1.544 1.544h-1.542a1.543 1.543 0 1 1 0-3.086h1.544-.002C-.692-1.542 0-.852 0 0"
            style={{
              fill:  hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1091.315 449.25)"
          />
          <path
            d="M0 0a5.403 5.403 0 0 0-5.401 5.401A5.401 5.401 0 1 0 0 0Zm3.859 13.827v1.219a1.937 1.937 0 0 1-1.93 1.929h-3.857a1.935 1.935 0 0 1-1.93-1.929v-1.219A9.26 9.26 0 0 1 0-3.85a9.26 9.26 0 0 1 3.859 17.677Z"
            style={{
              fill: hoverSelected === "topRight" ? "white" : "gray",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(1098.259 417.615)"
          />
          <path
            d="M0 0h-11.882v5.299c0 1.316-.282 2.58-.823 3.794V54.88H0Zm-15.382 12.607c-2.16 1.867-5.09 2.992-8.246 2.992h-11.626c-3.141 0-6.063-1.114-8.223-2.973V54.88h12.711V39.012c0-.041.019-.074.021-.112a8.34 8.34 0 0 1-7.052-8.25c0-4.615 3.734-11.039 8.362-11.039 4.615 0 8.363 6.421 8.363 11.039 0 4.177-3.055 7.614-7.041 8.247.002.038.021.074.021.115V54.88h12.711zm-30.772-3.478c-.549-1.223-.848-2.509-.848-3.83V0H-58.87v54.881h12.716zM1.338 57.557h-61.545a1.341 1.341 0 0 1-1.337-1.337V-1.337c0-.737.604-1.337 1.337-1.337H1.338c.736 0 1.337.603 1.337 1.337V56.22c0 .734-.601 1.337-1.337 1.337"
            style={{
              fill: hoverSelected === "topRight" ? "rgb(212, 21, 91)" :   "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(894.788 393.652)"
          />
          <path
            d="M0 0h-11.882v5.299c0 1.316-.282 2.58-.823 3.794V54.88H0Zm-15.382 12.607c-2.16 1.867-5.09 2.992-8.246 2.992h-11.626c-3.141 0-6.063-1.114-8.223-2.973V54.88h12.711V39.012c0-.041.019-.074.021-.112a8.34 8.34 0 0 1-7.052-8.25c0-4.615 3.734-11.039 8.362-11.039 4.615 0 8.363 6.421 8.363 11.039 0 4.177-3.055 7.614-7.041 8.247.002.038.021.074.021.115V54.88h12.711zm-30.772-3.478c-.549-1.223-.848-2.509-.848-3.83V0H-58.87v54.881h12.716zM1.338 57.557h-61.545a1.341 1.341 0 0 1-1.337-1.337V-1.337c0-.737.604-1.337 1.337-1.337H1.338c.736 0 1.337.603 1.337 1.337V56.22c0 .734-.601 1.337-1.337 1.337"
            style={{
              fill: hoverSelected === "topRight" ? "rgb(212, 21, 91)" :   "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(894.527 471.652)"
          />
          <path
            d="M0 0c-16.267 0-29.5 13.233-29.5 29.5S-16.267 59 0 59s29.5-13.233 29.5-29.5S16.267 0 0 0"
            style={{
              fill: hoverSelected === "topLeft" ||  hoverSelected === "topMid"  ||  hoverSelected === "topRight"  ||  hoverSelected === "bottomLeft"  ? "rgb(212, 21, 91)" : "#000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(716.037 391.5)"
          />
          <path
            d="M0 0c-16.818 0-30.5-13.682-30.5-30.5C-30.5-47.318-16.818-61 0-61c16.818 0 30.5 13.682 30.5 30.5C30.5-13.682 16.818 0 0 0m0-2c15.74 0 28.5-12.76 28.5-28.5S15.74-59 0-59s-28.5 12.76-28.5 28.5S-15.74-2 0-2"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(716.037 451.5)"
          />
          <path
            d="M0 0h-11.867C-13 0-13.74.709-13.759 1.835c-.013.804-.019 1.602.012 2.406.038.949.5 1.677 1.323 2.126 2.703 1.475 5.418 2.918 8.12 4.386.197.108.317.006.462-.069 2.311-1.209 4.665-1.311 7.07-.304.069.025.145.044.202.095.5.424.95.253 1.469-.032a633.875 633.875 0 0 1 7.31-3.95c1.057-.563 1.576-1.411 1.557-2.607-.013-.677 0-1.348 0-2.025C13.76.74 13.013 0 11.899 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(716.039 407.92)"
          />
          <path
            d="M0 0c-2.228.076-4.076 1.177-5.437 3.133-2.227 3.209-1.962 7.766.589 10.69 2.772 3.171 7.367 3.152 10.114-.038C8.184 10.392 8 5.038 4.842 1.962 3.551.703 2.006.025 0 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(715.843 419.243)"
          />
          <path
            d="M0 0c-16.267 0-29.5 13.233-29.5 29.5S-16.267 59 0 59s29.5-13.233 29.5-29.5S16.267 0 0 0"
            style={{
              fill: hoverSelected === "topLeft" ||  hoverSelected === "topMid"  ||  hoverSelected === "topRight"  ||  hoverSelected === "bottomLeft"  ? "rgb(212, 21, 91)" : "#000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(716.037 468.5)"
          />
          <path
            d="M0 0c-16.818 0-30.5-13.682-30.5-30.5C-30.5-47.318-16.818-61 0-61c16.818 0 30.5 13.682 30.5 30.5C30.5-13.682 16.818 0 0 0m0-2c15.74 0 28.5-12.76 28.5-28.5S15.74-59 0-59s-28.5 12.76-28.5 28.5S-15.74-2 0-2"
            style={{
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(716.037 528.5)"
          />
          <path
            d="M0 0h-11.867C-13 0-13.74.709-13.759 1.835c-.013.804-.019 1.602.012 2.406.038.949.5 1.677 1.323 2.126 2.703 1.475 5.418 2.918 8.12 4.386.197.108.317.006.462-.069 2.311-1.209 4.665-1.311 7.07-.304.069.025.145.044.202.095.5.424.95.253 1.469-.032a633.875 633.875 0 0 1 7.31-3.95c1.057-.563 1.576-1.411 1.557-2.607-.013-.677 0-1.348 0-2.025C13.76.74 13.013 0 11.899 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(716.039 484.92)"
          />
          <path
            d="M0 0c-2.228.076-4.076 1.177-5.437 3.133-2.227 3.209-1.962 7.766.589 10.69 2.772 3.171 7.367 3.152 10.114-.038C8.184 10.392 8 5.038 4.842 1.962 3.551.703 2.006.025 0 0Z"
            style={{
              fill: "#fafafa",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            transform="translate(715.843 496.243)"
          />
          <path
            d="M0 0v-139"
            style={{
              fill: "none",
              stroke: "#4d4d4d",
              strokeWidth: 12,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="translate(791 529.5)"
          />
        </g>
      </svg>
  </div>
)}

export default SvgComponent
