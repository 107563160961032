import * as React from "react"
import { getTranslationMap } from '../../features/translationMap';

const SvgComponent = (props) => {
const usingWith = props.selectedModal === "m_use--with-permission";
const usingWithout = props.selectedModal === "m_use--without-permission";
const {selectedTrans } = props;
return (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 215.12 463.77"
    {...props}
  >
    <defs>
      <pattern
        id="New_Pattern_Swatch_6"
        x={0}
        y={0}
        width={452.97}
        height={452.97}
        patternTransform={ props.isMobile ? "translate(-1851.36 8553.87) scale(1.6)" : "translate(-1851.36 8553.87)" }
        patternUnits="userSpaceOnUse"
      >
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h452.97v452.97H0z"
        />
        <path
          style={{
            fill: "#ffdd08",
          }}
          d="M0 0h452.97v452.97H0z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M7.18 41.75h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M16.84 41.75h1.85C15.31 34.27 8.45 28.49.01 26.15v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M25.83 41.75h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 52.35H2.51c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M12.58 52.35H10.9c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M20.97 52.35h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M29.36 52.35h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 52.35c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48H4.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M10.9 52.35h1.68c0-3.91-1.24-7.54-3.36-10.59H7.18c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M19.29 52.35h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M27.68 52.35h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M449.93 41.75a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 32.82v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 26.57c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 17.2v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM433.68 7.05H432c0 6.47 5.63 11.71 12.58 11.71V17.2c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 7.05h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 7.05h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 40.63c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M433.68 7.05c0-2.74 1.18-5.22 3.07-7.05h-2.2C432.96 1.96 432 4.4 432 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 7.05c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 7.05c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M408.51 7.05c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM444.58 62.5v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 71.87c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 78.12v1.56c2.92 0 5.73-.4 8.39-1.14V76.9c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 85.93v1.56c2.88 0 5.69-.31 8.39-.88V85c-2.69.6-5.5.93-8.39.93ZM444.58 87.49v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 79.68v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 71.87v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 64.06V62.5c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM444.58 41.75v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M438.62 41.75c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M7.18 132.35h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M16.84 132.35h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M25.83 132.35h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 142.94H2.51c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M12.58 142.94H10.9c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M20.97 142.94h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M29.36 142.94h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 142.94c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48H4.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M10.9 142.94h1.68c0-3.91-1.24-7.54-3.36-10.59H7.18c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M19.29 142.94h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M27.68 142.94h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M449.93 132.35a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 123.42v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 117.17c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 107.8v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM433.68 97.64H432c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 97.64h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 97.64h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 131.23c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M433.68 97.64c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 97.64c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 97.64c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M408.51 97.64c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM444.58 153.09v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 162.47c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 168.71v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 176.52v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM444.58 178.09v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 170.28v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 162.47v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 154.66v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM444.58 132.35v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M438.62 132.35c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M7.18 222.94h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M16.84 222.94h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M25.83 222.94h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 233.54H2.51c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M12.58 233.54H10.9c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M20.97 233.54h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M29.36 233.54h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 233.54c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48H4.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M10.9 233.54h1.68c0-3.91-1.24-7.54-3.36-10.59H7.18c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M19.29 233.54h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M27.68 233.54h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M449.93 222.94a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 214.01v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 207.76c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 198.39v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM433.68 188.24H432c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 188.24h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 188.24h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 221.82c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M433.68 188.24c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 188.24c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 188.24c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M408.51 188.24c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM444.58 243.69v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 253.06c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 259.31v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 267.12v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM444.58 268.68v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 260.87v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 253.06v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 245.25v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM444.58 222.94v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M438.62 222.94c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M7.18 313.54h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M16.84 313.54h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M25.83 313.54h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 324.13H2.51c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M12.58 324.13H10.9c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M20.97 324.13h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M29.36 324.13h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 324.13c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48H4.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M10.9 324.13h1.68c0-3.91-1.24-7.54-3.36-10.59H7.18c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M19.29 324.13h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M27.68 324.13h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M449.93 313.54a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 304.61v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 298.36c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 288.99v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM433.68 278.83H432c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 278.83h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 278.83h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 312.42c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M433.68 278.83c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 278.83c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 278.83c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M408.51 278.83c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM444.58 334.28v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 343.65c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 349.9v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 357.71v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM444.58 359.27v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 351.46v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 343.65v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 335.84v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM444.58 313.54v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M438.62 313.54c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M7.18 404.13h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M16.84 404.13h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M25.83 404.13h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 414.72H2.51c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M12.58 414.72H10.9c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M20.97 414.72h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M29.36 414.73h-1.68c0 15.83-11.83 29.13-27.68 32.66V449c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M4.19 414.72c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48H4.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M10.9 414.72h1.68c0-3.91-1.24-7.54-3.36-10.59H7.18c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M19.29 414.72h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M27.68 414.72h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M72.45 41.75a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 32.82v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 26.57c2.92 0 5.74.43 8.39 1.21v-1.64C72.84 25.4 70.03 25 67.11 25v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 17.2v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM56.2 7.05h-1.68c0 6.47 5.63 11.71 12.58 11.71V17.2c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 7.05h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 7.05h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 40.63c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M56.2 7.05c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 7.05c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 7.05c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M31.04 7.05c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM67.11 62.5v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 71.87c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 78.12v1.56c2.92 0 5.73-.4 8.39-1.14V76.9c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 85.93v1.56c2.88 0 5.69-.31 8.39-.88V85c-2.69.6-5.5.93-8.39.93ZM67.11 87.49v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 79.68v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 71.87v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 64.06V62.5c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM67.11 41.75v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM61.14 41.75c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M82.67 41.75h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M92.33 41.75h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M101.33 41.75h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 52.35h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M88.08 52.35H86.4c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M96.47 52.35h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M104.85 52.35h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 52.35c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M86.4 52.35h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M94.79 52.35h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M103.18 52.35h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M72.45 132.35a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 123.42v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 117.17c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 107.8v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM56.2 97.64h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 97.64h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 97.64h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 131.23c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M56.2 97.64c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 97.64c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 97.64c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M31.04 97.64c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM67.11 153.09v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 162.47c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 168.71v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 176.52v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM67.11 178.09v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 170.28v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 162.47v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 154.66v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM67.11 132.35v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM61.14 132.35c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M82.67 132.35h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M92.33 132.35h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M101.33 132.35h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 142.94h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M88.08 142.94H86.4c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M96.47 142.94h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M104.85 142.94h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 142.94c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M86.4 142.94h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M94.79 142.94h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M103.18 142.94h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M72.45 222.94a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 214.01v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 207.76c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 198.39v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM56.2 188.24h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 188.24h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 188.24h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 221.82c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M56.2 188.24c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 188.24c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 188.24c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M31.04 188.24c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM67.11 243.69v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 253.06c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 259.31v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 267.12v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM67.11 268.68v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 260.87v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 253.06v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 245.25v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM67.11 222.94v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM61.14 222.94c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M82.67 222.94h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M92.33 222.94h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M101.33 222.94h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 233.54h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M88.08 233.54H86.4c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M96.47 233.54h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M104.85 233.54h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 233.54c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M86.4 233.54h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M94.79 233.54h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M103.18 233.54h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M72.45 313.54a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 304.61v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 298.36c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 288.99v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM56.2 278.83h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 278.83h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 278.83h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 312.42c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M56.2 278.83c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 278.83c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 278.83c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M31.04 278.83c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM67.11 334.28v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 343.65c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 349.9v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 357.71v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM67.11 359.27v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 351.46v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 343.65v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 335.84v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM67.11 313.54v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM61.14 313.54c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M82.67 313.54h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M92.33 313.54h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M101.33 313.54h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 324.13h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M88.08 324.13H86.4c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M96.47 324.13h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M104.85 324.13h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 324.13c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M86.4 324.13h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M94.79 324.13h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M103.18 324.13h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M72.45 404.13a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 395.2v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 388.95c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 379.58v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM56.2 369.43h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 369.43h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 369.43h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 403.01c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M56.2 369.43c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M47.81 369.43c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M39.43 369.43c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M31.04 369.43c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM67.11 424.88v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 434.25c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 440.5v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 448.31v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM67.11 449.87v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 442.06v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M67.11 434.25v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M67.11 426.44v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM67.11 404.13v.44c3.37 0 6.39 1.43 8.39 3.67V406c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM61.14 404.13c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M82.67 404.13h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M92.33 404.13h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M101.33 404.13h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 414.72h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M88.08 414.72H86.4c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M96.47 414.72h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M104.85 414.73h-1.68c0 15.83-11.83 29.13-27.68 32.66V449c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M79.69 414.72c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M86.4 414.72h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M94.79 414.72h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M103.18 414.72h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M146.45 41.75a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 32.82v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 26.57c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 17.2v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM130.2 7.05h-1.68c0 6.47 5.63 11.71 12.58 11.71V17.2c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 7.05h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 7.05h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 40.63c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M130.2 7.05c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 7.05c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 7.05c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M105.04 7.05c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM141.11 62.5v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 71.87c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 78.12v1.56c2.92 0 5.73-.4 8.39-1.14V76.9c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 85.93v1.56c2.88 0 5.69-.31 8.39-.88V85c-2.69.6-5.5.93-8.39.93ZM141.11 87.49v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 79.68v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 71.87v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 64.06V62.5c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM141.11 41.75v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM135.14 41.75c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M156.67 41.75h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M166.33 41.75h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M175.33 41.75h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 52.35h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M162.08 52.35h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M170.47 52.35h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M178.85 52.35h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 52.35c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M160.4 52.35h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M168.79 52.35h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M177.18 52.35h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M146.45 132.35a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 123.42v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 117.17c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 107.8v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM130.2 97.64h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 97.64h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 97.64h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 131.23c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M130.2 97.64c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 97.64c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 97.64c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M105.04 97.64c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM141.11 153.09v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 162.47c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 168.71v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 176.52v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM141.11 178.09v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 170.28v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 162.47v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 154.66v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM141.11 132.35v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM135.14 132.35c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M156.67 132.35h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M166.33 132.35h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M175.33 132.35h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 142.94h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M162.08 142.94h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M170.47 142.94h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M178.85 142.94h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 142.94c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M160.4 142.94h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M168.79 142.94h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M177.18 142.94h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M146.45 222.94a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 214.01v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 207.76c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 198.39v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM130.2 188.24h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 188.24h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 188.24h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 221.82c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M130.2 188.24c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 188.24c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 188.24c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M105.04 188.24c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM141.11 243.69v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 253.06c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 259.31v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 267.12v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM141.11 268.68v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 260.87v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 253.06v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 245.25v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM141.11 222.94v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM135.14 222.94c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M156.67 222.94h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M166.33 222.94h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M175.33 222.94h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 233.54h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M162.08 233.54h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M170.47 233.54h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M178.85 233.54h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 233.54c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M160.4 233.54h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M168.79 233.54h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M177.18 233.54h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M146.45 313.54a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 304.61v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 298.36c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 288.99v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM130.2 278.83h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 278.83h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 278.83h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 312.42c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M130.2 278.83c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 278.83c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 278.83c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M105.04 278.83c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM141.11 334.28v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 343.65c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 349.9v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 357.71v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM141.11 359.27v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 351.46v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 343.65v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 335.84v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM141.11 313.54v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM135.14 313.54c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M156.67 313.54h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M166.33 313.54h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M175.33 313.54h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 324.13h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M162.08 324.13h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M170.47 324.13h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M178.85 324.13h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 324.13c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M160.4 324.13h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M168.79 324.13h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M177.18 324.13h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M146.45 404.13a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 395.2v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 388.95c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 379.58v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM130.2 369.43h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 369.43h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 369.43h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 403.01c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M130.2 369.43c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M121.81 369.43c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M113.43 369.43c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M105.04 369.43c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM141.11 424.88v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 434.25c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 440.5v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 448.31v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM141.11 449.87v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 442.06v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M141.11 434.25v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M141.11 426.44v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM141.11 404.13v.44c3.37 0 6.39 1.43 8.39 3.67V406c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM135.14 404.13c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M156.67 404.13h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M166.33 404.13h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M175.33 404.13h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 414.72h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M162.08 414.72h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M170.47 414.72h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M178.85 414.73h-1.68c0 15.83-11.83 29.13-27.68 32.66V449c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M153.69 414.72c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M160.4 414.72h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M168.79 414.72h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M177.18 414.72h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M222.45 41.75a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 32.82v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 26.57c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 17.2v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM206.2 7.05h-1.68c0 6.47 5.63 11.71 12.58 11.71V17.2c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 7.05h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 7.05h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 40.63c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M206.2 7.05c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 7.05c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 7.05c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M181.04 7.05c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM217.11 62.5v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 71.87c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 78.12v1.56c2.92 0 5.73-.4 8.39-1.14V76.9c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 85.93v1.56c2.88 0 5.69-.31 8.39-.88V85c-2.69.6-5.5.93-8.39.93ZM217.11 87.49v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 79.68v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 71.87v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 64.06V62.5c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM217.11 41.75v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM211.14 41.75c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M232.67 41.75h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M242.33 41.75h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M251.33 41.75h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 52.35h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M238.08 52.35h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M246.47 52.35h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M254.85 52.35h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 52.35c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M236.4 52.35h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M244.79 52.35h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M253.18 52.35h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M222.45 132.35a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 123.42v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 117.17c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 107.8v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM206.2 97.64h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 97.64h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 97.64h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 131.23c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M206.2 97.64c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 97.64c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 97.64c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M181.04 97.64c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM217.11 153.09v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 162.47c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 168.71v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 176.52v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM217.11 178.09v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 170.28v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 162.47v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 154.66v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM217.11 132.35v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM211.14 132.35c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M232.67 132.35h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M242.33 132.35h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M251.33 132.35h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 142.94h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M238.08 142.94h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M246.47 142.94h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M254.85 142.94h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 142.94c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M236.4 142.94h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M244.79 142.94h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M253.18 142.94h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M222.45 222.94a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 214.01v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 207.76c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 198.39v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM206.2 188.24h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 188.24h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 188.24h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 221.82c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M206.2 188.24c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 188.24c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 188.24c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M181.04 188.24c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM217.11 243.69v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 253.06c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 259.31v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 267.12v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM217.11 268.68v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 260.87v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 253.06v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 245.25v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM217.11 222.94v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM211.14 222.94c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M232.67 222.94h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M242.33 222.94h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M251.33 222.94h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 233.54h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M238.08 233.54h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M246.47 233.54h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M254.85 233.54h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 233.54c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M236.4 233.54h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M244.79 233.54h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M253.18 233.54h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M222.45 313.54a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 304.61v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 298.36c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 288.99v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM206.2 278.83h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 278.83h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 278.83h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 312.42c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M206.2 278.83c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 278.83c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 278.83c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M181.04 278.83c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM217.11 334.28v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 343.65c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 349.9v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 357.71v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM217.11 359.27v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 351.46v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 343.65v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 335.84v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM217.11 313.54v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM211.14 313.54c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M232.67 313.54h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M242.33 313.54h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M251.33 313.54h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 324.13h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M238.08 324.13h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M246.47 324.13h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M254.85 324.13h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 324.13c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M236.4 324.13h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M244.79 324.13h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M253.18 324.13h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M222.45 404.13a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 395.2v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 388.95c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 379.58v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM206.2 369.43h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 369.43h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 369.43h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 403.01c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M206.2 369.43c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M197.81 369.43c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M189.43 369.43c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M181.04 369.43c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM217.11 424.88v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 434.25c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 440.5v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 448.31v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM217.11 449.87v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 442.06v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M217.11 434.25v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M217.11 426.44v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM217.11 404.13v.44c3.37 0 6.39 1.43 8.39 3.67V406c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM211.14 404.13c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M232.67 404.13h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M242.33 404.13h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M251.33 404.13h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 414.72h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M238.08 414.72h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M246.47 414.72h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M254.85 414.73h-1.68c0 15.83-11.83 29.13-27.68 32.66V449c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M229.69 414.72c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M236.4 414.72h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M244.79 414.72h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M253.18 414.72h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M298.45 41.75a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 32.82v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 26.57c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 17.2v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM282.2 7.05h-1.68c0 6.47 5.63 11.71 12.58 11.71V17.2c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 7.05h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 7.05h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 40.63c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M282.2 7.05c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 7.05c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 7.05c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M257.04 7.05c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM293.11 62.5v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 71.87c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 78.12v1.56c2.92 0 5.73-.4 8.39-1.14V76.9c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 85.93v1.56c2.88 0 5.69-.31 8.39-.88V85c-2.69.6-5.5.93-8.39.93ZM293.11 87.49v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 79.68v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 71.87v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 64.06V62.5c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM293.11 41.75v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM287.14 41.75c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M308.67 41.75h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M318.33 41.75h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M327.33 41.75h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 52.35h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M314.08 52.35h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M322.47 52.35h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M330.85 52.35h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 52.35c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M312.4 52.35h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M320.79 52.35h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M329.18 52.35h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M298.45 132.35a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 123.42v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 117.17c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 107.8v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM282.2 97.64h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 97.64h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 97.64h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 131.23c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M282.2 97.64c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 97.64c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 97.64c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M257.04 97.64c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM293.11 153.09v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 162.47c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 168.71v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 176.52v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM293.11 178.09v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 170.28v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 162.47v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 154.66v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM293.11 132.35v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM287.14 132.35c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M308.67 132.35h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M318.33 132.35h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M327.33 132.35h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 142.94h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M314.08 142.94h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M322.47 142.94h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M330.85 142.94h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 142.94c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M312.4 142.94h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M320.79 142.94h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M329.18 142.94h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M298.45 222.94a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 214.01v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 207.76c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 198.39v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM282.2 188.24h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 188.24h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 188.24h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 221.82c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M282.2 188.24c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 188.24c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 188.24c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M257.04 188.24c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM293.11 243.69v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 253.06c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 259.31v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 267.12v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM293.11 268.68v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 260.87v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 253.06v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 245.25v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM293.11 222.94v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM287.14 222.94c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M308.67 222.94h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M318.33 222.94h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M327.33 222.94h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 233.54h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M314.08 233.54h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M322.47 233.54h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M330.85 233.54h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 233.54c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M312.4 233.54h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M320.79 233.54h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M329.18 233.54h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M298.45 313.54a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 304.61v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 298.36c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 288.99v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM282.2 278.83h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 278.83h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 278.83h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 312.42c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M282.2 278.83c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 278.83c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 278.83c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M257.04 278.83c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM293.11 334.28v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 343.65c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 349.9v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 357.71v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM293.11 359.27v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 351.46v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 343.65v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 335.84v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM293.11 313.54v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM287.14 313.54c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M308.67 313.54h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M318.33 313.54h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M327.33 313.54h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 324.13h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M314.08 324.13h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M322.47 324.13h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M330.85 324.13h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 324.13c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M312.4 324.13h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M320.79 324.13h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M329.18 324.13h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M298.45 404.13a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 395.2v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 388.95c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 379.58v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM282.2 369.43h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 369.43h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 369.43h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 403.01c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M282.2 369.43c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M273.81 369.43c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M265.43 369.43c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M257.04 369.43c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM293.11 424.88v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 434.25c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 440.5v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 448.31v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM293.11 449.87v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 442.06v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M293.11 434.25v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M293.11 426.44v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM293.11 404.13v.44c3.37 0 6.39 1.43 8.39 3.67V406c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM287.14 404.13c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M308.67 404.13h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M318.33 404.13h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M327.33 404.13h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 414.72h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M314.08 414.72h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M322.47 414.72h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M330.85 414.73h-1.68c0 15.83-11.83 29.13-27.68 32.66V449c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M305.69 414.72c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M312.4 414.72h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M320.79 414.72h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M329.18 414.72h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M374.45 41.75a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 32.82v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 26.57c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 17.2v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM358.2 7.05h-1.68c0 6.47 5.63 11.71 12.58 11.71V17.2c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 7.05h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 7.05h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 40.63c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M358.2 7.05c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 7.05c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 7.05c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M333.04 7.05c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM369.11 62.5v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 71.87c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 78.12v1.56c2.92 0 5.73-.4 8.39-1.14V76.9c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 85.93v1.56c2.88 0 5.69-.31 8.39-.88V85c-2.69.6-5.5.93-8.39.93ZM369.11 87.49v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 79.68v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 71.87v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 64.06V62.5c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM369.11 41.75v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM363.14 41.75c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M384.67 41.75h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M394.33 41.75h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M403.33 41.75h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 52.35h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M390.08 52.35h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M398.47 52.35h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M406.85 52.35h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 52.35c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M388.4 52.35h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M396.79 52.35h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M405.18 52.35h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M374.45 132.35a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 123.42v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 117.17c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 107.8v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM358.2 97.64h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 97.64h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 97.64h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 131.23c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M358.2 97.64c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 97.64c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 97.64c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M333.04 97.64c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM369.11 153.09v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 162.47c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 168.71v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 176.52v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM369.11 178.09v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 170.28v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 162.47v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 154.66v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM369.11 132.35v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM363.14 132.35c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M384.67 132.35h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M394.33 132.35h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M403.33 132.35h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 142.94h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M390.08 142.94h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M398.47 142.94h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M406.85 142.94h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 142.94c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M388.4 142.94h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M396.79 142.94h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M405.18 142.94h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M374.45 222.94a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 214.01v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 207.76c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 198.39v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM358.2 188.24h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 188.24h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 188.24h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 221.82c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M358.2 188.24c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 188.24c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 188.24c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M333.04 188.24c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM369.11 243.69v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 253.06c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 259.31v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 267.12v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM369.11 268.68v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 260.87v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 253.06v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 245.25v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM369.11 222.94v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM363.14 222.94c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M384.67 222.94h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M394.33 222.94h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M403.33 222.94h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 233.54h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M390.08 233.54h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M398.47 233.54h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M406.85 233.54h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 233.54c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M388.4 233.54h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M396.79 233.54h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M405.18 233.54h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M374.45 313.54a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 304.61v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 298.36c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 288.99v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM358.2 278.83h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 278.83h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 278.83h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 312.42c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M358.2 278.83c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 278.83c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 278.83c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M333.04 278.83c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM369.11 334.28v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 343.65c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 349.9v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 357.71v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM369.11 359.27v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 351.46v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 343.65v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 335.84v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM369.11 313.54v.44c3.37 0 6.39 1.43 8.39 3.67v-2.24c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM363.14 313.54c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M384.67 313.54h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M394.33 313.54h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M403.33 313.54h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 324.13h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M390.08 324.13h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M398.47 324.13h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M406.85 324.13h-1.68c0 15.83-11.83 29.13-27.68 32.66v1.61c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 324.13c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M388.4 324.13h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M396.79 324.13h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M405.18 324.13h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M374.45 404.13a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 395.2v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 388.95c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 379.58v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM358.2 369.43h-1.68c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 369.43h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 369.43h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 403.01c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M358.2 369.43c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M349.81 369.43c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M341.43 369.43c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M333.04 369.43c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM369.11 424.88v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 434.25c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 440.5v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 448.31v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM369.11 449.87v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 442.06v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M369.11 434.25v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M369.11 426.44v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM369.11 404.13v.44c3.37 0 6.39 1.43 8.39 3.67V406c-.91-.76-1.93-1.39-3.04-1.88h-5.34ZM363.14 404.13c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M384.67 404.13h2.04c-2.23-3.2-5.43-5.76-9.22-7.3v1.72c2.86 1.29 5.32 3.22 7.18 5.58Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M394.33 404.13h1.85c-3.38-7.48-10.24-13.26-18.68-15.6v1.64c7.5 2.23 13.62 7.36 16.84 13.97Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M403.33 404.13h1.78c-4-11.8-14.51-20.9-27.61-23.67v1.61c12.15 2.71 21.92 11.14 25.83 22.06Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 414.72h-1.68c0 2.46-.95 4.72-2.52 6.48v2.24c2.57-2.14 4.19-5.25 4.19-8.71Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M390.08 414.72h-1.68c0 7.11-4.46 13.26-10.9 16.17v1.72c7.41-3.01 12.58-9.89 12.58-17.9Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M398.47 414.72h-1.68c0 11.49-8.12 21.24-19.29 24.56v1.64c12.13-3.36 20.97-13.81 20.97-26.2Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M406.85 414.73h-1.68c0 15.83-11.83 29.13-27.68 32.66V449c16.81-3.55 29.36-17.54 29.36-34.26Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M381.69 414.72c0-3.47-1.63-6.57-4.19-8.71v2.24c1.57 1.76 2.52 4.02 2.52 6.48h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M388.4 414.72h1.68c0-3.91-1.24-7.54-3.36-10.59h-2.04c2.34 2.97 3.73 6.63 3.73 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M396.79 414.72h1.68c0-3.76-.82-7.34-2.29-10.59h-1.85c1.57 3.23 2.46 6.82 2.46 10.59Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M405.18 414.72h1.68c0-3.69-.61-7.25-1.75-10.59h-1.78c1.19 3.33 1.85 6.89 1.85 10.59Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M449.93 404.13a13.29 13.29 0 0 0-5.34-1.12v1.12h5.34Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 395.2v1.56c3 0 5.85.64 8.39 1.79v-1.72c-2.57-1.05-5.4-1.63-8.39-1.63Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 388.95c2.92 0 5.74.43 8.39 1.21v-1.64c-2.66-.74-5.47-1.14-8.39-1.14v1.56Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 379.58v1.56c2.89 0 5.69.33 8.39.93v-1.61c-2.7-.57-5.5-.88-8.39-.88ZM433.68 369.43H432c0 6.47 5.63 11.71 12.58 11.71v-1.56c-6.01 0-10.9-4.55-10.9-10.15Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 369.43h-1.68c0 10.78 9.39 19.52 20.97 19.52v-1.56c-10.64 0-19.29-8.06-19.29-17.96Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 369.43h-1.68c0 15.1 13.14 27.33 29.36 27.33v-1.56c-15.26 0-27.68-11.56-27.68-25.77Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 403.01c-19.89 0-36.07-15.07-36.07-33.58h-1.68c0 17.52 13.77 32.04 31.78 34.7h5.97v-1.12Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M433.68 369.43c0-2.74 1.18-5.22 3.07-7.05h-2.2c-1.59 1.96-2.55 4.4-2.55 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M425.29 369.43c0-2.5.55-4.88 1.55-7.05h-1.81a18.29 18.29 0 0 0-1.41 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M416.9 369.43c0-2.44.38-4.81 1.06-7.05h-1.75a25.71 25.71 0 0 0-.99 7.05h1.68Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M408.51 369.43c0-2.42.28-4.77.81-7.05h-1.72c-.5 2.28-.76 4.63-.76 7.05h1.68ZM444.58 424.88v1.56c3.23 0 6.16-1.14 8.39-3v-2.24c-2 2.24-5.02 3.67-8.39 3.67Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 434.25c2.98 0 5.82-.58 8.39-1.63v-1.72a20.285 20.285 0 0 1-8.39 1.79v1.56Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 440.5v1.56c2.92 0 5.73-.4 8.39-1.14v-1.64c-2.65.79-5.46 1.21-8.39 1.21Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 448.31v1.56c2.88 0 5.69-.31 8.39-.88v-1.61c-2.69.6-5.5.93-8.39.93ZM444.58 449.87v-1.56c-4.1 0-7.74 1.84-10.03 4.67h2.2c1.98-1.91 4.76-3.1 7.83-3.1Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 442.06v-1.56c-8.91 0-16.52 5.18-19.56 12.48h1.81c2.95-6.41 9.79-10.91 17.74-10.91Z"
        />
        <path
          className="cls-Venn2-2-3-2"
          d="M444.58 434.25v-1.56c-13.6 0-25.03 8.61-28.37 20.29h1.75c3.3-10.79 13.98-18.72 26.62-18.72Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M444.58 426.44v-1.56c-18.25 0-33.48 12.06-36.99 28.1h1.72c3.49-15.14 17.97-26.53 35.26-26.53ZM444.58 404.13v.44c3.37 0 6.39 1.43 8.39 3.67V406c-.91-.76-1.93-1.39-3.04-1.88h-5.34Z"
        />
        <path
          className="cls-Venn2-2-4"
          d="M438.62 404.13c1.94.29 3.94.44 5.97.44v-.44h-5.97Z"
        />
      </pattern>
      <style>{".cls-1{clip-path:url(#clippath_mid);}.cls-mid-2{fill:#ffdd08}.cls-3{fill:#fff6c1}.cls-5{fill:none;stroke:#fff6c1;stroke-miterlimit:10}.cls-Venn2-2-3-2{fill:#d4155b}.cls-Venn2-2-4{fill:#4980c1}.cls-mid-2{fill:#ffdd08}.cls-3{fill:#fff6c1}.cls-5{fill:none;stroke:#fff6c1;stroke-miterlimit:10}"}</style>
            <clipPath id="clippath_mid">
              <path
              style={{ transform: 'translate(-10 0.87)'}}
                className="cls-mid-2"
                d="M215.12 231.89c0-92.97-41.78-176.16-107.56-231.89C41.78 55.72 0 138.92 0 231.89s41.78 176.16 107.56 231.89c65.79-55.72 107.56-138.92 107.56-231.89Z"/>
                       </clipPath>
            <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
              <feOffset dx={0} dy={0} />
              <feGaussianBlur result="blur" stdDeviation={10} />
              <feFlood floodColor="#000" floodOpacity={0.4} />
              <feComposite in2="blur" operator="in" />
              <feComposite in="SourceGraphic" />
            </filter>
            <style>{".cls-mid-2{fill:#ffdd08}"}</style>


    <clipPath id="clippath">
      <path class="cls-mid-2" d="M236.56,231.89c0-92.97-41.78-176.16-107.56-231.89C63.21,55.72,21.44,138.92,21.44,231.89s41.78,176.16,107.56,231.89c65.79-55.72,107.56-138.92,107.56-231.89Z"/>
    </clipPath>

    </defs>
    <g

    onMouseOut={(e) => { console.debug("out3",e.relatedTarget.className.baseVal)

    if(!props.isMobile && e.relatedTarget.className.baseVal === "") {
        props.updateVennState("initial");
    }

     }}
    style={{ filter: props.vennState === 1 || props.selectedModal === "m_use--without-permission" || props.selectedModal === "m_use--with-permission" ? "url(#drop-shadow-1)" : "",

    pointerEvents: props.doPointerEvents }} >

     <g className="toReveal"
        >
        <path onMouseOut={(e) => {
    if(props.isMobile) return;

            console.debug("out");
            console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);

        if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "cls-3-none-3" || e.relatedTarget.className.baseVal === "cls-3") return;

        props.updateVennState("initial")}
        }
      onMouseOver={(e) => {
        console.debug("in");

        if (props.selectedModal === "m_exploitation") return;
         props.updateVennState("middleHover");
         e.stopPropagation();
      }}
      d={props.vennState === 1 ? "M215.12 231.89c0-92.97-41.78-176.16-107.56-231.89C41.78 55.72 0 138.92 0 231.89s41.78 176.16 107.56 231.89c65.79-55.72 107.56-138.92 107.56-231.89Z"
                                                   :
      "M215.12 231.89c0-92.97-41.78-176.16-107.56-231.89C41.78 55.72 0 138.92 0 231.89s41.78 176.16 107.56 231.89c65.79-55.72 107.56-138.92 107.56-231.89Z" }
      style={{
        opacity: props.vennState === 1 || props.selectedModal === "m_use--without-permission" || props.selectedModal === "m_use--with-permission" ? 0 : 1,
//         visibility: !(props.vennState === 1 || props.selectedModal === "m_use--without-permission" || props.selectedModal === "m_use--with-permission") ? 'visible' : 'hidden',
        fill: props.vennState === 1 ? "" : "url(#New_Pattern_Swatch_6)",
      }}
        className={props.vennState === 1 ? "cls-mid-2" : ""}
      id="Layer_1-2"
    />
    <g
    onMouseOut={(e) => {
    if(props.isMobile) return;

            if (props.selectedModal === "m_exploitation" || e.relatedTarget.className.baseVal === "cls-3-none-3" || e.relatedTarget.className.baseVal === "cls-3") return;
            console.debug("out");
            console.debug("e.relatedTarget.className.baseVal",e.relatedTarget.className.baseVal);
            props.updateVennState("initial")}
            }
         onMouseOver={(e) => {
            if (props.selectedModal === "m_exploitation" || props.isMobile) return;
            console.debug("in");

             props.updateVennState("middleHover")
             e.stopPropagation();
             }
             }

            style={{
                opacity: props.vennState === 1  ? 1 : 0,
//         visibility: props.vennState === 1 ? 'visible' : 'hidden',
                pointerEvents: props.vennState === 1 ? 'none' : 'none',
              clipPath: "url(#clippath_mid)",
            }}
          >
            <path
              d="M0 116.26h232M0 232.14h232M0 348.01h232"
              style={{
                fill: "none",
                stroke: "#fff6c1",
                strokeMiterlimit: 10,
              }}
            />
          </g>
    </g>      <g
                  style={{
                    opacity: props.vennState === 1 || usingWith || usingWithout ? 1 : 0,
//         visibility: props.vennState === 1 || usingWith || usingWithout ? 'visible' : 'hidden',

                    pointerEvents: props.vennState === 1 ? 'all' : 'none',
                  }}>
                <path
                  className="cls-mid-2"
                  d="M215.12 231.89c0-92.97-41.78-176.16-107.56-231.89C41.78 55.72 0 138.92 0 231.89s41.78 176.16 107.56 231.89c65.79-55.72 107.56-138.92 107.56-231.89Z"
                />

      <g className="cls-1" style={{ opacity: props.isMobile && props.selectedModal === "" ? 0 : ''}}>
                { (!/* props.isMobile */false || (usingWith || usingWithout)) && <g
                  style={{
                    pointerEvents: (usingWith || usingWithout) ? 'all' : props.vennState !== 1 ? 'none' : '',
                  }}
                  className="midActiveG">
                  { (!props.isMobile || (usingWith || usingWithout)) && <rect
                onClick={ () => {
                window.location.hash = "idea-expression";
                props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"))}}
                  style={{
                    opacity: usingWith || usingWithout ? 1 : 0,
//         visibility: usingWith || usingWithout ? 'visible' : 'hidden',
                    fill:  usingWith ? 'white' : usingWithout ? '#4b7fbf' : ''
                  }}
                  className="cls-3"
                  y={0.39}
                  width={258}
                  height={116}
                /> }
  <text x="108" y="80" className={"notranslate"}  class="mid--label"
                  style={{
                    fill:  usingWith ? '' : usingWithout ? 'white' : ''
                  }}>{getTranslationMap(selectedTrans, "Ideas")}</text>
                </g>}
                {  (/* props.isMobile || */ true || (usingWith || usingWithout))  && <g
                  style={{
                    pointerEvents: (usingWith || usingWithout) ? 'all' : props.vennState !== 1 ? 'none' : '',
                  }}
                  className="midActiveG">
                {  (!props.isMobile || (usingWith || usingWithout))  && <rect
                  style={{
                    opacity:  (usingWith || usingWithout) ? 1 : 0,
//         visibility: usingWith || usingWithout ? 'visible' : 'hidden',
                    fill:  usingWith ? 'white' : usingWithout ? '#4b7fbf' : ''
                  }}
                onClick={ () =>  {
                    window.location.hash = "de-minimis";
                     props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"))}
                 }
                  className="cls-3"
                  y={116.34}
                  width={258}
                  height={116}
                />}
  <text x="108" y="180" className={"notranslate"} class="mid--label"
                  style={{
                    fill:  props.isMobile && usingWith ? '' : usingWithout ? 'white' : ''
                  }}>{getTranslationMap(selectedTrans, "De Minimis")}</text>
                </g>}
                <g
                  style={{
                    pointerEvents: (usingWith || usingWithout) ? 'all' : props.vennState !== 1 ? 'none' : '',
                  }}
                  className="midActiveG">
                {  (!props.isMobile || (usingWith || usingWithout))  && <rect

                onClick={ () => props.dispatch(props.setSelectedModal("m_exceptions"))}

                  style={{
                    opacity:  usingWith || usingWithout ? 1 : 0,
//         visibility: usingWith || usingWithout ? 'visible' : 'hidden',
                    fill:  usingWith ? 'white' : usingWithout ? '#4b7fbf' : ''
                  }}
                  className="cls-3"
                  y={232.34}
                  width={258}
                  height={116}
                />}
  <text x="108" y={ /* !props.isMobile */false && !usingWithout && !usingWith  ? "245.34" : "290.34"} className={"notranslate"} class="mid--label"

                  style={{
                    fill:  usingWith ? '' : usingWithout ? 'white' : '',
                    fontSize: /* props.isMobile */false && !usingWithout && !usingWith ? '2.0em' : ''
                  }}
  >{getTranslationMap(selectedTrans, "Exceptions")}</text>
                </g>
                { (true || (usingWith || usingWithout))  && <g
                  style={{
                    pointerEvents: (usingWith || usingWithout) ? 'all' : props.vennState !== 1 ? 'none' : '',
                  }}
                  className="midActiveG">
                { (!props.isMobile || (usingWith || usingWithout))  && <rect
                onClick={ () => {
                console.debug('dd1');
                window.location.hash = "open-licences";
                props.dispatch(props.setSelectedModal("THE PUBLIC DOMAIN"))}}
                  style={{
                    opacity:  usingWith || usingWithout ? 1 : 0,
//         visibility: usingWith || usingWithout ? 'visible' : 'hidden',
                    fill:  usingWith ? 'rgb(212, 21, 91)' : usingWithout ? 'white' : ''
                  }}
                  className="cls-3"
                  y={347.34}
                  width={258}
                  height={116}
                /> }
  <text x="108" y="380" className={"notranslate"} class="mid--label"
                  style={{
                    fill:  usingWith ? 'white' : usingWithout ? '#404040' : '',
//                     fontWeight:  props.isMobile ? 600 : 400

                  }}>{getTranslationMap(selectedTrans, "Open")}</text>
  <text x="108" y="410" className={"notranslate"}  class="mid--label"
                  style={{
                    fill:  usingWith ? 'white' : usingWithout ? '#404040' : '',
//                     fontWeight: props.isMobile ? 600 : 400

                  }}>{getTranslationMap(selectedTrans, "Licences")}</text>
                </g>}
                <line
                  style={{
                    stroke:  usingWith || usingWithout ? 'grey' : ''
                  }}
                  className="cls-5" x1={13} y1={116.26} x2={245} y2={116.26} />
                <line
                  style={{
                    stroke:  usingWith || usingWithout ? 'grey' : ''
                  }}className="cls-5" x1={0} y1={232.14} x2={245} y2={232.14} />
                <line
                  style={{
                    stroke:  usingWith || usingWithout ? 'grey' : ''
                  }}className="cls-5" x1={13} y1={348.01} x2={245} y2={348.01} />

              </g>
          </g>
          </g>
  </svg>
)}

export default SvgComponent
