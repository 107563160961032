import * as React from "react"
import { getTranslationMap } from '../../features/translationMap';

const SvgComponent = (props) => {
    const { selectedTrans } = props;

                                  const [hoverSelected, setHoverSelected] = React.useState("");

                                return (
  <svg
    className={"relatedRightsInfoClass"}
    viewBox={"0 0 1346 1086"}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="b">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="c">
        <path d="M517.591 707.85c0-146.908 119.092-266 265.999-266 146.908 0 266.001 119.092 266.001 266 0 146.907-119.093 265.999-266.001 265.999-146.907 0-265.999-119.092-265.999-265.999" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="e">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="f">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="g">
        <path d="M89 1014.4h20v-19.999H89Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="h">
        <path d="M88.872 869.209h20.302V846.11H88.872Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="i">
        <path d="M86.571 720.081h25v-25h-25Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="j">
        <path d="M88 558.151h20V539.08H88Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="k">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="l">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="m">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="n">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="o">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="p">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="q">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="r">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="s">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="t">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="u">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="v">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="w">
        <path d="M551 940.5h465v-465H551Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="a">
        <path d="M551 940.5h465v-465H551Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="z">
        <path d="M0 1259h1380V0H0Z" />
      </clipPath>
      <mask maskUnits="userSpaceOnUse" x={0} y={0} width={1} height={1} id="x">
        <g clipPath="url(#a)">
          <image
            width={1}
            height={1}
            style={{
              imageRendering: "optimizeSpeed",
            }}
            preserveAspectRatio="none"
            transform="matrix(465 0 0 -465 551 940.5)"
            xlinkHref="../CUEU%20Website%20Infographics_img12.png"
          />
        </g>
      </mask>
      <mask maskUnits="userSpaceOnUse" x={0} y={0} width={1} height={1} id="y">
        <image
          width={1}
          height={1}
          style={{
            imageRendering: "optimizeSpeed",
          }}
          preserveAspectRatio="none"
          xlinkHref="../CUEU%20Website%20Infographics_img13.png"
        />
      </mask>
      <pattern
        patternTransform="translate(-247.009 -3146.5)"
        patternUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={179.4}
        height={310.5}
        id="d"
      >
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.4 103.494)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(194.338 94.87)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(209.276 86.245)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(224.214 77.62)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(239.152 68.996)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(254.09 60.371)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.028 51.747)"
        />
        <path
          d="m0 0 89.629 51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.4)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 34.498)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 17.248)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(197.4 -.035)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(197.4 -.035)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(174.679 -8.913)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(0 103.494)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(14.938 94.87)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(29.876 86.245)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(44.814 77.62)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(59.752 68.996)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(74.69 60.371)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.628 51.747)"
        />
        <path
          d="m0 0 89.629 51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 34.498)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 17.248)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(18 -.035)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(18 -.035)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(-4.721 -8.913)"
        />
        <path
          d="m0 0-89.629-51.748v-103.494"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.1 258.744)"
        />
        <path
          d="m0 0-89.629-51.748v-103.494"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.7 258.744)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(104.638 250.12)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(119.576 241.495)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(134.514 232.87)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(149.452 224.246)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(164.39 215.621)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.328 206.997)"
        />
        <path
          d="m0 0 89.629 51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.7 155.25)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.328 189.748)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.328 172.498)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.328 155.25)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.328 138)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.328 120.751)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.328 103.502)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(197.7 103.215)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(197.7 103.215)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(174.979 94.337)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(107.7 155.215)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(107.7 155.215)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(84.979 146.337)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-.072 206.997)"
        />
        <path
          d="m0 0 89.629 51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-89.7 155.25)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-.071 189.748)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-.071 172.498)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-.071 155.25)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-.071 138)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-.071 120.751)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(-.071 103.502)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(18.3 103.215)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(18.3 103.215)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(-4.421 94.337)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.4 413.994)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(194.338 405.37)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(209.276 396.745)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(224.214 388.12)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(239.152 379.496)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(254.09 370.871)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.028 362.247)"
        />
        <path
          d="m0 0 89.629 51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(179.4 310.5)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 344.998)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 327.748)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 310.5)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 293.25)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 276.001)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(269.029 258.752)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(197.4 310.465)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(197.4 310.465)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(174.679 301.587)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(0 413.994)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(14.938 405.37)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(29.876 396.745)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(44.814 388.12)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(59.752 379.496)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(74.69 370.871)"
        />
        <path
          d="m0 0-89.629-51.747v-103.495"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.628 362.247)"
        />
        <path
          d="m0 0 89.629 51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(0 310.5)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 344.998)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 327.748)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 310.5)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 293.25)"
        />
        <path
          d="m0 0-89.629-51.748"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 276.001)"
        />
        <path
          d="m0 0-89.629-51.747"
          style={{
            fill: "none",
            stroke: "#000",
            strokeOpacity: 1,
            strokeWidth: 3,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(89.629 258.752)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(108 258.465)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(108 258.465)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(85.279 249.587)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0"
          style={{
            fill: "#d380e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(18 310.465)"
        />
        <path
          d="M0 0c0-9.985-8.094-18.079-18.079-18.079-9.985 0-18.079 8.094-18.079 18.079 0 9.985 8.094 18.079 18.079 18.079C-8.094 18.079 0 9.985 0 0Z"
          style={{
            fill: "none",
            stroke: "#d380e3",
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
          }}
          transform="translate(18 310.465)"
        />
        <path
          d="M0 0c-1.16 1.082-1.74 2.531-1.74 4.349v8.871c0 1.817.58 3.256 1.74 4.32 1.16 1.063 2.705 1.595 4.639 1.595 1.932 0 3.479-.522 4.638-1.566 1.16-1.044 1.74-2.435 1.74-4.175v-.058c0-.386-.194-.58-.58-.58l-1.391.058c-.387 0-.581.193-.581.58v.058c0 1.044-.358 1.885-1.072 2.523-.716.637-1.634.956-2.754.956-1.16 0-2.088-.319-2.784-.956-.695-.638-1.043-1.479-1.043-2.523V4.059c0-1.044.348-1.885 1.043-2.523C2.551.899 3.479.58 4.639.58c1.12 0 2.038.319 2.754.956.714.638 1.072 1.479 1.072 2.523v.058c0 .386.194.58.581.58l1.391.058c.386 0 .58-.194.58-.58 0-1.74-.59-3.141-1.769-4.204-1.18-1.064-2.716-1.595-4.609-1.595C2.705-1.624 1.16-1.083 0 0m13.771-6.726c2.725 1.545 4.861 3.672 6.407 6.378 1.546 2.705 2.32 5.72 2.32 9.046 0 3.363-.774 6.407-2.32 9.132a16.726 16.726 0 0 1-6.407 6.407c-2.725 1.546-5.789 2.32-9.191 2.32-3.441 0-6.523-.774-9.248-2.32-2.725-1.546-4.851-3.682-6.378-6.407-1.528-2.725-2.291-5.769-2.291-9.132 0-3.326.763-6.341 2.291-9.046 1.527-2.706 3.653-4.833 6.378-6.378 2.725-1.547 5.807-2.32 9.248-2.32 3.402 0 6.466.773 9.191 2.32M-6.175-9.307c-3.19 1.798-5.693 4.281-7.509 7.451-1.818 3.17-2.725 6.707-2.725 10.612 0 3.904.907 7.44 2.725 10.611 1.816 3.169 4.319 5.653 7.509 7.451 3.189 1.797 6.774 2.696 10.755 2.696 3.982 0 7.577-.899 10.786-2.696 3.208-1.798 5.711-4.282 7.509-7.451 1.797-3.171 2.696-6.707 2.696-10.611 0-3.905-.899-7.442-2.696-10.612-1.798-3.17-4.301-5.653-7.509-7.451-3.209-1.797-6.804-2.696-10.786-2.696-3.981 0-7.566.899-10.755 2.696"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(-4.721 301.587)"
        />
      </pattern>
    </defs>
    <g
      clipPath="url(#b)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M0 0c0-146.908-119.092-266-266-266-146.908 0-266 119.092-266 266 0 146.908 119.092 266 266 266C-119.092 266 0 146.908 0 0"
        style={{
          fill: "#d380e3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(1049.59 707.85)"
      />
    </g>
    <g
      clipPath="url(#c)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M1091.591 350.5h-915v728h915z"
        style={{
          fill: "url(#d)",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      clipPath="url(#e)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        className={ hoverSelected === "t12" || hoverSelected === "t00" ? "arrowRR purpleRR" : "" }
        d={ hoverSelected === "t12" ? "m 0.375 -0.818 l -20.18 19.683 l -74.933 -74.933 l -19.145 19.145 l 0.015 -59.619 l 59.613 -0.01 l -19.144 19.145 z" : hoverSelected === "t00" ?  "m 0 0 l 23.166 -23.596 l 74.933 74.933 l 19.145 -19.145 l -0.015 59.619 l -59.613 0.01 l 19.144 -19.145 z" : "m0 0 19.145-19.145-.01 59.614-59.619.015 19.145-19.145-74.933-74.933-19.145 19.145.015-59.619 59.613-.01-19.144 19.145z"}
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          transition: "none"
        }}
        transform={ hoverSelected === "t00" ? "translate(914.868 857.029)" : "translate(1024.868 927.029)" }
      />
      <path
        className={ hoverSelected === "t11" || hoverSelected === "t00" ? "arrowRR purpleRR" : "" }
        d={ hoverSelected === "t11" ? "m 0 0 l -21.737 -22.696 l 74.933 -74.933 l -19.145 -19.145 l 59.619 0.015 l 0.01 59.613 l -19.145 -19.144 z" : hoverSelected === "t00" ? "m 0 0 l 19.54 19.46 l -74.933 74.933 l 19.145 19.145 l -59.619 -0.015 l -0.01 -59.613 l 19.145 19.144 z" : "m0 0 19.145 19.145-59.614-.01-.015-59.619 19.145 19.145 74.933-74.933-19.145-19.145 59.619.015.01 59.613-19.145-19.144z"}
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero", transition: "none",
          stroke: "none",
        }}
        transform={ hoverSelected === "t00" ? "translate(634.471 839.368)" : "translate(564.471 949.368)"}
      />
      <path
        className={ hoverSelected === "t21" ||  hoverSelected === "t00" ? "arrowRR purpleRR" : "" }
        d={ hoverSelected === "t21" ? "m 0 0 l 23.166 -23.596 l 74.933 74.933 l 19.145 -19.145 l -0.015 59.619 l -59.613 0.01 l 19.144 -19.145 z" : hoverSelected === "t00" ? "m 0.375 -0.818 l -20.18 19.683 l -74.933 -74.933 l -19.145 19.145 l 0.015 -59.619 l 59.613 -0.01 l -19.144 19.145 z" : "m0 0-19.145 19.145.01-59.614 59.619-.015-19.145 19.145 74.933 74.933 19.145-19.145-.015 59.619-59.613.01 19.144-19.145z" }
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",transition: 'none'
        }}
        transform={ hoverSelected === "t00" ? "translate(652.132 560.971)" : "translate(542.132 488.971)" }
      />
      <path

        className={ hoverSelected === "t23" ||  hoverSelected === "t00" ? "arrowRR purpleRR" : "" }
                d={ hoverSelected === "t23" ? "m 0 0 l 19.54 19.46 l -74.933 74.933 l 19.145 19.145 l -59.619 -0.015 l -0.01 -59.613 l 19.145 19.144 z" : hoverSelected === "t00" ? "m 0 0 l -21.737 -22.696 l 74.933 -74.933 l -19.145 -19.145 l 59.619 0.015 l 0.01 59.613 l -19.145 -19.144 z" : "m0 0-19.145-19.145 59.614.01.015 59.619-19.145-19.145-74.933 74.933 19.145 19.145-59.619-.015-.01-59.613 19.145 19.144z"}

                style={{
                transition: 'none',
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform={ hoverSelected === "t00" ? "translate(942.529 572.632)" : "translate(1002.529 466.632)" }
      />
    </g>

    <g
      clipPath="url(#k)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M520 971.5H290v70h230z"
        className={ (hoverSelected === "t00" || hoverSelected === "t11" || hoverSelected === "c01" || hoverSelected === "c21" || hoverSelected === "c11") ? "purpleRR" : "" }
              onMouseOver={() => setHoverSelected("t11")}
              onMouseOut={() => setHoverSelected("")}

        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 26.881 114.247)"
        className={ (hoverSelected === "t00" || hoverSelected === "t11" || hoverSelected === "c01" || hoverSelected === "c21" || hoverSelected === "c11") ? "purpleRRText" : "" }
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fill:  "#000",
        fontStretch: "normal",
        fontSize: 20,
        writingMode: "lr-tb",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        y={0}
      >
        {getTranslationMap(selectedTrans, "Performers")}
      </tspan>
    </text>
    <g
      clipPath="url(#l)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M1288 970.5h-240v70h240z"
        className={ hoverSelected === "t00" || hoverSelected === "t12" || hoverSelected === "c11" ? "purpleRR" : "" }
              onMouseOver={() => setHoverSelected("t12")}
              onMouseOut={() => setHoverSelected("")}
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 1037.548 114.247)"
        className={ hoverSelected === "t00" || hoverSelected === "t12" || hoverSelected === "c11" ? "purpleRRText" : "" }
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        y={0}
      >
        {getTranslationMap(selectedTrans, "Phonogram Producers")}
      </tspan>
    </text>
    <g
      clipPath="url(#m)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M1300 749.5h-200v70h200z"
        className={ hoverSelected === "t24" || hoverSelected === "c24" ? "purpleRR" : "" }
              onMouseOver={() => setHoverSelected("t24")}
              onMouseOut={() => setHoverSelected("")}
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 1106.881 408.914)"
        className={ hoverSelected === "t24" || hoverSelected === "c24" ? "purpleRRText" : "" }
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        y={0}
      >
        {getTranslationMap(selectedTrans, "Press Publishers")}
      </tspan>
    </text>
    <g
      clipPath="url(#n)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M1288 374.5h-240v70h240z"
        className={ hoverSelected === "t00" || hoverSelected === "t23" ? "purpleRR" : "" }
              onMouseOver={() => setHoverSelected("t23")}
              onMouseOut={() => setHoverSelected("")}
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 1037.548 908.914)"
        className={ hoverSelected === "t00" || hoverSelected === "t23" ? "purpleRRText" : "" }
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        y={0}
      >
        {getTranslationMap(selectedTrans, "Film Producers")}
      </tspan>
    </text>
    <g
      clipPath="url(#o)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M940 271.5H740v70h200z"
        className={ hoverSelected === "t22" || hoverSelected === "c22" ? "purpleRR" : "" }
          onMouseOver={() => setHoverSelected("t22")}
          onMouseOut={() => setHoverSelected("")}
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 626.881 1046.247)"
        className={ hoverSelected === "t22" || hoverSelected === "c22" ? "purpleRRText" : "" }

      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        y={0}
      >
        {getTranslationMap(selectedTrans, "Database Owners")}
      </tspan>
    </text>
    <g
      clipPath="url(#p)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M520 373.5H290v70h230z"
        className={ hoverSelected === "t00" || hoverSelected === "t21" || hoverSelected === "c21" ? "purpleRR" : "" }
              onMouseOver={() => setHoverSelected("t21")}
              onMouseOut={() => setHoverSelected("")}
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 26.881 910.247)"
        className={ hoverSelected === "t00" || hoverSelected === "t21" || hoverSelected === "c21" ? "purpleRRText" : "" }
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        y={0}
      >
        {getTranslationMap(selectedTrans, "Broadcasters")}
      </tspan>
    </text>
    <g
      clipPath="url(#q)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0"
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(858.59 1006.5)"
      />
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 10,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(858.59 1006.5)"
      />
      <path
          onMouseOver={() => setHoverSelected("c11")}
          onMouseOut={() => setHoverSelected("")}
        className={ hoverSelected === "t11" || hoverSelected === "t12" || hoverSelected === "c11" ? "purpleRRStroke" : "" }
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          pointerEvents: 'bounding-box',
          strokeWidth: 2,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(858.59 1006.5)"
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 603.432 95.595)"
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        y={0}
        x="-20"
      >
        {getTranslationMap(selectedTrans, "Equitable Remuneration").split(" ")[0]}
      </tspan>
      <tspan
        x="-20.139999"
        y={24}
      >
        {getTranslationMap(selectedTrans, "Equitable Remuneration").split(" ")[1]}
      </tspan>
    </text>
    <g
      clipPath="url(#r)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0"
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(1182 637.5)"
      />
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 10,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(1182 637.5)"
      />
      <path
          onMouseOver={() => setHoverSelected("c24")}
          onMouseOut={() => setHoverSelected("")}
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        className={ hoverSelected === "t24" || hoverSelected === "c24" ? "purpleRRStroke" : "" }
        style={{
        pointerEvents: "bounding-box",
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 2,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(1182 637.5)"
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 1041.172 558.262)"
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        x="-10"
        y={0}
      >
        {getTranslationMap(selectedTrans, "Two year licence for online platforms").split(" ")[0]}
        { " " }
        {getTranslationMap(selectedTrans, "Two year licence for online platforms").split(" ")[1]}
      </tspan>
      <tspan x="-10" y={24}>
        {getTranslationMap(selectedTrans, "Two year licence for online platforms").split(" ")[2]}
      </tspan>
      <tspan
        x="-10"
        y={48}
      >
        {getTranslationMap(selectedTrans, "Two year licence for online platforms").split(" ")[3]}
      </tspan>
      <tspan
        x="-10"
        y={72}
      >
        {getTranslationMap(selectedTrans, "Two year licence for online platforms").split(" ")[4]}
      </tspan>
    </text>
    <g
      clipPath="url(#s)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0"
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(546 821.5)"
      />
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 10,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(546 821.5)"
      />
      <path
          onMouseOver={() => setHoverSelected("c01")}
          onMouseOut={() => setHoverSelected("")}
        className={ hoverSelected === "t11" || hoverSelected === "c01" ? "purpleRRStroke" : "" }
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
        pointerEvents: "bounding-box",
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 2,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(546 821.5)"
      />
    </g>
    <text
    title={getTranslationMap(selectedTrans, "Moral Rights")}
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 209.803 342.262)"
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan x="-20 " y={0} >
        {getTranslationMap(selectedTrans, "Moral Rights").split(" ")[0].substring(0, 10)}
        {getTranslationMap(selectedTrans, "Moral Rights").split(" ")[0].length > 10 ? "..." : ""}
      </tspan>
      <tspan x="-20.9400001" y={24}>
        {getTranslationMap(selectedTrans, "Moral Rights").split(" ")[1]}
      </tspan>
    </text>
    <g
      clipPath="url(#t)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0"
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(546 607.5)"
      />
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 10,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(546 607.5)"
      />
      <path
          onMouseOver={() => setHoverSelected("c21")}
          onMouseOut={() => setHoverSelected("")}
        className={ hoverSelected === "t11" || hoverSelected === "t21" || hoverSelected === "c21" ? "purpleRRStroke" : "" }
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
          fill: "none",
          pointerEvents: "bounding-box",
          stroke: "#dcdcdc",
          strokeWidth: 2,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(546 607.5)"
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 213.888 627.595)"
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan x="-10 " y={0}>
        {getTranslationMap(selectedTrans, "First Fixation").split(" ")[0]}
      </tspan>
      <tspan
        x="-14.42"
        y={24}
      >
        {getTranslationMap(selectedTrans, "First Fixation").split(" ")[1]}
      </tspan>
    </text>
    <g
      clipPath="url(#u)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0"
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(763 428.5)"
      />
      <path
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 10,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(763 428.5)"
      />
      <path
          onMouseOver={() => setHoverSelected("c22")}
          onMouseOut={() => setHoverSelected("")}
        className={ hoverSelected === "t22" ||  hoverSelected === "c22" ? "purpleRRStroke" : "" }
        d="M0 0c0-41.421-33.579-75-75-75s-75 33.579-75 75 33.579 75 75 75S0 41.421 0 0Z"
        style={{
        pointerEvents: "bounding-box",
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 2,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(763 428.5)"
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 469.708 866.262)"
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: 20,
        
        
        writingMode: "lr-tb",
        fill: "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        x="0"
        y={0}
      >
        {getTranslationMap(selectedTrans, "Extraction & Reuse").split(" ")[0]}
      </tspan>
      <tspan x="0" y={24}>
        {getTranslationMap(selectedTrans, "Extraction & Reuse").split(" ")[1]}
        {" "}
        {getTranslationMap(selectedTrans, "Extraction & Reuse").split(" ")[2]}
      </tspan>
    </text>
    <g
      clipPath="url(#v)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        className={ hoverSelected === "t11" || hoverSelected === "c01" ? "purpleRRStroke" : "" }
        d="M0 0h-5.493c-12.561 0-22.743 10.183-22.743 22.743v107.493"
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(380.236 821.5)"
      />
      <path
        d="m0 0 12.952-7.478L0-14.958Z"
        className={ hoverSelected === "t11" ? "purpleRR" : "" }
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "c01" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(378.048 828.979)"
      />
      <path
        d="m0 0-7.479 12.952L-14.958 0Z"
        className={ hoverSelected === "t11" ? "purpleRROpacity" : hoverSelected === "c01" ? "purpleRR" : "" }
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(359.479 949.548)"
      />
      <path
        className={ hoverSelected === "t24" || hoverSelected === "c24" ? "purpleRRStroke" : "" }
        d="M0 0h5.493c12.561 0 22.743 10.183 22.743 22.743v73.493"
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(1197.764 637.5)"
      />
      <path
        className={ hoverSelected === "t24" ? "purpleRR" : "" }
        d="m0 0-12.952 7.478L0 14.958Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "c24" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(1199.952 630.021)"
      />
      <path
        d="m0 0-7.479 12.952L-14.958 0Z"
        className={ hoverSelected === "c24" ? "purpleRR" : "" }

                style={{
          fill: "#dcdcdc",
          opacity: hoverSelected === "t24" ? 0 :  1,
        visibility: hoverSelected !== "t24" ? 'visible' : 'hidden',
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(1233.479 731.548)"
      />
      <path
        className={ hoverSelected === "t11" || hoverSelected === "c21" ? "purpleRRStroke" : "" }
        d="M0 0h-35.493c-12.561 0-22.743 10.183-22.743 22.743v309.993"
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(380.236 618.5)"
      />
      <path
        d="m0 0 12.952-7.478L0-14.958Z"
        className={ hoverSelected === "t11" ? "purpleRR" : "" }
        style={{
          fill: "#dcdcdc",
          fillOpacity:  hoverSelected === "c21" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(378.048 625.979)"
      />
      <path
        d="m0 0-7.479 12.952L-14.958 0Z"
        className={ hoverSelected === "t11" ? "purpleRROpacity" : hoverSelected === "c21" ? "purpleRR" : "" }
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(329.479 949.048)"
      />
      <path
        className={ hoverSelected === "t21" || hoverSelected === "c21" ? "purpleRRStroke" : "" }
        d="M0 0h-5.493c-12.561 0-22.743-10.183-22.743-22.743v-112.493"
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(380.236 597.5)"
      />
      <path
        className={ hoverSelected === "t21" ? "purpleRR" : "" }
        d="m0 0 12.952-7.478L0-14.958Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity:  hoverSelected === "c21" ? 0 :1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(378.048 604.979)"
      />
      <path
        className={ hoverSelected === "c21" ? "purpleRR" : "" }
        d="m0 0 7.479-12.952L14.958 0Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "t21" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(344.521 464.452)"
      />
      <path
        d="M0 0h-156.473"
        className={ hoverSelected === "t11" || hoverSelected === "c11"  ? "purpleRRStroke" : "" }
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(693.236 1006.5)"
      />
      <path
        className={ hoverSelected === "t11" ? "purpleRR" : "" }
        d="m0 0 12.952-7.478L0-14.958Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "c11" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(691.048 1013.979)"
      />
      <path
        d="m0 0-12.952-7.478L0-14.958Z"
        className={ hoverSelected === "c11"  ? "purpleRR" : "" }
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "t11" ? 0 : 1 ,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(538.952 1013.979)"
      />
      <path
        className={ hoverSelected === "t12" || hoverSelected === "c11" ? "purpleRRStroke" : "" }
        d="M0 0h156.473"
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(874.764 1006)"
      />
      <path
        className={ hoverSelected === "t12" ? "purpleRR" : "" }
        d="m0 0-12.952 7.478L0 14.958Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "c11" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(876.952 998.521)"
      />
      <path
        className={ hoverSelected === "t12" ? "purpleRROpacity" :  hoverSelected === "c11" ? "purpleRR" : "" }
        d="m0 0 12.952 7.478L0 14.958Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(1029.048 998.521)"
      />
      <g clipPath="url(#w)" opacity={0.4}>
        <g mask="url(#x)">
          <image
            width={1}
            height={1}
            style={{
              imageRendering: "optimizeSpeed",
            }}
            preserveAspectRatio="none"
            transform="matrix(465 0 0 -465 551 940.5)"
            xlinkHref="../CUEU%20Website%20Infographics_img14.png"
            mask="url(#y)"
          />
        </g>
      </g>
      <path
        d="M0 0c0-94.114-76.295-170.409-170.409-170.409-94.115 0-170.409 76.295-170.409 170.409 0 94.114 76.294 170.409 170.409 170.409C-76.295 170.409 0 94.114 0 0"
        style={{
          fill: "#d380e3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}


        className={"shadowSvgElement"}
        transform="translate(954 707.85)"
      />
      <path onMouseOver={() => setHoverSelected("t00")}
              onMouseOut={() => setHoverSelected("")}
        d="M0 0c0-94.114-76.295-170.409-170.409-170.409-94.115 0-170.409 76.295-170.409 170.409 0 94.114 76.294 170.409 170.409 170.409C-76.295 170.409 0 94.114 0 0Z"
        style={{
          fill: "none",
          stroke: "#dc9ae9",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(954 707.85)"
      />
    </g>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 615.53 381.457)"
      style={{
        fontVariant: "normal",
        fontWeight: 700,
        fontStretch: "normal",
        fontSize: 20,
        
        writingMode: "lr-tb",
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan y={0}>
        {getTranslationMap(selectedTrans, "Copy")}
      </tspan>
      <tspan x="0"
        y={60.92}
      >
        {getTranslationMap(selectedTrans, "Distribute")}
      </tspan>
      <tspan x="0" y={121.84}>
        {getTranslationMap(selectedTrans, "Rent")}
      </tspan>
      <tspan x="0"
        y={182.76}
      >
        {getTranslationMap(selectedTrans, "Communicate")}
      </tspan>
    </text>
    <g
      clipPath="url(#z)"
      transform="matrix(1.33333 0 0 -1.33333 -386.667 1448.667)"
    >
      <path
        d="M0 0h11.07a3.003 3.003 0 0 0 2.993-2.993v-11.07a3.003 3.003 0 0 0-2.993-2.994H8.176v5.309a4.264 4.264 0 0 1-4.251 4.251h-6.918v4.504A3.003 3.003 0 0 0 .001 0Zm-7.146-8.755h11.07a3.003 3.003 0 0 0 2.994-2.993v-11.07a3.003 3.003 0 0 0-2.994-2.993h-11.07a3.002 3.002 0 0 0-2.993 2.993v11.07a3.002 3.002 0 0 0 2.993 2.993"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        transform="translate(718.028 816.53)"
      />
      <path
        d="M0 0a.524.524 0 0 1 .728 0l4.059 4.031 1.009-1.008a.61.61 0 0 1 .644-.14c.224.084.392.28.42.532l.672 5.068a.62.62 0 0 1-.169.532.621.621 0 0 1-.531.168L1.736 8.54a.733.733 0 0 1-.364-.168.541.541 0 0 1-.14-.252.605.605 0 0 1 .14-.644L2.38 6.467l-4.06-4.06a.524.524 0 0 1 0-.728z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(725.758 748.596)"
      />
      <path
        d="M0 0a.524.524 0 0 1-.728 0l-4.059-4.031-1.009 1.008a.61.61 0 0 1-.644.14.642.642 0 0 1-.42-.532l-.672-5.068a.62.62 0 0 1 .169-.532.621.621 0 0 1 .531-.168l5.069.671c.14.029.28.085.364.169.056.056.112.14.14.251a.609.609 0 0 1-.14.645l-.952.98L1.68-2.436a.525.525 0 0 1 0 .729z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(714.221 740.418)"
      />
      <path
        d="M0 0a.733.733 0 0 1-.168.364.541.541 0 0 1-.252.14.607.607 0 0 1-.644-.14l-1.009-.98-4.06 4.032a.526.526 0 0 1-.728 0l-1.68-1.68a.525.525 0 0 1 0-.729l4.032-4.059-1.008-1.008a.61.61 0 0 1-.14-.644.64.64 0 0 1 .532-.42l5.068-.672a.62.62 0 0 1 .532.169.62.62 0 0 1 .168.531z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(732.618 737.003)"
      />
      <path
        d="M0 0a.541.541 0 0 1 .252-.14.61.61 0 0 1 .644.14l1.008 1.008 4.032-4.031a.524.524 0 0 1 .728 0l1.68 1.68a.524.524 0 0 1 0 .728L4.312 3.416 5.32 4.424a.61.61 0 0 1 .14.645.644.644 0 0 1-.532.42L-.14 6.16a.636.636 0 0 1-.533-.196.637.637 0 0 1-.196-.532L-.197.363C-.169.224-.113.084 0 0Z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(707.558 751.647)"
      />
      <path
        d="M0 0a4.284 4.284 0 1 0-8.568 0A4.284 4.284 0 0 0 0 0"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(724.274 744.507)"
      />
      <path
        d="M0 0c-.954-.353-2.474-.743-4.17-1.13-.601-.99-1.697-1.803-3.427-2.121-.39-.07-.848-.106-1.344-.141-1.2-.035-2.614.107-4.17.213-.106 0-.212 0-.317.036-.389.036-.707.317-.743.707-.036.494.354.883.813.847.107 0 .247-.036.354-.036 2.014-.176 3.923-.353 5.124-.106 2.226.424 2.862 1.767 2.72 2.897-.106.92-.813 1.803-1.908 1.731-1.554-.106-3.392 0-5.194.141-2.049.141-4.134.213-5.617.036-1.661-.177-5.018-1.413-7.987-3.427-1.45-.99-3.074-2.157-4.064-2.898a1.087 1.087 0 0 1-.213-1.554l4.311-5.584c.354-.494 1.06-.565 1.554-.212l1.591 1.237c.389.283.883.389 1.307.247 2.897-.92 6.007-1.52 8.021-1.626 4.417-.213 12.934 5.017 15.371 6.714C4.453-2.403 2.686.99 0 0Z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(733.593 687.06)"
      />
      <path
        d="M0 0a5.755 5.755 0 0 1 5.755 5.754A5.757 5.757 0 0 1 0 11.518a5.766 5.766 0 0 1-5.764-5.764c0-.497.083-.97.202-1.43L-12.198.938a6.804 6.804 0 0 1-5.203 2.414 6.845 6.845 0 0 1-6.844-6.843c0-3.766 3.066-6.832 6.844-6.832 1.916 0 3.645.791 4.884 2.06l4.364-2.593a5.688 5.688 0 0 1-.312-1.787c0-3.173 2.582-5.755 5.764-5.755a5.757 5.757 0 0 1 5.755 5.755 5.757 5.757 0 0 1-5.755 5.764 5.728 5.728 0 0 1-4.185-1.822l-4.236 2.516a6.818 6.818 0 0 1 .166 4.949l6.54 3.338C-3.36.829-1.784 0 0 0Z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(729.308 628.113)"
      />
      <path
        className={ hoverSelected === "t22" || hoverSelected === "c22" ? "purpleRRStroke" : "" }
        d="M0 0h5.493c12.561 0 22.743-10.183 22.743-22.743v-50.226"
        style={{
          fill: "none",
          stroke: "#dcdcdc",
          strokeWidth: 3,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(779.229 428.733)"
      />
      <path
        className={ hoverSelected === "t22" ? "purpleRR" : "" }
        d="m0 0-12.952 7.478L0 14.958Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "c22" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(781.418 421.254)"
      />
      <path
        className={ hoverSelected === "c22" ? "purpleRR" : "" }
        d="m0 0 7.478-12.952L14.958 0Z"
        style={{
          fill: "#dcdcdc",
          fillOpacity: hoverSelected === "t22" ? 0 : 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(799.987 357.952)"
      />
    </g>
  </svg>
)}

export default SvgComponent
