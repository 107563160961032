import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 155.75 59.7"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>{".cls-12{fill:#d4155b}"}</style>
    </defs>
    <g
      style={{
        isolation: "isolate",
      }}
    >
      <g id="Layer_2">
        <g id="Layer_1-2">
          <circle
            cx={!props.isActive ? 76 : 37.76}
            cy={29.85}
            r={28.25}
            style={{
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              opacity: 0.5,
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 49.22}
            cy={29.85}
            r={28.25}
            style={{
              opacity: 0.55,
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 60.68}
            cy={29.85}
            r={28.25}
            style={{
              opacity: 0.6,
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 72.15}
            cy={29.85}
            r={28.25}
            style={{
              opacity: 0.65,
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 83.61}
            cy={29.85}
            r={28.25}
            style={{
              opacity: 0.7,
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 95.07}
            cy={29.85}
            r={28.25}
            style={{
              opacity: 0.75,
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 106.53}
            cy={29.85}
            r={28.25}
            style={{
              opacity: 0.8,
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 117.99}
            cy={29.85}
            r={28.25}
            style={{
              fill: "#f2b9ce",
              mixBlendMode: "multiply",
              stroke: "#f2b7cc",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 125.9}
            cy={29.85}
            r={28.25}
            style={{
              stroke: "#d4155b",
              fill: "#fff",
              strokeMiterlimit: 10,
              strokeWidth: "3.2px",
            }}
          />
          <path
            className="cls-12"
            d={ (props.isActive ? "M120.79" : "M71.74") + " 37.05c-1.33-.91-2.28-2.16-2.85-3.76-.39-1.14-.58-2.48-.58-4.03s.19-2.85.58-3.96c.55-1.55 1.49-2.77 2.83-3.65 1.34-.89 2.93-1.33 4.75-1.33s3.42.46 4.8 1.37c1.38.91 2.29 2.06 2.75 3.45.18.55.27.96.27 1.23v.07c0 .2-.11.33-.34.38l-3.93.58h-.07c-.21 0-.34-.11-.41-.34l-.1-.55c-.16-.59-.51-1.08-1.06-1.47s-1.2-.58-1.95-.58-1.34.18-1.84.55c-.5.36-.85.87-1.06 1.5-.25.68-.38 1.62-.38 2.8s.11 2.12.34 2.8c.21.68.56 1.22 1.08 1.61s1.13.58 1.86.58 1.33-.19 1.86-.56c.53-.38.9-.89 1.11-1.55.02-.05.03-.11.03-.21.02-.02.03-.06.03-.1.02-.27.18-.39.48-.34l3.93.61c.23.05.34.17.34.38 0 .18-.07.52-.2 1.03-.48 1.53-1.41 2.72-2.78 3.59s-2.97 1.3-4.76 1.3-3.4-.46-4.73-1.37Z"}

          />
          <circle
            cx={!props.isActive ? 76 : 29.85}
            cy={29.85}
            r={28.25}
            style={{
              fill: "#fff",
            }}
          />
          <circle
            cx={!props.isActive ? 76 : 29.85}
            cy={29.85}
            r={28.25}
            style={{
        visibility: props.isActive ? 'visible' : 'hidden',
            opacity: !props.isActive ? 0 : 1,
              fill: "none",
              strokeDasharray: "0 0 12.68 12.68",
              stroke: "#d4155b",
              strokeMiterlimit: 10,
              strokeWidth: "2.2px",
            }}
          />
          <path
            className="cls-12"
            d={ (!props.isActive ? "M71.74" : "M24.74") + " 37.05c-1.33-.91-2.28-2.16-2.85-3.76-.39-1.14-.58-2.48-.58-4.03s.19-2.85.58-3.96c.55-1.55 1.49-2.77 2.83-3.65 1.34-.89 2.93-1.33 4.75-1.33s3.42.46 4.8 1.37c1.38.91 2.29 2.06 2.75 3.45.18.55.27.96.27 1.23v.07c0 .2-.11.33-.34.38l-3.93.58h-.07c-.21 0-.34-.11-.41-.34l-.1-.55c-.16-.59-.51-1.08-1.06-1.47s-1.2-.58-1.95-.58-1.34.18-1.84.55c-.5.36-.85.87-1.06 1.5-.25.68-.38 1.62-.38 2.8s.11 2.12.34 2.8c.21.68.56 1.22 1.08 1.61s1.13.58 1.86.58 1.33-.19 1.86-.56c.53-.38.9-.89 1.11-1.55.02-.05.03-.11.03-.21.02-.02.03-.06.03-.1.02-.27.18-.39.48-.34l3.93.61c.23.05.34.17.34.38 0 .18-.07.52-.2 1.03-.48 1.53-1.41 2.72-2.78 3.59s-2.97 1.3-4.76 1.3-3.4-.46-4.73-1.37Z"}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
