import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Breadcrumb, BreadcrumbItem , FlexGrid, Grid, Column, Button, Modal, StructuredListBody, StructuredListCell, StructuredListHead, StructuredListRow, StructuredListWrapper } from '@carbon/react';
import { Routes, Route } from 'react-router-dom';
import { IconButton } from '@carbon/react';
import { Add } from '@carbon/react/icons';


import { qlMapping } from '../App'
import EnforcementInfographic from '../assets/SVGRs/EnforcementInfographic';
import MoralRightsInfographic from '../assets/SVGRs/MoralRightsInfographic';
import EconomicRightsInfographic from '../assets/SVGRs/EconomicRightsInfographic';
import CopyrightDurationInfographic from '../assets/SVGRs/CopyrightDurationInfographic';
import RelatedRightsInfographic from '../assets/SVGRs/RelatedrightsInfographic';
import ExceptionsInfographic from '../assets/SVGRs/ExceptionsInfographic';
import circleGuidance from '../assets/circleGuidance.png';
import iconHeader from '../assets/header0.png';
import iconLicensing from '../assets/Icon-Licensing.svg';
import iconUsing from '../assets/Icon-Using.svg';
import iconProtect from '../assets/Icon-Protect.svg';
import Disciplines from '../assets/SVGRs/Disciplines'
import { Container, Row, Col } from 'react-grid-system';
import { I18nContext, useTranslation, withTranslation, Trans } from "react-i18next";
import { translationMap, getTranslationMap } from '../features/translationMap';
// rename imports according to file name


import title_Authors from '../assets/titleImages/Authors-and-Owners.png'
import title_Collecting from '../assets/titleImages/Collecting-Societies.png'
import title_Contract from '../assets/titleImages/Contract-and-TPMS.png'
import title_Duration from '../assets/titleImages/Copyright-Duration.png'
import title_Exceptions from '../assets/titleImages/Copyright-Exceptions.png'
import title_Economic from '../assets/titleImages/Economic-Rights.png'
import title_Enforcement from '../assets/titleImages/Enforcement.png'
import title_Exceptions2 from '../assets/titleImages/Exceptions.png'
import title_Exploitation from '../assets/titleImages/Exploitation.png'
import title_Permission from '../assets/titleImages/Getting-Permission.png'
import title_Permission2 from '../assets/titleImages/Giving-Permission.png'
import title_Exceptions3 from '../assets/titleImages/Mandatory-Exceptions.png'
import title_Moral from '../assets/titleImages/Moral-Rights.png'
import title_Online from '../assets/titleImages/Online-Intermediaries.png'
import title_Exceptions4 from '../assets/titleImages/Optional-Exceptions.png'
import title_Orphan from '../assets/titleImages/Orphan-Works.png'
import title_Out from '../assets/titleImages/Out-Of-Commerce.png'
import title_Private from '../assets/titleImages/Private-Enforcement.png'
import title_Protected from '../assets/titleImages/Protected-Works.png'
import title_Protection from '../assets/titleImages/Protection.png'
import title_Public from '../assets/titleImages/The-Public-Domain.png'
import title_Public2 from '../assets/titleImages/Public-Enforcement.png'
import title_About from '../assets/titleImages/About.png'
import title_History from '../assets/titleImages/History.png'
import title_Related from '../assets/titleImages/Related-Rights.png'
import title_Use from '../assets/titleImages/Use.png'


import {ReactComponent as AllWorks } from '../assets/icons/All Works.svg';
import {ReactComponent as Architecture } from '../assets/icons/Architecture.svg';
import {ReactComponent as Audiovisual } from '../assets/icons/Audiovisual.svg';
import {ReactComponent as Database } from '../assets/icons/Database.svg';
import {ReactComponent as Literary } from '../assets/icons/Literary.svg';
import {ReactComponent as Broadcast } from '../assets/icons/Broadcast.svg';
import {ReactComponent as Licensing } from '../assets/icons/Licensing.svg';
import {ReactComponent as Musical } from '../assets/icons/Musical.svg';
import {ReactComponent as Performance } from '../assets/icons/Performance.svg';
import {ReactComponent as Software } from '../assets/icons/Software.svg';
import {ReactComponent as SoundRecording } from '../assets/icons/Sound Recording.svg';
import {ReactComponent as Typography } from '../assets/icons/Typographic.svg';
import {ReactComponent as VideoGames } from '../assets/icons/Video Games.svg';
import {ReactComponent as Artistic } from '../assets/icons/Artistic.svg';

/*

import the following files:
3D Printer.jpg  Cinema.png   Gramaphone.jpg        Printing press.jpg   Theatre.jpg
Animation.jpg   Data.jpg     Microchip.jpg         Projector.jpg        VHS.jpg
Arcade.jpg      Ereader.jpg  Moulding.jpg          Screen printing.jpg  VR.jpg
Cassette.jpg    eSports.jpg  Photo processing.jpg  Streaming.jpg

from the following folder:

../assets/leafBackgrounds/

*/

import leaf_3DPrinter from '../assets/leafBackgrounds/3D Printer.jpg';
import leaf_Cinema from  '../assets/leafBackgrounds/Cinema.png';
import leaf_Braille from '../assets/leafBackgrounds/Braille.jpg';
import leaf_Gramaphone from '../assets/leafBackgrounds/Gramaphone.jpg';
import leaf_PrintingPress from '../assets/leafBackgrounds/Printing press.jpg';
import leaf_Theatre from '../assets/leafBackgrounds/Theatre.jpg';
import leaf_Animation from '../assets/leafBackgrounds/Animation.jpg';
import leaf_Data from '../assets/leafBackgrounds/Data.jpg';
import ArrowLeft from '../assets/SVGRs/ArrowLeft';
import leaf_Microchip from '../assets/leafBackgrounds/Microchip.jpg';
import leaf_Projector from '../assets/leafBackgrounds/Projector.jpg';
import leaf_VHS from '../assets/leafBackgrounds/VHS.jpg';
import leaf_Arcade from '../assets/leafBackgrounds/Arcade.jpg';
import leaf_Ereader from '../assets/leafBackgrounds/Ereader.jpg';
import leaf_Moulding from '../assets/leafBackgrounds/Moulding.jpg';
import leaf_ScreenPrinting from '../assets/leafBackgrounds/Screen printing.jpg';
import leaf_VR from '../assets/leafBackgrounds/VR.jpg';
import leaf_Cassette from '../assets/leafBackgrounds/Cassette.jpg';
import leaf_eSports from '../assets/leafBackgrounds/eSports.jpg';
import leaf_eSports2 from '../assets/leafBackgrounds/eSports2.jpg';
import leaf_Photograph from '../assets/leafBackgrounds/Photograph.jpg';
import leaf_Streaming from '../assets/leafBackgrounds/Streaming.jpg';

// create an array of the leaf images

const leafImgArray = [
  leaf_3DPrinter,
  leaf_Cinema,
  leaf_Braille,
  leaf_Gramaphone,
  leaf_PrintingPress,
  leaf_Theatre,
  leaf_Animation,
  leaf_Data,
  leaf_Microchip,
  leaf_Projector,
  leaf_VHS,
  leaf_Arcade,
  leaf_Ereader,
  leaf_Moulding,
  leaf_ScreenPrinting,
  leaf_VR,
  leaf_Cassette,
  leaf_eSports,
  leaf_eSports2,
  leaf_Photograph,
  leaf_Streaming
];

function jumpToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) element.scrollIntoView({behavior: "smooth"});
}

const titleImgMap = {
'about': title_About,
'history': title_History,
  'authors-and-owners': title_Authors,
  'collecting-societies': title_Collecting,
  'contracts-and-tpms': title_Contract,
  'copyright-duration': title_Duration,
  'copyright-exceptions': title_Exceptions,
  'economic-rights': title_Economic,
  'enforcement': title_Enforcement,
  'exceptions': title_Exceptions2,
  'exploitation': title_Exploitation,
  'getting-permission': title_Permission,
  'giving-permission': title_Permission2,
  'mandatory-exceptions': title_Exceptions3,
  'moral-rights': title_Moral,
  'online-intermediaries': title_Online,
  'optional-exceptions': title_Exceptions4,
  'orphan-works': title_Orphan,
  'out-of-commerce-works': title_Out,
  'private-enforcement': title_Private,
  'protected-works': title_Protected,
  'protection': title_Protection,
  'the-public-domain': title_Public,
  'public-enforcement': title_Public2,
  'related-rights': title_Related,
  'use': title_Use,
}
const iconStyle = {
                                                 height: 32,
                                                 width: 32,
                                                 fill: 'white'
                                             };
                                             
 
const iconIdMap = {
    "all works": <AllWorks  style={ iconStyle }  />,
    "architecture": <Architecture  style={ iconStyle }  />,
    "audiovisual": <Audiovisual  style={ iconStyle }  />,
    "database": <Database  style={ iconStyle }  />,
    "literary": <Literary  style={ iconStyle }  />,
    "musical": <Musical  style={ iconStyle }  />,
    "performance": <Performance  style={ iconStyle }  />,
    "software": <Software  style={ iconStyle }  />,
    "licensing": <Licensing  style={ iconStyle }  />,
    "broadcast": <Broadcast  style={ iconStyle }  />,
    "sound recording": <SoundRecording  style={ iconStyle }  />,
    "typographical arrangement": <Typography style={ iconStyle }  />,
    "video games": <VideoGames  style={ iconStyle }  />,
    "artistic": <Artistic  style={ iconStyle }  />,
    "visual art": <Artistic  style={ iconStyle }  />,
}

const qmn = {
    "20": "Protection",
    "21": "Use",
    "22": "Exploitation",
    "23": "Enforcement",
}


class ModalLeaf extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

    state = {
    guidanceOpen: false,
        width: window.innerWidth,
                height: window.innerHeight ,
                vennState: 'initial',
                legendState: 'initial',
    };

    // check if props.selectedModal has changed
    componentDidUpdate(prevProps) {
        const { selectedModal, selectedTrans } = this.props;
        // check which props or states have changed

        if(prevProps.t(`${selectedModal.toUpperCase()}--leaf-content`, { returnObjects: true }) !== this.props.t(`${selectedModal.toUpperCase()}--leaf-content`, { returnObjects: true })) {
            console.debug("check3")
        }

        if (this.props.selectedModal !== prevProps.selectedModal) {
            console.debug("check2",this.modalRef.current)
            this.setState({ guidanceOpen: false });

            const modalContent = this.modalRef.current.querySelector('.cds--modal-content');
                if (modalContent) {
                  modalContent.scrollTop = 0;
//                     setTimeout(() => {
//                       modalContent.scrollTop = 0;
//                     }, 100);
                }

            setTimeout( () => jumpToElement(window.location.hash.substring(1)), 2000 );
        }
    }

    render() {
    const { dispatch, t, i18n, open, selectedTrans, isFullWidth, sidebarOpen, setSelectedModal, selectedModal, prevSelectedModal, previousPreviousValueModal, modalHeading, modalLabel } = this.props;

    const isMainContent = selectedModal.indexOf("m_") > -1 || selectedModal === "m_enforcement" || selectedModal === "m_copyright-duration" || selectedModal === "m_exceptions" || selectedModal === "m_related-rights" || selectedModal === "m_economic-rights" || selectedModal === "m_moral-rights";

    // const { i18n } = useTranslation();
    let casesContent = i18n.exists(`${selectedModal.toUpperCase()}--leaf-cases`) ? t(`${selectedModal.toUpperCase()}--leaf-cases`, { returnObjects: true }) : false;
    if (casesContent) {
        casesContent = casesContent.replaceAll("[All works]", '<img title="All Works" alt="icon All Works" class="caseIcons" src="/All Works.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[All Works]", '<img title="All Works" alt="icon All Works" class="caseIcons" src="/All Works.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Artistic]", '<img title="Artistic" alt="icon Artistic" class="caseIcons" src="/Artistic.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Literary]", '<img title="Literary" alt="icon Literary" class="caseIcons" src="/Literary.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Audiovisual]", '<img title="Audiovisual" alt="icon Audiovisual" class="caseIcons" src="/Audiovisual.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Database]", '<img title="Database" alt="icon Database" class="caseIcons" src="/Database.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Software]", '<img title="Software" alt="icon Software" class="caseIcons" src="/Software.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Performance]", '<img title="Performance" alt="icon Performance" class="caseIcons" src="/Performance.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Sound Recording]", '<img title="Sound Recording" alt="icon Sound Recording" class="caseIcons" src="/Sound Recording.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Video Games]", '<img title="Video Games" alt="icon Video Games" class="caseIcons" src="/Video Games.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Architecture]", '<img title="Architecture" alt="icon Architecture" class="caseIcons" src="/Architecture.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
        casesContent = casesContent.replaceAll("[Musical]", '<img title="Musical" alt="icon Musical" class="caseIcons" src="/Musical.svg" style="width: 46px;margin-top: 0;margin-bottom:0em;" />');
    }

    let legislationContent = i18n.exists(`${selectedModal.toUpperCase()}--leaf-legislation`) ? t(`${selectedModal.toUpperCase()}--legislation-cases`, { returnObjects: true }) : false;
    let researchContent = i18n.exists(`${selectedModal.toUpperCase()}--leaf-research`) ? t(`${selectedModal.toUpperCase()}--leaf-research`, { returnObjects: true }) : false;
    let guidanceContent = i18n.exists(`${selectedModal.toUpperCase()}--leaf-guidance`) ? t(`${selectedModal.toUpperCase()}--leaf-guidance`, { returnObjects: true }) : false;

    let mainContent = i18n.exists(`${selectedModal.toUpperCase()}--leaf-content`) ? t(`${selectedModal.toUpperCase()}--leaf-content`, { returnObjects: true }) : false;


    let buttonArrays = [];
    let mcArray = (mainContent || "").split("[BUTTON]");
    if (mcArray.length > 1) {
        mcArray.forEach((item, index) => {
                if(index !== 0) {
                    var buttonTitle = buttonArrays.push(mcArray[index].split("</p")[0].replace(/^\s+/, '').replace(/\s+$/, ''));
                }
        });

        mainContent = mcArray[0];
    }

// split guidanceContent string into multiple strings based on the pattern ([[A-Z])\w+]
let guidanceContentMap = {};
if (guidanceContent) {

    guidanceContent = guidanceContent.replaceAll(/\]<\/p>/g, "ENDKEY").replaceAll(/\] <\/p>/g, "ENDKEY").replaceAll(/\]<br>/g, "ENDKEY");
    guidanceContent = guidanceContent.replaceAll(/<p>\[/g, "STARTKEY").replaceAll(/<p> \[/g, "STARTKEY");

    // split guidanceContent string into multiple strings based on the pattern "<p>([a-zA-Z\s]{1,})["

    let guidanceContentArray = guidanceContent.split("STARTKEY").slice(1);

    guidanceContentArray.forEach((item, index) => {
        let key = item.split("ENDKEY")[0];
        if (key.indexOf("]") > -1) {
            key = key.replaceAll("] [", ", ").replaceAll("][", ", ");
        }
        let value = item.split("ENDKEY")[1];
        guidanceContentMap[key] = value;
    })
}
        let catId;
        let catName;
    if (!isMainContent) {
        catId = Object.keys(qlMapping)[Object.values(qlMapping).findIndex(d => d.includes(selectedModal))];
        catName = qmn[(catId || "").toString()] || ""
    }

    return <div id="modalLeaf"
    style={{ overflowY: selectedModal === "m_enforcement" ? 'visible' : '' }}
    className={ (selectedModal === "m_enforcement" && !this.props.isMobile ? 'isEnf ' : '') + (sidebarOpen ? "modal-wrapper cds--nav-expanded" : "modal-wrapper") + ( selectedModal === "m_enforcement" ? " hideClose " : " ") + ( selectedModal === "m_copyright-duration" || selectedModal === "m_exceptions" || selectedModal === "m_related-rights" || selectedModal === "m_economic-rights" || selectedModal === "m_moral-rights" ? " darkkCloseButton" : "lighttCloseButton") }>
                    <Modal
            style={{  background: 'white', pointerEvents: 'all'}}
           open={open && setSelectedModal !== ""}
           onRequestClose={() => { window.location.hash = "";
                console.debug(previousPreviousValueModal,prevSelectedModal,catName)

               if (prevSelectedModal.indexOf("m_") > -1 || prevSelectedModal === "") {
                 if (selectedModal === prevSelectedModal && prevSelectedModal !== "") {
                    dispatch(setSelectedModal(previousPreviousValueModal))
                 }
                 else {
                    dispatch(setSelectedModal(prevSelectedModal))
                 }
               } else if (catName && catName.length > 0) {
                   dispatch(setSelectedModal("m_" + catName.toLowerCase()) )
               } else {
               dispatch(setSelectedModal(""))
               }

               this.setState({ guidanceOpen: false })
           }}
           ref={this.modalRef}
           passiveModal
           >
           <div className="modal__body--wrapper">

{ !isMainContent && selectedModal && <div className=""><img  alt="icon vennCircle" src={leafImgArray[Math.floor(Math.random() * leafImgArray.length)]}
                            style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }} /></div> }
           <Container style={{ paddingLeft: 0, paddingTop: 16, marginLeft: 16 }}>

             <Row>
               <Col sm={12}>
               <div className="leaf__mainContent">

{ (selectedModal !== "m_exceptions" || this.props.isMobile) && selectedModal !== "m_enforcement" && <><Breadcrumb noTrailingSlash>
  <BreadcrumbItem onClick={ (e) => { e.preventDefault(); window.location.hash = ""; dispatch(setSelectedModal("")) }} className={"notranslate"} href="/">{getTranslationMap(selectedTrans, "Home")}</BreadcrumbItem>
  { !isMainContent && selectedModal && selectedModal.toLowerCase() !== "history" && selectedModal.toLowerCase() !== "about" && <BreadcrumbItem className={"notranslate"} onClick={ (e) => {e.preventDefault(); window.location.hash = ""; dispatch(setSelectedModal("m_" + catName.toLowerCase()) ) }} href="/">{getTranslationMap(selectedTrans, catName)}</BreadcrumbItem>}
  <BreadcrumbItem style={{ textTransform: ''}} href="#" isCurrentPage className={"notranslate"} >
    { selectedModal === "m_enforcement" ? getTranslationMap(selectedTrans, "Enforcement") :
    selectedModal === "m_economic-rights" ? getTranslationMap(selectedTrans, "Economic Rights") :
    selectedModal === "m_moral-rights" ? getTranslationMap(selectedTrans, "Moral Rights") :
    selectedModal === "m_related-rights" ? getTranslationMap(selectedTrans, "Related Rights" ) :
    selectedModal === "m_exceptions" ? getTranslationMap(selectedTrans, "Exceptions") :
    selectedModal === "m_copyright-duration" ? getTranslationMap(selectedTrans, "Copyright Duration") :
    selectedModal.indexOf("m_") > -1 ? '' : getTranslationMap(selectedTrans, selectedModal.toLowerCase().replaceAll("contracts and tpms","Contracts and TPMs")) }
  </BreadcrumbItem>
</Breadcrumb>
{ !isMainContent && selectedModal  && <h2>
 <img  alt={selectedModal} src={titleImgMap[selectedModal.replaceAll(" ","-").toLowerCase()] || titleImgMap[catName.toLowerCase()]}
 style={{ maxWidth: '90%', maxHeight: '128px', paddingTop: 18,
      marginLeft: -14,
      marginTop: -12,
      marginBottom: -32

  }} /></h2>}</>}
<br/>
{ !isMainContent && selectedModal && selectedModal !== "about"  && selectedModal !== "history" && <>
 <p className={'reviewDisclaimer notranslate'}  onClick={() => dispatch(setSelectedModal('history')) }  > Last review April 2023</p></>}
{ !isMainContent && <>
<br/>
    { mainContent && <div
    dangerouslySetInnerHTML={{ __html:  mainContent }}
    className="wp-site-blocks"
    /> }
  <div>
  { buttonArrays.length > 0 && <div>
    { buttonArrays.map((item, index) => {
        return <Button kind="tertiary" onClick={() => dispatch(setSelectedModal(item.toUpperCase())) } style={{ marginRight: '1em', marginBottom: '1em' }}>{item}</Button>
    })}
  </div>}
<br/>
<p className="guidanceDisciplineContent">
<Modal style={{background:"#eeeeee", padding: '1em', textAlign: 'left'}}  id="guidanceModal"
                           onRequestClose={() => { window.location.hash = "";
                           this.setState({ guidanceOpen: false })} }
                           open={this.state.guidanceOpen !== false}      passiveModal     modalHeading={<><h3>{this.state.guidanceOpen}</h3></>}>


                      {
                      this.state.guidanceOpen !== false && <div style={{ paddingTop: '1em' }}
                        dangerouslySetInnerHTML={{ __html:  guidanceContentMap[this.state.guidanceOpen]/* t(`${selectedModal.toUpperCase()}--leaf-guidance--${this.state.guidanceOpen.toUpperCase()}`, { returnObjects: true }) */ }}
                        className="wp-site-blocks"
                      />
                      }
                               </Modal>
{guidanceContent && this.state.guidanceOpen === false && !isMainContent && <div style={{background:"#eeeeee", padding: '1em', textAlign: 'left'}}>
        <h5> Guidance based on type of work</h5>
        <p style={{ paddingTop: 8, fontSize: '' }}>Click on the icons below to get guidance about specific types of work</p>
        <br/>

        { Object.keys(guidanceContentMap).map((key, index) => {
        if (key.indexOf(", ") > -1) {
           return <div style={{
               border: '4px solid rgb(73, 128, 193)',
               display: 'inline-block',
               marginRight: 4
           }} className={"guidanceWrapper"}
           onClick={() => {
               this.setState({ guidanceOpen: key });
               setTimeout(function() { window.doGTranslate("en|" + document.querySelector(".gt-current-lang").children[1].innerHTML)}, 400);}}
           >{key.split(", ").map(k => <IconButton
           size={'md'} className="discipline--listButton" label={k} style={{
                                                                               marginRight: 0,
                                                                               marginLeft: 0
                                                                           }} >
                           {iconIdMap[(k || "").toLowerCase()]}
                       </IconButton>)}</div>
        } else {
            return <IconButton size={'md'} className="discipline--listButton" label={key}
           onClick={() => {
               this.setState({ guidanceOpen: key });
               setTimeout(function() { window.doGTranslate("en|" + document.querySelector(".gt-current-lang").children[1].innerHTML)}, 400);}}>
                            {iconIdMap[(key || "").toLowerCase()]}
                        </IconButton>
        }
            })
        }
    </div>
}
</p>
<div className="tabClass">
  { (researchContent || legislationContent || casesContent) && <Tabs >
    <TabList aria-label="List of tabs" activation="manual">
      <Tab disabled={!researchContent}>Research</Tab>
      <Tab disabled={!legislationContent}>Legislation</Tab>
      <Tab disabled={!casesContent}>Related Cases</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>

      <div
          dangerouslySetInnerHTML={{ __html:  t(`${selectedModal.toUpperCase()}--leaf-research`, { returnObjects: true }) }}
          className="wp-site-blocks"
          />
      </TabPanel>
      <TabPanel>
      <div
          dangerouslySetInnerHTML={{ __html:  t(`${selectedModal.toUpperCase()}--leaf-legislation`, { returnObjects: true }) }}
          className="wp-site-blocks"
          />
      </TabPanel>
      { casesContent && <TabPanel>
      <p style={{ fontSize: "smaller"}}>
      Unless stated otherwise, the following cases are all decisions of the Court of Justice of the European Union (the CJEU).
      </p>
      <br/>
      <div
        dangerouslySetInnerHTML={{ __html:  casesContent }}
        className="wp-site-blocks"
      />
      </TabPanel>}

    </TabPanels>
  </Tabs> }
</div>
  </div>
</>}
</div>
                                   </Col>
                          </Row>

           </Container>

           </div>
            { isMainContent && this.props.isMobile && selectedModal === "m_enforcement" &&       <h5 style={{marginTop: -24 }} > ENFORCEMENT </h5> }
            { isMainContent && selectedModal === "m_enforcement" && <EnforcementInfographic
                selectedModal={selectedModal}
                styleCircleVenn={this.props.styleCircleVenn}
                selectedTrans={selectedTrans} isMobile={this.props.isMobile} style={{
                pointerEvents: 'none',
                position: 'relative',
                paddingRight: this.props.isMobile ? '0' : '3rem',
                maxHeight: this.props.isMobile ? '100%' : 'calc(100vh - 184px)',
                margin: '0% auto 0% 0%',
                boxSizing: 'border-box',
                paddingLeft: '0px',
                width: '100%',
                maxWidth: 'calc(100% - 0)',
                paddingTop: '0'
            }} />}

{ isMainContent && selectedModal === "m_moral-rights" && <MoralRightsInfographic selectedTrans={selectedTrans} style={{
//     pointerEvents: 'none',
    position: 'relative',
    paddingRight: this.props.isMobile ? '6px' : '3rem',
    maxHeight: 'calc(100vh - 184px)',
    margin: '0% auto 0% 0%',
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: this.props.isMobile ? '6px' : '3rem',
    maxWidth: 'calc(100% - 0)',
    paddingTop: '1rem'
}} />}

{ isMainContent && selectedModal === "m_related-rights" && <RelatedRightsInfographic selectedTrans={selectedTrans} style={{
//     pointerEvents: 'none',
    position: 'relative',
    width: '100%',
    paddingRight: this.props.isMobile ? '4px' : '3rem',
    margin: '0% auto 0% 0%',
    boxSizing: 'border-box',
    paddingLeft: this.props.isMobile ? '4px' : '3rem',
    maxWidth: '1280px',
    maxHeight: 'calc(100vh - 184px)',
    paddingTop: '1rem'
}} />}

{ isMainContent && selectedModal === "m_exceptions" && <ExceptionsInfographic selectedTrans={selectedTrans}
    isMobile={this.props.isMobile}
    dispatch={dispatch}
    setSelectedModal={setSelectedModal}
    style={{
    position: 'relative',
    paddingRight: '3rem',
    margin: '0% auto 0% 0%',
    boxSizing: 'border-box',
      pointerEvents: 'none',
    maxHeight: 'calc(100vh - 184px)',
    paddingLeft: '3rem',
    maxWidth: '800px',
    paddingTop: '1rem'
}} />}

{ isMainContent && selectedModal === "m_copyright-duration" && <CopyrightDurationInfographic selectedTrans={selectedTrans} style={{

//     pointerEvents: 'none',
    position: 'relative',
    paddingRight: this.props.isMobile ? '0' : '3rem',
    margin: '0% auto 0% 0%',
    boxSizing: 'border-box',
    paddingLeft: this.props.isMobile ? '0' : '3rem',
    maxWidth: 'calc(100% - 0)',
    paddingTop: '0'
}} />}

{ isMainContent && selectedModal === "m_economic-rights" && <EconomicRightsInfographic selectedTrans={selectedTrans} style={{
//     pointerEvents: 'none',
    position: 'relative',
    paddingRight: this.props.isMobile ? '0' : '3rem',
    maxHeight: '800px',
    width: '100%',
    margin: '0% auto 0% 0%',
    boxSizing: 'border-box',
    paddingLeft: this.props.isMobile ? '0' : '3rem',
    maxWidth: 'calc(100% - 0)',
    paddingTop: '0'
}} />}

{ isMainContent && <div className="readmoreWrapper" style={{ maxWidth:  selectedModal === "m_enforcement" ? '100%' : ''}}>
 <hr/>
        <button className={'readMoreButton notranslate'}
           onClick={() => {
                dispatch(setSelectedModal( selectedModal === "m_enforcement" ? "ENFORCEMENT" :
                 selectedModal === "m_moral-rights" ? "MORAL RIGHTS" :
                 selectedModal === "m_economic-rights" ? "ECONOMIC RIGHTS" :
                 selectedModal === "m_related-rights" ? "RELATED RIGHTS" :
                 selectedModal === "m_exceptions" ? "COPYRIGHT EXCEPTIONS" :
                 selectedModal === "m_copyright-duration" ? "COPYRIGHT DURATION" : "" ))
           } }
             >{getTranslationMap(selectedTrans, "Read More")}
         </button>

         {  this.props.isMobile && selectedModal === "m_enforcement" && <div style={{
                      position: '',
                      zIndex: selectedModal === "m_enforcement" ? 99 : '',
                      bottom: selectedModal === "m_enforcement" ? 128 : '',
                      width: selectedModal === "m_enforcement" ? '100%' : '',
                      paddingBottom: 40,
                      color: 'black'}}>
                         {  selectedModal !== "m_enforcement" && <h5 style={{ fontWeight: 400, paddingTop: '.8em', paddingBottom: '.8em'}}>Previous</h5>}<ArrowLeft
                         isMobile={this.props.isMobile}
                             navLabel = {"Exploitation"}
                             navIcon = {<image x="47.5" y={"9.5"} height="32" width="32"  href={iconLicensing} style={{ height: 8 }} alt="ProtectLogo"/>}
                             style={{
                             pointerEvents: 'all',
//                                height: 400,
                               width: '40%',
                               maxWidth: 300,
                               minWidth: 300,
                               paddingTop: '2em',
                               paddingBottom: '2em',
         //                       marginTop: Math.floor(styleCircleVenn.height / 2 - styleCircleVenn.height / 12)
                             }}
                             onClick={() => dispatch(setSelectedModal("m_exploitation"))}
                             className="arrowLeft"
                         /></div>}
 </div> }
         </Modal>

    </div>
    }
}


export default withTranslation()(ModalLeaf);