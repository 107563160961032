export const translationMap = {
                           "protected works": {
                               "transId": "PROTECTED WORKS",
                               "bg": "ЗАКРИЛЯНИ ПРОИЗВЕДЕНИЯ",
                               "cs": "CHRÁNĚNÁ DÍLA",
                               "da": "BESKYTTET VÆRK",
                               "de": "GESCHÜTZTE WERKE",
                               "el": "ΠΡΟΣΤΑΤΕΥΟΜΕΝΑ ΕΡΓΑ",
                               "es": "OBRAS PROTEGIDAS",
                               "et": "KAITSTUD TÖÖD",
                               "fi": "SUOJATUT TEOKSET",
                               "fr": "ŒUVRES PROTÉGÉES",
                               "ga": "SAOTHAIR FAOI CHOSAINT",
                               "hr": "ZAŠTIĆENA DJELA",
                               "hu": "VÉDETT MŰVEK",
                               "it": "OPERE PROTETTE",
                               "lt": "SAUGOMI KŪRINIAI",
                               "lv": "AIZSARGĀTIE DARBI",
                               "mt": "XOGĦLIJIET PROTETTI",
                               "nl": "BESCHERMDE WERKEN",
                               "pl": "UTWORY CHRONIONE",
                               "pt": "OBRAS PROTEGIDAS",
                               "ro": "OPERE PROTEJATE",
                               "se": "UPPHOVRÄTTSSKYDDADE VERK ",
                               "sl": "VAROVANA DELA",
                               "sk": "CHRÁNENÉ DIELA",
                               "en": "PROTECTED WORKS"
                           },
                           "economic rights": {
                               "transId": "Economic rights",
                               "bg": "Имуществени права",
                               "cs": "Majetková práva",
                               "da": "Økonomiske rettigheder",
                               "de": "Verwertungsrechte",
                               "el": "Περιουσιακές εξουσίες",
                               "es": "Derechos patrimoniales",
                               "et": "Varalised õigused",
                               "fi": "Taloudelliset oikeudet",
                               "fr": "Droits économiques",
                               "ga": "Cearta eacnamaíochta",
                               "hr": "Imovinska prava",
                               "hu": "Vagyoni jogok",
                               "it": "Diritti patrimoniali",
                               "lt": "Turtinės teisės",
                               "lv": "Mantiskās tiesības",
                               "mt": "Drittijiet ekonomiċi",
                               "nl": "Exploitatierechten",
                               "pl": "Prawa majątkowe",
                               "pt": "Direitos patrimoniais",
                               "ro": "Drepturi economice",
                               "se": "Ekonomisk rätt",
                               "sl": "Ekonomske pravice",
                               "sk": "Majetkové práva",
                               "en": "Economic rights"
                           },
                           "related rights": {
                               "transId": "Related rights",
                               "bg": "Сродни права",
                               "cs": "Práva související",
                               "da": "Beslægtede rettigheder",
                               "de": "Verwandte Schutzrechte",
                               "el": "Συγγενικά δικαιώματα",
                               "es": "Derechos afines o derechos conexos",
                               "et": "Kaasnevad õigused",
                               "fi": "Lähioikeudet",
                               "fr": "Droits voisins",
                               "ga": "Cearta gaolmhara",
                               "hr": "Srodna prava",
                               "hu": "Kapcsolódó jogok",
                               "it": "Diritti connessi",
                               "lt": "Gretutinės teisės",
                               "lv": "Blakustiesības",
                               "mt": "Drittijiet relatati",
                               "nl": "Naburige rechten",
                               "pl": "Prawa pokrewne",
                               "pt": "Direitos conexos",
                               "ro": "Drepturi conexe",
                               "se": "Närstående rätt)",
                               "sl": "Sorodne pravice",
                               "sk": "Súvisiace práva",
                               "en": "Related rights"
                           },
                           "moral rights": {
                               "transId": "Moral rights",
                               "bg": "Неимуществени права",
                               "cs": "Osobnostní práva",
                               "da": "Moralske rettigheder",
                               "de": "Urheberpersönlichkeitsrechte",
                               "el": "Ηθικό δικαίωμα",
                               "es": "Derechos morales",
                               "et": "Isiklikud õigused",
                               "fi": "Moraaliset oikeudet",
                               "fr": "Droits moraux",
                               "ga": "Cearta morálta",
                               "hr": "Moralna prava",
                               "hu": "személyhez fűződő jogok",
                               "it": "Diritti morali",
                               "lt": "Neturtinės teisės",
                               "lv": "Privātās tiesības",
                               "mt": "Drittijiet Morali",
                               "nl": "Morele rechten",
                               "pl": "Prawa osobiste",
                               "pt": "Direitos morais",
                               "ro": "Drepturi morale",
                               "se": "Ideella rätter",
                               "sl": "Moralne pravice",
                               "sk": "Osobnostné práva",
                               "en": "Moral rights"
                           },
                           "authors and owners": {
                               "transId": "AUTHORS AND OWNERS",
                               "bg": "АВТОРИ И ПРАВОНОСИТЕЛИ",
                               "cs": "AUTOŘI A NOSITELÉ PRÁV",
                               "da": "FORFATTER OG EJER",
                               "de": "URHEBER- UND INHABERSCHAFT",
                               "el": "ΔΗΜΙΟΥΡΓΟΙ ΚΑΙ ΔΙΚΑΙΟΥΧΟΙ",
                               "es": "AUTORES Y TITULARES DE DERECHOS",
                               "et": "AUTORID JA OMANIKUD",
                               "fi": "TEKIJÄT JA OIKEUDENHALTIJAT ",
                               "fr": "AUTEURS ET PROPRIÉTAIRES",
                               "ga": "ÚDAR AGUS ÚINÉIRÍ",
                               "hr": "AUTORI I NOSITELJI",
                               "hu": "SZERZŐK ÉS TULAJDONOSOK",
                               "it": "AUTORI E TITOLARI",
                               "lt": "AUTORIAI IR SAVININKAI",
                               "lv": "AUTORI UN ĪPAŠNIEKI",
                               "mt": "AWTURIU SIDIEN",
                               "nl": "MAKERS EN AUTEURSRECHTHEBBENDEN",
                               "pl": "TWÓRCY I UPRAWNIENI",
                               "pt": "AUTORES E DETENTORES",
                               "ro": "AUTORI ŞI PROPRIETARI",
                               "se": "UPPHOVSMÄN OCH RÄTTSINNEHAVARE",
                               "sl": "AVTORJI IN LASTNIKI",
                               "sk": "AUTORI A VLASTNÍCI",
                               "en": "AUTHORS AND OWNERS"
                           },
                           "the public domain": {
                               "transId": "THE PUBLIC DOMAIN",
                               "bg": "ОБЩЕСТВЕНОТО ДОСТОЯНИЕ",
                               "cs": "VOLNÁ DÍLA",
                               "da": "DET OFFENTLIGE DOMÆNE",
                               "de": "GEMEINFREIHEIT",
                               "el": "Ο ΔΗΜΟΣΙΟΣ ΤΟΜΕΑΣ",
                               "es": "EL DOMINIO PÚBLICO",
                               "et": "AUTORIÕIGUSE KEHTIVUSE TÄHTAJA LÕPPEMINE",
                               "fi": "VAPAASTI SAATAVILLA OLEVAT TEOKSET ",
                               "fr": "LE DOMAINE PUBLIC",
                               "ga": "AN FEARANN POIBLÍ",
                               "hr": "JAVNA DOMENA",
                               "hu": "A KÖZKINCS",
                               "it": "PUBBLICO DOMINIO",
                               "lt": "VIEŠASIS DOMENAS",
                               "lv": "PUBLISKAIS DOMĒNS",
                               "mt": "ID-DOMINJU PUBBLIKU",
                               "nl": "HET PUBLIEKE DOMEIN",
                               "pl": "DOMENA PUBLICZNA",
                               "pt": "O DOMÍNIO PÚBLICO",
                               "ro": "DOMENIUL PUBLIC",
                               "se": "ALLMÄN EGENDOM",
                               "sl": "JAVNA DOMENA",
                               "sk": "VEREJNÁ DOMÉNA",
                               "en": "THE PUBLIC DOMAIN"
                           },
                           "copyright duration": {
                               "transId": "Copyright duration",
                               "bg": "Срок на авторското право",
                               "cs": "Trvání práv",
                               "da": "Ophavsrettens varighed",
                               "de": "Schutzdauer",
                               "el": "Διάρκεια προστασίας της πνευματικής ιδιοκτησίας",
                               "es": "Duración de los derechos de autor",
                               "et": "Autoriõiguse ajaline kehtivus",
                               "fi": "Tekijänoikeuden suoja-aika",
                               "fr": "Durée de protection",
                               "ga": "Fad an chóipchirt",
                               "hr": "Trajanje autorskopravne zaštite",
                               "hu": "védelmi idő",
                               "it": "Durata del diritto d’autore",
                               "lt": "Autorių teisių galiojimas",
                               "lv": "Autortiesību ilgums",
                               "mt": "Perjodu ta’ protezzjoni għad-drittijiet tal-awtur",
                               "nl": "Duur van het auteursrecht",
                               "pl": "Czas trwania praw autorskich",
                               "pt": "Duração do direito de autor",
                               "ro": "Durata drepturilor de autor",
                               "se": "Upphovsrättens giltighet",
                               "sl": "Trajanje avtorskih pravic",
                               "sk": "Trvanie autorských práv",
                               "en": "Copyright duration"
                           },
                           "copyright exceptions": {
                               "transId": "COPYRIGHT EXCEPTIONS",
                               "bg": "ИЗКЛЮЧЕНИЯ ОТ АВТОРСКОТО ПРАВО",
                               "cs": "VÝJIMKY A OMEZENÍ AUTORSKÉHO PRÁVA",
                               "da": "OPHAVSRET UNDTAGELSER",
                               "de": "AUSNAHMEN DES URHEBERRECHTS",
                               "el": "ΕΞΑΙΡΕΣΕΙΣ ΑΠΟ ΤΗΝ ΠΝΕΥΜΑΤΙΚΗ ΙΔΙΟΚΤΗΣΙΑ",
                               "es": "EXCEPCIONES A LOS DERECHOS DE AUTOR",
                               "et": "AUTORIÕIGUSE ERANDID",
                               "fi": "TEKIJÄNOIKEUDEN RAJOITUKSET",
                               "fr": "EXCEPTIONS AU DROIT D’AUTEUR ",
                               "ga": "EISCEACHTAÍ CÓIPCHIRT",
                               "hr": "IZNIMKE OD AUTORSKOG PRAVA",
                               "hu": "SZERZŐI JOGI KIVÉTELEK",
                               "it": "ECCEZIONI AL DIRITTO D’AUTORE",
                               "lt": "AUTORIŲ TEISĖS IŠIMTYS",
                               "lv": "AUTORTIESĪBU IZŅĒMUMI",
                               "mt": "EĊĊEZZJONIJIET TA' COPYRIGHT",
                               "nl": "UITZONDERINGEN OP HET AUTEURSRECHT",
                               "pl": "WYJĄTKI W PRAWIE AUTORSKIM",
                               "pt": "EXCEÇÕES AOS DIREITOS DE AUTOR",
                               "ro": "EXCEPȚII DE LA DREPTUL DE AUTOR",
                               "se": "UNDANTAG I UPPHOVSRÄTTEN ",
                               "sl": "IZJEME PRI AVTORSKIH PRAVICAH",
                               "sk": "VÝNIMKY AUTORSKÝCH PRÁVA",
                               "en": "COPYRIGHT EXCEPTIONS"
                           },
                           "mandatory exceptions": {
                               "transId": "MANDATORY EXCEPTIONS",
                               "bg": "ЗАДЪЛЖИТЕЛНИ ИЗКЛЮЧЕНИЯ",
                               "cs": "POVINNÉ VÝJIMKY",
                               "da": "OBLIGATORISKE UNDTAGELSER",
                               "de": "VERPFLICHTENDE AUSNAHMEN",
                               "el": "ΥΠΟΧΡΕΩΤΙΚΕΣ ΕΞΑΙΡΕΣΕΙΣ",
                               "es": "EXCEPCIONES OBLIGATORIAS",
                               "et": "KOHUSTUSLIKUD ERANDID",
                               "fi": "PAKOLLISET RAJOITUKSET ",
                               "fr": "EXCEPTIONS OBLIGATOIRES",
                               "ga": "EISCEACHTAÍ ÉIGEANTACHA",
                               "hr": "OBAVEZNE IZNIMKE",
                               "hu": "KÖTELEZŐ KIVÉTELEK",
                               "it": "ECCEZIONI OBBLIGATORIE",
                               "lt": "PRIVALOMOSIOS IŠIMTYS",
                               "lv": "OBLIGĀTIE IZŅĒMUMI",
                               "mt": "EĊĊEZZJONIJIET OBBLIGATORJI",
                               "nl": "VERPLICHTE UITZONDERINGEN",
                               "pl": "OBOWIĄZKOWE WYJĄTKI",
                               "pt": "EXCEÇÕES OBRIGATÓRIAS",
                               "ro": "EXCEPȚII OBLIGATORII",
                               "se": "OBLIGATORISKA UNDANTAG",
                               "sl": "OBVEZNE IZJEME",
                               "sk": "POVINNÉ VÝNIMKY",
                               "en": "MANDATORY EXCEPTIONS"
                           },
                           "optional exceptions": {
                               "transId": "OPTIONAL EXCEPTIONS",
                               "bg": "НЕЗАДЪЛЖИТЕЛНИ ИЗКЛЮЧЕНИЯ",
                               "cs": "VOLITELNÉ VÝJIMKY",
                               "da": "VALGFRIE UNDTAGELSER",
                               "de": "OPTIONALE AUSNAHMEN",
                               "el": "ΠΡΟΑΙΡΕΤΙΚΕΣ ΕΞΑΙΡΕΣΕΙΣ",
                               "es": "EXCEPCIONES OPCIONALES",
                               "et": "VALIKULISED ERANDID",
                               "fi": "VALINNAISET RAJOITUKSET",
                               "fr": "EXCEPTIONS FACULTATIVES",
                               "ga": "EISCEACHTAÍ ROGHNACHA",
                               "hr": "FAKULTATIVNE IZNIMKE",
                               "hu": "OPCIONÁLIS KIVÉTELEK",
                               "it": "ECCEZIONI FACOLTATIVE",
                               "lt": "NEPRIVALOMOSIOS IŠIMTYS",
                               "lv": "IZVĒLES IZŅĒMUMI",
                               "mt": "EĊĊEZZJONIJIET FAKULTATTIVI",
                               "nl": "OPTIONELE UITZONDERINGEN",
                               "pl": "OPCJONALNE WYJĄTKI",
                               "pt": "EXCEÇÕES OPCIONAIS",
                               "ro": "EXCEPȚII OPȚIONALE",
                               "se": "VALFRIGA UNDANTAG",
                               "sl": "NEOBVEZNE IZJEME",
                               "sk": "VOLITEĽNÉ VÝNIMKY",
                               "en": "OPTIONAL EXCEPTIONS"
                           },
                           "getting permission": {
                               "transId": "GETTING PERMISSION",
                               "bg": "ПОЛУЧАВАНЕ НА РАЗРЕШЕНИЕ",
                               "cs": "ZÍSKÁNÍ SVOLENÍ",
                               "da": "OPNÅELSE AF TILLADELSE",
                               "de": "ERLAUBNISERTEILUNG",
                               "el": "ΛΗΨΗ ΑΔΕΙΑΣ",
                               "es": "OBTENER PERMISO",
                               "et": "NÕUSOLEKU SAAMINE",
                               "fi": "LUVAN HANKINTA",
                               "fr": "OBTENIR LA PERMISSION",
                               "ga": "CEAD A FHÁIL",
                               "hr": "DOBIVANJE DOZVOLE",
                               "hu": "ENGEDÉLYKÉRÉS",
                               "it": "OTTENERE L’AUTORIZZAZIONE",
                               "lt": "LEIDIMO GAVIMAS",
                               "lv": "ATĻAUJAS SAŅEMŠANA",
                               "mt": "KSIB TA’PERMESS",
                               "nl": "TOESTEMMING KRIJGEN",
                               "pl": "UZYSKIWANIE UPOWAŻNIENIA",
                               "pt": "OBTER AUTORIZAÇÃO",
                               "ro": "OBȚINEREA PERMISIUNII",
                               "se": "FÅ TILLSTÅND",
                               "sl": "PRIDOBIVANJE DOVOLJENJA",
                               "sk": "ZÍSKAVANIE POVOLENIA",
                               "en": "GETTING PERMISSION"
                           },
                           "contracts and tpms": {
                               "transId": "Contracts and TPMs",
                               "bg": "Договори и технически средства за защита",
                               "cs": "Smlouvy a technické prostředky",
                               "da": "Kontrakter og TPMs",
                               "de": "Verträge und TPMs",
                               "el": "Συμβάσεις και Τεχνολογικά Μέτρα",
                               "es": "Contratos y medidas tecnológicas de protección (MTPs)",
                               "et": "Lepingud ja tehnilised kaitsemeetmed",
                               "fi": "Sopimukset ja tekniset toimenpiteet",
                               "fr": "Contrats et mesures techniques de protection (MTP)",
                               "ga": "Conarthaí agus BCTanna",
                               "hr": "Ugovori i TPM",
                               "hu": "Szerződések és hatásos műszaki intézkedések",
                               "it": "Contratti e TPM",
                               "lt": "Sandoriai ir TAP",
                               "lv": "Līgumi un TAP",
                               "mt": "Kuntratti u TPMs",
                               "nl": "Contracten en TPMs",
                               "pl": "Umowy i technologiczne środki ochrony (TPM)",
                               "pt": "Contratos e medidas de caráter tecnológico",
                               "ro": "Contracte și MPT-uri",
                               "se": "Avtal och TPMs",
                               "sl": "Pogodbe in TPM-ji",
                               "sk": "Zmluvy a TPM",
                               "en": "Contracts and TPMs"
                           },
                           "orphan works": {
                               "transId": "Orphan works",
                               "bg": "Осиротели произведения",
                               "cs": "Osiřelá díla",
                               "da": "Hjemløse værker",
                               "de": "Verwaiste Werke",
                               "el": "Ορφανά έργα",
                               "es": "Obras huérfanas",
                               "et": "Orbteos",
                               "fi": "Orpoteokset",
                               "fr": "Œuvres orphelines",
                               "ga": "Saothair dhílleachta",
                               "hr": "Djela siročad",
                               "hu": "Árva művek",
                               "it": "Opere orfane",
                               "lt": "Nežinomų autorių teisių kūriniai",
                               "lv": "Nenosakāmu autortiesību subjektu darbi",
                               "mt": "Xogħolijiet orfni",
                               "nl": "Verweesde werken",
                               "pl": "Dzieła osierocone",
                               "pt": "Obras órfãs",
                               "ro": "Opere orfane",
                               "se": "Herrelösa verk",
                               "sl": "Osirotela dela",
                               "sk": "Osirelé diela",
                               "en": "Orphan works"
                           },
                           "out of commerce works": {
                               "transId": "OUT OF COMMERCE WORKS",
                               "bg": "ПРОИЗВЕДЕНИЯ ИЗВЪН ТЪРГОВСКО ОБРАЩЕНИЕ",
                               "cs": "DÍLA NEDOSTUPNÁ NA TRHU",
                               "da": "VÆRKER UDENFOR MARKEDET",
                               "de": "VERGRIFFENE WERKE",
                               "el": "ΕΡΓΑ ΜΗ ΔΙΑΘΕΣΙΜΑ ΣΤΟ ΕΜΠΟΡΙΟ",
                               "es": "OBRAS FUERA DEL COMERCIO ",
                               "et": "KAUBANDUSVÄLISED TEOSED",
                               "fi": "KAUPALLISESTA JAKELUSTA POISTUNEET TEOKSET",
                               "fr": "ŒUVRES INDISPONIBLES DANS LE COMMERCE",
                               "ga": "SAOTHAIR NACH BHFUIL AR FÁIL AR BHONN TRÁCHTÁLA",
                               "hr": "DJELA IZVAN PROMETA",
                               "hu": "KERESKEDELMI FORGALOMBAN NEM KAPHATÓ MŰVEK",
                               "it": "OPERE FUORI COMMERCIO",
                               "lt": "KŪRINIAI, KURIAIS NEBEPREKIAUJAMA",
                               "lv": "VAIRS NETIRGOTIE DARBI",
                               "mt": "XOGĦLIJIET LI MHUMIEX FIS-SUQ",
                               "nl": "OUT-OF-COMMERCE WERKEN",
                               "pl": "UTWORY NIEDOSTĘPNE W OBROCIE HANDLOWYM",
                               "pt": "OBRAS FORA DO CIRCUITO COMERCIAL",
                               "ro": "OPERE ÎN AFARA COMERȚULUI",
                               "se": "UTGÅNGNA VERK",
                               "sl": "DELA IZVEN PROMETA",
                               "sk": "OBCHODNE NEDOSTUPNÉ DIELA",
                               "en": "OUT OF COMMERCE WORKS"
                           },
                           "giving permission": {
                               "transId": "GIVING PERMISSION",
                               "bg": "ДАВАНЕ НА РАЗРЕШЕНИЕ",
                               "cs": "POSKYTNUTÍ SVOLENÍ",
                               "da": "AT GIVE TILLADELSE",
                               "de": "DAS ERTEILEN EINER ERLAUBNIS",
                               "el": "ΠΑΡΟΧΗ ΑΔΕΙΑΣ",
                               "es": "DAR PERMISO",
                               "et": "NÕUSOLEKU ANDMINE",
                               "fi": "LUVAN ANTAMINEN",
                               "fr": "DONNER L’AUTORISATION",
                               "ga": "CEAD A THABHAIRT ",
                               "hr": "DAVANJE DOPUŠTENJA",
                               "hu": "A FELHASZNÁLÁSI ENGEDÉLY ",
                               "it": "CONCEDERE L’AUTORIZZAZIONE",
                               "lt": "LEIDIMO SUTEIKIMAS",
                               "lv": "ATĻAUJAS PIEŠĶIRSANA",
                               "mt": "L-GĦOTJA TAL-PERMESS",
                               "nl": "TOESTEMMING GEVEN",
                               "pl": "UDZIELENIE ZEZWOLENIA",
                               "pt": "DAR AUTORIZAÇÃO ",
                               "ro": "ACORDAREA PERMISIUNII",
                               "se": "GE TILLSTÅND",
                               "sl": "DAJANJE DOVOLJENJA",
                               "sk": "UDEĽOVANIE POVOLENIA",
                               "en": "GIVING PERMISSION"
                           },
                           "collecting societies": {
                               "transId": "COLLECTING SOCIETIES",
                               "bg": "ОРГАНИЗАЦИИ ЗА КОЛЕКТИВНО УПРАВЛЕНИЕ НА ПРАВА",
                               "cs": "KOLEKTIVNÍ SPRÁVA",
                               "da": "INDSAMLER FORENINGER",
                               "de": "VERWERTUNGSGESELLSCHAFTEN",
                               "el": "ΟΡΓΑΝΙΣΜΟΙ ΣΥΛΛΟΓΙΚΗΣ ΔΙΑΧΕΙΡΙΣΗΣ",
                               "es": "SOCIEDADES DE GESTIÓN",
                               "et": "KOLLEKTIIVSE ESINDAMISE ORGANISATSIOONID",
                               "fi": "TEKIJÄNOIKEUSJÄRJESTÖT",
                               "fr": "SOCIÉTÉS DE GESTION COLLECTIVE",
                               "ga": "CUMAINN BHAILITHE",
                               "hr": "ORGANIZACIJE ZA KOLEKTIVNO OSTVARIVANJE PRAVA",
                               "hu": "KÖZÖS JOGKEZELŐ SZERVEZETEK",
                               "it": "SOCIETA' DI GESTIONE COLLETTIVA",
                               "lt": "KOLEKTYVINIO ADMINISTRAVIMO ASOCIACIJOS",
                               "lv": "PĀRVALDĪBAS IESTĀDES",
                               "mt": "COLLECTING SOCIETY",
                               "nl": "COLLECTIEVE BEHEERSORGANISATIES",
                               "pl": "ORGANIZACJE ZBIOROWEGO ZARZĄDZANIA",
                               "pt": "SOCIEDADES DE GESTÃO COLETIVA",
                               "ro": "SOCIETĂȚI COLECTIVE",
                               "se": "RÄTTIGHETSFÖRVALTANDE ORGANISATIONER",
                               "sl": "KOLEKCIJSKE DRUŽBE",
                               "sk": "VYBERAJÚCE ORGANIZÁCIE",
                               "en": "COLLECTING SOCIETIES"
                           },
                           "enforcement": {
                               "transId": "Enforcement",
                               "bg": "Правоприлагане",
                               "cs": "Vymáhání",
                               "da": "Håndhævelse",
                               "de": "Durchsetzung",
                               "el": "Επιβολή",
                               "es": "Aplicación",
                               "et": "Õiguste kaitse",
                               "fi": "Täytäntöönpano",
                               "fr": "Application",
                               "ga": "Forfheidhmiú",
                               "hr": "Primjena",
                               "hu": "jogérvényesítés",
                               "it": "Applicazione",
                               "lt": "Gynimas",
                               "lv": "Izpilde",
                               "mt": "Infurzar",
                               "nl": "Handhaving",
                               "pl": "Egzekwowanie",
                               "pt": "Defesa",
                               "ro": "Recunoaștere",
                               "se": "Tillämpning",
                               "sl": "Izvrševanje",
                               "sk": "Vymožiteľnosť",
                               "en": "Enforcement"
                           },
                           "private enforcement": {
                               "transId": "PRIVATE ENFORCEMENT",
                               "bg": "ГРАЖДАНСКОПРАВНИ СПОСОБИ ЗА ЗАЩИТА",
                               "cs": "CIVILNÍ VYMÁHÁNÍ PRÁV",
                               "da": "PRIVAT HÅNDHÆVELSE",
                               "de": "PRIVATE RECHTSDURCHSETZUNG",
                               "el": "ΙΔΙΩΤΙΚΗ ΕΠΙΒΟΛΗ",
                               "es": "APLICACIÓN PRIVADA DEL DERECHO",
                               "et": "ÕIGUSTE KAITSE",
                               "fi": "YKSITYINEN TÄYTÄNTÖÖNPANO",
                               "fr": "APPLICATION PRIVÉE",
                               "ga": "FORFHEIDHMIÚ PRÍOBHÁIDEACH",
                               "hr": "PRIVATNO IZVRŠENJE",
                               "hu": "A JOGÉRVÉNYESÍTÉS MAGÁNJOGI ÚTJA",
                               "it": "MISURE DI ESECUZIONE PRIVATA",
                               "lt": "CIVILINIAI TEISIŲ GYNIMO BŪDAI",
                               "lv": "PRIVĀTĀ IZPILDE",
                               "mt": "INFURZAR PRIVAT",
                               "nl": "PRIVATE HANDHAVING",
                               "pl": "PRYWATNE EGZEKWOWANIE PRAW",
                               "pt": "APLICAÇÃO PRIVADA",
                               "ro": "RECUNOAȘTEREA DREPTURILOR DE AUTOR PRIN MĂSURI INDIVIDUALE",
                               "se": "PRIVAT TILLÄMPNING",
                               "sl": "ZASEBNO VARSTVO PRAVIC",
                               "sk": "SÚKROMNOPRÁVNE VYMÁHANIE",
                               "en": "PRIVATE ENFORCEMENT"
                           },
                           "online intermediaries": {
                               "transId": "ONLINE INTERMEDIARIES",
                               "bg": "ОНЛАЙН ПОСРЕДНИЦИ",
                               "cs": "ONLINE ZPROSTŘEDKOVATELÉ",
                               "da": "ONLINE FORMIDLER",
                               "de": "ONLINE-INTERMEDIÄRE",
                               "el": "ΔΙΑΔΙΚΤΥΑΚΟΙ ΔΙΑΜΕΣΟΛΑΒΗΤΕΣ",
                               "es": "INTERMEDIARIOS EN LÍNEA",
                               "et": "ONLINE VAHENDJAD",
                               "fi": "VÄLITTÄJÄT",
                               "fr": "INTERMÉDIAIRES EN LIGNE ",
                               "ga": "IDIRGHABHÁLAITHE AR LÍNE",
                               "hr": "INTERNETSKI POSREDNICI",
                               "hu": "ONLINE KÖZVETÍTŐ SZOLGÁLTATÓK",
                               "it": "INTERMEDIARI ONLINE",
                               "lt": "INTERNETO TARPININKAI",
                               "lv": "TIEŠSAISTES STARPNIEKI",
                               "mt": "INTERMEDJARJI ONLAJN",
                               "nl": "ONLINE TUSSENPERSONEN",
                               "pl": "POŚREDNICY INTERNETOWI",
                               "pt": "INTERMEDIÁRIOS EM LINHA",
                               "ro": "INTERMEDIARI ONLINE",
                               "se": "ONLINEPLATTFORMAR",
                               "sl": "SPLETNI POSREDNIKI",
                               "sk": "ONLINE SPROSTREDKOVATELIA",
                               "en": "ONLINE INTERMEDIARIES"
                           },
                           "public enforcement": {
                               "transId": "PUBLIC ENFORCEMENT",
                               "bg": "МЕРКИ ЗА ДЪРЖАВНА ПРИНУДА",
                               "cs": "VEŘEJNOPRÁVNÍ VYMÁHÁNÍ PRÁV",
                               "da": "OFFENTLIG HÅNDHÆVELSE",
                               "de": "STAATLICHE DURCHSETZUNG",
                               "el": "ΔΗΜΟΣΙΑ ΕΦΑΡΜΟΓΗ",
                               "es": "APLICACIÓN PÚBLICA DEL DERECHO",
                               "et": "ÕIGUSTE KAITSE",
                               "fi": "TEKIJÄNOIKEUKSIEN JULKINEN VALVONTA ",
                               "fr": "APPLICATION PUBLIQUE",
                               "ga": "FORFHEIDHMIÚ POIBLÍ",
                               "hr": "JAVNO IZVRŠENJE",
                               "hu": "A JOGÉRVÉNYESÍTÉS KÖZJOGI ÚTJAI",
                               "it": "MISURE DI ESECUZIONE PUBBLICA",
                               "lt": "VIEŠOSIOS TEISĖS NUMATYTI TEISIŲ GYNIMO BŪDAI",
                               "lv": "PUBLISKĀ IZPILDE",
                               "mt": "INFURZAR PUBBLIKU",
                               "nl": "OPENBARE HANDHAVING",
                               "pl": "PUBLICZNE EGZEKWOWANIE PRAW",
                               "pt": "EXECUÇÃO PÚBLICA",
                               "ro": "RECUNOAȘTEREA DREPTURILOR DE AUTOR PRIN MĂSURI PUBLICE",
                               "se": "OFFENTLIG TILLÄMPNING",
                               "sl": "JAVNO VARSTVO PRAVIC",
                               "sk": "VEREJNOPRÁVNE VYMÁHANIE",
                               "en": "PUBLIC ENFORCEMENT"
                           },
                           "home": {
                               "transId": "Home",
                               "bg": "Начало",
                               "cs": "Úvod",
                               "da": "Hjem",
                               "de": "Start",
                               "el": "Αρχική",
                               "es": "Inicio",
                               "et": "Avaleht",
                               "fi": "Etusivu / Koti",
                               "fr": "Accueil",
                               "ga": "Baile",
                               "hr": "Početna",
                               "hu": "Otthon",
                               "it": "Home",
                               "lt": "Pagrindinis",
                               "lv": "Sākums",
                               "mt": "Dħul",
                               "nl": "Home",
                               "pl": "Strona główna",
                               "pt": "Início / Página Principal / Página Inicial",
                               "ro": "Acasă",
                               "se": "Hem",
                               "sl": "Domov",
                               "sk": "Domov",
                               "en": "Home"
                           },
                           "quick links": {
                               "transId": "Quick Links",
                               "bg": "Бързи връзки",
                               "cs": "Užitečné odkazy",
                               "da": "Hurtig link",
                               "de": "Quick Links",
                               "el": "Σύντομοι Σύνδεσμοι",
                               "es": "Enlaces Rápidos",
                               "et": "Kiirklahvid",
                               "fi": "Pikalinkit",
                               "fr": "Liens utiles",
                               "ga": "Naisc Thapa",
                               "hr": "Izravne poveznice",
                               "hu": "gyorslinkek",
                               "it": "Sitemap",
                               "lt": "Sparčiosios Nuorodos",
                               "lv": "Ātrās saites",
                               "mt": "Quick Links",
                               "nl": "Snelkoppelingen",
                               "pl": "Szybkie linki",
                               "pt": "Hiperligações",
                               "ro": "Link-uri rapide",
                               "se": "Snabblänkar",
                               "sl": "Hitre povezave",
                               "sk": "Rýchle odkazy",
                               "en": "Quick Links"
                           },
                           "protection": {
                               "transId": "Protection",
                               "bg": "Закрила",
                               "cs": "Ochrana",
                               "da": "Beskyttelse",
                               "de": "Schutz",
                               "el": "Προστασία",
                               "es": "Protección",
                               "et": "Teose kaitse",
                               "fi": "Suoja / Suojaus",
                               "fr": "Protection",
                               "ga": "Cosaint",
                               "hr": "Zaštita",
                               "hu": "védelem",
                               "it": "Protezione",
                               "lt": "Apsauga",
                               "lv": "Aizsardzība",
                               "mt": "Protezzjoni",
                               "nl": "Bescherming",
                               "pl": "Ochrona",
                               "pt": "Proteção",
                               "ro": "Protejare",
                               "se": "Skydd",
                               "sl": "Zaščita",
                               "sk": "Ochrana",
                               "en": "Protection"
                           },
                           "use": {
                               "transId": "Use",
                               "bg": "Използване",
                               "cs": "Použití",
                               "da": "Brug",
                               "de": "Nutzung",
                               "el": "Χρήση",
                               "es": "Uso",
                               "et": "Kasutusviisid",
                               "fi": "Käyttö",
                               "fr": "Utilisation",
                               "ga": "Úsáid",
                               "hr": "Korištenje",
                               "hu": "felhasználás, használat",
                               "it": "Uso",
                               "lt": "Naudojimas",
                               "lv": "Izmantošana",
                               "mt": "Użu",
                               "nl": "Gebruik",
                               "pl": "Korzystanie",
                               "pt": "Utilização",
                               "ro": "Utilizare",
                               "se": "Bruk",
                               "sl": "Uporaba",
                               "sk": "Použitie",
                               "en": "Use"
                           },
                           "exploitation": {
                               "transId": "Exploitation",
                               "bg": "Експлоатация",
                               "cs": "Využití práv",
                               "da": "Udnyttelse",
                               "de": "Verwertung",
                               "el": "Εκμετάλλευση",
                               "es": "Explotación",
                               "et": "Tulusus",
                               "fi": "Hyödyntäminen",
                               "fr": "Exploitation",
                               "ga": "Dúshaothrú",
                               "hr": "Iskorištavanje",
                               "hu": "hasznosítás",
                               "it": "Sfruttamento",
                               "lt": "Eksploatacija",
                               "lv": "Ekspluatācija",
                               "mt": "Esplojtazzjoni",
                               "nl": "Exploitatie",
                               "pl": "Eksploatacja",
                               "pt": "Exploração",
                               "ro": "Folosire",
                               "se": "Utnyttjande",
                               "sl": "Izkoriščanje",
                               "sk": "Využívanie",
                               "en": "Exploitation"
                           },
                           "about": {
                               "transId": "About",
                               "bg": "За нас",
                               "cs": "O nás – Copyrightuser.eu",
                               "da": "Om",
                               "de": "Über",
                               "el": "Σχετικά",
                               "es": "Acerca de",
                               "et": "Kodulehest",
                               "fi": "Tietoa",
                               "fr": "À propos",
                               "ga": "Maidir Le",
                               "hr": "O",
                               "hu": "-ról",
                               "it": "Chi siamo",
                               "lt": "Apie",
                               "lv": "Par",
                               "mt": "Dwarna",
                               "nl": "Over",
                               "pl": "O stronie",
                               "pt": "Sobre",
                               "ro": "Despre",
                               "se": "Om",
                               "sl": "O tem",
                               "sk": "Bližšie",
                               "en": "About"
                           },
                           "welcome to copyrightuser.eu": {
                               "transId": "Welcome to CopyrightUser.EU",
                               "bg": "Добре дошли на CopyrightUser.EU",
                               "cs": "Vítejte na CopyrightUser.EU",
                               "da": "Velkommen til OphavsretBruger.EU",
                               "de": "Willkommen auf CopyrightUser.EU",
                               "el": "Καλώς Ήλθατε στο CopyrightUser.EU",
                               "es": "Bienvenido a CopyrightUser.EU",
                               "et": "Tere tulemast CopyrightUser.EU lehele",
                               "fi": "Tervetuloa osoitteeseen CopyrightUser.EU",
                               "fr": "Bienvenue sur CopyrightUser.EU",
                               "ga": "Fáilte go CopyrightUser.EU",
                               "hr": "Dobrodošli na CopyrightUser.EU",
                               "hu": "Üdvözöljük a CopyrightUser.EU oldalon!",
                               "it": "Benvenuti su CopyrightUser.EU",
                               "lt": "Sveiki atvykę į CopyrightUser.EU",
                               "lv": "Laipni lūgti CopyrightUser.EU",
                               "mt": "Merħba għal CoprightUser.EU",
                               "nl": "Welkom op CopyrightUser.EU",
                               "pl": "Witamy na stronie CopyrightUser.EU",
                               "pt": "Bem-vindo ao CopyrightUser.EU",
                               "ro": "Bun venit pe CopyrightUser.EU",
                               "se": "Välkommen till CopyrightUser.EU",
                               "sl": "Dobrodošli na CopyrightUser.EU",
                               "sk": "Vitajte na CopyrightUser.EU",
                               "en": "Welcome to CopyrightUser.EU"
                           },
                           "life of author": {
                               "transId": "Life of author",
                               "bg": "Живота на автора",
                               "cs": "Život autora",
                               "da": "Ophavsmandens levetid",
                               "de": "Leben des Urhebers",
                               "el": "Ζωή του δημιουργού",
                               "es": "Vida del autor",
                               "et": "Autori eluaeg",
                               "fi": "Tekijän elinikä",
                               "fr": "Vie de l’auteur",
                               "ga": "Saolré an údair",
                               "hr": "Život autora",
                               "hu": "A szerző élete",
                               "it": "Vita dell’autore",
                               "lt": "Autoriaus gyvenimo trukmė",
                               "lv": "Autora dzīves ilgums",
                               "mt": "Ħajja tal-awtur",
                               "nl": "Leven van de maker",
                               "pl": "Okres życia twórcy",
                               "pt": "Vida do autor",
                               "ro": "Viața autorului",
                               "se": "Skaparens livstid",
                               "sl": "Življenje avtorja",
                               "sk": "Život autora",
                               "en": "Life of author"
                           },
                           "70 years": {
                               "transId": "70 years",
                               "bg": "70 години",
                               "cs": "70 let",
                               "da": "70 år",
                               "de": "70 Jahre",
                               "el": "70 έτη",
                               "es": "70 años",
                               "et": "70 aastat",
                               "fi": "70 vuotta",
                               "fr": "70 ans",
                               "ga": "70 bliain",
                               "hr": "70 godina",
                               "hu": "70 év",
                               "it": "70 anni",
                               "lt": "70 metų",
                               "lv": "70 gadi",
                               "mt": "70 sena",
                               "nl": "70 jaar",
                               "pl": "70 lat",
                               "pt": "Setenta anos",
                               "ro": "70 de ani",
                               "se": "70 år",
                               "sl": "70 let",
                               "sk": "70 rokov",
                               "en": "70 years"
                           },
                           "work created": {
                               "transId": "Work created",
                               "bg": "Създаване на произведението",
                               "cs": "Vznik díla",
                               "da": "Skabte værker",
                               "de": "Werk wird erschaffen",
                               "el": "Δημιουργηθέν έργο",
                               "es": "Obra creada",
                               "et": "Loodud teos",
                               "fi": "Teoksen luomishetkestä",
                               "fr": "Œuvre créée",
                               "ga": "Saothar a cruthaíodh",
                               "hr": "Stvaranja djela",
                               "hu": "Létrehozott mű",
                               "it": "L’opera viene creata",
                               "lt": "Sukurtas kūrinys",
                               "lv": "Darbs radīts",
                               "mt": "Xogħol maħluq",
                               "nl": "Werk gemaakt",
                               "pl": "Powstanie utworu",
                               "pt": "Obra",
                               "ro": "Opere create",
                               "se": "Verk skapas",
                               "sl": "Ustvarjeno delo",
                               "sk": "Vytvorenie diela",
                               "en": "Work created"
                           },
                           "work enters into public domain": {
                               "transId": "Work enters the Public Domain",
                               "bg": "Произведението става обществено достояние",
                               "cs": "Dílo se stává volným",
                               "da": "Værket bliver en del af det offentlige domæne",
                               "de": "Werk wird gemeinfrei",
                               "el": "Έργο που εμπίπτει στον δημόσιο τομέα",
                               "es": "La obra pasa a formar parte del dominio público",
                               "et": "Teos läheb avalikku omandisse",
                               "fi": "Teos siirtyy osaksi public domainia",
                               "fr": "L’œuvre tombe dans le Domaine Public",
                               "ga": "Téann an saothar san Fhearann Poiblí",
                               "hr": "Djela ulaze u javnu domenu",
                               "hu": "A mű a közkinccsé válik",
                               "it": "L’opera entra nel pubblico dominio",
                               "lt": "Kūrinys patenka į Viešąjį Domeną",
                               "lv": "Darbs nonāk publiskajā domēnā",
                               "mt": "Xogħol li waqa’ fil-qasam pubbliku",
                               "nl": "Werk wordt publiek domein",
                               "pl": "Przejście utworu do domeny publicznej",
                               "pt": "Queda no domínio público",
                               "ro": "Operele intră în domeniul public",
                               "se": "Verk blir allmän egendom",
                               "sl": "Delo pride v javno domeno",
                               "sk": "Dielo sa stáva súčasťou verejnej domény",
                               "en": "Work enters the Public Domain"
                           },
                           "work enters the public domain": {
                               "transId": "Work enters the Public Domain",
                               "bg": "Произведението става обществено достояние",
                               "cs": "Dílo se stává volným",
                               "da": "Værket bliver en del af det offentlige domæne",
                               "de": "Werk wird gemeinfrei",
                               "el": "Έργο που εμπίπτει στον δημόσιο τομέα",
                               "es": "La obra pasa a formar parte del dominio público",
                               "et": "Teos läheb avalikku omandisse",
                               "fi": "Teos siirtyy osaksi public domainia",
                               "fr": "L’œuvre tombe dans le Domaine Public",
                               "ga": "Téann an saothar san Fhearann Poiblí",
                               "hr": "Djela ulaze u javnu domenu",
                               "hu": "A mű a közkinccsé válik",
                               "it": "L’opera entra nel pubblico dominio",
                               "lt": "Kūrinys patenka į Viešąjį Domeną",
                               "lv": "Darbs nonāk publiskajā domēnā",
                               "mt": "Xogħol li waqa’ fil-qasam pubbliku",
                               "nl": "Werk wordt publiek domein",
                               "pl": "Przejście utworu do domeny publicznej",
                               "pt": "Queda no domínio público",
                               "ro": "Operele intră în domeniul public",
                               "se": "Verk blir allmän egendom",
                               "sl": "Delo pride v javno domeno",
                               "sk": "Dielo sa stáva súčasťou verejnej domény",
                               "en": "Work enters the Public Domain"
                           },
                           "ideas": {
                               "transId": "Ideas",
                               "bg": "Идеи",
                               "cs": "Myšlenky",
                               "da": "Ideér",
                               "de": "Ideen",
                               "el": "Ιδέες",
                               "es": "Ideas",
                               "et": "Ideed",
                               "fi": "Ideat",
                               "fr": "Idées",
                               "ga": "Smaointe",
                               "hr": "ideje",
                               "hu": "Ötletek",
                               "it": "Idee",
                               "lt": "Idėjos",
                               "lv": "Idejas",
                               "mt": "Ideat",
                               "nl": "Ideeën",
                               "pl": "Pomysły",
                               "pt": "Ideias",
                               "ro": "Idei",
                               "se": "Idéer",
                               "sl": "Ideje",
                               "sk": "Myšlienky",
                               "en": "Ideas"
                           },
                           "de minimis": {
                               "transId": "De minimis",
                               "bg": "De minimis",
                               "cs": "Nepodstatné užití",
                               "da": "De minimis",
                               "de": "De minimis",
                               "el": "De minimis",
                               "es": "De minimis",
                               "et": "De minimis",
                               "fi": "De minimis",
                               "fr": "De minimis",
                               "ga": "De minimis",
                               "hr": "minimalno",
                               "hu": "Csekély jelentőségű",
                               "it": "De minimis",
                               "lt": "De minimis",
                               "lv": "De minimis",
                               "mt": "De Minimis",
                               "nl": "De minimis",
                               "pl": "De minimis",
                               "pt": "De minimis",
                               "ro": "De minimis",
                               "se": "De minimis",
                               "sl": "De minimis",
                               "sk": "De minimis",
                               "en": "De minimis"
                           },
                           "exceptions": {
                               "transId": "Exceptions",
                               "bg": "Изключения",
                               "cs": "Výjimky",
                               "da": "Undtagelser",
                               "de": "Ausnahmen",
                               "el": "Εξαιρέσεις",
                               "es": "Excepciones o limitaciones",
                               "et": "Erandid",
                               "fi": "Rajoitukset",
                               "fr": "Exceptions",
                               "ga": "Eisceachtaí",
                               "hr": "iznimke",
                               "hu": "Kivételek",
                               "it": "Eccezioni",
                               "lt": "Išimtys",
                               "lv": "Izņēmumi",
                               "mt": "Eċċezzjonijiet",
                               "nl": "Uitzonderingen",
                               "pl": "Wyjątki",
                               "pt": "Exceções",
                               "ro": "Excepții",
                               "se": "Undantag",
                               "sl": "Izjeme",
                               "sk": "Výnimky",
                               "en": "Exceptions"
                           },
                           "open licences": {
                               "transId": "Open licences",
                               "bg": "Отворени лицензи",
                               "cs": "Veřejné licence",
                               "da": "Åbne licenser",
                               "de": "Offene Lizenzen",
                               "el": "Άδειες για ελεύθερη χρήση",
                               "es": "Licencias abiertas",
                               "et": "Avatud sisulitsentsid",
                               "fi": "Avoimet lisenssit",
                               "fr": "Licences libres",
                               "ga": "Ceadúnais oscailte",
                               "hr": "Otvorene licence",
                               "hu": "Nyílt engedélyek",
                               "it": "Licenze libere",
                               "lt": "Atvirosios licencijos",
                               "lv": "Atvērtās licences",
                               "mt": "Liċenzja ħielsa",
                               "nl": "Open licenties",
                               "pl": "Licencje otwarte",
                               "pt": "Licenças abertas",
                               "ro": "Licențe libere",
                               "se": "Öppen licens",
                               "sl": "Odprte licence",
                               "sk": "Verejné licencie",
                               "en": "Open licences"
                           },
                           "out of copyright works": {
                               "transId": "Out of copyright works",
                               "bg": "Произведения извън търговско обращение",
                               "cs": "Volná díla",
                               "da": "Uden for ophavsretlige værker",
                               "de": "Nicht mehr geschützte Werke",
                               "el": "Έργα εκτός πνευματικής ιδιοκτησίας",
                               "es": "Obras no sujetas a derechos de autor",
                               "et": "Autoriõiguse kehtivuse tähtaeg",
                               "fi": "Tekijänoikeudella suojatuista teoksista",
                               "fr": "Travaux non protégés",
                               "ga": "Saothair le cóipcheart in éag",
                               "hr": "Djela bez autorskopravne zaštite",
                               "hu": "Szerzői jog által nem védett művek",
                               "it": "Opere libere dal diritto d’autore",
                               "lt": "Iš autorių teisių saugomų kūrinių",
                               "lv": "Ārpus autortiesību darbiem",
                               "mt": "xogħlijiet bla protezzjoni tad-drittijiet tal-awtur",
                               "nl": "Vervallen auteursrecht",
                               "pl": "Utwory niechronione",
                               "pt": "Obras sem direitos autorais",
                               "ro": "Opere aparținând domeniului public",
                               "se": "Verk utan upphovsrätt",
                               "sl": "Dela izven varstva (z avtorskimi pravicami)",
                               "sk": "Autorskoprávne voľné diela",
                               "en": "Out of copyright works"
                           },
                           "author": {
                               "transId": "Author",
                               "bg": "Автор",
                               "cs": "Autor",
                               "da": "Forfatter",
                               "de": "Urheber",
                               "el": "Δημιουργός",
                               "es": "Autor",
                               "et": "Author",
                               "fi": "Tekijä",
                               "fr": "Auteur",
                               "ga": "Údar",
                               "hr": "Autor",
                               "hu": "Szerző",
                               "it": "Autore",
                               "lt": "Autorius",
                               "lv": "Autors",
                               "mt": "Awtur",
                               "nl": "Maker",
                               "pl": "Twórca",
                               "pt": "Autor",
                               "ro": "Autor",
                               "se": "Skapare",
                               "sl": "Avtor",
                               "sk": "Autor",
                               "en": "Author"
                           },
                           "personality": {
                               "transId": "Personality",
                               "bg": "Личност",
                               "cs": "Osobnost autora",
                               "da": "Personlighed",
                               "de": "Persönlichkeit",
                               "el": "Προσωπικότητα",
                               "es": "Personalidad",
                               "et": "Autori isikupära",
                               "fi": "Persoonallisuus",
                               "fr": "Personnalité",
                               "ga": "Pearsantacht",
                               "hr": "Osobnost",
                               "hu": "Személyiség",
                               "it": "Personalità",
                               "lt": "Asmenybė",
                               "lv": "Personība",
                               "mt": "Personalita’",
                               "nl": "Persoonlijk",
                               "pl": "Osobowość",
                               "pt": "Personalidade",
                               "ro": "Personalitate",
                               "se": "Personlighet",
                               "sl": "Osebnost",
                               "sk": "Osobnosť",
                               "en": "Personality"
                           },
                           "creative choices": {
                               "transId": "Creative choices",
                               "bg": "Творчески принос",
                               "cs": "Tvůrčí činnost",
                               "da": "Kreative valg",
                               "de": "Kreative Entscheidung",
                               "el": "Δημιουργικές επιλογές",
                               "es": "Opciones creativas",
                               "et": "Loomingulised valikud",
                               "fi": "Luovat valinnat",
                               "fr": "Choix créatifs",
                               "ga": "Roghanna cruthaitheacha",
                               "hr": "Kreativni odabiri",
                               "hu": "Kreatív választás",
                               "it": "Scelte creative",
                               "lt": "Kūrybiški pasirinkimai",
                               "lv": "Radošas izvēles",
                               "mt": "Għażliet liberi u kreattivi",
                               "nl": "Creatieve keuzes",
                               "pl": "Twórcze wybory",
                               "pt": "Escolhas criativas",
                               "ro": "Alegeri creative",
                               "se": "Kreativa val",
                               "sl": "Ustvarjalne izbire",
                               "sk": "Tvorivé rozhodnutia",
                               "en": "Creative choices"
                           },
                           "author’s own intellectual creation": {
                               "transId": "Author’s own intellectual creation",
                               "bg": "Собствено интелектуално творение",
                               "cs": "Vlastní duševní výtvor autora",
                               "da": "Forfatterens egne intellektuelle frembringelser",
                               "de": "Eigene geistige Schöpfung",
                               "el": "Προσωπική πνευματική εργασία του δημιουργού",
                               "es": "La creación intelectual propia del autor",
                               "et": "Autori enda intellektuaalne looming",
                               "fi": "Tekijänsä henkinen luomus",
                               "fr": "(La propre) création intellectuelle de l’auteur",
                               "ga": "Cruthú intleachtúil féin an údair",
                               "hr": "Autorova originalna intelektualna tvorevina",
                               "hu": "A szerző egyéni, eredeti alkotása",
                               "it": "Creazione propria del suo autore",
                               "lt": "Autoriaus intelektinė kūryba",
                               "lv": "Paša autora intelektuālais radījums",
                               "mt": "Kreazzjoni intellettwali tal-awtur",
                               "nl": "Eigen intellectuele schepping (van de maker)",
                               "pl": "Własna twórczość intelektualna autora",
                               "pt": "Criação intelectual específica do respectivo autor",
                               "ro": "Creația intelectuală proprie a autorului",
                               "se": "Skapares egen intellektuella skapelse",
                               "sl": "Avtorjeva lastna inetelktualna stvaritev",
                               "sk": "Vlastná duševná činnosť autora",
                               "en": "Author’s own intellectual creation"
                           },
                           "copyright owner": {
                               "transId": "Copyright owner",
                               "bg": "Правоносител",
                               "cs": "Nositel práv",
                               "da": "Ophavsmand",
                               "de": "Urheberrechtsinhaber",
                               "el": "Δικαιούχος πνευματικής ιδιοκτησίας",
                               "es": "",
                               "et": "Autoriõiguse omanik",
                               "fi": "Tekijänoikeudenhaltija",
                               "fr": "Propriétaire du droit d’auteur",
                               "ga": "Úinéir an chóipchirt",
                               "hr": "Nositelj autorskog prava",
                               "hu": "Szerzői jogi jogosult",
                               "it": "Titolare del diritto d’autore",
                               "lt": "Autoriaus teisių savininkas",
                               "lv": "Autortiesību īpašnieks",
                               "mt": "Is-sid tad-drittijiet tal-awtur",
                               "nl": "Auteursrechthebbende",
                               "pl": "Podmiot praw autorskich",
                               "pt": "Detentor do direito de autor",
                               "ro": "Proprietarul drepturilor de autor",
                               "se": "Upphovsman",
                               "sl": "Imetnik avtorske pravice",
                               "sk": "Vlastník autorských práv",
                               "en": "Copyright owner"
                           },
                           "with permission": {
                               "transId": "With permission",
                               "bg": "С разрешение",
                               "cs": "Se svolením",
                               "da": "Med tilladelse",
                               "de": "Mit Erlaubnis",
                               "el": "Με άδεια",
                               "es": "Con permiso",
                               "et": "Nõusolekuga",
                               "fi": "Luvan kanssa",
                               "fr": "Avec l’autorisation du titulaire",
                               "ga": "Le cead",
                               "hr": "Uz dopuštenje",
                               "hu": "Engedéllyel",
                               "it": "Con autorizzazione",
                               "lt": "Su sutikimu",
                               "lv": "Ar atļauju",
                               "mt": "Bil-permess",
                               "nl": "Met toestemming",
                               "pl": "Za zgodą",
                               "pt": "Com autorização",
                               "ro": "Cu permisiune",
                               "se": "Med tillstånd",
                               "sl": "Z dovoljenjem",
                               "sk": "So súhlasom",
                               "en": "With permission"
                           },
                           "without permission": {
                               "transId": "Without permission",
                               "bg": "Без разрешение",
                               "cs": "Bez svolení",
                               "da": "Uden tilladelse",
                               "de": "Ohne Erlaubnis",
                               "el": "Χωρίς άδεια",
                               "es": "Sin permiso",
                               "et": "Nõusolekuta",
                               "fi": "Ilman lupaa",
                               "fr": "Sans l’autorisation du titulaire",
                               "ga": "Gan chead",
                               "hr": "Bez dopuštenja",
                               "hu": "Engedély nélkül",
                               "it": "Senza autorizzazione",
                               "lt": "Be sutikimo",
                               "lv": "Bez atļaujas",
                               "mt": "Mingħajr permess",
                               "nl": "Zonder toestemming",
                               "pl": "Bez zgody",
                               "pt": "Sem autorização",
                               "ro": "Fără permisiune",
                               "se": "Utan tillstånd",
                               "sl": "Brez dovoljenja",
                               "sk": "Bez súhlasu",
                               "en": "Without permission"
                           },
                           "copyright": {
                               "transId": "Copyright",
                               "bg": "Авторско право",
                               "cs": "Autorské právo",
                               "da": "Ophavsret",
                               "de": "Urheberrecht",
                               "el": "Πνευματική ιδιοκτησία",
                               "es": "Derecho(s) de autor",
                               "et": "Autoriõigus",
                               "fi": "Tekijänoikeus",
                               "fr": "Droit d’auteur",
                               "ga": "Cóipcheart",
                               "hr": "Autorsko pravo",
                               "hu": "Szerzői jog",
                               "it": "Diritto d’autore",
                               "lt": "Autorių teisės",
                               "lv": "Autortiesības",
                               "mt": "Drittijiet tal-Awtur",
                               "nl": "Auteursrecht",
                               "pl": "Prawo autorskie",
                               "pt": "Direitos de autor",
                               "ro": "Drepturi de autor",
                               "se": "Upphovsrätt",
                               "sl": "Avtorska pravica",
                               "sk": "Autorské právo",
                               "en": "Copyright"
                           },
                           "licences": {
                               "transId": "Licences",
                               "bg": "Лицензи",
                               "cs": "Licence",
                               "da": "Licenser",
                               "de": "Lizenzen",
                               "el": "Άδειες",
                               "es": "Licencia",
                               "et": "Litsentsid",
                               "fi": "Lisenssit",
                               "fr": "Licences",
                               "ga": "Ceadúnais",
                               "hr": "Licencek",
                               "hu": "Engedélyek",
                               "it": "Licenze",
                               "lt": "Licencijos",
                               "lv": "Licences",
                               "mt": "Liċenzji",
                               "nl": "Licenties",
                               "pl": "Licencje",
                               "pt": "Licenças",
                               "ro": "Licențe",
                               "se": "Licenser",
                               "sl": "Licence",
                               "sk": "Licencie",
                               "en": "Licences"
                           },
                           "assignment": {
                               "transId": "Assignment",
                               "bg": "Прехвърляне",
                               "cs": "Postoupení práv",
                               "da": "Opgave",
                               "de": "Abtretung",
                               "el": "Μεταβίβαση",
                               "es": "Cesión",
                               "et": "Loovutamine",
                               "fi": "Siirto",
                               "fr": "Cession de droits",
                               "ga": "Sannadh",
                               "hr": "prijenos",
                               "hu": "Átruházás",
                               "it": "Cessione",
                               "lt": "Perleidimas",
                               "lv": "Piešķiršana",
                               "mt": "Ċessjoni",
                               "nl": "Overdracht",
                               "pl": "Przeniesienie",
                               "pt": "Transferência",
                               "ro": "Atribuire",
                               "se": "Upplåtelse",
                               "sl": "Naloga",
                               "sk": "Prevod",
                               "en": "Assignment"
                           },
                           "exclusive": {
                               "transId": "Exclusive",
                               "bg": "Изключителни",
                               "cs": "Výhradní",
                               "da": "Eksklusiv",
                               "de": "Ausschließlich",
                               "el": "Αποκλειστικές",
                               "es": "Exclusivo/a",
                               "et": "Ainulitsents",
                               "fi": "Yksinomainen",
                               "fr": "Exclusif",
                               "ga": "Eisiach",
                               "hr": "isključivo",
                               "hu": "Kizárólagos",
                               "it": "Esclusivo",
                               "lt": "Išimtinės",
                               "lv": "Izņēmuma",
                               "mt": "Esklussiv",
                               "nl": "Exclusief",
                               "pl": "Wyłączna",
                               "pt": "Exclusiva",
                               "ro": "Exclusiv",
                               "se": "Exklusiva",
                               "sl": "Izključno",
                               "sk": "Výhradný",
                               "en": "Exclusive"
                           },
                           "non exclusive": {
                               "transId": "Non exclusive",
                               "bg": "Неизключителни",
                               "cs": "Nevýhradní",
                               "da": "Ikke eksklusiv",
                               "de": "Nicht ausschließlich",
                               "el": "Μη αποκλειστικές",
                               "es": "No exclusivo/a",
                               "et": "Lihtlitsents",
                               "fi": "Non-eksklusiivinen",
                               "fr": "Non exclusif",
                               "ga": "Neamh-eisiach",
                               "hr": "neisključivo",
                               "hu": "Nem kizárólagos",
                               "it": "Non esclusivo",
                               "lt": "Neišimtinės",
                               "lv": "Vispārējās",
                               "mt": "Mhux esklussiv",
                               "nl": "Niet-exclusief",
                               "pl": "Niewyłączna",
                               "pt": "Não exclusiva",
                               "ro": "Neexclusiv",
                               "se": "Icke-exklusiva",
                               "sl": "Neizključno",
                               "sk": "Nevýhradný",
                               "en": "Non exclusive"
                           },
                           "collective": {
                               "transId": "Collective",
                               "bg": "Колективни",
                               "cs": "Kolektivní",
                               "da": "Kollektiv",
                               "de": "Kollektiv",
                               "el": "Συλλογικές",
                               "es": "Colectiva/o",
                               "et": "Kollektiivne",
                               "fi": "Kollektiivinen",
                               "fr": "Collectif",
                               "ga": "Comhchoiteann",
                               "hr": "kolektivno",
                               "hu": "Kollektív",
                               "it": "Collettivo",
                               "lt": "Kolektyvinis",
                               "lv": "Kolektīvās",
                               "mt": "Kollettiv",
                               "nl": "Collectief",
                               "pl": "W ramach zbiorowego zarządzania",
                               "pt": "Coletiva",
                               "ro": "Colectiv(ă)",
                               "se": "Kollektiva",
                               "sl": "Kolektivno",
                               "sk": "Kolektívny",
                               "en": "Collective"
                           },
                           "open": {
                               "transId": "Open",
                               "bg": "Отворени",
                               "cs": "Veřejné",
                               "da": "Åben",
                               "de": "Offen",
                               "el": "Ανοιχτές",
                               "es": "Abierto/a",
                               "et": "Avatud",
                               "fi": "Avoin",
                               "fr": "Ouvert",
                               "ga": "Oscailte",
                               "hr": "otvoreno",
                               "hu": "Nyílt",
                               "it": "Aperto",
                               "lt": "Atviras",
                               "lv": "Atvērtās",
                               "mt": "Miftuħa",
                               "nl": "Open",
                               "pl": "Otwarta",
                               "pt": "Aberta",
                               "ro": "Liber",
                               "se": "Öppna",
                               "sl": "Odprto",
                               "sk": "Otvorený",
                               "en": "Open"
                           },
                           "owner": {
                               "transId": "Owner",
                               "bg": "Правоносител",
                               "cs": "Nositel práv",
                               "da": "Ejer",
                               "de": "Inhaber",
                               "el": "Δικαιούχος",
                               "es": "Titular",
                               "et": "Omanik",
                               "fi": "Oikeudenhaltija",
                               "fr": "Propriétaire",
                               "ga": "Úinéir",
                               "hr": "Vlasnik",
                               "hu": "Tulajdonos",
                               "it": "Proprietario",
                               "lt": "Savininkas",
                               "lv": "Īpašnieks",
                               "mt": "Sid",
                               "nl": "Auteursrechthebbende",
                               "pl": "Uprawniony",
                               "pt": "Detentor",
                               "ro": "Proprietar",
                               "se": "Innehavare",
                               "sl": "Lastnik",
                               "sk": "Vlastník",
                               "en": "Owner"
                           },
                           "online platform": {
                               "transId": "Online platform",
                               "bg": "Онлайн платформа",
                               "cs": "Online platforma",
                               "da": "Online platform",
                               "de": "Online Plattform",
                               "el": "Διαδικτυακή πλατφόρμα",
                               "es": "Plataforma digital",
                               "et": "Veebiplatvorm",
                               "fi": "Verkkoalusta",
                               "fr": "Plateforme en ligne",
                               "ga": "Ardán ar líne",
                               "hr": "Internetska platforma",
                               "hu": "Online platform",
                               "it": "Piattaforma online",
                               "lt": "Internetinė platforma",
                               "lv": "Tiešsaistes platforma",
                               "mt": "Pjattaforma onlajn",
                               "nl": "Online platfom",
                               "pl": "Platforma internetowa",
                               "pt": "Plataforma",
                               "ro": "Platformă online",
                               "se": "Onlineplattform",
                               "sl": "Spletna platforma",
                               "sk": "Online platforma",
                               "en": "Online platform"
                           },
                           "state": {
                               "transId": "State",
                               "bg": "Държава",
                               "cs": "Stát",
                               "da": "Stat",
                               "de": "Staat",
                               "el": "Κράτος",
                               "es": "Estado",
                               "et": "Riik",
                               "fi": "Valtio",
                               "fr": "État",
                               "ga": "Stát",
                               "hr": "država",
                               "hu": "Állam",
                               "it": "Stato",
                               "lt": "Valstybė",
                               "lv": "Valsts",
                               "mt": "Stat",
                               "nl": "Staat",
                               "pl": "Państwo",
                               "pt": "Estado",
                               "ro": "Stat",
                               "se": "Stat",
                               "sl": "Država",
                               "sk": "Štát",
                               "en": "State"
                           },
                           "infringers": {
                               "transId": "Infringers",
                               "bg": "Нарушители",
                               "cs": "Narušitel",
                               "da": "Krænkelser",
                               "de": "Verletzer",
                               "el": "Παραβάτες",
                               "es": "Infractor/a",
                               "et": "Autoriõiguse rikkujad",
                               "fi": "Loukkaajat",
                               "fr": "Contrefacteurs",
                               "ga": "Sáraitheoirí",
                               "hr": "Počinitelji povreda",
                               "hu": "Jogsértők",
                               "it": "Trasgressori",
                               "lt": "Pažeidėjai",
                               "lv": "Pārkāpēji",
                               "mt": "Kontravventuri",
                               "nl": "Inbreukmakers",
                               "pl": "Naruszyciele",
                               "pt": "Infratores",
                               "ro": "Contravenienți / Persoane care încalcă dreptul de autor",
                               "se": "Intrångsgörare",
                               "sl": "Kršitelji",
                               "sk": "Porušovateľ",
                               "en": "Infringers"
                           },
                           "stop": {
                               "transId": "Stop",
                               "bg": "Прекратяване",
                               "cs": "Stop",
                               "da": "Stop",
                               "de": "Stop",
                               "el": "Σταματώ",
                               "es": "Cesar",
                               "et": "Lõpetama",
                               "fi": "Keskeytys",
                               "fr": "Cesser",
                               "ga": "Stad",
                               "hr": "Stop",
                               "hu": "Állj!",
                               "it": "Stop",
                               "lt": "Sustabdyti",
                               "lv": "Apturēt",
                               "mt": "Ieqaf",
                               "nl": "Verbod",
                               "pl": "Zaniechanie",
                               "pt": "Travar",
                               "ro": "Oprire",
                               "se": "Sluta",
                               "sl": "Stop",
                               "sk": "Zastaviť",
                               "en": "Stop"
                           },
                           "money": {
                               "transId": "Money",
                               "bg": "Пари",
                               "cs": "Peníze",
                               "da": "Penge",
                               "de": "Geld",
                               "el": "Χρήμα",
                               "es": "Dinero",
                               "et": "Raha",
                               "fi": "Raha",
                               "fr": "Argent",
                               "ga": "Airgead",
                               "hr": "novac",
                               "hu": "Pénz",
                               "it": "Soldi",
                               "lt": "Pinigai",
                               "lv": "Nauda",
                               "mt": "Flus",
                               "nl": "Geld",
                               "pl": "Pieniądze",
                               "pt": "Dinheiro",
                               "ro": "Bani",
                               "se": "Pengar",
                               "sl": "Denar",
                               "sk": "Peniaze",
                               "en": "Money"
                           },
                           "penalty": {
                               "transId": "Penalty",
                               "bg": "Санкция",
                               "cs": "Sankce",
                               "da": "Sanktion",
                               "de": "Sanktion",
                               "el": "Ποινή",
                               "es": "Sanción o pena",
                               "et": "Karistus",
                               "fi": "Rangaistus",
                               "fr": "Pénalité / sanctions",
                               "ga": "Pionós",
                               "hr": "kazna",
                               "hu": "Büntetés",
                               "it": "Sanzione",
                               "lt": "Bauda",
                               "lv": "Sods",
                               "mt": "Penali / Piena",
                               "nl": "Boete",
                               "pl": "Kara",
                               "pt": "Penalização",
                               "ro": "Penalități",
                               "se": "Straff",
                               "sl": "Kazen",
                               "sk": "Pokuta",
                               "en": "Penalty"
                           },
                           "read more": {
                               "transId": "Read more",
                               "bg": "Прочетете повече",
                               "cs": "Více informací",
                               "da": "Læs mere",
                               "de": "Lies mehr",
                               "el": "Διαβάστε περισσότερα",
                               "es": "Leer más",
                               "et": "Loe rohkem",
                               "fi": "Aiheesta lisää",
                               "fr": "En savoir plus",
                               "ga": "Léigh tuilleadh",
                               "hr": "Pročitaj više",
                               "hu": "Olvass többet!",
                               "it": "Per saperne di più",
                               "lt": "Skaitykite daugiau",
                               "lv": "Lasīt vairāk",
                               "mt": "Aktar tagħrif",
                               "nl": "Lees meer",
                               "pl": "Przeczytaj więcej",
                               "pt": "Ler mais",
                               "ro": "Mai multe despre",
                               "se": "Läs mer",
                               "sl": "Preberite več",
                               "sk": "Čítať viac",
                               "en": "Read more"
                           },
                           "reproduction right": {
                               "transId": "Reproduction right",
                               "bg": "Право на възпроизвеждане",
                               "cs": "Právo na rozmnožování",
                               "da": "Reproduktionsret",
                               "de": "Vervielfältigungsrecht",
                               "el": "Εξουσία αναπαραγωγής",
                               "es": "Derecho de reproducción",
                               "et": "Õigus reprodutseerimisele",
                               "fi": "Oikeus valmistaa kappaleita",
                               "fr": "Droit de reproduction",
                               "ga": "Ceart atáirgthe",
                               "hr": "Pravo reproduciranja",
                               "hu": "Többszörözés joga",
                               "it": "Diritto di riproduzione",
                               "lt": "Atgaminimo teisė",
                               "lv": "Reproducēšanas tiesības",
                               "mt": "Dritt ta’ riproduzzjoni",
                               "nl": "Reproductierecht",
                               "pl": "Prawo do zwielokrotniania",
                               "pt": "Direito de reprodução",
                               "ro": "Dreptul de reproducere",
                               "se": "Mångfaldiganderätt",
                               "sl": "Pravica do razmnoževanja",
                               "sk": "Právo na vyhotovovanie rozmnoženín",
                               "en": "Reproduction right"
                           },
                           "distribution right": {
                               "transId": "Distribution right",
                               "bg": "Право на разпространение",
                               "cs": "Právo na rozšiřování",
                               "da": "Distributionsret",
                               "de": "Verbreitungsrecht",
                               "el": "Εξουσία διανομής",
                               "es": "Derecho de distribución",
                               "et": "Õigus levitamisele",
                               "fi": "Oikeus levittää kappaleita",
                               "fr": "Droit de distribution",
                               "ga": "Ceart dáileacháin",
                               "hr": "Pravo distribuiranja",
                               "hu": "Terjesztés joga",
                               "it": "Diritto di distribuzione",
                               "lt": "Platinimo teisė",
                               "lv": "Izplatīšanas tiesības",
                               "mt": "Dritt ta’ distribuzzjoni",
                               "nl": "Distributierecht",
                               "pl": "Prawo do rozpowszechniania",
                               "pt": "Direito de distribuição",
                               "ro": "Dreptul de distribuire",
                               "se": "Distributionsrätt",
                               "sl": "Distribucijska pravica",
                               "sk": "Právo na rozširovanie",
                               "en": "Distribution right"
                           },
                           "rental & lending rights": {
                               "transId": "Rental & lending rights",
                               "bg": "Право на отдаване под наем и даване в заем",
                               "cs": "Právo na pronájem a půjčování",
                               "da": "Udlejnings- og udlånsret",
                               "de": "Vermiet- und Verleihrecht",
                               "el": "Εξουσία εκμίσθωσης και δανεισμού",
                               "es": "Derecho de alquiler y de préstamo",
                               "et": "Õigust rentida ja laenutada",
                               "fi": "Vuokraus- ja lainausoikeudet",
                               "fr": "Droit de prêt et de location",
                               "ga": "Cearta cíosa agus iasachta",
                               "hr": "Prava iznajmljivanja i javne posudbe",
                               "hu": "Bérlet és haszonkölcsönzési jog",
                               "it": "Diritti di noleggio e prestito",
                               "lt": "Nuomos ir panaudos teisės",
                               "lv": "Nomas un aizdošanas tiesības",
                               "mt": "Dritt tas-self b'renta u l-kiri",
                               "nl": "Verhuur- & uitleenrechten",
                               "pl": "Prawo do najmu i użyczenia",
                               "pt": "Direitos de aluguer e comodato",
                               "ro": "Drepturile de închiriere & împrumut",
                               "se": "Rätt till utlåning och uthyrning",
                               "sl": "Pravice do najema in posojanja",
                               "sk": "Právo na nájom & vypožičanie",
                               "en": "Rental & lending rights"
                           },
                           "right of communication to the public": {
                               "transId": "Right of communication to the public",
                               "bg": "Право на публично разгласяване",
                               "cs": "Právo na sdělování díla veřejnosti",
                               "da": "Ret til kommunikation til offentligheden",
                               "de": "Recht der öffentlichen Wiedergabe",
                               "el": "Εξουσία παρουσίασης στο κοινό",
                               "es": "Derecho a la comunicación pública",
                               "et": "Õigus üldsusele suunata",
                               "fi": "Oikeus yleisölle välittämiseen",
                               "fr": "Droit de communication au public",
                               "ga": "Ceart cumarsáide leis an bpobal",
                               "hr": "Pravo priopćavanja javnosti",
                               "hu": "Nyilvánossághoz közvetítés joga",
                               "it": "Diritto di comunicazione di opere al pubblico",
                               "lt": "Viešo paskelbimo teisė",
                               "lv": "Publiskošanas tiesības",
                               "mt": "Dritt ta’ komunikazzjoni lill-pubbliku",
                               "nl": "Recht tot openbaarmaking",
                               "pl": "Prawo do publicznego udostępniania",
                               "pt": "Direito de comunicação de obras ao público",
                               "ro": "Dreptul de comunicare către public",
                               "se": "Överföringsrätt till allmänheten",
                               "sl": "Pravica do priobčitve javnosti",
                               "sk": "Právo na verejný prenos",
                               "en": "Right of communication to the public"
                           },
                           "european union": {
                               "transId": "European Union",
                               "bg": "Европейски съюз",
                               "cs": "Evropská unie",
                               "da": "Europæiske Union",
                               "de": "Europäische Union",
                               "el": "Ευρωπαϊκή Ένωση",
                               "es": "Unión Europea",
                               "et": "Euroopa Liit",
                               "fi": "Euroopan unioni",
                               "fr": "Union Européenne",
                               "ga": "An tAontas Eorpach",
                               "hr": "Europska Unija",
                               "hu": "Európai Unió",
                               "it": "Unione Europea",
                               "lt": "Europos Sąjunga",
                               "lv": "Eiropas Savienība",
                               "mt": "Unjoni Ewropea",
                               "nl": "Europese Unie",
                               "pl": "Unia Europejska",
                               "pt": "União Europeia",
                               "ro": "Uniunea Europeană",
                               "se": "Europeiska unionen",
                               "sl": "Evropska unija",
                               "sk": "Európska únia",
                               "en": "European Union"
                           },
                           "berne convention art. 6bis (concerning authors)": {
                               "transId": "Berne Convention Art. 6bis (concerning authors)",
                               "bg": "Чл. 6bis на Бернската Конвенция (относно авторите)",
                               "cs": "Článek 6bis Bernské úmluvy (autoři)",
                               "da": "Bernerkonventionen art. 6 bis (omhandlende forfattere)",
                               "de": "Berner Übereinkunft Art. 6bis (betreffend Urheber)",
                               "el": "δις της Σύμβασης της Άρθρο 6 Βέρνης (σχετικά με τους δημιουργούς)",
                               "es": "Convención de Berna artículo 6bis (relativo a los autores)",
                               "et": "Berni kirjandus- ja kunstiteoste kaitse konventsioon artikkel 6bis (autori õigustest)",
                               "fi": "Bernin yleissopimus 6 bis artikla (tekijöitä koskien)",
                               "fr": "Article 6bis de la Convention de Berne (concernant les auteurs)",
                               "ga": "Airteagal 6bis de Choinbhinsiún Berne (maidir le húdair)",
                               "hr": "Članak 6bis Bernske konvencije (koji se odnosi na autore)",
                               "hu": "Berni Uniós Egyezmény szerzőket érintő 6bis Cikke",
                               "it": "Convenzione di Berna Art. 6-bis (riguardo agli autori)",
                               "lt": "Berno konvencijos 6bis str. (dėl autorių)",
                               "lv": "Bernes konvencijas 6bis pants (attiecas uz autoriem)",
                               "mt": "Konvenzjoni ta’ Berna (Fuq l-awturi)",
                               "nl": "Berner Conventie art. 6bis (betreffende auteurs)",
                               "pl": "Konwencja berneńska art. 6-bis (dot. autorów)",
                               "pt": "Convenção de Berna, Artigo 6ºbis",
                               "ro": "Convenția de la Berna Art. 6bis (cu privire la autori)",
                               "se": "Bernekonventionen Art. 6bis (gällande skapare)",
                               "sl": "Bernska konvencija, čl. 6bis (o avtorjih)",
                               "sk": "Bernský dohovor čl. 6bis (týkajúci sa autorov)",
                               "en": "Berne Convention Art. 6bis (concerning authors)"
                           },
                           "wipo performances and phonograms treaty (wppt) art. 5 (concerning performers)": {
                               "transId": "WIPO Performances and Phonograms Treaty (WPPT) Art. 5 (concerning performers)",
                               "bg": "Договор на СОИС за изпълненията и звукозаписите (WPPT)  Чл. 5 (относно изпълнителите)",
                               "cs": "Článek 5 Smlouvy WIPO o výkonech výkonných umělců a zvukových záznamech (výkonní umělci)",
                               "da": "WIPO om fremførelser og fonogrammer (WPPT) art. 5 (om udøvende kunstnere)",
                               "de": "Art. 5 WIPO-Vertrag über Darbietungen und Tonträger (WPPT) (betreffend ausübende Künstler)",
                               "el": "Άρθρο 5 της Σύμβασης WIPO για τις Ερμηνείες-Εκτελέσεις και τα Φωνογραφήματα (σχετικά με τους ερμηνευτές ή εκτελεστές καλλιτέχνες)",
                               "es": "Tratado de la OMPI sobre Interpretaciones y Fonogramas (WPPT, por sus siglas en inglés) art. 5 (relativo a los intérpretes)",
                               "et": "WIPO esitus- ja fonogrammilepingu artikkel 5 (esitajate õigustest)",
                               "fi": "WIPO:n esitys- ja äänitesopimus (WPPT) artikla 5 (koskien esittäviä taiteilijoita)",
                               "fr": "Article 5 du Traité de l’OMPI sur les interprétations et exécutions de phonogrammes (concernant les artistes-interprètes)",
                               "ga": "Airteagal 5 de Chonradh Léirithe agus Fónagram EDMI (WPPT) (maidir le taibheoirí)",
                               "hr": "Ugovor o izvedbama i fonogramima Svjetske organizacije za intelektualno vlasništvo (WPPT), čl. 5 (koji se odnosi na umjetnike izvođače)",
                               "hu": "WIPO Előadásokról és Hangfelvételekről szóló Egyezményének (WPPT) előadókat érintő 5. cikke",
                               "it": "Trattato dell’OMPI sulle interpretazioni ed esecuzioni e sui fonogrammi (WPPT) Art. 5 (riguardo agli artisti)",
                               "lt": "PINO atlikimų ir fonogramų sutarties (WPPT) 5 str. (dėl atlikėjų)",
                               "lv": "WIPO Līgums par izpildījumu un fonogrammām (WPPT) 5.pants (attiecas uz izpildītājiem)",
                               "mt": "Trattat tal-WIPO dwar Wirjiet u Fonogrammi, Art.5 (Fuq l-artisti)",
                               "nl": "WIPO-verdrag inzake uitvoeringen en fonogrammen (WPPT) art. 5 (betreffende uitvoerende kunstenaars)",
                               "pl": "Traktat WIPO o artystycznych wykonaniach i fonogramach (WPPT) art. 5 (dot. wykonawców)",
                               "pt": "Tratado da OMPI sobre prestações e fonogramas (WPPT), Artigo 5º",
                               "ro": "Tratatul OMPI privind spectacolele și fonogramele (cu privire la interpreți)",
                               "se": "WIPO:s fördrag om framföranden och fonogram (WPPT) Art. 5 (gällande framförare)",
                               "sl": "Pogodba WIPO o izvedbah in fonogramih (WPPT), čl. 5 (o izvajalcih)",
                               "sk": "Zmluva WIPO o výkonoch a zvukových záznamoch (WPPT) čl. 5 (týkajúci sa výkonných umelcov)",
                               "en": "WIPO Performances and Phonograms Treaty (WPPT) Art. 5 (concerning performers)"
                           },
                           "right of attribution": {
                               "transId": "Right of attribution",
                               "bg": "Признаване на авторство",
                               "cs": "Právo být uveden jako autor",
                               "da": "Tilskrivningsret",
                               "de": "Recht auf Anerkennung",
                               "el": "Εξουσία πατρότητας",
                               "es": "Derecho de atribución",
                               "et": "Õigus autorsusele",
                               "fi": "Isyysoikeus",
                               "fr": "Droit de paternité",
                               "ga": "an ceart um chur i leith",
                               "hr": "Pravo dodijeljivanja",
                               "hu": "tulajdonítás joga",
                               "it": "Diritto alla paternità",
                               "lt": "Autorystės teisė",
                               "lv": "Attiecinājuma tiesības",
                               "mt": "Dritt għall-attribuzzjoni",
                               "nl": "Recht op naamsvermelding",
                               "pl": "prawo do oznaczenia autorstwa",
                               "pt": "Direito de reivindicar paternidade",
                               "ro": "dreptul de a pretinde recunoaşterea calităţii de autor al operei",
                               "se": "namngivningsrätt",
                               "sl": "pravica do priznanja avtorstva",
                               "sk": "právo na označenie",
                               "en": "Right of attribution"
                           },
                           "right of integrity": {
                               "transId": "Right of integrity",
                               "bg": "Запазване на целостта",
                               "cs": "Právo na nedotknutelnost díla",
                               "da": "Ret til integritet",
                               "de": "Recht auf Integrität",
                               "el": "Εξουσία ακεραιότητας",
                               "es": "Derecho de integridad",
                               "et": "Õigus teose puutumatusele",
                               "fi": "Respektioikeus",
                               "fr": "Droit au respect de l’intégrité",
                               "ga": "an ceart ar shláine",
                               "hr": "Pravo integriteta",
                               "hu": "a mű integritásához való jog",
                               "it": "Diritto all’integrità",
                               "lt": "Neliečiamumo teisė",
                               "lv": "Tiesības uz neaizskaramību",
                               "mt": "Dritt għall-integrita’",
                               "nl": "Recht op integriteit",
                               "pl": "prawo do integralności",
                               "pt": "Direito de assegurar integridade",
                               "ro": "dreptul de a pretinde respectarea integrității operei",
                               "se": "respekträtt",
                               "sl": "pravica do spoštovanja dela",
                               "sk": "právo na nedotknuteľnosť",
                               "en": "Right of integrity"
                           },
                           "member state of eu": {
                               "transId": "Member State of EU",
                               "bg": "Държава членка на ЕС",
                               "cs": "Členský stát EU",
                               "da": "EU medlemsstat",
                               "de": "EU-Mitgliedstaat",
                               "el": "Κράτος Μέλος της ΕΕ",
                               "es": "Estado miembro de la Unión Europea",
                               "et": "Euroopa Liidu liikmesriik",
                               "fi": "EU-jäsenvaltio",
                               "fr": "État Membre de l’UE",
                               "ga": "Ballstát AE",
                               "hr": "Država članica",
                               "hu": "Az Európai Unió tagállama",
                               "it": "Stato Membro dell’UE",
                               "lt": "ES Valstybė Narė",
                               "lv": "ES dalībvalsts",
                               "mt": "Stati Membri tal-UE",
                               "nl": "Lidstaat van de EU",
                               "pl": "państwo członkowskie UE",
                               "pt": "Estado-Membro da UE",
                               "ro": "Stat membru al UE",
                               "se": "EU medlemsstat",
                               "sl": "Država članica EU",
                               "sk": "Členský štát EÚ",
                               "en": "Member State of EU"
                           },
                           "copy": {
                               "transId": "Copy",
                               "bg": "Копиране",
                               "cs": "Kopírovat",
                               "da": "Kopi",
                               "de": "Kopieren",
                               "el": "Αντιγράφω",
                               "es": "Copiar",
                               "et": "Koopia",
                               "fi": "Valmistaa kappaleita",
                               "fr": "Copier",
                               "ga": "Cóipeáil",
                               "hr": "Kopirati",
                               "hu": "másol",
                               "it": "Copiare",
                               "lt": "Kopija",
                               "lv": "Kopēt",
                               "mt": "Kopja",
                               "nl": "Kopie",
                               "pl": "Zwielokrotnianie",
                               "pt": "Copiar",
                               "ro": "Copiază",
                               "se": "Kopiera",
                               "sl": "Kopija",
                               "sk": "Rozmnoženina",
                               "en": "Copy"
                           },
                           "distribute": {
                               "transId": "Distribute",
                               "bg": "Разпространение",
                               "cs": "Šířit",
                               "da": "Distribuere",
                               "de": "Verbreiten",
                               "el": "Διανέμω",
                               "es": "Distribuir",
                               "et": "Levitama",
                               "fi": "Levittää",
                               "fr": "Distribuer",
                               "ga": "Dáil",
                               "hr": "Distribuirati",
                               "hu": "terjeszt",
                               "it": "Distribuire",
                               "lt": "Platinti",
                               "lv": "Izplatīt",
                               "mt": "Distribuzzjoni",
                               "nl": "Distributie",
                               "pl": "Rozpowszchnianie",
                               "pt": "Distribuir",
                               "ro": "Distribuie",
                               "se": "Distribuera",
                               "sl": "Distribuirati",
                               "sk": "Šíriť",
                               "en": "Distribute"
                           },
                           "rent": {
                               "transId": "Rent",
                               "bg": "Наем",
                               "cs": "Pronajmout",
                               "da": "Leje",
                               "de": "Vermieten",
                               "el": "Μισθώνω",
                               "es": "Alquilar",
                               "et": "Rentima",
                               "fi": "Vuokrata",
                               "fr": "Louer",
                               "ga": "Lig ar cíos",
                               "hr": "Iznamljivati",
                               "hu": "bérbe vesz",
                               "it": "Affittare",
                               "lt": "Nuomoti",
                               "lv": "Nomāt",
                               "mt": "Tikri",
                               "nl": "Verhuur",
                               "pl": "Najem",
                               "pt": "Alugar",
                               "ro": "Închiriază",
                               "se": "Hyra",
                               "sl": "Najem",
                               "sk": "Prenajať",
                               "en": "Rent"
                           },
                           "communicate": {
                               "transId": "Communicate",
                               "bg": "Разгласяване",
                               "cs": "Sdělovat veřejnosti",
                               "da": "Kommunikere",
                               "de": "Wiedergeben",
                               "el": "Παρουσιάζω",
                               "es": "Comunicar",
                               "et": "Avalikustama",
                               "fi": "Välittää",
                               "fr": "Communiquer",
                               "ga": "Déan cumarsáid",
                               "hr": "Priopćavati",
                               "hu": "közvetít",
                               "it": "Comunicare",
                               "lt": "Viešai paskelbti",
                               "lv": "Padarīt pieejamu",
                               "mt": "Tikkomunika",
                               "nl": "Openbaarmaking",
                               "pl": "Udostępnianie",
                               "pt": "Comunicar",
                               "ro": "Comunică",
                               "se": "Överföra",
                               "sl": "Komunicirati",
                               "sk": "Prenášať",
                               "en": "Communicate"
                           },
                           "performers": {
                               "transId": "Performers",
                               "bg": "Изпълнители",
                               "cs": "Výkonní umělci",
                               "da": "Udøvende kunstnere",
                               "de": "Ausübende Künstler",
                               "el": "Ερμηνευτές ή εκτελεστές καλλιτέχνες",
                               "es": "Intérpretes o ejecutantes",
                               "et": "Esitajad",
                               "fi": "Esittävät taiteilijat",
                               "fr": "Artistes-interprètes",
                               "ga": "Taibheoirí",
                               "hr": "Umjetnici izvođači",
                               "hu": "előadók",
                               "it": "Artisti interpreti ed esecutori",
                               "lt": "Atlikėjai",
                               "lv": "Izpildītāji",
                               "mt": "Artisti",
                               "nl": "Uitvoerende kunstenaars",
                               "pl": "Wykonawcy",
                               "pt": "Intérpretes ou Executantes",
                               "ro": "Interpreți",
                               "se": "Framförare",
                               "sl": "Izvajalci",
                               "sk": "Výkonní umelci",
                               "en": "Performers"
                           },
                           "equitable remuneration": {
                               "transId": "Equitable remuneration",
                               "bg": "Справедливо възнаграждение",
                               "cs": "Spravedlivá odměna",
                               "da": "Rimelig aflønning",
                               "de": "Angemessene Vergütung",
                               "el": "Εύλογη αμοιβή",
                               "es": "Compensación equitativa o compensación justa",
                               "et": "Õiglane tasu",
                               "fi": "Kohtuullinen korvaus",
                               "fr": "Rémunération équitable",
                               "ga": "Luach saothair cothrom",
                               "hr": "Primjerena naknada",
                               "hu": "egyenlő mértékű díjazás",
                               "it": "Remunerazione equa",
                               "lt": "Teisingas atlyginimas",
                               "lv": "Taisnīgs atalgojums",
                               "mt": "Rimunerazzjoni unika ġusta",
                               "nl": "Billijke vergoeding",
                               "pl": "Godziwe wynagrodzenie",
                               "pt": "Compensação equitativa",
                               "ro": "Remunerație echitabilă",
                               "se": "Skälig ersättning",
                               "sl": "Pravično nadomestilo",
                               "sk": "Primeraná odmena",
                               "en": "Equitable remuneration"
                           },
                           "first fixation": {
                               "transId": "First fixation",
                               "bg": "Първоначален запис",
                               "cs": "Prvotní záznam",
                               "da": "Første fiksering",
                               "de": "Erste Fixierung",
                               "el": "Πρώτη ενσωμάτωση",
                               "es": "Primera fijación",
                               "et": "Esmasalvestuse tootja",
                               "fi": "Ensimmäinen tallenne",
                               "fr": "Première fixation",
                               "ga": "An chéad fhosú",
                               "hr": "Prva fiksacija",
                               "hu": "első rögzítés",
                               "it": "Prima fissazione",
                               "lt": "Pirmasis įrašas",
                               "lv": "Pirmā fiksācija",
                               "mt": "l-ewwel fissazzjoni",
                               "nl": "Eerste vastlegging",
                               "pl": "Pierwsze utrwalenie",
                               "pt": "Primeira fixação",
                               "ro": "Prima fixare",
                               "se": "Första upptagning",
                               "sl": "Prva fiksacija",
                               "sk": "Prvý záznam",
                               "en": "First fixation"
                           },
                           "broadcasters": {
                               "transId": "Broadcasters",
                               "bg": "Радио- и телевизионни организации",
                               "cs": "Vysílatelé",
                               "da": "Broadcastere",
                               "de": "Sendeunternehmen",
                               "el": "Ραδιοτηλεοπτικοί οργανισμοί",
                               "es": "Radiodifusores",
                               "et": "Televisiooni- või raadioteenuse osutajad",
                               "fi": "Lähettäjäyritykset",
                               "fr": "Radiodiffuseurs et télédiffuseurs",
                               "ga": "Craoltóirí",
                               "hr": "Organizacije za radiodifuziju",
                               "hu": "sugárzók",
                               "it": "Emittenti",
                               "lt": "Transliuotojai",
                               "lv": "Radioorganizācijas",
                               "mt": "Xandara",
                               "nl": "Omroeporganisaties",
                               "pl": "Nadawcy",
                               "pt": "Organismos de Radiodifusão",
                               "ro": "Posturi de radio-emisie",
                               "se": "Mediaföretag",
                               "sl": "Oddaje",
                               "sk": "Vysielatelia",
                               "en": "Broadcasters"
                           },
                           "extraction & reuse": {
                               "transId": "Extraction & Reuse",
                               "bg": "Извличане и повторно използване",
                               "cs": "Vytěžení a zužitkování",
                               "da": "Ekstrahering og genbrug",
                               "de": "Entnahme & Weiterverw",
                               "el": "Εξαγωγή και Επανάχρηση",
                               "es": "Extracción y reutilización",
                               "et": "Väljavõte ja taaskasutamine",
                               "fi": "Kopiointi & uudelleenkäyttö",
                               "fr": "Extraction et réutilisation",
                               "ga": "Asbhaint & Athúsáid",
                               "hr": "Izdvajanje i ponovno korištenje",
                               "hu": "Kivonatol & újrahasznosít",
                               "it": "Estrazione e reimpiego",
                               "lt": "Perkėlimas ir Pakartotinis Naudojimas",
                               "lv": "Iegūšana un atkārtota izmantošana",
                               "mt": "Estrazzjonu u l-utilizzazzjoni mill-ġdid",
                               "nl": "Extractierecht & hergebruik",
                               "pl": "Pobieranie i ponowne wykorzystanie",
                               "pt": "Extração e reutilização",
                               "ro": "Extragere & Reutilizare",
                               "se": "Utdrag & återanvändning",
                               "sl": "Pridobivanje in ponovna uporaba",
                               "sk": "Extrakcia & reutilizácia",
                               "en": "Extraction & Reuse"
                           },
                           "database owners": {
                               "transId": "Database owners",
                               "bg": "Производители на бази данни",
                               "cs": "Vlastník databáze",
                               "da": "Database ejere",
                               "de": "Datenbankhersteller",
                               "el": "Κατασκευαστές βάσεων δεδομένων",
                               "es": "Propietarios de base de datos",
                               "et": "Andmebaasi omanikud",
                               "fi": "Tietokantojen oikeuksien haltijat",
                               "fr": "Propriétaires de bases de données",
                               "ga": "Úinéirí bunachar sonraí",
                               "hr": "Nositelji prava na bazama podataka",
                               "hu": "Adatbázis tulajdonosai",
                               "it": "Proprietari di banche dati",
                               "lt": "Duomenų bazių savininkai",
                               "lv": "Datubāzu īpašnieki",
                               "mt": "Sid ta’ database",
                               "nl": "Maker van databank",
                               "pl": "Właściciele baz danych",
                               "pt": "Detentor de base de dados",
                               "ro": "Proprietarii bazei de date",
                               "se": "Databasägare",
                               "sl": "Lastniki podatkovnih baz",
                               "sk": "Vlastník databázy",
                               "en": "Database owners"
                           },
                           "film producers": {
                               "transId": "Film producers",
                               "bg": "Филмови продуценти",
                               "cs": "Audiovizuální tvůrci",
                               "da": "Filmproducenter",
                               "de": "Filmhersteller",
                               "el": "Παραγωγοί ταινιών",
                               "es": "Productores de cine",
                               "et": "Filmitootjad",
                               "fi": "Elokuvatuottajat",
                               "fr": "Producteurs de films",
                               "ga": "Léiritheoirí scannán",
                               "hr": "Filmski producenti",
                               "hu": "Filmelőállítók",
                               "it": "Produttori cinematografici",
                               "lt": "Filmų gamintojai",
                               "lv": "Filmu producenti",
                               "mt": "Produtturi tal-films",
                               "nl": "Filmproducenten",
                               "pl": "Producenci filmów",
                               "pt": "Produtores de videogramas",
                               "ro": "Producători de filme",
                               "se": "Filmproducenter",
                               "sl": "Filmski producenti",
                               "sk": "Filmoví producenti",
                               "en": "Film producers"
                           },
                           "press publishers": {
                               "transId": "Press publishers",
                               "bg": "Издатели на публикации в пресата",
                               "cs": "Vydavatel tisku",
                               "da": "Presseforlag",
                               "de": "Presseverleger",
                               "el": "Εκδότες τύπου",
                               "es": "Editores de prensa",
                               "et": "Ajakirjandusväljaande kirjastaja",
                               "fi": "Lehtikustantajat",
                               "fr": "Éditeurs de publications de presse",
                               "ga": "Foilsitheoirí preasa",
                               "hr": "Nakladnici informativnih publikacija",
                               "hu": "Sajtókiadók",
                               "it": "Editori di stampa",
                               "lt": "Spaudos leidėjai",
                               "lv": "Preses izdevēji",
                               "mt": "Pubblikatur tal-istampa",
                               "nl": "Uitgevers van perspublicaties",
                               "pl": "Wydawcy prasy",
                               "pt": "Editores de publicações de imprensa",
                               "ro": "Editoride presă",
                               "se": "Utgivare",
                               "sl": "Izdajatelji tiskovin",
                               "sk": "Vydavatelia periodík",
                               "en": "Press publishers"
                           },
                           "two year licence for online platforms": {
                               "transId": "Two year licence for online platforms",
                               "bg": "Двегодишно право спрямо онлайн доставчици",
                               "cs": "Dvouletá licence pro online platformy",
                               "da": "To-årig licens for online platforme",
                               "de": "2 Jahres Lizenz Online Plattformen",
                               "el": "Διετής άδεια για τις διαδικτυακές πλατφόρμες",
                               "es": "Licencia de dos años para la plataforma digital",
                               "et": "Kahe aastane litsents veebiplatvormidele",
                               "fi": "Kahden vuoden lisenssi verkkoalustoille",
                               "fr": "Licence de deux ans pour les plateformes en ligne",
                               "ga": "Ceadúnas dhá bhliain d’ardáin ar líne",
                               "hr": "Dvogodišnja licenca za internetske platforme",
                               "hu": "Kétéves felhasználási engedély az online platformok számára",
                               "it": "Licenze biennali per piattaforme online",
                               "lt": "Dviejų metų trukmės internetinių platformų licencija",
                               "lv": "Divu gadu licence tiešsaistes platformām",
                               "mt": "Liċenzja ta’ sentejn ghal pjattaformi onlajn",
                               "nl": "Twee jaar licentie voor online platforms",
                               "pl": "Dwuletnia licencja dla platform internetowych",
                               "pt": "Licença de dois anos para plataformas em linha.",
                               "ro": "Licență pe termen de doi ani pentru platformele online",
                               "se": "Två års licens för onlineplattformar",
                               "sl": "Dvoletna licence za spletne platforme",
                               "sk": "Licencia na dva roky pre online platformy",
                               "en": "Two year licence for online platforms"
                           },
                           "phonogram producers": {
                               "transId": "Phonogram producers",
                               "bg": "Продуценти на звукозаписи",
                               "cs": "Výrobci zvukových záznamů",
                               "da": "Fonogram producenter",
                               "de": "Tonträgerhersteller",
                               "el": "Παραγωγοί υλικών φορέων ήχου",
                               "es": "Productores de fonogramas",
                               "et": "Fonogrammi tootjad",
                               "fi": "Äänitallenteiden tuottajat",
                               "fr": "Producteurs de phonogrammes",
                               "ga": "Léiritheoirí fónagram",
                               "hr": "Proizvođači fonograma",
                               "hu": "Hangfelvétel-előállítók",
                               "it": "Produttori di fonogrammi",
                               "lt": "Fonogramų gamintojai",
                               "lv": "Fonogrammu producenti",
                               "mt": "Produtturi ta’ fonogrammi",
                               "nl": "Fonogrammenproducenten",
                               "pl": "Producenci fonogramów",
                               "pt": "Produtores de fonogramas",
                               "ro": "Producători de fonograme",
                               "se": "Producenter av fonogram",
                               "sl": "Izdelovalci fonografov",
                               "sk": "Výrobcovia zvukových záznamov",
                               "en": "Phonogram producers"
                           },
                           "personal autonomy": {
                               "transId": "Personal autonomy",
                               "bg": "Лично пространство",
                               "cs": "Osobní autonomie",
                               "da": "Personlig autonomi",
                               "de": "Privatautonomie",
                               "el": "Προσωπική αυτονομία",
                               "es": "Autonomía personal",
                               "et": "Isiklik autonoomia",
                               "fi": "Itsemääräämisoikeus",
                               "fr": "Autonomie individuelle",
                               "ga": "Neamhspleáchas pearsanta",
                               "hr": "Slobodna volja",
                               "hu": "Személyes autonómia",
                               "it": "Autonomia personale",
                               "lt": "Asmeninė autonomija",
                               "lv": "Personiskā autonomija",
                               "mt": "Awtonomija personali",
                               "nl": "Persoonlijke autonomie",
                               "pl": "Autonomia osobista",
                               "pt": "Autonomia individual",
                               "ro": "Autonomie personală",
                               "se": "Personlig självständighet",
                               "sl": "Osebna avtonomija",
                               "sk": "Osobná autonómia",
                               "en": "Personal autonomy"
                           },
                           "free speech": {
                               "transId": "Free speech",
                               "bg": "Свобода на словото",
                               "cs": "Svoboda projevu",
                               "da": "Fri tale",
                               "de": "Freie Rede",
                               "el": "Ελευθερία λόγου",
                               "es": "Libertad de expresión",
                               "et": "Sõnavabadus",
                               "fi": "Sananvapaus",
                               "fr": "Liberté d’expression",
                               "ga": "Saoirse cainte",
                               "hr": "Sloboda govora",
                               "hu": "Szabad véleménynyilvánítás",
                               "it": "Libertà di espressione",
                               "lt": "Žodžio laisvė",
                               "lv": "Vārda brīvība",
                               "mt": "Liberta’ tal-espressjoni",
                               "nl": "Vrijheid van meningsuiting",
                               "pl": "Wolność słowa",
                               "pt": "Liberdade de expressão",
                               "ro": "Libera exprimare",
                               "se": "Yttrande-frihet",
                               "sl": "Svoboda govora",
                               "sk": "Sloboda prejavu",
                               "en": "Free speech"
                           },
                           "teaching & research": {
                               "transId": "Teaching & research",
                               "bg": "Преподавателска и научноизследователска дейност",
                               "cs": "Výuka a výzkum",
                               "da": "Undervisning og forskning",
                               "de": "Lehre & Forschung",
                               "el": "Διδασκαλία και έρευνα",
                               "es": "Enseñanza e investigación",
                               "et": "Õppimine ja uurimine",
                               "fi": "Opetus ja tutkimus",
                               "fr": "Enseignement & recherche",
                               "ga": "Teagasc & taighde",
                               "hr": "Podučavanje i istraživanje",
                               "hu": "Oktatás és kutatás",
                               "it": "Insegnamento e ricerca",
                               "lt": "Mokymai ir tyrimai",
                               "lv": "Apmācība un pētniecība",
                               "mt": "Tagħlim u ricerka",
                               "nl": "Onderwijs & onderzoek",
                               "pl": "Nauczanie i badania",
                               "pt": "Ensino e investigação",
                               "ro": "Predare & cercetare",
                               "se": "Undervisning & forskning",
                               "sl": "Poučevanje in raziskovanje",
                               "sk": "Vyučovanie & výskum",
                               "en": "Teaching & research"
                           },
                           "cultural heritage": {
                               "transId": "Cultural heritage",
                               "bg": "Културно наследство",
                               "cs": "Kulturní dědictví",
                               "da": "Kulturarv",
                               "de": "Kulturerbe",
                               "el": "Πολιτιστική κληρονομιά",
                               "es": "Patrimonio cultural",
                               "et": "Kultuuriline pärand",
                               "fi": "Kulttuuriperintö",
                               "fr": "Héritage culturel",
                               "ga": "Oidhreacht chultúrtha",
                               "hr": "Kulturna baština",
                               "hu": "Kulturális örökség",
                               "it": "Patrimonio culturale",
                               "lt": "Kultūrinis paveldas",
                               "lv": "Kultūras mantojums",
                               "mt": "Wirt kulturali",
                               "nl": "Cultureel erfgoed",
                               "pl": "Dziedzictwo kulturowe",
                               "pt": "Património Cultural",
                               "ro": "Moștenire culturală",
                               "se": "kulturarv",
                               "sl": "Kulturna dediščina",
                               "sk": "Kultúrne dedičstvo",
                               "en": "Cultural heritage"
                           },
                           "mandatory": {
                               "transId": "Mandatory",
                               "bg": "Задължителни",
                               "cs": "Povinné",
                               "da": "Obligatorisk",
                               "de": "Verpflichtend",
                               "el": "Υποχρεωτικός",
                               "es": "Obligatorio",
                               "et": "Kohustuslik",
                               "fi": "Pakollinen",
                               "fr": "Obligatoire",
                               "ga": "Éigeantach",
                               "hr": "Obavezno",
                               "hu": "Kötelező",
                               "it": "Obbligatorio",
                               "lt": "Privalomas",
                               "lv": "Obligāts",
                               "mt": "Obbligatorju",
                               "nl": "Oicht",
                               "pl": "Obowiązkowe",
                               "pt": "Compulsório",
                               "ro": "Obligatoriu",
                               "se": "Obligatoriska",
                               "sl": "Obvezno",
                               "sk": "Povinné",
                               "en": "Mandatory"
                           },
                           "back-up copying (software)": {
                               "transId": "Back-up copying (software)",
                               "bg": "Резервно копие (софтуер)",
                               "cs": "Záložní rozmnoženiny (software)",
                               "da": "Sikkerhedskopiering (software)",
                               "de": "Sicherungskopie (Software)",
                               "el": "Αντίγραφο ασφαλείας (λογισμικό)",
                               "es": "Copia de seguridad (programas de ordenador)",
                               "et": "Varukoopia tegemine",
                               "fi": "Varmuuskopion valmistaminen (tietokoneohjelmia koskien)",
                               "fr": "Copie de sauvegarde (logiciels)",
                               "ga": "Cóipeáil chúltaca (bogearraí)",
                               "hr": "Sigurnosno kopiranje (računalni program)",
                               "hu": "Biztonsági másolat készítése (szoftver)",
                               "it": "Copia di back-up (software)",
                               "lt": "Atsarginės kopijos kūrimas (programinės įrangos)",
                               "lv": "Rezerves kopēšana (programmatūra)",
                               "mt": "Back-up copy (ta’ programm)",
                               "nl": "Reservekopie (software)",
                               "pl": "Tworzenie kopii zapasowych (oprogramowania)",
                               "pt": "Cópia de segurança",
                               "ro": "Copiere de backup (software)",
                               "se": "Säkerhetskopior (mjukvara)",
                               "sl": "Varnostno kopiranje (programska oprema)",
                               "sk": "Vyhotovovanie záložných rozmnoženín (softvér)",
                               "en": "Back-up copying (software)"
                           },
                           "make an accessible copy": {
                               "transId": "Make an accessible copy",
                               "bg": "Създаването на копие в достъпен формат",
                               "cs": "Vytvoření přístupné rozmnoženiny",
                               "da": "Lave en tilgængelig kopi",
                               "de": "Barrierefreie Kopie",
                               "el": "Δημιουργώ ένα διαθέσιμο αντίγραφο",
                               "es": "Realizar una copia accesible",
                               "et": "Kättesaadava koopia tegemine",
                               "fi": "Kappaleen valmistaminen saavutettavassa muodossa",
                               "fr": "Réaliser une copie accessible",
                               "ga": "Cóip inrochtana a dhéanamh",
                               "hr": "Stvoriti pristupnu koppiju",
                               "hu": "Hozzáférhető másolat készítése",
                               "it": "Realizzare una copia accessibile",
                               "lt": "Sukurti pritaikomą kopiją",
                               "lv": "Izveidot pieejamu kopiju",
                               "mt": "Tagħmel kopja aċċessibbli",
                               "nl": "Thuiskopie",
                               "pl": "Wykonanie kopii w dostępnym formacie",
                               "pt": "Cópias em formato acessível",
                               "ro": "Creați o copie accesibilă",
                               "se": "Skapa en tillgänglig kopia",
                               "sl": "Izdelajte dostopno kopijo",
                               "sk": "Vyhotoviť rozmnoženinu v prístupnom formáte",
                               "en": "Make an accessible copy"
                           },
                           "illustration for teaching online": {
                               "transId": "Illustration for teaching online",
                               "bg": "Илюстриране за онлайн обучение",
                               "cs": "Ilustrace při digitální výuce",
                               "da": "Illustration til undervisning online",
                               "de": "Illustration für Online-Lehre",
                               "el": "Παράδειγμα κατά την διαδικτυακή διδασκαλία",
                               "es": "Ilustración para la enseñanza en línea",
                               "et": "Illustreeriv materjal veebipõhiseks õpetamiseks",
                               "fi": "Käyttö opetuksen havainnollistamiseen verkko- ja etäopetuksessa",
                               "fr": "Illustration pour l’enseignement en ligne",
                               "ga": "Léaráid le haghaidh teagaisc ar líne",
                               "hr": "Ilustracija radi podučavanja na daljinu",
                               "hu": "Illusztráció tanításhoz vagy tudományos kutatáshoz",
                               "it": "Illustrazione per la didattica online",
                               "lt": "Pavyzdžio naudojimas internetinių mokymų metu",
                               "lv": "Ilustrācija mācībām tiešsaistē",
                               "mt": "Illustrazzjoni ghal tagħlim onlajn",
                               "nl": "Illustratie voor online lesgeven",
                               "pl": "Zilustrowanie w ramach nauczania zdalnego",
                               "pt": "Utilização em atividades pedagógicas digitais.",
                               "ro": "Ilustrație pentru predarea online",
                               "se": "Illustrativt syfte inom onlineundervisning",
                               "sl": "Ilustracija za spletno poučevanje",
                               "sk": "Názorná ukážka pre online vyučovanie",
                               "en": "Illustration for teaching online"
                           },
                           "text and data mining for scientific research": {
                               "transId": "Text and data mining for scientific research",
                               "bg": "Извличане на информация от текст и данни за научни изследвания",
                               "cs": "Vytěžování textů a dat pro účely vědeckého výzkumu",
                               "da": "Tekst og datamining til videnskabelig forskning",
                               "de": "Text und Data Mining für wissenschaftliche Forschung",
                               "el": "Εξόρυξη κειμένων και δεδομένων για επιστημονική έρευνα",
                               "es": "Minería de textos y datos para investigación científica",
                               "et": "Teksti- ja andmekaeve kasutamine teadusuuringutes",
                               "fi": "Tekstin- ja tiedonlouhinta tieteellistä tutkimusta varten",
                               "fr": "Fouille de texte et de données pour la recherche scientifique",
                               "ga": "Mianadóireacht téacs agus sonraí do thaighde eolaíochta",
                               "hr": "Rudarenje teksta i podataka u svrhe znanstvenog istraživanja",
                               "hu": "Adatbányászat tudományos kutatáshoz",
                               "it": "Text e data mining per la ricerca scientifica",
                               "lt": "Tekstų ir duomenų gavyba mokslinių tyrimui tikslais",
                               "lv": "Teksta un datu ieguve zinātniskiem pētījumiem",
                               "mt": "Kitba u estrazzjoni tad-dejta għall-għan ta’ riċerka xjentifika",
                               "nl": "Tekst- en datamining voor wetenschappelijk onderzoek",
                               "pl": "Eksploracja tekstów i danych do celów badań naukowych",
                               "pt": "Prospeção de textos e dados para fins de investigação científica",
                               "ro": "Extragerea de texte și date pentru cercetarea științifică",
                               "se": "Data och textutvinning för stöd till forskning",
                               "sl": "Besedilno in podatkovno rudarjenje za znanstvene namene",
                               "sk": "Čerpanie údajov pre vedecký výskum",
                               "en": "Text and data mining for scientific research"
                           },
                           "preservation of cultural heritage": {
                               "transId": "Preservation of cultural heritage",
                               "bg": "Съхранение на културно наследство",
                               "cs": "Zachování kulturního dědictví",
                               "da": "Bevarelse af kulturarv",
                               "de": "Erhalt des Kulturerbes",
                               "el": "Διατήρηση της πολιτιστικής κληρονομιάς",
                               "es": "Preservación del patrimonio cultural",
                               "et": "Kultuuripärandi säilitamine",
                               "fi": "Kulttuuriperinnön säilyttäminen",
                               "fr": "Préservation de l’héritage culturel",
                               "ga": "Oidhreacht chultúrtha a chaomhnú",
                               "hr": "Zaštita kulturne baštine",
                               "hu": "Kulturális örökség megőrzése",
                               "it": "Conservazione del patrimonio culturale",
                               "lt": "Kultūrinio paveldo išsaugojimas",
                               "lv": "Kultūras mantojuma saglabāšana",
                               "mt": "Preservazzjoni tal-wirt kulturali",
                               "nl": "Behoud van cultureel erfgoed",
                               "pl": "Zachowanie dziedzictwa kulturowego",
                               "pt": "Conservação do património cultural",
                               "ro": "Păstrarea moștenirii culturale",
                               "se": "Bevarande av kulturarv",
                               "sl": "Ohranjanje kulturne dediščine",
                               "sk": "Ochrana kultúrneho dedičstva",
                               "en": "Preservation of cultural heritage"
                           },
                           "optional": {
                               "transId": "Optional",
                               "bg": "Факултативни",
                               "cs": "Volitelné",
                               "da": "Valgfri",
                               "de": "Optional",
                               "el": "Προαιρετικός",
                               "es": "Opcional",
                               "et": "Valikuline",
                               "fi": "Valinnainen",
                               "fr": "Optionnel",
                               "ga": "Roghnach",
                               "hr": "Fakultativno",
                               "hu": "Opcionális",
                               "it": "Facoltativo",
                               "lt": "Neprivalomas",
                               "lv": "Izvēles",
                               "mt": "Mhux obbligatorju",
                               "nl": "Optioneel",
                               "pl": "Opcjonalne",
                               "pt": "Opcional",
                               "ro": "Opțional",
                               "se": "Valfria",
                               "sl": "Izbirno",
                               "sk": "Dobrovoľné",
                               "en": "Optional"
                           },
                           "private use": {
                               "transId": "Private use",
                               "bg": "Частно използване",
                               "cs": "Osobní užití",
                               "da": "Privat brug",
                               "de": "Privatgebrauch",
                               "el": "Ιδιωτική χρήση",
                               "es": "Uso privado",
                               "et": "Kasutamine isiklikeks vajadusteks",
                               "fi": "Yksityinen käyttö",
                               "fr": "Usage privé",
                               "ga": "Úsáid phríobháideach",
                               "hr": "Privatno korištenje",
                               "hu": "Magáncélú felhasználás",
                               "it": "Uso privato",
                               "lt": "Asmeninis naudojimas",
                               "lv": "Privāta lietošana",
                               "mt": "Użu privat",
                               "nl": "Privégebruik",
                               "pl": "Użytek osobisty",
                               "pt": "Uso privado",
                               "ro": "Uz privat",
                               "se": "Privat bruk",
                               "sl": "Zasebna uporaba",
                               "sk": "Súkromné použitie",
                               "en": "Private use"
                           },
                           "persons with a disability": {
                               "transId": "Persons with a disability",
                               "bg": "Лица с увреждания",
                               "cs": "Osoby se zdravotním postižením",
                               "da": "Personer med et handicap",
                               "de": "Menschen mit Behinderung",
                               "el": "Άτομα με αναπηρία",
                               "es": "Personas con discapacidad",
                               "et": "Puudega inimesed",
                               "fi": "Vammaiset henkilöt",
                               "fr": "Personnes handicapées",
                               "ga": "Daoine faoi mhíchumas",
                               "hr": "Osobe s invaliditetom",
                               "hu": "Fogyatékkal élő személyek",
                               "it": "Persone con disabilità",
                               "lt": "Negalią turintys asmenys",
                               "lv": "Personas ar invaliditāti",
                               "mt": "Nies b’diżabilita’",
                               "nl": "Personen met een handicap",
                               "pl": "Osoby z niepełnosprawnością",
                               "pt": "Pessoas com deficiência",
                               "ro": "Persoane cu dizabilități",
                               "se": "Person med funktionsnedsättning",
                               "sl": "Osebe z omejitvami",
                               "sk": "Osoby so zdravotným postihnutím",
                               "en": "Persons with a disability"
                           },
                           "reporting current events": {
                               "transId": "Reporting current events",
                               "bg": "Съобщаване на актуални събития",
                               "cs": "Aktuální zpravodajství",
                               "da": "Rapportere aktuelle begivenheder",
                               "de": "Bericht über Tagesereignisse",
                               "el": "Παρουσίαση επίκαιρων γεγονότων",
                               "es": "Información sobre eventos actuales",
                               "et": "Päevasündmuste ülevaade",
                               "fi": "Ajankohtaisten asioiden raportointi",
                               "fr": "Couverture des évènements d’actualité",
                               "ga": "Cúrsaí reatha a thuairisciú",
                               "hr": "Izvještavanje o tekućim događajima",
                               "hu": "Tájékoztatás aktuális eseményekről",
                               "it": "Cronaca di eventi attuali",
                               "lt": "Naujienų pranešimas apie įvykius",
                               "lv": "Ziņošana par aktuālajiem notikumiem",
                               "mt": "Rappurtaġġ ta’ ġrajjiet kurrenti",
                               "nl": "Rapporteren van actuele gebeurtenissen",
                               "pl": "Relacjonowanie aktualnych wydarzeń",
                               "pt": "Relatos de acontecimentos de actualidade",
                               "ro": "Raportarea evenimentelor curente",
                               "se": "Nyhetsrapportering",
                               "sl": "Poročanje o trenutnih dogodkih",
                               "sk": "Informovanie o aktuálnych udalostiach",
                               "en": "Reporting current events"
                           },
                           "quotation for criticism or review": {
                               "transId": "Quotation for criticism or review",
                               "bg": "Цитиране за критика и обзор",
                               "cs": "Citace pro kritiku nebo recenzi",
                               "da": "Citat til kritik eller evaluering",
                               "de": "Zitat zwecks Kritik oder Rezension",
                               "el": "Παράθεση αποσπασμάτων με σκοπό την άσκηση κριτικής ή βιβλιοπαρουσίασης",
                               "es": "Cita para crítica o reseña",
                               "et": "Tsiteerida kriitika või arvustuse eesmärgil",
                               "fi": "Lainaus arvostelua tai selostusta varten",
                               "fr": "Citation à des fins de critique ou de revue",
                               "ga": "Luachan le haghaidh critice nó léirmheasa",
                               "hr": "Citiranje radi kritike ili pregleda",
                               "hu": "Idézés kritika vagy ismertetés céljából",
                               "it": "Citazione per critica e recensione",
                               "lt": "Citavimas kritikos ar apžvalgos tikslais",
                               "lv": "Citāts kritikai vai pārskatam",
                               "mt": "Kwotazzjonijiet għal skopijiet ta’ kritika jew rivista",
                               "nl": "Citaat voor kritiek of beoordeling",
                               "pl": "Cytowanie do celów krytyki lub recenzji",
                               "pt": "Inserção de citações ou resumos de obras alheias com fins de crítica e discussão.",
                               "ro": "Citare pentru critică sau recenzie",
                               "se": "Citat för användning i kritik och recensioner",
                               "sl": "Citat za kritiko ali recenzijo",
                               "sk": "Citácia na účely kritiky alebo recenzie",
                               "en": "Quotation for criticism or review"
                           },
                           "caricature, parody and pastiche": {
                               "transId": "Caricature, parody and pastiche",
                               "bg": "Карикатура, пародия и пастиш",
                               "cs": "Karikatura, parodie a pastiš",
                               "da": "Karikatur, parodi og pastiche",
                               "de": "Karikatur, Parodie und Pastiche",
                               "el": "Γελοιογραφία, παρωδία και μίμηση",
                               "es": "Caricatura, parodia y pastiche",
                               "et": "Karikatuur, paroodia ja pastišš",
                               "fi": "Karikatyyri, parodia ja pastissi",
                               "fr": "Caricature, parodie et pastiche",
                               "ga": "Scigléiriú, scigaithris agus paistís",
                               "hr": "Karikatura, parodija i pastiš",
                               "hu": "Karikatúra, paródia és utánzat",
                               "it": "Caricatura, parodia e pastiche",
                               "lt": "Karikatūra, parodija, pastišas",
                               "lv": "Karikatūra, parodija un pastišs",
                               "mt": "Karikatura, parodija u (and) pastiche",
                               "nl": "Karikatuur, parodie of pistache",
                               "pl": "Karykatura, parodia i pastisz",
                               "pt": "Paricatura, paródia e pastiche.",
                               "ro": "Caricatură, parodie și pastișă",
                               "se": "karikatyr, parodi och pastische",
                               "sl": "Karikatura, parodija in pastiš",
                               "sk": "Karikatúra, paródia a pastiš",
                               "en": "Caricature, parody and pastiche"
                           },
                           "dedicated terminals for research or private study": {
                               "transId": "Dedicated terminals for research or private study",
                               "bg": "Специализирани терминали за частно изследване или проучване",
                               "cs": "Vyhrazená zařízení pro výzkum nebo soukromé studium",
                               "da": "Dedikerede terminaler til forskning eller privat undersøgelse",
                               "de": "Für wissenschaftliche oder private Studien bestimmte Terminals",
                               "el": "Παρουσίαση ή διάθεση, με σκοπό την έρευνα ή την ιδιωτική μελέτη",
                               "es": "Terminales dedicados a la investigación o estudio privado",
                               "et": "Spetsiaalsed terminalid uurimistööks või eraõppeks",
                               "fi": "Tutkimuskäyttöä tai yksityistä käyttöä varten varatut laitteet",
                               "fr": "Terminaux spécialisés à des fins de recherches ou d’études privées",
                               "ga": "Teirminéil thiomnaithe do thaighde nó do staidéar príobháideach",
                               "hr": "Namjenski terminali za istraživanje ili privatno proučavanje",
                               "hu": "üzembe állított számítógépes terminálok képernyőjén tudományos kutatás vagy egyéni tanulás céljára",
                               "it": "Terminali dedicati per la ricerca o per lo studio privato",
                               "lt": "Įkurti terminalai moksliniams ar privatiems tyrimams",
                               "lv": "Īpaši termināli pētījumiem vai individuālām mācībām",
                               "mt": "Terminali dedikati fil-fond ta’ riċerka xjentifika jew (or) studju privat",
                               "nl": "Bestemde terminals voor onderzoek of privéstudie",
                               "pl": "Wyposażone terminale do badań lub prywatnych studiów",
                               "pt": "Terminais destinados para efeitos de investigação ou estudos pessoais.",
                               "ro": "Terminale specifice pentru cercetare sau studiu privat",
                               "se": "avsedda terminaler för forskning och privata studier",
                               "sl": "Namenski terminali za raziskave ali zasebni študij",
                               "sk": "Vyhradené terminály na výskum alebo súkromné štúdium",
                               "en": "Dedicated terminals for research or private study"
                           },
                           "illustration for teaching": {
                               "transId": "Illustration for teaching",
                               "bg": "Илюстриране при преподаване",
                               "cs": "Ilustrace při výuce",
                               "da": "Illustration til undervisning",
                               "de": "Illustration für Lehre",
                               "el": "Παράδειγμα κατά την διδασκαλία",
                               "es": "Ilustración para la enseñanza",
                               "et": "Illustreeriv materjal õppe-eesmärgil",
                               "fi": "Käyttö opetuksen havainnollistamiseen",
                               "fr": "Illustration à des fins d’enseignement",
                               "ga": "Léaráid le haghaidh teagaisc",
                               "hr": "Ilustracija radi podučavanja",
                               "hu": "szemléltetés iskolai oktatási célra",
                               "it": "Illustrazione per la didattica",
                               "lt": "Pavyzdžio naudojimas mokymų tikslais",
                               "lv": "Ilustrācija apmācībai",
                               "mt": "Illustrazzjoni għat-tagħlim",
                               "nl": "Illustraties voor onderwijs",
                               "pl": "Zilustrowanie w ramach nauczania",
                               "pt": "Ilustração didática",
                               "ro": "Ilustrație pentru predare",
                               "se": "Illustrativt syfte inom undervisning",
                               "sl": "Ilutracija za poučevanje",
                               "sk": "Názorná ukážka pre vyučovanie",
                               "en": "Illustration for teaching"
                           },
                           "preservation of broadcasts": {
                               "transId": "Preservation of broadcasts",
                               "bg": "Съхраняване на радио- и телевизионни  програми",
                               "cs": "Uchování záznamů vysílání",
                               "da": "Bevarelse af udsendelser",
                               "de": "Erhalt von Sendungen",
                               "el": "Συντήρηση των ραδιοτηλεοπτικών μεταδόσεων",
                               "es": "Preservación de emisiones",
                               "et": "Televisiooni- ja raadiosaadete säilitamine",
                               "fi": "Lähetysten tallentaminen/säilyttäminen",
                               "fr": "Conservation des émissions",
                               "ga": "Craolacháin a chaomhnú",
                               "hr": "Očuvanje emitaranja",
                               "hu": "A sugárzott műsorok megőrzése",
                               "it": "Conservazione delle trasmissioni",
                               "lt": "Transliacijų išsaugojimas",
                               "lv": "Raidījumu saglabāšana",
                               "mt": "Preservazzjoni ta’ xandir",
                               "nl": "Behoud van uitzendingen",
                               "pl": "Archiwizacja nadań",
                               "pt": "Conservação de transmissões / radiodifusões.",
                               "ro": "Arhivarea emisiunilor",
                               "se": "Bevarande av sändningar",
                               "sl": "Ohranjanje oddaj",
                               "sk": "Uchovávanie vysielaní",
                               "en": "Preservation of broadcasts"
                           },
                           "examples": {
                               "transId": "Examples",
                               "bg": "Примери",
                               "cs": "Příklady",
                               "da": "Eksempler",
                               "de": "Beispiele",
                               "el": "Παραδείγματα",
                               "es": "Ejemplos",
                               "et": "Näited",
                               "fi": "esimerkit",
                               "fr": "Exemples",
                               "ga": "Samplaí",
                               "hr": "Primjeri",
                               "hu": "Példák",
                               "it": "Esempi",
                               "lt": "Pavyzdžiai",
                               "lv": "Piemēri",
                               "mt": "Eżempji",
                               "nl": "Voorbeelden",
                               "pl": "Przykłady",
                               "pt": "Exemplos",
                               "ro": "Exemple",
                               "se": "Exempel",
                               "sl": "Vzorci",
                               "sk": "Príklady",
                               "en": "Examples"
                           },
                           "literary, dramatic, musical, artistic works": {
                               "transId": "Literary, Dramatic, Musical, Artistic Works",
                               "bg": "Литературни, драматични, музикални произведения и произведения на изобразителното изкуство",
                               "cs": "Literární, dramatické, hudební, umělecké dílo",
                               "da": "Litterært, dramatisk, musikalsk, kunstnerisk værk",
                               "de": "Literarische, dramatische, musikalische, künstlerische Werke",
                               "el": "Έργα λόγου, θεατρικά έργα, μουσικά έργα, έργα τέχνης",
                               "es": "Obras literarias, dramáticas, musicales y artísticas",
                               "et": "Kirjandus-, kunsti-, audiovisuaalsed- ja teadusteosed",
                               "fi": "Kirjalliset teokset, näytelmät,  musikaalit, taiteelliset teokset",
                               "fr": "Œuvres littéraires, dramatiques, musicales, artistiques",
                               "ga": "Saothair Liteartha, Dhrámata, Cheoil, Ealaíonta",
                               "hr": "Književna, dramska, glazbena I umjetnička djela",
                               "hu": "Irodalmi, színpadi, zenei, művészeti művek",
                               "it": "Opere letterarie, drammatiche, musicali, artistiche",
                               "lt": "Literatūriniai, Dramos, Muzikos, Vaizduojamojo meno Kūriniai",
                               "lv": "Literārie, dramatiskie, muzikālie, mākslinieciskie darbi",
                               "mt": "Xogħol letterarju, mużikali, artistiku",
                               "nl": "Literaire, dramatische, muzikale, artistieke werken",
                               "pl": "Utwory literackie, teatralne, muzyczne, artystyczne",
                               "pt": "Obras literárias, dramáticas, musicais, artísticas",
                               "ro": "Opere literare, dramaturgice, muzicale",
                               "se": "Litterära, dramatiska, musikaliska, konstnärliga verk",
                               "sl": "Literarna, dramska, glasbena, umetniška dela",
                               "sk": "Literárne, divadelné, hudobné, umelecké diela",
                               "en": "Literary, Dramatic, Musical, Artistic Works"
                           },
                           "films": {
                               "transId": "Films",
                               "bg": "Филми",
                               "cs": "Filmy",
                               "da": "Film",
                               "de": "Filmwerke",
                               "el": "Ταινίες",
                               "es": "Películas",
                               "et": "Filmid",
                               "fi": "Elokuvat",
                               "fr": "Films",
                               "ga": "Scannáin",
                               "hr": "Filmovi",
                               "hu": "Filmek",
                               "it": "Film",
                               "lt": "Filmai",
                               "lv": "Filmas",
                               "mt": "Film",
                               "nl": "Films",
                               "pl": "Filmy",
                               "pt": "Obras cinematográficas",
                               "ro": "Filme",
                               "se": "Filmer",
                               "sl": "Filmi",
                               "sk": "Filmy",
                               "en": "Films"
                           },
                           "life of director, author of screenplay, author of dialogue, composer of music": {
                               "transId": "Life of director, author of screenplay, author of dialogue, composer of music",
                               "bg": "Живота на режисьора, сценариста, автора на диалога, композитора на музиката",
                               "cs": "Život režiséra, autora scénáře, autora dialogů, skladatele hudby",
                               "da": "Instruktørens liv, undertekst forfatter, forfatter af dialogen, komponisten",
                               "de": "Leben des Regisseurs, Drehbuchautors, Urhebers der Dialoge, Komponisten",
                               "el": "Ζωή του σκηνοθέτη, του δημιουργού του σεναρίου, του δημιουργού του διαλόγου, του συνθέτη της μουσικής",
                               "es": "Vida del director, autor del guión, autor de los diálogos, compositor de la música",
                               "et": "Režissööri elulugu, stsenaariumi autor, dialoogi autor, muusika helilooja",
                               "fi": "Ohjaajan elinaika, näytelmän käsikirjoittaja, dialogin kirjoittaja, säveltäjä",
                               "fr": "Vie du réalisateur, auteur du scénario, auteur du dialogue, compositeur de la musique",
                               "ga": "Saolré stiúrthóra, údair scripte scannáin, údair dialóige, cumadóra ceoil",
                               "hr": "Život direktora, autora scenarija, autora dijaloga i skladatelja glazbe",
                               "hu": "A rendező élete, a forgatókönyv szerzője, a párbeszédek szerzője, a zene zeneszerzője.",
                               "it": "Vita del direttore, autore della sceneggiatura, autore dei dialoghi, compositore di musica",
                               "lt": "Režisieriaus, scenarijaus autoriaus, dialogų autoriaus, kompozitoriaus gyvenimo trukmė",
                               "lv": "Režisora dzīve, scenārija autors, dialogu autors, mūzikas komponists",
                               "mt": "Ħajja: tad-direttur, tal-awtur tal-iscreenplay, tal-awtur tad-djalogu, tal-kompożitur tal-mużika",
                               "nl": "Leven van regisseur, auteur van scenario, auteur van dialoog, componist van muziek",
                               "pl": "Okres życia reżysera, autora scenariusza, autora dialogów, kompozytora muzyki",
                               "pt": "Vida do realizador, autor de obra dramática e dramático-musical e sua encenação, autor do argumento e diálogo, compositor musical",
                               "ro": "Viața regizorului, a autorului scenariului, a autorului dialogului, compozitorului",
                               "se": "Livstid av huvudregissören, filmmanusets författare, författare till dialogen, och musikkompositören",
                               "sl": "Življenje režiserja, avtorja scenarija, avtorja dialogov, skladatelja glasbe",
                               "sk": "Život režiséra, autora scenára, autora dialógov, hudobného skladateľa",
                               "en": "Life of director, author of screenplay, author of dialogue, composer of music"
                           },
                           "works of joint authorship": {
                               "transId": "Works of Joint Authorship",
                               "bg": "Произведения в съавторство",
                               "cs": "Spoluautorská díla",
                               "da": "Værker af flere forfattere",
                               "de": "Werke gemeinsamer Urheberschaft",
                               "el": "Έργα Συν-δημιουργίας",
                               "es": "Obras en Colaboración",
                               "et": "Ühine autorsus",
                               "fi": "Yhteisteokset",
                               "fr": "Œuvres de collaboration",
                               "ga": "Saothair chomh-údarthachta",
                               "hr": "Djela u koautorstvu",
                               "hu": "Közös szerzői művek",
                               "it": "Opere a paternità congiunta",
                               "lt": "Bendraautorių kūriniai",
                               "lv": "Līdzautoru darbi",
                               "mt": "Xogħol ta’ ko-awturi",
                               "nl": "Werken van gemeenschappelijk auteursrecht",
                               "pl": "Dzieła współautorskie",
                               "pt": "Obra feita em colaboração",
                               "ro": "Opere scrise de co-autori",
                               "se": "Gemensamt Upphovsmannaskap",
                               "sl": "Dela s skupnim avtorstvom",
                               "sk": "Spoluautorské diela",
                               "en": "Works of Joint Authorship"
                           },
                           "life of joint authors": {
                               "transId": "Life of joint authors",
                               "bg": "Живота на последния автор",
                               "cs": "Život spoluautorů",
                               "da": "Levetid for flere forfattere",
                               "de": "Leben gemeinsamer Urheber",
                               "el": "Ζωή των συν-δημιουργών",
                               "es": "Vida de los coautores",
                               "et": "Ühiste autorite eluaeg",
                               "fi": "Yhteisteoksen tekijöiden elinaika",
                               "fr": "Vie des co-auteurs",
                               "ga": "Saolré comh-údar",
                               "hr": "Život koautora",
                               "hu": "Közös művek szerzőinek élete",
                               "it": "Vita dei coautori",
                               "lt": "Bendraautorių gyvenimo trukmė",
                               "lv": "Līdzautoru dzīves ilgums",
                               "mt": "Il-ħajja tal-ko-awturi",
                               "nl": "Leven van coauteurs",
                               "pl": "Okres życia współautorów",
                               "pt": "Vida dos colaboradores de obra feita em colaboração",
                               "ro": "Viața co-autorilor",
                               "se": "Gemensamma livstid",
                               "sl": "Živlnjenje skupnih avtorjev",
                               "sk": "Život spoluautorov",
                               "en": "Life of joint authors"
                           },
                           "anonymous and pseudonymous works": {
                               "transId": "Anonymous and Pseudonymous Works",
                               "bg": "Произведения под псевдоним и анонимни",
                               "cs": "Anonymní a pseudonymní díla",
                               "da": "Anonyme og psedonyme værker",
                               "de": "Anonyme und pseudonyme Werke",
                               "el": "Ανώνυμα έργα και έργα με ψευδώνυμο",
                               "es": "Obras anónimas y Pseudónimas",
                               "et": "Anonüümselt ja pseudonüümi all avalikustatud teosed",
                               "fi": "Nimettömät ja taiteilijanimien alla tuotetut teokset",
                               "fr": "Œuvres anonymes et pseudonymes",
                               "ga": "Saothair Gan Ainm agus le hAinm Cleite",
                               "hr": "Djela anonimnog autora I pod pseudonimom",
                               "hu": "név nélkül vagy felvett néven alkotott művek",
                               "it": "Opere anonime e pseudonime",
                               "lt": "Anoniminiai ir Pseudoniminiai kūriniai",
                               "lv": "Anonīmie un pseidonīmie darbi",
                               "mt": "Xogħol anonimu / Xogħol psewdonimu",
                               "nl": "Anonieme en pseudonieme werken",
                               "pl": "Utwory anonimowe i pod pseudonimem",
                               "pt": "Obra de autor anónimo / pseudónimo",
                               "ro": "Opere anonime și sub pseudonim",
                               "se": "anonyma och pseudonyma verk",
                               "sl": "Anonimna ali psedvonimna dela",
                               "sk": "Anonymné a pseudonymné diela",
                               "en": "Anonymous and Pseudonymous Works"
                           },
                           "work made available to the public": {
                               "transId": "Work made available to the public",
                               "bg": "Произведението е разгласено",
                               "cs": "Zveřejnění díla",
                               "da": "Værker som er offentliggjorte",
                               "de": "Werk öffentlich zugänglich gemacht",
                               "el": "Έργα διατιθέμενα στο κοινό",
                               "es": "Obra puesta a disposición del público",
                               "et": "Üldsusele avalikustatud teosed",
                               "fi": "Teoksen julkaiseminen",
                               "fr": "Œuvre mise à la disposition du public",
                               "ga": "Saothar curtha ar fáil don phobal",
                               "hr": "Djelo učinjeno dostupnim javnosti",
                               "hu": "nyilvánosság számára lehívásra hozzáférhetővé tett művek",
                               "it": "Opera resa disponibile al pubblico",
                               "lt": "Viešai prieinamas kūrinys",
                               "lv": "Sabiedrībai darīts pieejams darbs",
                               "mt": "Xogħol disponibbli għall-pubbliku",
                               "nl": "Werk toegankelijk gemaakt voor het publiek",
                               "pl": "Utwór udostępniony publicznie",
                               "pt": "Obra disponibilizada ao púbico",
                               "ro": "Opere puse la dispoziția publicului",
                               "se": "Verk överförs till allmänheten",
                               "sl": "Delo, ki je na voljo javnosti",
                               "sk": "Sprístupnenie diela verejnosti",
                               "en": "Work made available to the public"
                           },
                           "author’s identity revealed": {
                               "transId": "Author’s identity revealed",
                               "bg": "Разкриване на личността на автора",
                               "cs": "Totožnost autora odhalena",
                               "da": "Forfatterens identitet afsløret",
                               "de": "Bekanntwerden des Urhebers",
                               "el": "Αποκαλυφθείσα ταυτότητα του δημιουργού",
                               "es": "Identidad del autor/a revelada",
                               "et": "Teose autori üldsusele teatavaks tegemine",
                               "fi": "Tekijän henkilöllisyyden paljast",
                               "fr": "Révélation de l’identité de l’auteur",
                               "ga": "Aitheantas an údair nochta",
                               "hr": "Otkrivanje identiteta autora",
                               "hu": "A szerző személyazonossága felfedve",
                               "it": "Rivelazione dell’identità dell’autore",
                               "lt": "Atskleidžiama autoriaus tapatybė",
                               "lv": "Atklāta autora identitāte",
                               "mt": "L-identita’ tal-awtur żvelatha",
                               "nl": "Identiteit van maker bekend geworden",
                               "pl": "Ujawnienie tożsamości autora",
                               "pt": "Revelação da identidade do autor",
                               "ro": "Identitatea dezvăluită a autorului",
                               "se": "Skaparens identitet avslöjas",
                               "sl": "Razkritje avtorjeve identitete",
                               "sk": "Zverejnenie totožnosti autora",
                               "en": "Author’s identity revealed"
                           },
                           "sound recording / performance": {
                               "transId": "Sound Recording / Performance",
                               "bg": "Звукозапис/ Изпълнение",
                               "cs": "Zvuková nahrávka / Umělecký výkon",
                               "da": "Lydoptagelser/optrædener",
                               "de": "Tonaufnahmen / Aufführungen",
                               "el": "Ηχογράφηση/Ερμηνεία ή εκτέλεση",
                               "es": "Fonograma/Interpretación",
                               "et": "Helisalvestis / Esitus",
                               "fi": "Äänitallenne / esitys",
                               "fr": "Enregistrement sonore / performance",
                               "ga": "Taifeadadh Fuaime / Taibhiú",
                               "hr": "Zvučna snimka/umjetnička izvedba",
                               "hu": "Hangfelvétel/előadás",
                               "it": "Registrazione sonora / Esecuzione",
                               "lt": "Garso įrašas / Atlikimas",
                               "lv": "Skaņas ieraksts / Izpildījums",
                               "mt": "Produzzjoni tas-smigħ / Wirja",
                               "nl": "Geluidsopname/Uitvoering",
                               "pl": "Nagranie dźwiękowe/artystyczne wykonanie",
                               "pt": "Fonograma / Interpretação (/execução)",
                               "ro": "Înregistrare sonoro / Interpretare",
                               "se": "Ljudinspelning / Framförande",
                               "sl": "Zvočni posnetek / Nastop",
                               "sk": "Zvukový záznam / Umelecký výkon",
                               "en": "Sound Recording / Performance"
                           },
                           "recording made /performance takes place": {
                               "transId": "Recording made /performance takes place",
                               "bg": "Направен е запис / Изпълнение",
                               "cs": "Pořízení záznamu / Živý umělecký výkon",
                               "da": "Optagelser lavet/optrædener finder sted",
                               "de": "Aufnahme hergestellt / Aufführung stattgefunden",
                               "el": "Γενόμενη ηχογράφηση/η ερμηνεία ή η εκτέλεση λαμβάνει χώρα",
                               "es": "Grabación hecha/Interpretación tiene lugar",
                               "et": "Salvestus tehtud / Esitus toimub",
                               "fi": "Äänitteen tallentamisesta / esitystapahtuma",
                               "fr": "Enregistrement effectué / performance ayant lieu",
                               "ga": "Taifeadadh déanta / taibhiú ar siúl",
                               "hr": "Snimka napravljena/umjetnička izvedba se izvodi",
                               "hu": "Felvétel készült/előadásra kerül sor",
                               "it": "Registrazione eseguita / Svolgimento dell’esecuzione",
                               "lt": "Sukuriamas įrašas / atliekamas pasirodymas",
                               "lv": "Veikts skaņas ieraksts / Notiek izrāde",
                               "mt": "Reġistrazzjoni ta’ smigħ / l-esekuzzjoni tal-wirja",
                               "nl": "Opname gemaakt/Uitvoering vindt plaats",
                               "pl": "Dokonanie nagrania/moment wykonania",
                               "pt": "Fixação fonográfica / representação ou execução pelo artista intérprete ou executante",
                               "ro": "Înregistrare realizată / Interpretarea are loc",
                               "se": "Upptagning utförts/framförande äger rum",
                               "sl": "Posnetek narejen / Nastop poteka",
                               "sk": "Vyhotovenie záznamu /konanie umeleckého výkonu",
                               "en": "Recording made /performance takes place"
                           },
                           "50 years": {
                               "transId": "50 years",
                               "bg": "50 години",
                               "cs": "50 let",
                               "da": "50 år",
                               "de": "50 Jahre",
                               "el": "50 έτη",
                               "es": "50 años",
                               "et": "50 aastat",
                               "fi": "50 vuotta",
                               "fr": "50 ans",
                               "ga": "50 bliain",
                               "hr": "50 godina",
                               "hu": "50 év",
                               "it": "50 anni",
                               "lt": "50 metų",
                               "lv": "50 gadi",
                               "mt": "50 sena / Ħamsin sena",
                               "nl": "50 jaar",
                               "pl": "50 lat",
                               "pt": "Cinquenta anos",
                               "ro": "50 de ani",
                               "se": "50 år",
                               "sl": "50 let",
                               "sk": "50 rokov",
                               "en": "50 years"
                           },
                           "publication / released to the public": {
                               "transId": "Publication / Released to the public",
                               "bg": "Публикация / Разгласяване на публиката",
                               "cs": "Vydání / Sdělení veřejnosti",
                               "da": "Publiceret/udgivet til offentligheden",
                               "de": "Herausgabe / Veröffentlichung",
                               "el": "Έκδοση/Διάθεση στο κοινό",
                               "es": "Publicación/puesta a disposición del público",
                               "et": "Avaldamine / Üldsusele avaldatud",
                               "fi": "Julkaisu / Julkaistu",
                               "fr": "Publication / mis à la disposition du public",
                               "ga": "Foilsiú / Curtha amach don phobal",
                               "hr": "Javna objava",
                               "hu": "Kiadvány/ Nyilvánosságra hozták",
                               "it": "Pubblicazione / Rilasciato al pubblico",
                               "lt": "Išleidimas / Išleidimas",
                               "lv": "Publikācija / Publiskots",
                               "mt": "Pubblikazzjoni / Komunikazzjoni lill-pubbliku",
                               "nl": "Publicatie/Vrijgegeven aan het publiek",
                               "pl": "Publikacja/udostępnienie publiczne",
                               "pt": "Publicação / Disponibilização Pública",
                               "ro": "Publicare / Lansare pentru public",
                               "se": "Tillgängliggörs för allmänheten",
                               "sl": "Objava / Razkritje javnosti",
                               "sk": "Vydanie / Zverejnenie",
                               "en": "Publication / Released to the public"
                           },
                           "first transmission": {
                               "transId": "First transmission",
                               "bg": "Първочално предаване",
                               "cs": "První vysílání",
                               "da": "Første transmission",
                               "de": "Erste Ausstrahlung",
                               "el": "Πρώτη μετάδοση",
                               "es": "Primera transmisión",
                               "et": "Saate esmakordne edastamine",
                               "fi": "Ensimmäinen lähetys",
                               "fr": "Première transmission",
                               "ga": "An chéad chraoladh",
                               "hr": "Prvo emitiranje",
                               "hu": "első átvitel",
                               "it": "Prima trasmissione",
                               "lt": "Pirmasis perdavimas",
                               "lv": "Pirmā pārraide",
                               "mt": "L-ewwel trasmissjoni",
                               "nl": "Eerste uitzending",
                               "pl": "Pierwsze nadanie",
                               "pt": "Primeira difusão",
                               "ro": "Prima transmisie",
                               "se": "Första upptagning",
                               "sl": "Prvi prenos",
                               "sk": "Prvý prenos",
                               "en": "First transmission"
                           },
                           "broadcasts": {
                               "transId": "Broadcasts",
                               "bg": "Програми",
                               "cs": "Vysílání",
                               "da": "Broadcasts",
                               "de": "Sendungen",
                               "el": "Ραδιοτηλεοπτικές μεταδόσεις",
                               "es": "Radiodifusión",
                               "et": "Ülekanded",
                               "fi": "Lähetykset",
                               "fr": "Émissions",
                               "ga": "Craolacháin",
                               "hr": "Emitiranja",
                               "hu": "sugárzás",
                               "it": "Trasmissioni",
                               "lt": "Transliacijos",
                               "lv": "Pārraide",
                               "mt": "Xandir",
                               "nl": "Uitzendingen",
                               "pl": "Nadania",
                               "pt": "Radiodifusão sonora ou visual",
                               "ro": "Emisiuni",
                               "se": "Sändningar",
                               "sl": "Oddaje",
                               "sk": "Vysielania",
                               "en": "Broadcasts"
                           },
                           "perpetual (in certain jurisdictions)": {
                               "transId": "Perpetual (in certain jurisdictions)",
                               "bg": "Завинаги (в някои юрисдикции)",
                               "cs": "Bez omezení (v některých právních řádech)",
                               "da": "Evig (I visse jurisdiktioner)",
                               "de": "Ewig (in bestimmten Jurisdiktionen)",
                               "el": "Παντοτινό (σε συγκεκριμένες έννομες τάξεις)",
                               "es": "Perpetuo (en determinadas jurisdicciones)",
                               "et": "Tähtajatult (teatud jurisdiktsioonides)",
                               "fi": "Ikuinen (tietyissä oikeusjärjestelmissä)",
                               "fr": "Perpétuel (dans certaines juridictions)",
                               "ga": "Buan (i ndlínsí áirithe)",
                               "hr": "Neprekidan (u određenim jurisdikcijama)",
                               "hu": "Örökös (bizonyos joghatóságokban)",
                               "it": "Perpetuo (in alcune giurisdizioni)",
                               "lt": "Amžinas (tam tikrose jurisdikcijose)",
                               "lv": "Mūžīgās (noteiktās jurisdikcijās)",
                               "mt": "Perpetwu (f’ċerti ġuridizzjonijiet)",
                               "nl": "Eeuwigdurend (in bepaalde jurisdicties)",
                               "pl": "Nieograniczone w czasie (w niektórych krajach)",
                               "pt": "Perpétuo (em certas jurisdições)",
                               "ro": "Perpetuu/ă (în anumite jurisdicții)",
                               "se": "Permanenta (inom vissa jurisdiktioner)",
                               "sl": "Večno (v določenih pravnih redih)",
                               "sk": "Večné (v určitých jurisdikciách)",
                               "en": "Perpetual (in certain jurisdictions)"
                           }
                       }

export function getTranslationMap(language, transId) {
//    return ".";
    const lowerCaseKey = (transId || "").toLowerCase(); // "Work enters the Public Domain"
    return (translationMap[lowerCaseKey] || {})[language] || transId;
}