import { configureStore } from '@reduxjs/toolkit';
import vennStateReducer from './features/counter/vennState-slice';
import selectedModalReducer from './features/counter/selectedModal-slice';

export const store = configureStore({
  reducer: {
     selectedModal: selectedModalReducer,
     vennState: vennStateReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
