import * as React from "react"
import { getTranslationMap } from '../../features/translationMap';

const SvgComponent = (props) => {

                                  const [hoverSelected, setHoverSelected] = React.useState("");
const { selectedTrans } = props;
const translatedd = getTranslationMap(selectedTrans, "berne convention art. 6bis (concerning authors)").split(" ");
const translatedWipo = getTranslationMap(selectedTrans, "wipo performances and phonograms treaty (wppt) art. 5 (concerning performers)").split(" ");
                                return (
  <svg
    viewBox={"0 0 1310 477"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_b">
        <path d="M383 648.5h1v-59h-1z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_d">
        <path d="M490 484.5h1v-59h-1z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_f">
        <path d="M775 484.5h1v-59h-1z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_g">
        <path d="M594.124 705.376h292.324v-85.18h-292.324z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_h">
        <path d="M915.66 705.376h325.25v-45.18h-325.25z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_j">
        <path d="M640 648.5h1v-59h-1z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_k">
        <path d="M358.378 414.376h564.894v-69.18H358.378Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_l">
        <path d="M730.703 414.376h273.167v-45.18h-273.167z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse"  id="mr_n">
        <path d="M1178 648.5h1v-59h-1z" />
      </clipPath>
    </defs>
    <text
      xmlSpace="preserve"
      transform="matrix(1.33333 0 0 1.33333 21.653 20.26)"
      style={{
        fontVariant: "normal",
        fontWeight: hoverSelected === "b" ? 700 : 400,
        fontStretch: "normal",
        fontSize: 20,
        writingMode: "lr-tb",
        fill: hoverSelected === "b" ? "rgb(220, 68, 124)" : "#000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        opacity: hoverSelected === "b" ? 1 : 0.5
      }}
    >
      <tspan
        y={0}
      >
      {translatedd.slice(0, 4).join(" ")}

      </tspan>
      <tspan
        x="0"
        y={24}
      >
      {translatedd.slice(4, 14).join(" ")}
      </tspan>
    </text>
    <g
      clipPath="url(#mr_a)"
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <g clipPath="url(#mr_b)" opacity={0.5}>
        <path
          d="M0 0v-59"
          style={{
            fill: "none",
            stroke: "#666",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(383.5 648.5)"
        />
      </g>
    </g>
    <g
      clipPath="url(#mr_c)"
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <g clipPath="url(#mr_d)" opacity={0.5}>
        <path
          d="M0 0v-59"
          style={{
            fill: "none",
            stroke: "#666",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(490.5 484.5)"
        />
      </g>
    </g>
    <g
      clipPath="url(#mr_e)"
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <g clipPath="url(#mr_f)" opacity={0.5}>
        <path
          d="M0 0v-59"
          style={{
            fill: "none",
            stroke: "#666",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(775.5 484.5)"
        />
      </g>
    </g>
    <g
      clipPath="url(#mr_g)"
      opacity={hoverSelected === "m1" ? 1 : 0.5}
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <text
        xmlSpace="preserve"
        transform="matrix(1 0 0 -1 608.86 688.376)"
        style={{
          fontVariant: "normal",
          fontWeight:  hoverSelected === "m1" ? 700 : 400,
          fontStretch: "normal",
          fontSize: 20,
           
           
          writingMode: "lr-tb",
          fill:hoverSelected === "m1" ? "rgb(220, 68, 124)" : "#000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      >
        <tspan
          x="0 7.4180002 12.318 23.066 33.717999 40.998001 44.998001 55.73"
          y={0}
        >
          {""}
        </tspan>
        <tspan
          x="-14.74"
          y={24}
        >
      {getTranslationMap(selectedTrans, "right of attribution")}
        </tspan>
      </text>
    </g>
    <g
      clipPath="url(#mr_h)"
      opacity={ hoverSelected === "ms" ? 1 : 0.5}
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <text
        xmlSpace="preserve"
        transform="matrix(1 0 0 -1 1115.662 688.376)"
        style={{
          fontVariant: "normal",
          fontWeight: hoverSelected === "ms" ? 700 : 400,
          fontStretch: "normal",
          fontSize: 20,
           
           
          writingMode: "lr-tb",
          fill: hoverSelected === "ms" ? "rgb(220, 68, 124)" : "#000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      >
        <tspan
          x="0 16.540001 27.16 43.700001 54.562 65.181999 72.461998 76.461998 86.220001 93.517998 103.682 110.572 121.232"
          y={0}
        >
          {""}
        </tspan>
        <tspan x="-72.200001" y={24}>
          {getTranslationMap(selectedTrans, "Member State of EU")}
        </tspan>
      </text>
    </g>
    <g
      clipPath="url(#mr_i)"
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <g clipPath="url(#mr_j)" opacity={0.5}>
        <path
          d="M0 0v-59"
          style={{
            fill: "none",
            stroke: "#666",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(640.5 648.5)"
        />
      </g>
    </g>
    <g
      clipPath="url(#mr_k)"
      opacity={hoverSelected === "w" ? 1 : .5}
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <text
        xmlSpace="preserve"
        transform="matrix(1 0 0 -1 387.198 397.377)"
        style={{
          fontVariant: "normal",
          fontWeight: hoverSelected === "w" ? 700 : 400,
          fontStretch: "normal",
          fontSize: 20,
           
           
          writingMode: "lr-tb",
          fill: hoverSelected === "w" ? "rgb(220, 68, 124)" : "#000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      >
        <tspan
          x=""
          y={0}
        >
        {translatedWipo.slice(0, 3).join(" ")}
        </tspan>
        <tspan
          x="-14.42"
          y={24}
        >
        {translatedWipo.slice(3, 6).join(" ")}
        </tspan>
        <tspan
          x="-28.82"
          y={48}
        >
        {translatedWipo.slice(6, 12).join(" ")}
        </tspan>
      </text>
    </g>
    <g
      clipPath="url(#mr_l)"
      opacity={ hoverSelected === "m2" ? 1 :0.5}
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <text
        xmlSpace="preserve"
        transform="matrix(1 0 0 -1 735.86 397.377)"
        style={{
          fontVariant: "normal",
          fontWeight: hoverSelected === "m2" ? 700 : 400,
          fontStretch: "normal",
          fontSize: 20,
           
           
          writingMode: "lr-tb",
          fill:  hoverSelected === "m2" ? "rgb(220, 68, 124)" : "#000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      >
        <tspan
          x="0"
          y={0}
        >
          {getTranslationMap(selectedTrans, "right of integrity")}
        </tspan>
      </text>
    </g>
    <g
      clipPath="url(#mr_m)"
      transform="matrix(1.33333 0 0 -1.33333 -361.218 938.096)"
    >
      <g clipPath="url(#mr_n)" opacity={0.5}>
        <path
          d="M0 0v-59"
          style={{
            fill: "none",
            stroke: "#666",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(1178.5 648.5)"
        />
      </g>
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 42.126 241.09)"
      clipPath="url(#mr_o)"
    >
      <path
        d="M0 0c-2.689 3.463-4.83 7.573-6.423 12.334-1.595 4.76-2.392 10.124-2.392 16.092 0 5.967.807 11.377 2.425 16.229 1.617 4.851 3.769 9.008 6.458 12.47.274.319.615.479 1.025.479h7.721c.319 0 .535-.092.65-.274.113-.183.102-.41-.035-.683-4.966-8.473-7.448-17.858-7.448-28.153C1.981 18.199 4.463 8.86 9.429.478c.091-.181.137-.318.137-.409 0-.365-.251-.547-.752-.547H1.025C.614-.478.272-.318 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 90.593 242.001)"
      clipPath="url(#mr_p)"
    >
      <path
        d="M0 0c-2.37 3.463-4.283 7.698-5.74 12.71-1.459 5.011-2.187 10.386-2.187 16.126 0 5.877.74 11.354 2.22 16.433 1.48 5.08 3.427 9.351 5.843 12.812.182.274.455.411.82.411h4.92c.274 0 .455-.08.546-.24.091-.16.069-.353-.068-.58-2.005-3.6-3.655-7.892-4.954-12.88C.101 39.803-.547 34.507-.547 28.905c0-5.604.648-10.865 1.947-15.785S4.349 3.964 6.354.41c.091-.181.137-.318.137-.41 0-.273-.205-.41-.615-.41H.819C.5-.41.227-.273 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 150.997 198.236)"
    >
      <path
        className={ hoverSelected === "b" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("b")}
        onMouseOut={() => setHoverSelected("")}
        d="M0 0c-1.64 0-2.855-.71-3.716-2.172-.905-1.561-1.366-3.807-1.366-6.667 0-2.859.414-5.011 1.229-6.395.922-1.652 2.201-2.445 3.921-2.445 1.859 0 3.205.793 4.115 2.425.868 1.569 1.309 3.705 1.309 6.346 0 2.931-.498 5.185-1.48 6.7C3.093-.716 1.785 0 0 0m2.118-27.947c-2.897 0-5.333.885-7.268 2.637v-.271c0-.493-.183-.939-.531-1.287a1.786 1.786 0 0 0-1.288-.532h-7.996c-.496 0-.943.184-1.29.533a1.777 1.777 0 0 0-.529 1.286v46.192c0 .495.184.941.532 1.288.349.349.794.532 1.287.532h7.996c.491 0 .935-.182 1.284-.528.352-.356.535-.8.535-1.292V7.904c1.847 1.58 4.184 2.365 6.994 2.365 3.534 0 6.553-.931 8.975-2.766C13.22 5.685 15 3.196 16.11.108c.901-2.464 1.358-5.431 1.358-8.811 0-3.235-.407-6.122-1.211-8.579-1.111-3.282-2.914-5.901-5.361-7.792-2.47-1.906-5.423-2.873-8.778-2.873"
        style={{
          fill: "rgb(220, 68, 124)",
          pointerEvents: "bounding-box",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          stroke: "white",
          strokeWidth: hoverSelected === "b" ? "0px" : "2px",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 236.135 200.865)"
      clipPath="url(#mr_s)"
    >
      <path
        d="M0 0a.795.795 0 0 0 .239-.581v-6.628A.79.79 0 0 0 0-7.79a.791.791 0 0 0-.581-.239h-9.36c-.23 0-.342-.114-.342-.342v-9.224a.789.789 0 0 0-.24-.581.79.79 0 0 0-.581-.239h-6.559a.786.786 0 0 0-.581.239.788.788 0 0 0-.239.581v9.224c0 .228-.115.342-.341.342h-9.363a.786.786 0 0 0-.58.239.786.786 0 0 0-.239.581v6.628c0 .227.079.421.239.581a.79.79 0 0 0 .58.239h9.363c.226 0 .341.113.341.342v9.43c0 .227.079.42.239.58a.79.79 0 0 0 .581.239h6.559c.228 0 .421-.08.581-.239a.79.79 0 0 0 .24-.58V.581c0-.229.112-.342.342-.342h9.36C-.354.239-.16.159 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 272.441 232.525)"
      clipPath="url(#mr_t)"
    >
      <path
        className={ hoverSelected === "w" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("w")}
        onMouseOut={() => setHoverSelected("")}
        d="m0 0-9.84 33.55-.068.274c0 .455.25.683.751.683h8.269c.501 0 .82-.228.956-.683l4.921-20.636c.044-.137.113-.205.204-.205.091 0 .16.068.205.205l5.125 20.636c.136.455.455.683.957.683h7.175c.5 0 .819-.228.957-.683l5.397-20.704c.045-.137.113-.205.205-.205.091 0 .159.068.206.205l5.329 20.704c.138.455.455.683.957.683l8.2-.137c.273 0 .467-.08.58-.238.114-.161.148-.376.103-.65L30.613 0c-.138-.456-.434-.683-.889-.683h-8.268c-.502 0-.819.227-.957.683l-5.261 18.313c-.046.136-.114.205-.205.205-.092 0-.16-.069-.204-.205L10.182 0c-.137-.456-.457-.683-.957-.683H.957C.455-.683.137-.456 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          pointerEvents: "bounding-box",
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 363.094 164.56)"
      clipPath="url(#mr_u)"
    >
      <path
        d="M0 0c2.413-3.462 4.349-7.699 5.808-12.709 1.458-5.012 2.186-10.387 2.186-16.126 0-5.832-.741-11.287-2.221-16.366-1.481-5.08-3.428-9.372-5.842-12.88-.182-.273-.456-.41-.82-.41h-4.989c-.272 0-.455.08-.545.239-.093.16-.069.353.068.581 2.05 3.599 3.723 7.892 5.022 12.88C-.035-39.803.614-34.507.614-28.904c0 5.557-.649 10.807-1.947 15.75C-2.632-8.211-4.305-3.963-6.355-.41c-.092.182-.137.319-.137.41 0 .274.205.41.614.41h5.057C-.502.41-.23.274 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 394.569 220.271)"
      clipPath="url(#mr_v)"
    >
      <path
        d="M0 0a.786.786 0 0 0-.239.581v6.628c0 .227.079.42.239.58.159.16.352.24.581.24h27.606a.79.79 0 0 0 .58-.24.793.793 0 0 0 .239-.58V.581A.79.79 0 0 0 28.767 0a.79.79 0 0 0-.58-.24H.581A.787.787 0 0 0 0 0m0 13.12a.786.786 0 0 0-.239.58v6.629c0 .227.079.42.239.58.159.16.352.24.581.24h27.606a.79.79 0 0 0 .58-.24.793.793 0 0 0 .239-.58V13.7a.79.79 0 0 0-.239-.58.79.79 0 0 0-.58-.24H.581a.787.787 0 0 0-.581.24"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 521.893 190.16)"
      clipPath="url(#mr_w)"
    >
      <path
        className={ hoverSelected === "m1" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("m1")}
        onMouseOut={() => setHoverSelected("")}
        d="M0 0c2.003-2.142 3.007-5.148 3.007-9.02v-22.617a.787.787 0 0 0-.24-.581.787.787 0 0 0-.581-.239h-7.994a.784.784 0 0 0-.581.239.787.787 0 0 0-.24.581v20.499c0 1.867-.478 3.348-1.435 4.442-.957 1.093-2.232 1.639-3.826 1.639-1.595 0-2.893-.546-3.894-1.639-1.004-1.094-1.504-2.552-1.504-4.374v-20.567a.79.79 0 0 0-.239-.581.787.787 0 0 0-.581-.239h-7.926a.786.786 0 0 0-.582.239.786.786 0 0 0-.239.581v20.499c0 1.821-.502 3.291-1.502 4.408-1.003 1.115-2.301 1.673-3.896 1.673-1.458 0-2.676-.433-3.656-1.298-.979-.866-1.56-2.073-1.741-3.621v-21.661a.787.787 0 0 0-.24-.581.787.787 0 0 0-.581-.239h-7.994a.784.784 0 0 0-.581.239.787.787 0 0 0-.24.581V1.845c0 .228.08.42.24.581a.792.792 0 0 0 .581.239h7.994c.228 0 .42-.08.581-.239a.792.792 0 0 0 .24-.581V-.82c0-.137.044-.228.136-.273.091-.046.181 0 .274.136 1.867 2.779 4.759 4.169 8.677 4.169 2.278 0 4.271-.479 5.979-1.436 1.708-.956 3.018-2.323 3.929-4.1.137-.272.296-.272.479 0 1.001 1.868 2.357 3.257 4.065 4.169 1.709.911 3.633 1.367 5.774 1.367C-4.783 3.212-2.006 2.141 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          pointerEvents: "bounding-box",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 538.819 226.594)"
      clipPath="url(#mr_x)"
    >
      <path
        className={ hoverSelected === "m1" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("m1")}
        onMouseOut={() => setHoverSelected("")}
        d="M0 0h4.821a.468.468 0 0 0 .339-.139.464.464 0 0 0 .138-.339v-26.93a.462.462 0 0 0-.138-.338.461.461 0 0 0-.339-.14H.16a.457.457 0 0 0-.339.14.46.46 0 0 0-.14.338v21.831c0 .053-.027.106-.079.159-.053.054-.106.066-.16.04l-3.944-1.075-.158-.04c-.239 0-.359.146-.359.438l-.119 3.426c0 .265.119.452.357.558L-.598-.119C-.464-.039-.266 0 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          pointerEvents: "bounding-box",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 608.396 200.865)"
      clipPath="url(#mr_y)"
    >
      <path
        d="M0 0a.795.795 0 0 0 .239-.581v-6.628A.79.79 0 0 0 0-7.79a.791.791 0 0 0-.581-.239h-9.36c-.23 0-.342-.114-.342-.342v-9.224a.789.789 0 0 0-.24-.581.79.79 0 0 0-.581-.239h-6.559a.786.786 0 0 0-.581.239.788.788 0 0 0-.239.581v9.224c0 .228-.115.342-.341.342h-9.363a.786.786 0 0 0-.58.239.786.786 0 0 0-.239.581v6.628c0 .227.079.421.239.581a.79.79 0 0 0 .58.239h9.363c.226 0 .341.113.341.342v9.43c0 .227.079.42.239.58a.79.79 0 0 0 .581.239h6.559c.228 0 .421-.08.581-.239a.79.79 0 0 0 .24-.58V.581c0-.229.112-.342.342-.342h9.36C-.354.239-.16.159 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 697.363 190.16)"
      clipPath="url(#mr_z)"
    >
      <path
        className={ hoverSelected === "m2" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("m2")}
        onMouseOut={() => setHoverSelected("")}
        d="M0 0c2.003-2.142 3.007-5.148 3.007-9.02v-22.617a.787.787 0 0 0-.24-.581.787.787 0 0 0-.581-.239h-7.994a.784.784 0 0 0-.581.239.787.787 0 0 0-.24.581v20.499c0 1.867-.478 3.348-1.435 4.442-.957 1.093-2.232 1.639-3.826 1.639-1.595 0-2.893-.546-3.894-1.639-1.004-1.094-1.504-2.552-1.504-4.374v-20.567a.79.79 0 0 0-.239-.581.787.787 0 0 0-.581-.239h-7.926a.786.786 0 0 0-.582.239.786.786 0 0 0-.239.581v20.499c0 1.821-.502 3.291-1.502 4.408-1.003 1.115-2.301 1.673-3.896 1.673-1.458 0-2.676-.433-3.656-1.298-.979-.866-1.56-2.073-1.741-3.621v-21.661a.787.787 0 0 0-.24-.581.787.787 0 0 0-.581-.239h-7.994a.784.784 0 0 0-.581.239.787.787 0 0 0-.24.581V1.845c0 .228.08.42.24.581a.792.792 0 0 0 .581.239h7.994c.228 0 .42-.08.581-.239a.792.792 0 0 0 .24-.581V-.82c0-.137.044-.228.136-.273.091-.046.181 0 .273.136 1.868 2.779 4.76 4.169 8.678 4.169 2.278 0 4.271-.479 5.979-1.436 1.708-.956 3.018-2.323 3.929-4.1.137-.272.296-.272.479 0 1.001 1.868 2.357 3.257 4.065 4.169 1.709.911 3.633 1.367 5.774 1.367C-4.783 3.212-2.006 2.141 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          pointerEvents: "bounding-box",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 718.54 257.242)"
      clipPath="url(#mr_A)"
    >
      <path
        className={ hoverSelected === "m2" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("m2")}
        onMouseOut={() => setHoverSelected("")}
        d="M0 0c.027-.053.08-.079.16-.079h10.796a.457.457 0 0 0 .337-.14.458.458 0 0 0 .14-.339v-3.864a.454.454 0 0 0-.14-.338.454.454 0 0 0-.337-.14H-6.972a.457.457 0 0 0-.338.14.458.458 0 0 0-.14.338v3.665c0 .239.08.438.239.598a94.356 94.356 0 0 1 3.984 4.163c1.355 1.5 2.205 2.436 2.55 2.808.744.877 1.5 1.713 2.271 2.51 2.416 2.682 3.624 4.674 3.624 5.975 0 .93-.332 1.694-.995 2.291-.664.598-1.527.896-2.589.896-1.063 0-1.926-.298-2.59-.896-.664-.597-.997-1.388-.997-2.37v-.996a.464.464 0 0 0-.138-.339.463.463 0 0 0-.339-.139h-4.701a.463.463 0 0 0-.339.139.461.461 0 0 0-.139.339v1.872c.081 1.435.532 2.702 1.355 3.805.822 1.102 1.918 1.945 3.286 2.529 1.368.584 2.901.877 4.602.877 1.885 0 3.526-.353 4.919-1.056 1.395-.704 2.47-1.654 3.227-2.848.757-1.195 1.136-2.524 1.136-3.984 0-1.115-.279-2.258-.837-3.426-.558-1.169-1.395-2.43-2.509-3.785a47.872 47.872 0 0 0-2.669-3.067C3.944 4.129 2.522 2.669.638.757L.04.159C-.013.106-.027.053 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          pointerEvents: "bounding-box",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 774.136 164.923)"
      clipPath="url(#mr_B)"
    >
      <path
        d="M0 0c2.687-3.463 4.827-7.585 6.423-12.368 1.594-4.784 2.392-10.159 2.392-16.126 0-5.969-.81-11.367-2.426-16.195-1.618-4.829-3.771-8.974-6.457-12.436a1.298 1.298 0 0 0-1.025-.478h-7.722c-.319 0-.537.091-.649.273-.114.183-.102.41.034.683 4.964 8.381 7.448 17.766 7.448 28.153 0 10.203-2.484 19.542-7.448 28.016-.092.181-.136.318-.136.409 0 .365.249.547.751.547h7.79C-.615.478-.274.318 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 824.29 233.026)"
      clipPath="url(#mr_C)"
    >
      <path
        d="M0 0c-.115.205-.08.444.103.717l11.001 16.332a.34.34 0 0 1 0 .409L.103 33.79a1.09 1.09 0 0 0-.138.478c0 .364.228.547.684.547H9.19c.411 0 .729-.183.957-.547l6.15-9.361c.181-.274.341-.274.479 0l6.15 9.361c.227.364.545.547.955.547h9.157c.319 0 .523-.103.615-.308.091-.205.045-.444-.136-.717L22.584 17.458a.61.61 0 0 1 0-.409L33.517.717a.843.843 0 0 0 .136-.478c0-.365-.205-.547-.615-.547h-8.61c-.409 0-.728.182-.957.547L17.322 9.6c-.136.274-.274.274-.41 0L10.762.239c-.228-.365-.547-.547-.957-.547H.649C.33-.308.113-.205 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 919.361 241.09)"
      clipPath="url(#mr_D)"
    >
      <path
        d="M0 0c-2.689 3.463-4.83 7.573-6.423 12.334-1.595 4.76-2.392 10.124-2.392 16.092 0 5.967.807 11.377 2.425 16.229 1.617 4.851 3.769 9.008 6.458 12.47.274.319.615.479 1.025.479h7.721c.319 0 .535-.092.65-.274.113-.183.102-.41-.035-.683-4.966-8.473-7.448-17.858-7.448-28.153C1.981 18.199 4.463 8.86 9.429.478c.091-.181.137-.318.137-.409 0-.365-.251-.547-.752-.547H1.025C.614-.478.272-.318 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 978.669 222.23)"
      clipPath="url(#mr_E)"
    >
      <path
        d="M0 0c.045-.091.137-.137.274-.137h18.517c.228 0 .422-.08.582-.239a.793.793 0 0 0 .239-.58v-6.629a.793.793 0 0 0-.239-.581.792.792 0 0 0-.582-.239h-30.749a.788.788 0 0 0-.581.239.788.788 0 0 0-.238.581v6.287c0 .41.136.751.409 1.025a160.686 160.686 0 0 1 6.833 7.14 700.681 700.681 0 0 0 4.374 4.818 89.098 89.098 0 0 0 3.894 4.305c4.145 4.6 6.219 8.017 6.219 10.249 0 1.595-.571 2.904-1.708 3.929-1.14 1.025-2.621 1.538-4.441 1.538-1.823 0-3.305-.513-4.442-1.538-1.14-1.025-1.708-2.38-1.708-4.065v-1.709a.786.786 0 0 0-.24-.58.792.792 0 0 0-.581-.24h-8.062a.79.79 0 0 0-.582.24.786.786 0 0 0-.239.58v3.212c.137 2.46.912 4.634 2.324 6.526 1.412 1.889 3.291 3.336 5.637 4.338 2.346 1.002 4.977 1.504 7.893 1.504 3.233 0 6.047-.604 8.438-1.811 2.392-1.207 4.236-2.836 5.535-4.886 1.298-2.05 1.947-4.328 1.947-6.833 0-1.913-.478-3.872-1.435-5.876-.957-2.005-2.391-4.169-4.305-6.491a79.96 79.96 0 0 0-4.578-5.262c-1.64-1.732-4.077-4.237-7.311-7.516L.069.274C-.023.182-.045.091 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g transform="" clip-path="url(#mr_F)">
    <text d=""
    style={{
    fill: "rgb(220, 68, 124)",
    fillOpacity: 1,
    fillRule: "nonzero",
    stroke: "none",
    fontSize: "92px",
    fontWeight: 800}}
        x="1008" y="233.23">7
    </text></g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 1077.157 233.026)"
      clipPath="url(#mr_G)"
    >
      <path
        d="M0 0c-.115.205-.08.444.103.717l11.001 16.332a.34.34 0 0 1 0 .409L.103 33.79a1.09 1.09 0 0 0-.138.478c0 .364.228.547.684.547H9.19c.411 0 .729-.183.957-.547l6.15-9.361c.181-.274.341-.274.479 0l6.15 9.361c.226.364.545.547.955.547h9.157c.319 0 .523-.103.615-.308.091-.205.045-.444-.136-.717L22.584 17.458a.61.61 0 0 1 0-.409L33.517.717a.843.843 0 0 0 .136-.478c0-.365-.205-.547-.615-.547h-8.61c-.409 0-.728.182-.957.547L17.322 9.6c-.136.274-.274.274-.41 0L10.762.239c-.228-.365-.547-.547-.957-.547H.649C.33-.308.113-.205 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
        className={ hoverSelected === "ms" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("ms")}
        onMouseOut={() => setHoverSelected("")}
        style={{
          pointerEvents: "bounding-box"
          }}
      transform="matrix(1.33333 0 0 -1.33333 1190.175 169.66)"
      clipPath="url(#mr_H)"
    >
      <path
        d="M0 0h7.994c.228 0 .422-.08.582-.239a.795.795 0 0 0 .239-.581v-46.192a.792.792 0 0 0-.239-.581.792.792 0 0 0-.582-.239H0a.788.788 0 0 0-.581.239.787.787 0 0 0-.238.581v30.134c0 .182-.047.274-.138.274-.092 0-.183-.069-.273-.205l-7.243-11.344c-.229-.365-.546-.546-.957-.546h-4.031c-.411 0-.73.181-.957.546l-7.242 11.344c-.093.136-.183.193-.274.171-.092-.024-.138-.127-.138-.308v-30.066a.795.795 0 0 0-.238-.581.793.793 0 0 0-.581-.239h-7.994a.788.788 0 0 0-.582.239.788.788 0 0 0-.239.581V-.82c0 .227.079.421.239.581.16.159.352.239.582.239h7.994c.41 0 .729-.183.957-.547l10.25-15.921c.136-.273.273-.273.409 0L-.957-.547C-.729-.183-.41 0 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 1220.905 231.842)"
      clipPath="url(#mr_I)"
    >
      <path
        className={ hoverSelected === "ms" ? "shadowSvgElement" : "" }
        onMouseOver={() => setHoverSelected("ms")}
        onMouseOut={() => setHoverSelected("")}
        d="M0 0c-2.733 1.162-4.841 2.824-6.321 4.988-1.48 2.163-2.22 4.681-2.22 7.551v1.503c0 .227.078.421.239.581.16.159.352.239.581.239H.068c.228 0 .422-.08.582-.239a.795.795 0 0 0 .239-.581v-1.025c0-1.822.842-3.36 2.528-4.612 1.685-1.254 3.963-1.88 6.833-1.88 2.414 0 4.213.513 5.398 1.538 1.184 1.025 1.776 2.289 1.776 3.792 0 1.094-.364 2.016-1.093 2.768-.728.752-1.72 1.411-2.972 1.981-1.253.57-3.245 1.356-5.979 2.358-3.052 1.048-5.637 2.118-7.755 3.212a15.55 15.55 0 0 0-5.33 4.475c-1.435 1.89-2.153 4.225-2.153 7.004 0 2.733.706 5.125 2.118 7.175s3.371 3.621 5.877 4.715c2.504 1.093 5.398 1.64 8.678 1.64 3.462 0 6.548-.615 9.259-1.846 2.71-1.229 4.828-2.949 6.354-5.158 1.526-2.21 2.289-4.773 2.289-7.688v-1.025a.79.79 0 0 0-.239-.58.79.79 0 0 0-.58-.24H18.04a.789.789 0 0 0-.581.24.786.786 0 0 0-.239.58v.547c0 1.914-.786 3.542-2.358 4.886-1.571 1.343-3.723 2.016-6.457 2.016-2.142 0-3.815-.456-5.022-1.367-1.208-.912-1.811-2.164-1.811-3.758 0-1.139.352-2.096 1.058-2.87.706-.776 1.754-1.47 3.145-2.084 1.388-.615 3.54-1.447 6.457-2.494 3.233-1.186 5.773-2.233 7.619-3.143 1.844-.913 3.507-2.267 4.987-4.066 1.48-1.801 2.221-4.134 2.221-7.004 0-4.283-1.571-7.665-4.714-10.147-3.144-2.484-7.404-3.724-12.779-3.724C5.922-1.742 2.733-1.162 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          pointerEvents: "bounding-box",
          stroke: "none",
        }}
      />
    </g>
    <g
      transform="matrix(1.33333 0 0 -1.33333 1298.62 164.923)"
      clipPath="url(#mr_J)"
    >
      <path
        d="M0 0c2.687-3.463 4.827-7.585 6.423-12.368 1.594-4.784 2.392-10.159 2.392-16.126 0-5.969-.81-11.367-2.426-16.195-1.618-4.829-3.77-8.974-6.457-12.436a1.298 1.298 0 0 0-1.025-.478h-7.722c-.319 0-.536.091-.649.273-.114.183-.102.41.034.683 4.965 8.381 7.448 17.766 7.448 28.153 0 10.203-2.483 19.542-7.448 28.016-.092.181-.136.318-.136.409 0 .365.249.547.751.547h7.79C-.615.478-.274.318 0 0"
        style={{
          fill: "#dc447c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
  </svg>
)}

export default SvgComponent
