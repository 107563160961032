import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="pies"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 807.56 807.56"
    {...props}
  ><g >
    <defs>
      <pattern
        id="Motivo_nuevo_2Pies"
        x={0}
        y={0}
        width={179.4}
        height={310.5}
        patternTransform="translate(-6422 2918.1)"
        patternUnits="userSpaceOnUse"
      >
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h179.4v310.5H0z"
        />
        <path className="cls-pies-1-2" d="m179.4 207.01-89.63 51.74v103.5" />
        <path className="cls-pies-1-2" d="m194.34 215.63-89.63 51.75v103.49" />
        <path className="cls-pies-1-2" d="M209.28 224.25 119.65 276v103.5" />
        <path className="cls-pies-1-2" d="m224.21 232.88-89.63 51.75v103.49" />
        <path className="cls-pies-1-2" d="m239.15 241.5-89.63 51.75v103.5" />
        <path className="cls-pies-1-2" d="m254.09 250.13-89.63 51.75v103.49" />
        <path
          className="cls-pies-1-2"
          d="M269.03 258.75 179.4 310.5V414m0-103.5 89.63-51.75m0 17.25-89.63 51.75m89.63-34.5L179.4 345m89.63-34.5-89.63 51.75"
        />
        <circle className="cls-pies-1-4" cx={179.32} cy={310.53} r={18.08} />
        <path d="M168.5 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-pies-1-2" d="m0 207.01-89.63 51.74v103.5" />
        <path className="cls-pies-1-2" d="m14.94 215.63-89.63 51.75v103.49" />
        <path className="cls-pies-1-2" d="M29.88 224.25-59.75 276v103.5" />
        <path className="cls-pies-1-2" d="m44.81 232.88-89.63 51.75v103.49" />
        <path className="cls-pies-1-2" d="m59.75 241.5-89.63 51.75v103.5" />
        <path className="cls-pies-1-2" d="m74.69 250.13-89.63 51.75v103.49" />
        <path
          className="cls-pies-1-2"
          d="M89.63 258.75 0 310.5V414m0-103.5 89.63-51.75m0 17.25L0 327.75m89.63-34.5L0 345m89.63-34.5L0 362.25"
        />
        <circle className="cls-pies-1-4" cx={-0.08} cy={310.53} r={18.08} />
        <path d="M-10.9 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-pies-1-2"
          d="m269.1 51.76-89.63 51.74V207M89.7 51.76.07 103.5V207"
        />
        <path className="cls-pies-1-2" d="m104.64 60.38-89.63 51.75v103.49" />
        <path className="cls-pies-1-2" d="m119.58 69-89.63 51.75v103.5" />
        <path className="cls-pies-1-2" d="m134.51 77.63-89.63 51.75v103.49" />
        <path className="cls-pies-1-2" d="M149.45 86.25 59.82 138v103.5" />
        <path className="cls-pies-1-2" d="m164.39 94.88-89.63 51.75v103.49" />
        <path
          className="cls-pies-1-2"
          d="M179.33 103.5 89.7 155.25v103.5m0-103.5 89.63-51.75m0 17.25L89.7 172.5m89.63-34.5L89.7 189.75m89.63-34.5L89.7 207m89.63-34.5L89.7 224.25m89.63-34.5L89.7 241.5m89.63-34.5L89.7 258.75"
        />
        <circle className="cls-pies-1-4" cx={179.62} cy={207.28} r={18.08} />
        <path d="M168.8 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-pies-1-4" cx={89.62} cy={155.28} r={18.08} />
        <path d="M78.8 173.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-pies-1-2"
          d="m-.07 103.5-89.63 51.75v103.5m0-103.5L-.07 103.5m0 17.25L-89.7 172.5M-.07 138l-89.63 51.75m89.63-34.5L-89.7 207m89.63-34.5-89.63 51.75m89.63-34.5L-89.7 241.5M-.07 207l-89.63 51.75"
        />
        <circle className="cls-pies-1-4" cx={0.22} cy={207.28} r={18.08} />
        <path d="M-10.6 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-pies-1-2" d="M179.4-103.49 89.77-51.75v103.5" />
        <path className="cls-pies-1-2" d="m194.34-94.87-89.63 51.75V60.37" />
        <path className="cls-pies-1-2" d="M209.28-86.25 119.65-34.5V69" />
        <path className="cls-pies-1-2" d="m224.21-77.62-89.63 51.75V77.62" />
        <path className="cls-pies-1-2" d="m239.15-69-89.63 51.75v103.5" />
        <path className="cls-pies-1-2" d="M254.09-60.37 164.46-8.62V94.87" />
        <path
          className="cls-pies-1-2"
          d="M269.03-51.75 179.4 0v103.5m0-103.5 89.63-51.75m0 17.25L179.4 17.25m89.63-34.5L179.4 34.5M269.03 0 179.4 51.75m89.63-34.5L179.4 69m89.63-34.5L179.4 86.25m89.63-34.5L179.4 103.5"
        />
        <circle className="cls-pies-1-4" cx={179.32} cy={0.03} r={18.08} />
        <path d="M168.5 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-pies-1-2" d="m0-103.49-89.63 51.74v103.5" />
        <path className="cls-pies-1-2" d="m14.94-94.87-89.63 51.75V60.37" />
        <path className="cls-pies-1-2" d="M29.88-86.25-59.75-34.5V69" />
        <path className="cls-pies-1-2" d="m44.81-77.62-89.63 51.75V77.62" />
        <path className="cls-pies-1-2" d="m59.75-69-89.63 51.75v103.5" />
        <path className="cls-pies-1-2" d="M74.69-60.37-14.94-8.62V94.87" />
        <path
          className="cls-pies-1-2"
          d="M89.63-51.75 0 0v103.5M0 0l89.63-51.75m0 17.25L0 17.25m89.63-34.5L0 34.5M89.63 0 0 51.75m89.63-34.5L0 69m89.63-34.5L0 86.25m89.63-34.5L0 103.5"
        />
        <circle className="cls-pies-1-4" cx={89.92} cy={52.03} r={18.08} />
        <path d="M79.1 70.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-pies-1-4" cx={-0.08} cy={0.03} r={18.08} />
        <path d="M-10.9 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.78-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32ZM-4.72 8.91c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57C5.72-7.61 6.3-6.21 6.3-4.48v.06c0 .39-.19.58-.58.58L4.33-3.9c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
      </pattern>
      <pattern
        id="Copyright_3"
        x={0}
        y={0}
        width={179.4}
        height={310.5}
        patternTransform="translate(-6702.49 2738.6)"
        patternUnits="userSpaceOnUse"
      >
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h179.4v310.5H0z"
        />
        <path className="cls-2-2" d="m179.4 207.01-89.63 51.74v103.5" />
        <path className="cls-2-2" d="m194.34 215.63-89.63 51.75v103.49" />
        <path className="cls-2-2" d="M209.28 224.25 119.65 276v103.5" />
        <path className="cls-2-2" d="m224.21 232.88-89.63 51.75v103.49" />
        <path className="cls-2-2" d="m239.15 241.5-89.63 51.75v103.5" />
        <path className="cls-2-2" d="m254.09 250.13-89.63 51.75v103.49" />
        <path
          className="cls-2-2"
          d="M269.03 258.75 179.4 310.5V414m0-103.5 89.63-51.75m0 17.25-89.63 51.75m89.63-34.5L179.4 345m89.63-34.5-89.63 51.75"
        />
        <circle className="cls-2-4" cx={179.32} cy={310.53} r={18.08} />
        <path d="M168.5 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-2-2" d="m0 207.01-89.63 51.74v103.5" />
        <path className="cls-2-2" d="m14.94 215.63-89.63 51.75v103.49" />
        <path className="cls-2-2" d="M29.88 224.25-59.75 276v103.5" />
        <path className="cls-2-2" d="m44.81 232.88-89.63 51.75v103.49" />
        <path className="cls-2-2" d="m59.75 241.5-89.63 51.75v103.5" />
        <path className="cls-2-2" d="m74.69 250.13-89.63 51.75v103.49" />
        <path
          className="cls-2-2"
          d="M89.63 258.75 0 310.5V414m0-103.5 89.63-51.75m0 17.25L0 327.75m89.63-34.5L0 345m89.63-34.5L0 362.25"
        />
        <circle className="cls-2-4" cx={-0.08} cy={310.53} r={18.08} />
        <path d="M-10.9 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-2-2"
          d="m269.1 51.76-89.63 51.74V207M89.7 51.76.07 103.5V207"
        />
        <path className="cls-2-2" d="m104.64 60.38-89.63 51.75v103.49" />
        <path className="cls-2-2" d="m119.58 69-89.63 51.75v103.5" />
        <path className="cls-2-2" d="m134.51 77.63-89.63 51.75v103.49" />
        <path className="cls-2-2" d="M149.45 86.25 59.82 138v103.5" />
        <path className="cls-2-2" d="m164.39 94.88-89.63 51.75v103.49" />
        <path
          className="cls-2-2"
          d="M179.33 103.5 89.7 155.25v103.5m0-103.5 89.63-51.75m0 17.25L89.7 172.5m89.63-34.5L89.7 189.75m89.63-34.5L89.7 207m89.63-34.5L89.7 224.25m89.63-34.5L89.7 241.5m89.63-34.5L89.7 258.75"
        />
        <circle className="cls-2-4" cx={179.62} cy={207.28} r={18.08} />
        <path d="M168.8 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-2-4" cx={89.62} cy={155.28} r={18.08} />
        <path d="M78.8 173.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-2-2"
          d="m-.07 103.5-89.63 51.75v103.5m0-103.5L-.07 103.5m0 17.25L-89.7 172.5M-.07 138l-89.63 51.75m89.63-34.5L-89.7 207m89.63-34.5-89.63 51.75m89.63-34.5L-89.7 241.5M-.07 207l-89.63 51.75"
        />
        <circle className="cls-2-4" cx={0.22} cy={207.28} r={18.08} />
        <path d="M-10.6 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-2-2" d="M179.4-103.49 89.77-51.75v103.5" />
        <path className="cls-2-2" d="m194.34-94.87-89.63 51.75V60.37" />
        <path className="cls-2-2" d="M209.28-86.25 119.65-34.5V69" />
        <path className="cls-2-2" d="m224.21-77.62-89.63 51.75V77.62" />
        <path className="cls-2-2" d="m239.15-69-89.63 51.75v103.5" />
        <path className="cls-2-2" d="M254.09-60.37 164.46-8.62V94.87" />
        <path
          className="cls-2-2"
          d="M269.03-51.75 179.4 0v103.5m0-103.5 89.63-51.75m0 17.25L179.4 17.25m89.63-34.5L179.4 34.5M269.03 0 179.4 51.75m89.63-34.5L179.4 69m89.63-34.5L179.4 86.25m89.63-34.5L179.4 103.5"
        />
        <circle className="cls-2-4" cx={179.32} cy={0.03} r={18.08} />
        <path d="M168.5 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-2-2" d="m0-103.49-89.63 51.74v103.5" />
        <path className="cls-2-2" d="m14.94-94.87-89.63 51.75V60.37" />
        <path className="cls-2-2" d="M29.88-86.25-59.75-34.5V69" />
        <path className="cls-2-2" d="m44.81-77.62-89.63 51.75V77.62" />
        <path className="cls-2-2" d="m59.75-69-89.63 51.75v103.5" />
        <path className="cls-2-2" d="M74.69-60.37-14.94-8.62V94.87" />
        <path
          className="cls-2-2"
          d="M89.63-51.75 0 0v103.5M0 0l89.63-51.75m0 17.25L0 17.25m89.63-34.5L0 34.5M89.63 0 0 51.75m89.63-34.5L0 69m89.63-34.5L0 86.25m89.63-34.5L0 103.5"
        />
        <circle className="cls-2-4" cx={89.92} cy={52.03} r={18.08} />
        <path d="M79.1 70.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-2-4" cx={-0.08} cy={0.03} r={18.08} />
        <path d="M-10.9 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.78-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32ZM-4.72 8.91c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57C5.72-7.61 6.3-6.21 6.3-4.48v.06c0 .39-.19.58-.58.58L4.33-3.9c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
      </pattern>
      <pattern
        id="Copyright_2"
        x={0}
        y={0}
        width={179.4}
        height={310.5}
        patternTransform="translate(-6383.62 2738.6)"
        patternUnits="userSpaceOnUse"
      >
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h179.4v310.5H0z"
        />
        <path className="cls-3-2" d="m179.4 207.01-89.63 51.74v103.5" />
        <path className="cls-3-2" d="m194.34 215.63-89.63 51.75v103.49" />
        <path className="cls-3-2" d="M209.28 224.25 119.65 276v103.5" />
        <path className="cls-3-2" d="m224.21 232.88-89.63 51.75v103.49" />
        <path className="cls-3-2" d="m239.15 241.5-89.63 51.75v103.5" />
        <path className="cls-3-2" d="m254.09 250.13-89.63 51.75v103.49" />
        <path
          className="cls-3-2"
          d="M269.03 258.75 179.4 310.5V414m0-103.5 89.63-51.75m0 17.25-89.63 51.75m89.63-34.5L179.4 345m89.63-34.5-89.63 51.75"
        />
        <circle className="cls-3-4" cx={179.32} cy={310.53} r={18.08} />
        <path d="M168.5 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-3-2" d="m0 207.01-89.63 51.74v103.5" />
        <path className="cls-3-2" d="m14.94 215.63-89.63 51.75v103.49" />
        <path className="cls-3-2" d="M29.88 224.25-59.75 276v103.5" />
        <path className="cls-3-2" d="m44.81 232.88-89.63 51.75v103.49" />
        <path className="cls-3-2" d="m59.75 241.5-89.63 51.75v103.5" />
        <path className="cls-3-2" d="m74.69 250.13-89.63 51.75v103.49" />
        <path
          className="cls-3-2"
          d="M89.63 258.75 0 310.5V414m0-103.5 89.63-51.75m0 17.25L0 327.75m89.63-34.5L0 345m89.63-34.5L0 362.25"
        />
        <circle className="cls-3-4" cx={-0.08} cy={310.53} r={18.08} />
        <path d="M-10.9 328.72c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-3-2"
          d="m269.1 51.76-89.63 51.74V207M89.7 51.76.07 103.5V207"
        />
        <path className="cls-3-2" d="m104.64 60.38-89.63 51.75v103.49" />
        <path className="cls-3-2" d="m119.58 69-89.63 51.75v103.5" />
        <path className="cls-3-2" d="m134.51 77.63-89.63 51.75v103.49" />
        <path className="cls-3-2" d="M149.45 86.25 59.82 138v103.5" />
        <path className="cls-3-2" d="m164.39 94.88-89.63 51.75v103.49" />
        <path
          className="cls-3-2"
          d="M179.33 103.5 89.7 155.25v103.5m0-103.5 89.63-51.75m0 17.25L89.7 172.5m89.63-34.5L89.7 189.75m89.63-34.5L89.7 207m89.63-34.5L89.7 224.25m89.63-34.5L89.7 241.5m89.63-34.5L89.7 258.75"
        />
        <circle className="cls-3-4" cx={179.62} cy={207.28} r={18.08} />
        <path d="M168.8 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-3-4" cx={89.62} cy={155.28} r={18.08} />
        <path d="M78.8 173.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path
          className="cls-3-2"
          d="m-.07 103.5-89.63 51.75v103.5m0-103.5L-.07 103.5m0 17.25L-89.7 172.5M-.07 138l-89.63 51.75m89.63-34.5L-89.7 207m89.63-34.5-89.63 51.75m89.63-34.5L-89.7 241.5M-.07 207l-89.63 51.75"
        />
        <circle className="cls-3-4" cx={0.22} cy={207.28} r={18.08} />
        <path d="M-10.6 225.47c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-3-2" d="M179.4-103.49 89.77-51.75v103.5" />
        <path className="cls-3-2" d="m194.34-94.87-89.63 51.75V60.37" />
        <path className="cls-3-2" d="M209.28-86.25 119.65-34.5V69" />
        <path className="cls-3-2" d="m224.21-77.62-89.63 51.75V77.62" />
        <path className="cls-3-2" d="m239.15-69-89.63 51.75v103.5" />
        <path className="cls-3-2" d="M254.09-60.37 164.46-8.62V94.87" />
        <path
          className="cls-3-2"
          d="M269.03-51.75 179.4 0v103.5m0-103.5 89.63-51.75m0 17.25L179.4 17.25m89.63-34.5L179.4 34.5M269.03 0 179.4 51.75m89.63-34.5L179.4 69m89.63-34.5L179.4 86.25m89.63-34.5L179.4 103.5"
        />
        <circle className="cls-3-4" cx={179.32} cy={0.03} r={18.08} />
        <path d="M168.5 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <path className="cls-3-2" d="m0-103.49-89.63 51.74v103.5" />
        <path className="cls-3-2" d="m14.94-94.87-89.63 51.75V60.37" />
        <path className="cls-3-2" d="M29.88-86.25-59.75-34.5V69" />
        <path className="cls-3-2" d="m44.81-77.62-89.63 51.75V77.62" />
        <path className="cls-3-2" d="m59.75-69-89.63 51.75v103.5" />
        <path className="cls-3-2" d="M74.69-60.37-14.94-8.62V94.87" />
        <path
          className="cls-3-2"
          d="M89.63-51.75 0 0v103.5M0 0l89.63-51.75m0 17.25L0 17.25m89.63-34.5L0 34.5M89.63 0 0 51.75m89.63-34.5L0 69m89.63-34.5L0 86.25m89.63-34.5L0 103.5"
        />
        <circle className="cls-3-4" cx={89.92} cy={52.03} r={18.08} />
        <path d="M79.1 70.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.77-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32Zm-13.77-6.73c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57c1.16 1.04 1.74 2.44 1.74 4.17v.06c0 .39-.19.58-.58.58l-1.39-.06c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
        <circle className="cls-3-4" cx={-0.08} cy={0.03} r={18.08} />
        <path d="M-10.9 18.22c-3.19-1.8-5.69-4.28-7.51-7.45-1.82-3.17-2.72-6.71-2.72-10.61s.91-7.44 2.72-10.61c1.82-3.17 4.32-5.65 7.51-7.45s6.78-2.7 10.76-2.7 7.58.9 10.79 2.7c3.21 1.8 5.71 4.28 7.51 7.45 1.8 3.17 2.7 6.71 2.7 10.61s-.9 7.44-2.7 10.61c-1.8 3.17-4.3 5.65-7.51 7.45-3.21 1.8-6.8 2.7-10.79 2.7s-7.57-.9-10.76-2.7Zm19.95-2.58c2.72-1.55 4.86-3.67 6.41-6.38 1.55-2.71 2.32-5.72 2.32-9.05s-.77-6.41-2.32-9.13a16.727 16.727 0 0 0-6.41-6.41c-2.73-1.55-5.79-2.32-9.19-2.32s-6.52.77-9.25 2.32c-2.73 1.55-4.85 3.68-6.38 6.41-1.53 2.73-2.29 5.77-2.29 9.13s.76 6.34 2.29 9.05c1.53 2.71 3.65 4.83 6.38 6.38 2.72 1.55 5.81 2.32 9.25 2.32s6.47-.77 9.19-2.32ZM-4.72 8.91c-1.16-1.08-1.74-2.53-1.74-4.35v-8.87c0-1.82.58-3.26 1.74-4.32 1.16-1.06 2.71-1.59 4.64-1.59s3.48.52 4.64 1.57C5.72-7.61 6.3-6.21 6.3-4.48v.06c0 .39-.19.58-.58.58L4.33-3.9c-.39 0-.58-.19-.58-.58v-.06c0-1.04-.36-1.88-1.07-2.52-.72-.64-1.63-.96-2.75-.96s-2.09.32-2.78.96c-.7.64-1.04 1.48-1.04 2.52v9.39c0 1.04.35 1.88 1.04 2.52.7.64 1.62.96 2.78.96s2.04-.32 2.75-.96c.71-.64 1.07-1.48 1.07-2.52v-.06c0-.39.19-.58.58-.58l1.39-.06c.39 0 .58.19.58.58 0 1.74-.59 3.14-1.77 4.2-1.18 1.06-2.72 1.59-4.61 1.59s-3.48-.54-4.64-1.62Z" />
      </pattern>
      <style>
        {
          ".cls-pies-1-2{fill:none}.cls-pies-1-4{fill:#d4155b;stroke:#d4155b;stroke-miterlimit:10}.cls-pies-1-2{stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}.cls-2-2{fill:none}.cls-2-4{fill:#e44083;stroke:#e44083;stroke-miterlimit:10}.cls-2-2{stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}.cls-3-2{fill:none}.cls-3-4{fill:#d380e3;stroke:#d380e3;stroke-miterlimit:10}.cls-3-2{stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}"
        }
      </style>
    </defs>
    <g id="Layer_1-2">
      <path
        d="M248.08 286.51 0 143.28C79.1 6.27 254.3-40.68 391.31 38.43c45.62 26.34 78.51 59.23 104.85 104.85L248.08 286.51Z"
        style={{
          fill: "#d4155b",
        }}
        transform="translate(56 16)"
      />
      <path
        d="M248.08 286.51 0 143.28C79.1 6.27 254.3-40.68 391.31 38.43c45.62 26.34 78.51 59.23 104.85 104.85L248.08 286.51Z"
        style={{
          fill: "url(#Motivo_nuevo_2Pies)",
        }}
        transform="translate(56 16)"
      />
    </g>
    <g id="Layer_1-3" style={{  }}>
      <path
        d="M0 143.23 248.08 0c79.1 137.01 32.16 312.21-104.85 391.31C97.61 417.65 52.68 429.69 0 429.69V143.23Z"
        style={{
          fill: "#e44083",
        }}
        transform="translate(304 159)"
      />
      <path
        d="M0 143.23 248.08 0c79.1 137.01 32.16 312.21-104.85 391.31C97.61 417.65 52.68 429.69 0 429.69V143.23Z"
        style={{
          fill: "url(#Copyright_3)",
        }}
        transform="translate(304 159)"
      />
    </g>
    <g id="Layer_1-4" style={{  }}>
      <path
        d="M286.46 143.23v286.46C128.25 429.69 0 301.44 0 143.23 0 90.55 12.04 45.62 38.38 0l248.08 143.23Z"
        style={{
          fill: "#d380e3",
        }}
        transform="translate(18 159)"
      />
      <path
        d="M286.46 143.23v286.46C128.25 429.69 0 301.44 0 143.23 0 90.55 12.04 45.62 38.38 0l248.08 143.23Z"
        style={{
          fill: "url(#Copyright_2)",
        }}
        transform="translate(18 159)"
      />
    </g>
    </g>
  </svg>
)

export default SvgComponent
