import React from 'react';
import { FlexGrid, Grid, Column, Button, Modal, StructuredListBody, StructuredListCell, StructuredListHead, StructuredListRow, StructuredListWrapper } from '@carbon/react';
import { Add } from '@carbon/react/icons';
import { Routes, Route } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import iconEnforcement from '../assets/Icon-Enforcement.svg';
import iconLicensing from '../assets/Icon-Licensing.svg';
import iconUsing from '../assets/Icon-Using.svg';
import iconProtect from '../assets/Icon-Protect.svg';
import { translationMap, getTranslationMap } from '../features/translationMap';

import { Container, Row, Col } from 'react-grid-system';

       class ModalHistory extends React.Component {
    state = {
        width: window.innerWidth,
                height: window.innerHeight ,
                vennState: 'initial',
                legendState: 'initial',
    };

    render() {
        const { open, isFullWidth, sidebarOpen, selectedTrans, setSelectedModal, modalHeading, modalLabel } = this.props;

        return <div id="modalQuicklinks" className={ sidebarOpen ? "modal-wrapper cds--nav-expanded" : "modal-wrapper" }>
     <Modal
     style={{ background: 'transparent', pointerEvents: 'all'}}
           open={open && setSelectedModal !== ""}
           onRequestClose={() => setSelectedModal("") }
           passiveModal
           >
           <div className="modal__body--wrapper">

           <Container style={{ paddingLeft: 0, paddingTop: 16, marginLeft: 16 }}>
             <Row>

           <Col sm={6}>

                      <StructuredListWrapper>
                        <StructuredListHead>
                          <StructuredListRow head>
                            <StructuredListCell head noWrap>

              <div className="qlIconWrapper"><img  src={iconProtect} style={{ height: 22, marginLeft: '1px' }} alt="CopyrightUserlogo"/></div><span className="qlTitle notranslate"> {getTranslationMap(selectedTrans, "Protection")} </span>
                            </StructuredListCell>
                          </StructuredListRow>
                        </StructuredListHead>
                        <StructuredListBody style={{ textAlign: 'left' }}>
           <Container style={{ paddingLeft: 0, paddingTop: 16, marginLeft: 16 }}>
             <Row>
                   <Col sm={6}>
                   </Col>
             </Row>



           </Container>
                        </StructuredListBody>
                      </StructuredListWrapper>

                      </Col>


               <Col sm={6}>

           <StructuredListWrapper>
             <StructuredListHead>
               <StructuredListRow head>
                 <StructuredListCell head noWrap>

   <div className="qlIconWrapper"><img  src={iconUsing} style={{ height: 22, margin: '0px' }} alt="CopyrightUserlogo"/></div><span className="qlTitle notranslate"> {getTranslationMap(selectedTrans, "Use")} </span>
                 </StructuredListCell>
               </StructuredListRow>
             </StructuredListHead>
             <StructuredListBody style={{ textAlign: 'left' }}>
<Container style={{ paddingLeft: 0, paddingTop: 16, marginLeft: 16 }}>
  <Row>
    <Col sm={6}>
    </Col>
  </Row>
</Container>
             </StructuredListBody>
           </StructuredListWrapper>

           </Col>
             </Row>
<br/>
<br/>


             <Row>
                            <Col sm={6}>

                        <StructuredListWrapper>
                          <StructuredListHead>
                            <StructuredListRow head>
                              <StructuredListCell head noWrap>

                <div className="qlIconWrapper"><img  src={iconLicensing} style={{ height: 22, margin: '0px' }} alt="CopyrightUserlogo"/></div><span className="qlTitle notranslate"> {getTranslationMap(selectedTrans, "Exploitation")} </span>
                              </StructuredListCell>
                            </StructuredListRow>
                          </StructuredListHead>
                          <StructuredListBody style={{ textAlign: 'left' }}>
             <Container style={{ paddingLeft: 0, paddingTop: 16, marginLeft: 16 }}>
               <Row>
                     <Col sm={6}>
                     </Col>
               </Row>
             </Container>
                          </StructuredListBody>
                        </StructuredListWrapper>

                        </Col>

                        <Col sm={6}>

                                   <StructuredListWrapper>
                                     <StructuredListHead>
                                       <StructuredListRow head>
                                         <StructuredListCell head noWrap>

                           <div className="qlIconWrapper notranslate"><img  src={iconEnforcement} style={{ height: 22, marginLeft: '0px' }} alt="CopyrightUserlogo"/></div><span className="qlTitle">
                           {getTranslationMap(selectedTrans, "Enforcement")}</span>
                                         </StructuredListCell>
                                       </StructuredListRow>
                                     </StructuredListHead>
                                     <StructuredListBody style={{ textAlign: 'left' }}>
                        <Container style={{ paddingLeft: 0, paddingTop: 16, marginLeft: 16 }}>
                          <Row>
                                <Col sm={6}>
                                </Col>
                          </Row>



                        </Container>
                                     </StructuredListBody>
                                   </StructuredListWrapper>

                                   </Col>
                          </Row>
                          <br />
                          <br />
                          <hr />
                          <Row>
                                <Col sm={12} style={{ textAlign: 'left' }}>
                                 </Col>

                          </Row>
           </Container>

           </div>


         </Modal>

    </div>
    }
}


export default withTranslation()(ModalHistory);